import React from 'react';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { ConfluenceIcon } from '../../../icons/product-icons/ConfluenceIcon';
import { JiraCloudIcon } from '../../../icons/product-icons/JiraCloudIcon';
import { JiraServiceManagementIcon } from '../../../icons/product-icons/JiraServiceManagementIcon';
import { ConnectionDialog } from '../../ConnectionDialog';

export interface AtlassianCloudManageConnectionDialogProps {
    readonly instanceUrl?: string;
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly authorizeLoading: boolean;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
}

export const AtlassianCloudManageConnectionDialog: React.FC<AtlassianCloudManageConnectionDialogProps> = ({
    name,
    saving,
    onSave,
    authorizeLoading,
    onAuthorize,
    instanceUrl,
    open,
    onCancel,
    authorized,
    errors,
    connectionType,
}) => {
    return (
        <ConnectionDialog
            authorized={authorized}
            name={name}
            onSave={onSave}
            onAuthorize={onAuthorize}
            instanceUrl={instanceUrl}
            saving={!!saving}
            authorizeLoading={!!authorizeLoading}
            icon={getAtlassianCloudIcon(connectionType)}
            open={open}
            onCancel={onCancel}
            errors={errors}
            connectionType={connectionType}
        />
    );
};

export const getAtlassianCloudIcon = (type?: string): JSX.Element => {
    switch (type) {
        case 'Jira Cloud':
            return <JiraCloudIcon fontSize="inherit" />;
        case 'Confluence Cloud':
            return <ConfluenceIcon fontSize="inherit" />;
        case 'Jira Service Management Cloud':
            return <JiraServiceManagementIcon fontSize="inherit" />;
        default:
            return <HelpOutlinedIcon fontSize="inherit" />;
    }
};
