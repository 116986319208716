import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import { SetupGuideReviewScreenItem } from '../SetupGuideReviewScreenItem';
import { StyledListItemLeftContainer, StyledSetupGuideReviewScreenListContainer } from '../../SetupGuideComponents';
import { Language } from '../../../workspace-dialogs/language-selector';
import { SetupGuideScript } from '../../types';

export interface SetupGuideReviewScreenScriptsProps {
    language: Language;
    scripts: SetupGuideScript[];
    onEdit: () => void;
}

export const SetupGuideReviewScreenScripts: React.FC<SetupGuideReviewScreenScriptsProps> = ({
    language,
    scripts,
    onEdit,
}) => {
    return (
        <SetupGuideReviewScreenItem itemCount={scripts.length} itemName="Scripts" onEdit={onEdit}>
            {scripts.map((c) => {
                return (
                    <StyledSetupGuideReviewScreenListContainer key={c.uid}>
                        <StyledListItemLeftContainer>
                            <SetupGuideIndicator
                                left={{ icon: <CodeOutlinedIcon /> }}
                                right={{
                                    icon: (
                                        <Box
                                            component="img"
                                            src={process.env.PUBLIC_URL + (language === 'js' ? '/js.png' : '/ts.png')}
                                            alt={language === 'js' ? 'js' : 'ts' + ' icon'}
                                        />
                                    ),
                                }}
                            />
                            <Typography>{c.path}</Typography>
                        </StyledListItemLeftContainer>
                    </StyledSetupGuideReviewScreenListContainer>
                );
            })}
        </SetupGuideReviewScreenItem>
    );
};
