import { styled } from '@mui/material';
import Link from '@mui/material/Link';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface InfoIconProps extends SvgIconProps {
    'aria-label'?: string;
    href?: string;
    tooltip?: string;
    tooltipPlacement?: TooltipProps['placement'];
}

export const StyledInfoIcon = styled(InfoOutlinedIcon, { shouldForwardProp: (prop) => prop !== 'clickable' })<{
    clickable?: boolean;
}>(({ clickable, theme }) => ({
    color: theme.palette.primary.dark,
    cursor: clickable ? 'pointer' : undefined,
    height: 20,
    width: 20,
}));

export const InfoIcon: React.FC<InfoIconProps> = ({
    'aria-label': ariaLabel = 'info',
    href,
    tooltip,
    tooltipPlacement = 'top',
    ...props
}) => {
    return href ? (
        <Tooltip placement={tooltipPlacement} title={tooltip}>
            <Link href={href} target="_blank">
                <StyledInfoIcon aria-label={ariaLabel} clickable {...props} />
            </Link>
        </Tooltip>
    ) : (
        <Tooltip placement={tooltipPlacement} title={tooltip}>
            <StyledInfoIcon aria-label={ariaLabel} {...props} />
        </Tooltip>
    );
};
