import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { getUserDisplayName } from '../../utils/user';
import { Button } from '../common/buttons/Button';
import { Alert } from '../common/alerts/Alert';

export interface AppBannersProps {
    isConnectedToFeedback?: boolean;
    impersonating?: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    notificationBanner?: {
        message: string;
        level: 'info' | 'warning' | 'error';
    };
    showRefreshSessionWarning?: boolean;
    sessionExpired?: boolean;
    onSetLoadTokens(loadTokens: boolean): void;
    onStopImpersonation?(): void;
}

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
}));

export const AppBanners: React.FC<AppBannersProps> = ({
    isConnectedToFeedback,
    impersonating,
    notificationBanner,
    showRefreshSessionWarning,
    onStopImpersonation,
    onSetLoadTokens,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={containerRef}>
            {!isConnectedToFeedback && (
                <Alert
                    severity="warning"
                    title="Connection to feedback service has been interrupted, you will not receive proper feedback from the app."
                    text=" Please try refreshing the browser, if the issue persists please contact support."
                />
            )}
            {impersonating && (
                <Alert
                    severity="warning"
                    title="Impersonation Warning"
                    text={`You are impersonating: ${getUserDisplayName(impersonating)} (${impersonating.email})`}
                    action={
                        <StyledButton variant="contained" onClick={onStopImpersonation}>
                            Stop Impersonating
                        </StyledButton>
                    }
                />
            )}
            {notificationBanner && (
                <Alert severity={notificationBanner.level} title="Notification" text={notificationBanner.message} />
            )}
            {showRefreshSessionWarning && (
                <Alert
                    severity="warning"
                    action={
                        <StyledButton
                            variant="contained"
                            onClick={() => {
                                onSetLoadTokens(true);
                            }}
                        >
                            REFRESH SESSION
                        </StyledButton>
                    }
                    title="Your session has expired and cannot be refreshed"
                    text="Please check your network connection and ensure you are online then click on REFRESH SESSION. If the issue persists, please contact support."
                />
            )}
        </div>
    );
};
