import { useEffect, useState } from 'react';
import { styled, Theme } from '@mui/material';
import cronstrue from 'cronstrue';
import Cron, { HEADER } from 'react-cron-generator';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { Dialog } from '../../../common/Dialog';
import { StyledBorderBox, StyledBorderBoxContent } from '../../../common/LayoutComponents';
import { StyledSuccessItem } from '../../SetupGuideComponents';
import { handleKeyDown } from '../../../../utils/handleKeyDown';

export interface CronBuilderDialogProps {
    open: boolean;
    onCancel(): void;
    onConfirm(cron: string): void;
}

const bgStyles = (theme: Theme) =>
    ({
        //backgroundColor: theme.palette.action.selected,
        backgroundColor: theme.palette.background.default,
        opacity: 1,
    } as const);

//Replicate Bootstrap styles with a couple of adjustments from figma. Theme not applicable besides margins.
export const StyledCronWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    minWidth: 600,
    '& .cron_builder': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.constants.borderRadius,
        padding: 0,
        '& .nav-tabs': {
            display: 'flex',
            justifyContent: 'flex-start',
            listStyle: 'none',
            margin: theme.spacing(1, 0, 0),
            padding: 0,
            position: 'relative',
            top: 1,
            '& .nav-item': {
                fontWeight: theme.typography.fontWeightBold,
                '& .nav-link': {
                    borderTopLeftRadius: theme.constants.borderRadius,
                    borderTopRightRadius: theme.constants.borderRadius,
                    color: theme.palette.text.secondary,
                    display: 'inline-block',
                    padding: theme.spacing(1, 1, 0.5, 1),
                    position: 'relative',
                    width: '100%',
                    '&:hover': {
                        // color: theme.palette.primary.main,
                        backgroundColor: theme.palette.action.hover,
                    },
                    '&.active': {
                        // color: theme.palette.text.primary,
                        ...bgStyles(theme),
                        border: '1px solid ' + theme.palette.action.selected,
                        borderBottom: theme.constants.borderHidden,
                    },
                },
            },
        },
        '& .cron_builder_bordering': {
            ...bgStyles(theme),
            border: '0 none',
            borderTop: '1px solid ' + theme.palette.action.selected,
            borderRadius: theme.constants.borderRadius,
            padding: theme.spacing(3, 2, 1),
            textAlign: 'left',
        },
        '& .container-fluid .row': {
            display: 'flex',
            '& .col-sm-6': {
                flex: '0 0 50%',
            },
        },
        '&.cron_builder': {
            '& .tab-content, .well, .tab-pane.active': {
                ...bgStyles(theme),
                border: '0 none',
                boxShadow: 'unset',
            },
            //hiding the two last options of the 'Monthly' tab
            '& .tab-pane > .well-small:nth-of-type(3), & .well-small:nth-of-type(4)': {
                display: 'none',
            },
        },
    },
}));

const defaultCron = '0 0 00 1/1 * ?';

export const CronBuilderDialog: React.FC<CronBuilderDialogProps> = ({ open = false, onCancel, onConfirm }) => {
    const [cronExpression, setCronExpression] = useState(defaultCron);

    useEffect(() => {
        setCronExpression(defaultCron);
    }, [open]);

    const cronDescription = cronstrue.toString(cronExpression);

    const cronBuilderHeaderOptions = {
        headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.MINUTES, HEADER.HOURLY, HEADER.DAILY],
    };

    const removeExtraSegment = (expr: string): string => {
        const tokens = expr.split(' ');
        return tokens.slice(0, tokens.length - 1).join(' ');
    };

    const buttons = [
        <Button variant="outlined" onClick={onCancel}>
            Cancel
        </Button>,
        <Button onClick={() => onConfirm(removeExtraSegment(cronExpression))}>Confirm</Button>,
    ];
    return (
        <Dialog
            buttons={buttons}
            open={open}
            title="Set up schedule"
            onClose={onCancel}
            onKeyDown={(event) =>
                handleKeyDown({ event, enterFn: () => onConfirm(removeExtraSegment(cronExpression)), escFn: onCancel })
            }
        >
            <StyledCronWrapper>
                <Cron
                    options={cronBuilderHeaderOptions}
                    showResultText={false}
                    showResultCron={false}
                    onChange={(v) => {
                        setCronExpression(v);
                    }}
                    value={cronExpression}
                />
            </StyledCronWrapper>
            <StyledBorderBox>
                <StyledBorderBoxContent>
                    <Typography variant="subtitle2">What is your proposed trigger schedule?</Typography>
                    <StyledSuccessItem>
                        <Typography variant="subtitle2">{cronDescription}</Typography>
                    </StyledSuccessItem>
                </StyledBorderBoxContent>
            </StyledBorderBox>
        </Dialog>
    );
};
