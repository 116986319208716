import { useObservableState } from 'observable-hooks';
import { CreateOrganizationWizardDialog } from '../../components/organizations/create-organization-wizard/CreateOrganizationWizardDialog';
import { billingEntities$ } from '../../store/billing-details';
import {
    applyDiscountInProgress$,
    applyOrganizationDiscountAction$,
    closeCreateOrganizationWizardDialogAction$,
    createOrganizationActionV2$,
    createOrganizationValidationError$,
    createOrganizationWizardDialogOpen$,
    createOrganizationWizardLoading$,
    createOrganizationWizardSaving$,
    discountCodeValidationError$,
    loadCreateOrganizationWizardBillingDetailsAction$,
    loggedInUserOrganizations$,
    organizationAppliedDiscountCode$,
} from '../../store/organizations';
import { loggedInUserDetails$ } from '../../store/user';

export const CreateOrganizationWizardContainer: React.FC = () => {
    const createOrganizationWizardDialogOpen = useObservableState(createOrganizationWizardDialogOpen$);
    const saving = useObservableState(createOrganizationWizardSaving$);
    const errors = useObservableState(createOrganizationValidationError$);
    const organizations = useObservableState(loggedInUserOrganizations$);
    const billingEntities = useObservableState(billingEntities$);
    const userDetails = useObservableState(loggedInUserDetails$);
    const applyDiscountInProgress = useObservableState(applyDiscountInProgress$);
    const organizationAppliedDiscountCode = useObservableState(organizationAppliedDiscountCode$);
    const discountCodeValidationError = useObservableState(discountCodeValidationError$);
    const createOrganizationWizardLoading = useObservableState(createOrganizationWizardLoading$);

    const freeOrganizations = organizations.filter((o) => o.planName === 'Free plan');
    const freeOrganizationsLeft =
        userDetails?.maxFreeTeams !== undefined ? userDetails.maxFreeTeams - freeOrganizations.length : 0;

    return (
        <CreateOrganizationWizardDialog
            billingEntities={billingEntities}
            errors={errors}
            freeOrganizationsLeft={freeOrganizationsLeft}
            open={createOrganizationWizardDialogOpen}
            onAvatarUpload={() => alert('Feature not yet implemented')}
            onCancel={() => closeCreateOrganizationWizardDialogAction$.next()}
            onCreate={(event) => createOrganizationActionV2$.next(event)}
            saving={saving}
            isSalablePlan={true}
            loading={createOrganizationWizardLoading}
            applyDiscountInProgress={applyDiscountInProgress}
            appliedDiscountCode={organizationAppliedDiscountCode}
            onApplyDiscount={(code) => applyOrganizationDiscountAction$.next(code)}
            onDeleteDiscount={() => organizationAppliedDiscountCode$.next(undefined)}
            discountCodeValidationError={discountCodeValidationError}
            clearDiscountValidationError={() => discountCodeValidationError$.next(undefined)}
            onBillingDetails={() => loadCreateOrganizationWizardBillingDetailsAction$.next()}
        />
    );
};
