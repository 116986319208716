import { useObservableState } from 'observable-hooks';
import { Language, LanguageSelector } from '../../components/workspace-dialogs/language-selector';
import { selectedWorkspace$, selectedWorkspaceUid$ } from '../../store/workspace';
import {
    languageSelectorError$,
    languageSelectorOpen$,
    languageSelectorSaving$,
    updateWorkspaceLanguageAction$,
} from '../../store/workspace/language-selector';

export const LanguageSelectorContainer: React.FC = () => {
    const errors = useObservableState(languageSelectorError$);
    const open = useObservableState(languageSelectorOpen$);
    const saving = useObservableState(languageSelectorSaving$);
    const workspace = useObservableState(selectedWorkspace$);
    const workspaceUid = useObservableState(selectedWorkspaceUid$);

    const handleLanguageUpdate = (language: Language): void => {
        if (workspaceUid) {
            updateWorkspaceLanguageAction$.next({
                workspaceUid,
                language,
            });
        }
    };

    const language = (workspace?.language ?? 'ts') as Language;

    return (
        <LanguageSelector
            errors={errors}
            open={open}
            saving={saving}
            selectedLanguage={language}
            onApply={handleLanguageUpdate}
            onCancel={() => languageSelectorOpen$.next(false)}
        />
    );
};
