import { BehaviorSubject } from 'rxjs';
import { monitor } from './monitor';
import { InformativeError, PermissionError } from '../utils/repository';
import { Auth0Error } from '../utils/auth0';

interface LoadErrorPageProps {
    error: unknown;
    pageTitle?: string;
    genericMessage?: string;
    background?: 'default' | 'paper';
    skipRefreshMessageOverride?: string;
    showDashboardLink?: boolean;
}

interface ErrorPageDetails {
    errorMessage?: string;
    pageTitle?: string;
    genericMessage?: string;
    background?: 'default' | 'paper';
    skipRefreshMessage?: boolean;
    skipRefreshMessageOverride?: string;
    showDashboardLink?: boolean;
}

export const errorPageDetails$ = monitor(
    'errorPageDetails$',
    new BehaviorSubject<ErrorPageDetails | undefined>(undefined)
);

export const loadErrorPage = ({
    error,
    pageTitle,
    genericMessage,
    background,
    skipRefreshMessageOverride,
    showDashboardLink = true,
}: LoadErrorPageProps): void => {
    let errorMessage: string | undefined;
    let skipRefreshMessage = false;

    if (error instanceof InformativeError) {
        errorMessage = error.message;
    } else if (error instanceof PermissionError) {
        errorMessage = `Access is denied or resource does not exist`;
        skipRefreshMessage = true;
    } else if (error instanceof Auth0Error) {
        errorMessage = genericMessage;
        genericMessage = 'Auth0Error: Failed to load user';
    } else {
        console.error('Failed to load resource', error);
        errorMessage = genericMessage;
    }
    errorPageDetails$.next({
        errorMessage,
        pageTitle,
        genericMessage,
        background,
        skipRefreshMessage,
        skipRefreshMessageOverride,
        showDashboardLink,
    });
};
