import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
    PageContainer,
    PageTitle,
    PageUpperContainer,
    PageUpperGroup,
} from '../../app-main/PageComponentsForDeprecation';
import { ApiKeys } from '../../../data/user';
import { StyledBorderBox, StyledBorderBoxContent } from '../../common/LayoutComponents';
import { EmptyState } from '../../common/EmptyState';
import { Button } from '../../common/buttons/Button';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { IconButton } from '../../common/buttons/IconButton';

interface ApiKeysProps {
    apiKeys: ApiKeys;
    onCreateNewApiKey(): void;
    onDeleteApiKey(uid: string): void;
}

const StyledTableCell = styled(TableCell)(() => ({
    width: 150,
}));

export const ApiKeysPage: React.FC<ApiKeysProps> = ({ apiKeys, onCreateNewApiKey, onDeleteApiKey }) => {
    const docsUrl = 'https://docs.adaptavist.com/src/latest'; // TODO: fix URL

    const createLabel = (): string => {
        const currentResultsLength = apiKeys.length ?? 0;
        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    return (
        <PageContainer>
            <PageUpperContainer>
                <PageTitle title="API Keys" label={apiKeys.length > 0 ? createLabel() : undefined} />
                {apiKeys.length > 0 && (
                    <PageUpperGroup>
                        <Button variant="outlined" key="learn-more" onClick={() => window.open(docsUrl, '_blank')}>
                            Learn more
                        </Button>
                        {/* TODO: fix margin hack */}
                        <Button onClick={onCreateNewApiKey} startIcon={<AddIcon />} sx={{ marginLeft: '10px' }}>
                            Create New
                        </Button>
                    </PageUpperGroup>
                )}
            </PageUpperContainer>
            {apiKeys.length === 0 && (
                <Box display="flex" justifyContent="center" mt={3}>
                    <StyledBorderBox maxWidth={1040} width="100%">
                        <StyledBorderBoxContent>
                            <EmptyState
                                title="No API keys created"
                                subtitle="API keys allow authorizing API calls against ScriptRunner Connect API endpoints."
                                icon={<VpnKeyOutlined />}
                                iconState="incomplete"
                                buttons={[
                                    <Button
                                        variant="outlined"
                                        key="learn-more"
                                        onClick={() => window.open(docsUrl, '_blank')}
                                    >
                                        Learn more
                                    </Button>,
                                    <Button key="create-api-key" onClick={onCreateNewApiKey}>
                                        Create API key
                                    </Button>,
                                ]}
                            />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </Box>
            )}
            {apiKeys.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Created</TableCell>
                            <TableCell>API Key Name</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiKeys.map((apiKey) => (
                            <TableRow key={apiKey.uid}>
                                <StyledTableCell>{new Date(apiKey.createdDate).toLocaleString()}</StyledTableCell>
                                <TableCell>{apiKey.keyName}</TableCell>
                                <StyledTableCell>
                                    <IconButton
                                        aria-label="Delete API Key"
                                        icon={<DeleteIcon />}
                                        tooltip="Delete API Key"
                                        onClick={() => onDeleteApiKey(apiKey.uid)}
                                    />
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </PageContainer>
    );
};
