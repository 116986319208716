import { BehaviorSubject, map, Subject } from 'rxjs';
import { monitor } from '../monitor';
import { getReplayInvocationPayload } from '../../data/replay-invocation';
import { InformativeError, PermissionError } from '../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { executeInvocationReplay } from '../../utils/eventListener';
import { saveScriptBundledOutput } from '../../utils/script';

export interface ReplayInvocationDetails {
    invocationUid: string;
    workspaceUid: string;
    environmentUid: string;
    scriptUid: string;
}

export const replayInvocationDialogOpen$ = monitor('replayInvocationDialogOpen$', new BehaviorSubject(false));
export const currentReplayInvocationDetails$ = monitor(
    'currentReplayInvocationDetails$',
    new BehaviorSubject<ReplayInvocationDetails | null>(null)
);
export const replayInvocationPayload$ = monitor('replayInvocationPayload$', new BehaviorSubject<string>(''));
export const replayInvocationReviewAction$ = monitor(
    'replayInvocationReviewAction$',
    new Subject<ReplayInvocationDetails>()
);
export const navigateToReplayInvocationReviewAction$ = monitor(
    'navigateToReplayInvocationReviewAction$',
    new Subject<ReplayInvocationDetails>()
);
export const loadingReplayInvocationPayload$ = monitor(
    'loadingReplayInvocationPayload$',
    new BehaviorSubject<boolean>(false)
);
export const triggerReplayInvocationAction$ = monitor(
    'triggerReplayInvocationAction$',
    new Subject<{ invocationUid: string; payload: string }>()
);

replayInvocationReviewAction$
    .pipe(
        map(async (details) => {
            currentReplayInvocationDetails$.next(details);
            replayInvocationDialogOpen$.next(true);
            loadingReplayInvocationPayload$.next(true);
            try {
                const payload = await getReplayInvocationPayload(details);
                replayInvocationPayload$.next(payload);
                navigateToReplayInvocationReviewAction$.next(details);
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to replay invocation, try again, if the issue persists please contact support.`,
                    });
                }
                replayInvocationDialogOpen$.next(false);
            }
            loadingReplayInvocationPayload$.next(false);
        })
    )
    .subscribe();

triggerReplayInvocationAction$
    .pipe(
        map(async ({ invocationUid, payload }) => {
            try {
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: `Replaying invocation: ${invocationUid}`,
                    noToast: true,
                });
                replayInvocationDialogOpen$.next(false);
                if (currentReplayInvocationDetails$.value?.scriptUid) {
                    await saveScriptBundledOutput(currentReplayInvocationDetails$.value.scriptUid, [], true);
                }
                await executeInvocationReplay(invocationUid, payload);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof PermissionError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                        noToast: true,
                    });
                } else {
                    console.error('Error while replaying invocation', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to replay invocation, try again, if the issue persists please contact support.`,
                        noToast: true,
                    });
                }
            }
            replayInvocationPayload$.next('');
            currentReplayInvocationDetails$.next(null);
        })
    )
    .subscribe();
