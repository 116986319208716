import { useObservableState } from 'observable-hooks';
import {
    closeWorkspaceSetupGuideConnectionSelectorAction$,
    createWorkspaceSetupGuideConnectionAction$,
    selectWorkspaceSetupGuideConnectionAction$,
    modifiedWorkspaceSetupGuideAppInstanceDetails$,
    workspaceSetupGuideConnectionSelectorOpen$,
} from '../../../store/setup-guide';
import { SetupGuideConnectionSelector } from '../../../components/setup-guide/connections/SetupGuideConnectionSelector';
import { loggedInUserConnections$ } from '../../../store/connections';

export const SetupGuideConnectionSelectorContainer: React.FC = () => {
    const details = useObservableState(modifiedWorkspaceSetupGuideAppInstanceDetails$);
    const open = useObservableState(workspaceSetupGuideConnectionSelectorOpen$);
    const connections = useObservableState(loggedInUserConnections$);

    const filteredConnections = connections
        .filter((c) => c.connectionType.name === details?.appName)
        .map((fc) => ({ authorized: fc.authorized, name: fc.name, uid: fc.uid }));

    return (
        <SetupGuideConnectionSelector
            appName={details?.appName ?? ''}
            connections={filteredConnections}
            open={open}
            onClose={() => closeWorkspaceSetupGuideConnectionSelectorAction$.next()}
            onCreateNew={() =>
                createWorkspaceSetupGuideConnectionAction$.next({
                    appName: details?.appName ?? '',
                    group: details?.group,
                })
            }
            onSelect={(uid) => selectWorkspaceSetupGuideConnectionAction$.next(uid)}
        />
    );
};
