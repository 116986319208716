import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { InformationChip } from '../../common/chips/InformationChip';
import { SetupGuideStep } from '../types';
import { getSetupGuideStepIcon } from '../utils';

export interface SetupGuideWelcomeScreenStepProps extends SetupGuideStep {
    onSelect(uid: string): void;
}

const StyledItem = styled(ButtonBase)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    maxWidth: 688,
    minHeight: 68,
    padding: theme.spacing(1.5),
    width: '100%',
    '&:hover': {
        border: theme.constants.borderSelected,
    },
    '&:focus-visible': {
        backgroundColor: theme.palette.action.focus,
        border: theme.constants.borderSelected,
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], // Need new colour in theme, action.disabledBackground is way too
        color: theme.palette.text.secondary,
    },
}));

const StyledMediaContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledNumberContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], // Need new colour in theme
    borderRadius: '50%',
    height: 24,
    justifyContent: 'center',
    width: 24,
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], // Need new colour in theme
    borderRadius: theme.constants.borderRadius,
    height: 44,
    justifyContent: 'center',
    width: 44,
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledTextContainer = styled(Box)(() => ({
    flexGrow: 1,
    textAlign: 'start',
}));

export const SetupGuideWelcomeScreenStep: React.FC<SetupGuideWelcomeScreenStepProps> = ({
    completed,
    description,
    disabled = false,
    name,
    number,
    required = false,
    title,
    onSelect,
}) => {
    const nextStepPendoAttribute = number === 1 ? 'setupStarted' : undefined;

    return (
        <StyledItem data-pendo={nextStepPendoAttribute} disabled={disabled} onClick={() => onSelect(name)}>
            <StyledMediaContainer>
                {completed ? (
                    <CheckCircleIcon color="success" />
                ) : (
                    <StyledNumberContainer>{number}</StyledNumberContainer>
                )}
                <StyledIconContainer> {getSetupGuideStepIcon(name)}</StyledIconContainer>
            </StyledMediaContainer>
            <StyledTextContainer>
                <Typography variant="subtitle2">{title}</Typography>
                <Typography color="text.secondary">{description}</Typography>
            </StyledTextContainer>
            <StyledMediaContainer>
                <InformationChip
                    label={required ? 'Required' : 'Optional'}
                    severity={required ? 'primary' : 'grey'}
                    sx={{ pointerEvents: 'none' }}
                />
                {!disabled && !completed && <KeyboardArrowRightIcon />}
            </StyledMediaContainer>
        </StyledItem>
    );
};
