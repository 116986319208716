import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

export const githubManageConnectionOpenDialogAction$ = monitor(
    'githubManageConnectionOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const githubManageConnectionCloseDialogAction$ = monitor(
    'githubManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const githubManageConnectionInitiateAuthorizeAction$ = monitor(
    'githubManageConnectionInitiateAuthorizeAction$',
    new Subject<string>()
);

export const githubSaveConnectionAction$ = monitor('githubSaveConnectionAction$', new Subject<string>());
export const githubConnectionCreatedAction$ = monitor('githubConnectionCreatedAction$', new Subject<string>());
export const githubConnectionSavedAction$ = monitor(
    'githubConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const githubManageConnectionInitiateSetupAction$ = monitor(
    'githubManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const githubManageConnectionDialogOpen$ = monitor(
    'githubManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const githubConnectionSaving$ = monitor('githubConnectionSaving$', new BehaviorSubject(false));
export const githubManageConnectionAuthorizeLoading$ = monitor(
    'githubManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const githubManageConnectionDetails$ = monitor(
    'githubManageConnectionDetails$',
    new BehaviorSubject<ConnectionDetails | undefined>(undefined)
);
export const githubManageConnectionDialogErrors$ = monitor(
    'githubManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

githubManageConnectionOpenDialogAction$.subscribe((details) => {
    githubManageConnectionDialogErrors$.next(undefined);
    githubManageConnectionDialogOpen$.next(true);
    githubManageConnectionDetails$.next(details);
});

githubManageConnectionCloseDialogAction$.subscribe(() => {
    githubManageConnectionAuthorizeLoading$.next(false);
    githubManageConnectionDialogOpen$.next(false);
    githubManageConnectionDetails$.next(undefined);
});

githubConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

githubSaveConnectionAction$
    .pipe(
        map(async (name) => {
            githubConnectionSaving$.next(true);
            githubManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = githubManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('GitHub connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveGithubConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveGithubConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                githubConnectionCreatedAction$.next(uid);
                                githubConnectionSavedAction$.next({ uid, connectionTypeUid });
                                githubManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, githubManageConnectionDialogErrors$, APP.GITHUB.NAME);
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, githubManageConnectionDialogErrors$, APP.GITHUB.NAME);
            }

            githubConnectionSaving$.next(false);
        })
    )
    .subscribe();

githubManageConnectionInitiateAuthorizeAction$
    .pipe(
        map(async (name) => {
            githubConnectionSaving$.next(true);
            githubManageConnectionAuthorizeLoading$.next(true);
            githubManageConnectionDialogErrors$.next(undefined);

            const baseUrl = configTopic$.value.connection?.github?.baseUrl;
            if (!baseUrl) {
                throw new Error('No GitHub Connector URL configured in meta');
            }
            const initiateUrl = `${baseUrl}/initiate`;

            const { uid, connectionType } = githubManageConnectionDetails$.value ?? {};

            if (!connectionType) {
                throw Error('GitHub Connector type not defined');
            }
            const connectionTypeUid = connectionType.uid;
            const connectionTypeName = connectionType.name.toLowerCase();

            if (uid) {
                await saveConnection(uid, name);

                githubConnectionSavedAction$.next({ uid, connectionTypeUid });
                const response = await initiate({ connectionId: uid, connectionType: connectionTypeName }, initiateUrl);
                openConsentWindow(response.location, githubManageConnectionAuthorizeLoading$);
            } else {
                try {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    githubConnectionCreatedAction$.next(uid);
                    githubConnectionSavedAction$.next({ uid, connectionTypeUid });
                    githubManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    githubConnectionSaving$.next(false);
                    const response = await initiate(
                        { connectionId: uid, connectionType: connectionTypeName },
                        initiateUrl
                    );
                    openConsentWindow(response.location, githubManageConnectionAuthorizeLoading$);
                    connectionCreatedFirstTime$.next(true);
                } catch (e) {
                    handleManageConnectionError(e, githubManageConnectionDialogErrors$, APP.GITHUB.NAME);
                    githubManageConnectionAuthorizeLoading$.next(false);
                }
            }

            githubConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(githubConnectionSavedAction$);

const saveGithubConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        githubConnectionSavedAction$.next({ uid, connectionTypeUid });
        githubManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, githubManageConnectionDialogErrors$, APP.GITHUB.NAME);
    }
};
