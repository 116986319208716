import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import TextField from '@mui/material/TextField';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../for-deprecation/IconCircle';
import { autoFocus } from '../../utils/autoFocus';
import { handleKeyDown } from '../../utils/handleKeyDown';

interface NewEnvironmentDialogProps {
    errors?: string;
    name?: string;
    open?: boolean;
    rename?: boolean;
    saving?: boolean; // Show spinner for save button and disable it
    onCancel(): void;
    onSave(name: string): void;
}

export const NewEnvironmentDialog: React.FC<NewEnvironmentDialogProps> = ({
    rename = false,
    name = '',
    errors,
    saving = false,
    open = false,
    onSave,
    onCancel,
}) => {
    const [currentName, setCurrentName] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentName(name);
        autoFocus(inputRef);
    }, [open]);

    const hasChanged = currentName !== name;
    const canSave = !saving && !!currentName && (rename ? hasChanged : true);

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: canSave, enterFn: () => onSave(currentName), escFn: onCancel })
            }
        >
            <DialogTitleMain
                title={rename ? 'Rename Environment' : 'New Environment'}
                icon={<IconCircle icon={<DisplaySettingsRoundedIcon />} />}
            />
            {errors && <DialogAlert severity="error" title={errors} />}
            <DialogContent>
                <TextField
                    inputRef={inputRef}
                    label="Environment name"
                    variant="outlined"
                    placeholder="Enter a name"
                    required
                    value={currentName}
                    onChange={(e) => setCurrentName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} variant="outlined">
                    Cancel
                </Button>
                <Button busy={saving} disabled={!canSave} onClick={() => onSave(currentName)}>
                    {rename ? 'Save' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
