import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    handleManageConnectionError,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';
import { saveApiKey } from './save';

interface StatuspageSetupSaveProps {
    apiKey: string;
}

export const statuspageManageConnectionOpenDialogAction$ = monitor(
    'statuspageManageConnectionOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const statuspageManageConnectionCloseDialogAction$ = monitor(
    'statuspageManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const statuspageSetupOpenDialogAction$ = monitor(
    'statuspageSetupOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const statuspageSetupCloseDialogAction$ = monitor('statuspageSetupCloseDialogAction$', new Subject<void>());

export const statuspageSetupDialogOpen$ = monitor('statuspageSetupDialogOpen$', new BehaviorSubject(false));
export const statuspageSetupAuthorizeAction$ = monitor(
    'statuspageSetupAuthorizeAction$',
    new Subject<StatuspageSetupSaveProps>()
);
export const statuspageSetupDialogErrors$ = monitor(
    'statuspageSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const statuspageSaveConnectionAction$ = monitor('statuspageSaveConnectionAction$', new Subject<string>());
export const statuspageConnectionCreatedAction$ = monitor('statuspageConnectionCreatedAction$', new Subject<string>());
export const statuspageConnectionSavedAction$ = monitor(
    'statuspageConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const statuspageManageConnectionInitiateSetupAction$ = monitor(
    'statuspageManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const statuspageManageConnectionDialogOpen$ = monitor(
    'statuspageManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const statuspageConnectionSaving$ = monitor('statuspageConnectionSaving$', new BehaviorSubject(false));
export const statuspageManageConnectionAuthorizeLoading$ = monitor(
    'statuspageManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const statuspageManageConnectionDetails$ = monitor(
    'statuspageManageConnectionDetails$',
    new BehaviorSubject<ConnectionDetails | undefined>(undefined)
);
export const statuspageManageConnectionDialogErrors$ = monitor(
    'statuspageManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

const STATUSPAGE_CONNECTION_TYPE = 'Statuspage';

statuspageSetupOpenDialogAction$.subscribe((details) => {
    statuspageSetupDialogErrors$.next(undefined);
    statuspageManageConnectionDialogErrors$.next(undefined);
    statuspageSetupDialogOpen$.next(true);
    statuspageManageConnectionDetails$.next(details);
});

statuspageSetupCloseDialogAction$.subscribe(() => {
    statuspageSetupDialogOpen$.next(false);
    statuspageManageConnectionAuthorizeLoading$.next(false);
});

statuspageManageConnectionOpenDialogAction$.subscribe((details) => {
    statuspageSetupDialogErrors$.next(undefined);
    statuspageManageConnectionDialogErrors$.next(undefined);
    statuspageManageConnectionDialogOpen$.next(true);
    statuspageManageConnectionDetails$.next(details);
});

statuspageManageConnectionCloseDialogAction$.subscribe(() => {
    statuspageManageConnectionDialogOpen$.next(false);
    statuspageSetupDialogOpen$.next(false);
    statuspageManageConnectionDetails$.next(undefined);
});

statuspageConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

statuspageSetupAuthorizeAction$
    .pipe(
        map(async ({ apiKey }) => {
            statuspageConnectionSaving$.next(true);
            statuspageSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = statuspageManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Statuspage connector type not defined');
                }

                if (!uid) {
                    throw Error('Statuspage connector has not been created so cannot configure it');
                }

                await saveApiKey({ apiKey, connectionId: uid });

                statuspageConnectionCreatedAction$.next(uid);
                statuspageConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                statuspageSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Statuspage connector has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    statuspageSetupDialogErrors$.next(e.message);
                } else {
                    statuspageSetupDialogErrors$.next(
                        'Failed to save Statuspage connector details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Statuspage connector info', e);
                }
            }

            statuspageConnectionSaving$.next(false);
            statuspageManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

statuspageSaveConnectionAction$
    .pipe(
        map(async (name) => {
            statuspageConnectionSaving$.next(true);
            statuspageManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = statuspageManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Statuspage connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveStatuspageConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveStatuspageConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                statuspageConnectionCreatedAction$.next(uid);
                                statuspageConnectionSavedAction$.next({ uid, connectionTypeUid });
                                statuspageManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    statuspageManageConnectionDialogErrors$,
                                    STATUSPAGE_CONNECTION_TYPE
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, statuspageManageConnectionDialogErrors$, STATUSPAGE_CONNECTION_TYPE);
            }

            statuspageConnectionSaving$.next(false);
        })
    )
    .subscribe();

statuspageManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            statuspageConnectionSaving$.next(true);
            statuspageManageConnectionAuthorizeLoading$.next(true);
            statuspageManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = statuspageManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Statuspage connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    statuspageConnectionSavedAction$.next({ uid, connectionTypeUid });
                    statuspageSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    statuspageConnectionCreatedAction$.next(uid);
                    statuspageConnectionSavedAction$.next({ uid, connectionTypeUid });

                    statuspageManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    statuspageConnectionSaving$.next(false);

                    statuspageSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, statuspageManageConnectionDialogErrors$, STATUSPAGE_CONNECTION_TYPE);
                statuspageManageConnectionAuthorizeLoading$.next(false);
            }

            statuspageConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(statuspageConnectionSavedAction$);

const saveStatuspageConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        statuspageConnectionSavedAction$.next({ uid, connectionTypeUid });
        statuspageManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, statuspageManageConnectionDialogErrors$, STATUSPAGE_CONNECTION_TYPE);
    }
};
