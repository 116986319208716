import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { LoadingSpinner } from '../LoadingSpinner';

export interface CustomButtonProps extends ButtonProps {
    busy?: boolean;
    disabled?: boolean;
    tooltip?: string;
}

// Use Button directly after WS overhaul
const StyledButton = styled(MuiButton)(() => ({
    whiteSpace: 'nowrap',
}));

export const Button: React.FC<PropsWithChildren<CustomButtonProps>> = ({
    busy,
    disabled = false,
    variant = 'contained',
    children,
    ...props
}) => {
    return (
        <StyledButton disabled={disabled || busy} variant={variant} {...props}>
            {busy && <LoadingSpinner size="small" />}
            {children}
        </StyledButton>
    );
};
