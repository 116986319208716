// Restyle using new connector cards
import Grid from '@mui/material/Unstable_Grid2';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AppCard } from '../../common/AppCard';
import { Button } from '../../common/buttons/Button';
import { Dialog } from '../../common/Dialog';
import { StyledEmptyStateBox } from '../../common/LayoutComponents';
import { SetupGuideConnection } from '../types';
import { handleKeyDown } from '../../../utils/handleKeyDown';

interface SetupGuideConnectionSelectorProps {
    appName: string;
    connections?: SetupGuideConnection[];
    open?: boolean;
    onClose(): void;
    onCreateNew(): void;
    onSelect(connectionUid: string): void;
}

export const SetupGuideConnectionSelector: React.FC<SetupGuideConnectionSelectorProps> = ({
    appName,
    connections = [],
    open = false,
    onClose,
    onCreateNew,
    onSelect,
}) => {
    const items = connections.map((connection) => (
        <Grid xs="auto">
            <AppCard
                key={connection.uid}
                authorized={connection.authorized}
                title={connection.name}
                type={appName}
                uid={connection.uid}
                onSelect={onSelect}
                pendoAttribute="connectorTypeSelected"
            />
        </Grid>
    ));

    return (
        <Dialog
            size="medium"
            title="Select connector"
            open={open}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, escFn: onClose })}
        >
            {
                <Grid container spacing={2} maxWidth={944}>
                    {items}
                    <Grid xs="auto">
                        <StyledEmptyStateBox height={172} width={220}>
                            <Button startIcon={<AddOutlinedIcon />} onClick={onCreateNew}>
                                Create new
                            </Button>
                        </StyledEmptyStateBox>
                    </Grid>
                </Grid>
            }
        </Dialog>
    );
};
