import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { LoadingSpinner } from '../LoadingSpinner';

export interface CustomIconButtonProps extends IconButtonProps {
    'aria-label': string;
    border?: boolean;
    busy?: boolean;
    icon: JSX.Element;
    size?: 'small' | 'medium';
    tooltip: string;
    tooltipPlacement?: TooltipProps['placement'];
}

// Use IconButton directly after size has been changed in the theme
const StyledIconButton = styled(MuiIconButton, { shouldForwardProp: (prop) => prop !== 'border' })<{
    border: boolean;
    size: 'small' | 'medium';
}>(({ border, size, theme }) => ({
    border: border ? `1px solid ${theme.palette.divider}` : undefined,
    height: size === 'small' ? 30 : 36,
    position: 'relative',
    width: size === 'small' ? 30 : 36,
    '& .MuiSvgIcon-root': {
        height: size === 'small' ? 20 : 24,
        width: size === 'small' ? 20 : 24,
    },
}));

export const IconButton: React.FC<CustomIconButtonProps> = ({
    'aria-label': ariaLabel,
    border = false,
    busy = false,
    icon,
    size = 'medium',
    tooltip,
    tooltipPlacement = 'top',
    ...props
}) => {
    return busy || props.disabled ? (
        <StyledIconButton aria-label={ariaLabel} border={border} disabled size={size} {...props}>
            {busy && <LoadingSpinner size="small" />}
            {icon}
        </StyledIconButton>
    ) : (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            <StyledIconButton aria-label={ariaLabel} border={border} size={size} {...props}>
                {busy && <LoadingSpinner size="small" />}
                {icon}
            </StyledIconButton>
        </Tooltip>
    );
};
