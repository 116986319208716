import { BehaviorSubject, map, Subject } from 'rxjs';
import { WorkspaceSharingDetails, SaveWorkspaceSharingDetailsRequest } from '../../data/workspaces';
import { monitor } from '../monitor';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { getWorkspaceSharingDetails, saveWorkspaceSharingDetails } from '../../data/sharing';

export const workspaceSharingDialogOpen$ = monitor('workspaceSharingDialogOpen$', new BehaviorSubject(false));
export const workspaceSharingDetails$ = monitor(
    'workspaceSharingDetails$',
    new BehaviorSubject<WorkspaceSharingDetails | undefined>(undefined)
);
export const loadingWorkspaceSharingDetails$ = monitor('loadingWorkspaceSharingDetails$', new BehaviorSubject(false));
export const savingWorkspaceSharingDetails$ = monitor('savingWorkspaceSharingDetails$', new BehaviorSubject(false));

export const openWorkspaceSharingDialogAction$ = monitor('openWorkspaceSharingDialogAction$', new Subject<string>());
export const getWorkspaceSharingDetailsAction$ = monitor('getWorkspaceSharingDetailsAction$', new Subject<string>());
export const saveWorkspaceSharingDetailsAction$ = monitor(
    'saveWorkspaceSharingDetailsAction$',
    new Subject<SaveWorkspaceSharingDetailsRequest>()
);
export const cancelWorkspaceSharingDetailsAction$ = monitor(
    'cancelWorkspaceSharingDetailsAction$',
    new Subject<void>()
);

openWorkspaceSharingDialogAction$.subscribe((workspaceUid: string) => {
    getWorkspaceSharingDetailsAction$.next(workspaceUid);
    workspaceSharingDialogOpen$.next(true);
});

cancelWorkspaceSharingDetailsAction$.subscribe(() => {
    workspaceSharingDetails$.next(undefined);
    workspaceSharingDialogOpen$.next(false);
});

getWorkspaceSharingDetailsAction$
    .pipe(
        map(async (uid) => {
            loadingWorkspaceSharingDetails$.next(true);
            try {
                const response = await getWorkspaceSharingDetails(uid);
                workspaceSharingDetails$.next(response);
            } catch (err) {
                console.error('Error while retrieving workspace sharing details', err);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to retrieve Workspace sharing details, please try again, if the issue persists please contact support.`,
                });
            }
            loadingWorkspaceSharingDetails$.next(false);
        })
    )
    .subscribe();

saveWorkspaceSharingDetailsAction$
    .pipe(
        map(async ({ workspaceUid, visibility }) => {
            savingWorkspaceSharingDetails$.next(true);
            try {
                await saveWorkspaceSharingDetails(workspaceUid, visibility);
                workspaceSharingDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message:
                        visibility === 'PRIVATE'
                            ? 'Workspace shared with administrators only.'
                            : 'Workspace shared with all members.',
                });
            } catch (err) {
                console.error('Error while saving workspace sharing details', err);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to save Workspace sharing details, please try again, if the issue persists please contact support.`,
                });
            }
            savingWorkspaceSharingDetails$.next(false);
        })
    )
    .subscribe();
