import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import { IconButton } from '../../../common/buttons/IconButton';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { EmptyState } from '../../../common/EmptyState';
import { SetupGuideEventListenerListItem } from './SetupGuideEventListenerListItem';
import { StyledStepContainer } from '../../SetupGuideComponents';
import { SetupGuideSummaryBox } from '../../SetupGuideSummaryBox';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../../../common/LayoutComponents';
import { SetupGuideEventListener } from '../../types';

interface SetupGuideEventListenerScreenProps {
    errors?: string;
    eventListeners?: SetupGuideEventListener[];
    templateMode?: boolean;
    workspaceLocked: boolean;
    onCreateNewEventListener: () => void;
    onDeleteEventListener: (uid: string) => void;
    onEditEventListener: (uid: string) => void;
}

export const SetupGuideEventListenerScreen: React.FC<SetupGuideEventListenerScreenProps> = ({
    errors,
    eventListeners = [],
    templateMode = false,
    workspaceLocked,
    onCreateNewEventListener,
    onDeleteEventListener,
    onEditEventListener,
}) => {
    const needsSetup = eventListeners?.some((el) => el.needsSetup);
    const paragraphs = [
        'The event-listener feature allows you to listen and react to events originating from external services/apps. You must select which event type you want to listen to. Event types are specific to the app you have selected.',
    ];

    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox paragraphs={paragraphs} title="What is an event listener?" />
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        Event listeners
                    </Typography>
                    {!templateMode && (
                        <IconButton
                            aria-label="Create a new event listener"
                            disabled={workspaceLocked}
                            icon={<AddOutlinedIcon />}
                            tooltip="Create a new event listener"
                            onClick={() => onCreateNewEventListener()}
                        />
                    )}
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {eventListeners.length ? (
                        <>
                            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
                            {needsSetup && (
                                <Alert
                                    severity="warning"
                                    text={
                                        <strong>
                                            One or more elements are incomplete. Please review the following:
                                        </strong>
                                    }
                                />
                            )}
                            {eventListeners.map((el) => {
                                return (
                                    <SetupGuideEventListenerListItem
                                        key={el.uid}
                                        appName={el.appName}
                                        deleting={el.deleting}
                                        needsSetup={el.needsSetup}
                                        scriptName={el.scriptName}
                                        templateMode={templateMode}
                                        uid={el.uid}
                                        workspaceLocked={workspaceLocked}
                                        onDelete={onDeleteEventListener}
                                        onEdit={onEditEventListener}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <EmptyState
                            buttons={
                                !templateMode
                                    ? [
                                          <Button
                                              key="create-event-listener"
                                              disabled={workspaceLocked}
                                              onClick={onCreateNewEventListener}
                                          >
                                              Create event listener
                                          </Button>,
                                      ]
                                    : undefined
                            }
                            icon={<HearingOutlinedIcon />}
                            iconState={templateMode ? 'complete' : 'incomplete'}
                            subtitle={
                                !templateMode
                                    ? 'Create an event listener to start building your integration.'
                                    : 'Click Continue to proceed to the next step.'
                            }
                            title={templateMode ? 'No event listeners in this template' : 'No event listeners found'}
                        />
                    )}
                </StyledBorderBoxContent>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
