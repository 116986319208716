import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Button } from '../common/buttons/Button';
import { SetupGuideStep } from './types';

interface SetupGuideStepperProps {
    selectedStepUid?: string;
    steps: SetupGuideStep[];
    onNavigateToOverview(): void;
    onSelectStep(uid: string): void;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid ' + theme.palette.divider,
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: theme.spacing(0, 1.5),
}));

const StyledStepperContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%',
    margin: 0,
});

const StyledStepper = styled(Stepper)(() => ({
    height: '100%',
    minHeight: 'unset',
    margin: 0,
}));

const StyledStep = styled(Step)<{ selected?: boolean }>(({ theme, selected }) => ({
    borderBottom: `2px solid ${selected ? theme.palette.primary.main : 'transparent'}`,
    height: '100%',
}));

const StyledStepButton = styled(StepButton)(({ theme }) => ({
    height: '100%',
    padding: 0,
    margin: 0,
    '& .MuiStepLabel-label': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        transform: 'none !important', // Temporary, need to sort out the theme
        '&.Mui-completed': {
            color: theme.palette.text.secondary,
        },
        '&.Mui-active': {
            color: theme.palette.primary.main,
        },
        '&.Mui-disabled': {
            color: theme.palette.text.disabled,
        },
    },
    '& .MuiStepLabel-iconContainer': {
        transform: 'none !important', // Temporary, need to sort out the theme
    },
}));

const StyledStepIcon = styled(CheckOutlinedIcon)(({ theme }) => ({
    borderRadius: theme.constants.radiusCircle,
    height: 24,
    width: 24,
}));

const StyledCompletedIcon = styled(StyledStepIcon)(({ theme }) => ({
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
}));

const StyledInactiveIcon = styled(StyledStepIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
}));

const StyledSelectedStepIcon = styled(StyledStepIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

const getStepIcon = (selected?: boolean, completed?: boolean): JSX.Element =>
    completed ? <StyledCompletedIcon /> : selected ? <StyledSelectedStepIcon /> : <StyledInactiveIcon />;

export const SetupGuideStepper: React.FC<SetupGuideStepperProps> = ({
    selectedStepUid,
    steps,
    onNavigateToOverview,
    onSelectStep,
}) => {
    const selectedStep = steps.find((s) => s.uid === selectedStepUid);

    return (
        <StyledToolbar>
            <Button size="small" startIcon={<ArrowBackOutlinedIcon />} variant="text" onClick={onNavigateToOverview}>
                Overview
            </Button>
            <StyledStepperContainer>
                <StyledStepper activeStep={selectedStep && selectedStep.number - 1}>
                    {steps.map((s) => (
                        <StyledStep
                            key={s.uid}
                            active={s.uid === selectedStepUid}
                            completed={s.completed}
                            disabled={s.disabled}
                            selected={s.uid === selectedStepUid}
                        >
                            <StyledStepButton
                                icon={getStepIcon(s.uid === selectedStepUid, s.completed)}
                                onClick={() => onSelectStep(s.name)}
                            >
                                {`${s.number}. ${s.title}`}
                            </StyledStepButton>
                        </StyledStep>
                    ))}
                </StyledStepper>
            </StyledStepperContainer>
        </StyledToolbar>
    );
};
