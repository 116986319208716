import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import ListItem from '@mui/material/ListItem';

interface GenericEventListenerSetupDialogProps {
    open?: boolean;
    url: string;
    onClose: () => void;
}

export const GenericEventListenerSetupDialog: React.FC<GenericEventListenerSetupDialogProps> = ({
    open = false,
    url,
    onClose,
}) => {
    const appName = 'Generic';
    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={`${appName} App`}
                onClose={onClose}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                <Typography>Copy and use the URL to connect to ScriptRunner Connect.</Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField label="Webhook URL" value={url} />
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            Common use cases
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList counterType="alpha">
                            <ListItem>
                                <Typography>
                                    Register the URL as a webhook in an external app to listen for events.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>Build your own API by sending back JSON in the response.</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Build your own user interface by sending back HTML in the response.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
