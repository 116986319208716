import { useState } from 'react';
import { styled, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { AvatarContainer } from '../../common/Avatars';
import { UserNameDetails } from '../../../utils/types';
import { getUserDisplayName } from '../../../utils/user';
import { StyledLink, StyledMenu, StyledMenuItem, StyledMenuItemContent } from './SidenavComponents';

interface SidenavUserSettingsNavigatorProps {
    collapsed: boolean;
    isLogOutBusy?: boolean;
    userCredentials: UserNameDetails;
    useRouter?: boolean;
    basePath: string;
    displayApiKeys: boolean;
    onLogOut(): void;
}

const StyledUserSettingsButton = styled(ListItemButton)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    maxWidth: 210,
    padding: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
    },
}));

const StyledUserInformation = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledUserDisplayName = styled(Typography, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
    collapsed: boolean;
}>(({ collapsed }) => ({
    display: collapsed ? 'none' : undefined,
    maxWidth: 114,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

const StyledLogOutSpinner = styled(CircularProgress)(() => ({
    position: 'absolute',
}));

export const SidenavUserSettingsNavigator: React.FC<SidenavUserSettingsNavigatorProps> = ({
    collapsed,
    isLogOutBusy = false,
    userCredentials,
    useRouter = false,
    basePath,
    displayApiKeys,
    onLogOut,
}) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

    const userDisplayName = getUserDisplayName(userCredentials);

    const menuItems = [
        <StyledLink to={useRouter ? basePath + '/profile' : null} key="profile" data-pendo={'personalSettings'}>
            <StyledMenuItem onClick={() => setAnchor(null)}>
                <StyledMenuItemContent>
                    <ManageAccountsOutlinedIcon />
                    Profile settings
                </StyledMenuItemContent>
            </StyledMenuItem>
        </StyledLink>,
        <StyledLink to={useRouter ? basePath + '/apiKeys' : null} key="profile">
            <StyledMenuItem onClick={() => setAnchor(null)}>
                <StyledMenuItemContent>
                    <VpnKeyOutlined />
                    API Keys
                </StyledMenuItemContent>
            </StyledMenuItem>
        </StyledLink>,
        <StyledLink to={useRouter ? basePath + '/billing' : null} key="billing">
            <StyledMenuItem onClick={() => setAnchor(null)}>
                <StyledMenuItemContent>
                    <ReceiptLongOutlinedIcon />
                    Billing details
                </StyledMenuItemContent>
            </StyledMenuItem>
        </StyledLink>,
        <StyledMenuItem
            key="logout"
            disabled={isLogOutBusy}
            onClick={() => {
                onLogOut();
                setAnchor(null);
            }}
        >
            <StyledMenuItemContent>
                {isLogOutBusy && <StyledLogOutSpinner size="small" />}
                <LogoutOutlinedIcon />
                Log out
            </StyledMenuItemContent>
        </StyledMenuItem>,
    ];

    if (!displayApiKeys) {
        menuItems.splice(1, 1); // Remove API keys from the list
    }

    return (
        <>
            <StyledUserSettingsButton
                selected={!!anchor}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
                aria-label="User settings"
            >
                <Tooltip title={userDisplayName}>
                    <StyledUserInformation>
                        <AvatarContainer credentials={userCredentials}></AvatarContainer>
                        <StyledUserDisplayName collapsed={collapsed}>{userDisplayName}</StyledUserDisplayName>
                    </StyledUserInformation>
                </Tooltip>
                {!collapsed && <ArrowDropDownIcon />}
            </StyledUserSettingsButton>
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
