import { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ConnectionModalSecretTextField } from '../../../common/textfield/ConnectionModalTextField';
import { Alert } from '../../../common/alerts/Alert';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material';

interface MicrosoftEventListenerSetupDialogProps {
    errors?: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
    onSave: (token: string) => void;
}

const StyledFieldContainer = styled(Box)(() => ({
    '& > :first-of-type': {
        minWidth: '100%',
        '& .MuiInputBase-root': {
            width: '100%',
        },
    },
}));

export const MicrosoftEventListenerSetupDialog: React.FC<MicrosoftEventListenerSetupDialogProps> = ({
    errors,
    loading = false,
    open = false,
    saving = false,
    webhookBaseUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
    onSave,
}) => {
    const appName = APP.MICROSOFT.NAME;

    const [token, setToken] = useState('');

    const handleSaveToken = (): void => {
        onSave(token);
    };

    const canSave = !loading && !saving && !!token;

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button busy={saving} disabled={!canSave} onClick={handleSaveToken}>
                        Save changes
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: canSave, enterFn: () => onSave(token), escFn: onClose })
                }
                loading={loading}
            >
                {workspaceLocked && (
                    <Alert
                        severity="warning"
                        title="The workspace is currently locked and the token field cannot be edited"
                    />
                )}
                {errors && <Alert severity="error" title={errors} />}
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} channel to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                <Typography>
                                    Open <strong>Microsoft Teams</strong> and navigate to the team you want to setup the
                                    event listener for.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click on the <strong>three dots</strong> next to the team.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click on <strong>Manage team</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click on the <strong>Apps</strong> tab.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    On the bottom right, click on <strong>Create an outgoing webhook</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Give the webhook a name. This is the name you will use to invoke the webhook on the
                                    channel.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong>Callback URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            <ListItem>
                                <Typography>
                                    Give the webhook a description to make it easier for your team to know the purpose
                                    of this webhook.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click on <strong>Create</strong>. A security token will appear on the screen. Copy
                                    this token and paste it in the <strong>Security Token</strong> field below.
                                </Typography>
                            </ListItem>
                            <StyledFieldContainer>
                                <ConnectionModalSecretTextField
                                    disabled={workspaceLocked}
                                    label="Security Token"
                                    value={token}
                                    onUpdate={(e) => setToken(e.target.value.trim())}
                                />
                            </StyledFieldContainer>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
