import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import ListItem from '@mui/material/ListItem';

interface BitbucketCloudEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const BitbucketCloudEventListenerSetupDialog: React.FC<BitbucketCloudEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Bitbucket Cloud';

    return (
        <Dialog
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            icon={<IconCircle icon={<ProductIcon name={connectionTypeName} />} size="medium" />}
            open={open}
            title={`Event listener setup`}
            subtitle={connectionTypeName}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
            loading={loading}
        >
            <>
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                <Typography>
                                    {setupBaseUrl ? (
                                        <>
                                            Visit your workspace{' '}
                                            <Link target="_blank" href={setupBaseUrl}>
                                                Repositories
                                            </Link>{' '}
                                            page.
                                        </>
                                    ) : (
                                        'Open https://bitbucket.org/[YOUR_WORKSPACE_NAME] URL in your browser'
                                    )}
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>Click on the repository where you want to add the webhook.</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click <strong>Repository settings</strong> on the left side.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click <strong>Webhooks</strong> on the left side under <strong>Workflow</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click <strong>Add webhook</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Enter a meaningful name into the <strong>Title</strong> field.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong>URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            <ListItem>
                                <Typography>
                                    Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Then click <strong>Save</strong>.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </>
        </Dialog>
    );
};
