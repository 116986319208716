import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
    TimeoutError,
    generateConnectorCertificate,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface AtlassianOnPremiseConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    publicKey?: string;
    certificateId?: string;
    authorized?: boolean;
}

interface AtlassianOnPremiseAppSetupSaveProps {
    instanceUrl: string;
}

export const atlassianOnPremiseManageConnectionOpenDialogAction$ = monitor(
    'atlassianOnPremiseManageConnectionOpenDialogAction$',
    new Subject<AtlassianOnPremiseConnectionDetails>()
);
export const atlassianOnPremiseManageConnectionCloseDialogAction$ = monitor(
    'atlassianOnPremiseManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const atlassianOnPremiseAppSetupOpenDialogAction$ = monitor(
    'atlassianOnPremiseAppSetupOpenDialogAction$',
    new Subject<AtlassianOnPremiseConnectionDetails>()
);
export const atlassianOnPremiseAppSetupCloseDialogAction$ = monitor(
    'atlassianOnPremiseAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const atlassianOnPremiseAppSetupDialogOpen$ = monitor(
    'atlassianOnPremiseAppSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianOnPremiseAppSetupAuthorizeAction$ = monitor(
    'atlassianOnPremiseAppSetupAuthorizeAction$',
    new Subject<AtlassianOnPremiseAppSetupSaveProps>()
);
export const atlassianOnPremiseAppSetupDialogErrors$ = monitor(
    'atlassianOnPremiseAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const atlassianOnPremiseSaveConnectionAction$ = monitor(
    'atlassianOnPremiseSaveConnectionAction$',
    new Subject<string>()
);
export const atlassianOnPremiseConnectionCreatedAction$ = monitor(
    'atlassianOnPremiseConnectionCreatedAction$',
    new Subject<string>()
);
export const atlassianOnPremiseConnectionSavedAction$ = monitor(
    'atlassianOnPremiseConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const atlassianOnPremiseManageConnectionInitiateSetupAction$ = monitor(
    'atlassianOnPremiseManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const atlassianOnPremiseManageConnectionDialogOpen$ = monitor(
    'atlassianOnPremiseManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianOnPremiseConnectionSaving$ = monitor(
    'atlassianOnPremiseConnectionSaving$',
    new BehaviorSubject(false)
);
export const atlassianOnPremiseManageConnectionAuthorizeLoading$ = monitor(
    'atlassianOnPremiseManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const atlassianOnPremiseManageConnectionDetails$ = monitor(
    'atlassianOnPremiseManageConnectionDetails$',
    new BehaviorSubject<AtlassianOnPremiseConnectionDetails | undefined>(undefined)
);
export const atlassianOnPremiseManageConnectionDialogErrors$ = monitor(
    'atlassianOnPremiseManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const atlassianOnPremiseAppSetupDialogStage$ = monitor(
    'atlassianOnPremiseAppSetupDialogStage$',
    new BehaviorSubject(0)
);

atlassianOnPremiseAppSetupOpenDialogAction$.subscribe((details) => {
    atlassianOnPremiseAppSetupDialogErrors$.next(undefined);
    atlassianOnPremiseManageConnectionDialogErrors$.next(undefined);
    atlassianOnPremiseAppSetupDialogOpen$.next(true);
    atlassianOnPremiseAppSetupDialogStage$.next(0);
    atlassianOnPremiseManageConnectionDetails$.next(details);
});

atlassianOnPremiseAppSetupCloseDialogAction$.subscribe(() => {
    atlassianOnPremiseAppSetupDialogOpen$.next(false);
    atlassianOnPremiseManageConnectionAuthorizeLoading$.next(false);
});

atlassianOnPremiseManageConnectionOpenDialogAction$.subscribe((details) => {
    atlassianOnPremiseAppSetupDialogErrors$.next(undefined);
    atlassianOnPremiseManageConnectionDialogErrors$.next(undefined);
    atlassianOnPremiseManageConnectionDialogOpen$.next(true);
    atlassianOnPremiseManageConnectionDetails$.next(details);
});

atlassianOnPremiseManageConnectionCloseDialogAction$.subscribe(() => {
    atlassianOnPremiseManageConnectionDialogOpen$.next(false);
    atlassianOnPremiseAppSetupDialogOpen$.next(false);
    atlassianOnPremiseManageConnectionDetails$.next(undefined);
});

atlassianOnPremiseConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

atlassianOnPremiseAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            atlassianOnPremiseConnectionSaving$.next(true);
            atlassianOnPremiseAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = atlassianOnPremiseManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Atlassian On-Premise connector type not defined');
                }

                if (!uid) {
                    throw Error('Atlassian On-Premise connection has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.atlassianOnPremise?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Atlassian On-Premise Connector URL configured in meta');
                }
                const connectionTypeName = connectionType.name.toLowerCase().replaceAll(' ', '-');
                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: connectionTypeName,
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, atlassianOnPremiseManageConnectionAuthorizeLoading$);

                atlassianOnPremiseConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof TimeoutError) {
                    atlassianOnPremiseAppSetupDialogErrors$.next(e.message);
                } else {
                    atlassianOnPremiseAppSetupDialogErrors$.next(
                        'Failed to save Atlassian On-Premise application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Atlassian On-Premise application info', e);
                }
            }

            atlassianOnPremiseConnectionSaving$.next(false);
            atlassianOnPremiseManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

atlassianOnPremiseSaveConnectionAction$
    .pipe(
        map(async (name) => {
            atlassianOnPremiseConnectionSaving$.next(true);
            atlassianOnPremiseManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = atlassianOnPremiseManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Atlassian On-Premise connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveAtlassianOnPremiseConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveAtlassianOnPremiseConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );
                                atlassianOnPremiseConnectionCreatedAction$.next(uid);
                                atlassianOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
                                atlassianOnPremiseManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    atlassianOnPremiseManageConnectionDialogErrors$,
                                    ATLASSIAN_ON_PREMISE_APP
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(
                    e,
                    atlassianOnPremiseManageConnectionDialogErrors$,
                    ATLASSIAN_ON_PREMISE_APP
                );
            }

            atlassianOnPremiseConnectionSaving$.next(false);
        })
    )
    .subscribe();

atlassianOnPremiseManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            atlassianOnPremiseConnectionSaving$.next(true);
            atlassianOnPremiseManageConnectionAuthorizeLoading$.next(true);
            atlassianOnPremiseManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                authorized,
                ...restOfDetails
            } = atlassianOnPremiseManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Atlassian On-Premise connector type not defined');
                }

                const baseUrl = configTopic$.value.connection?.atlassianOnPremise?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Atlassian On-Premise Connector url configured in meta');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    if (authorized) {
                        promptQuestion({
                            title: 'By re-authorizing, a new public-private key pair will be automatically regenerated, which will invalidate your existing connector setup. Are you sure you want to proceed?',
                            onProceed: async () => {
                                const { publicCertificate: publicKey } = await generateConnectorCertificate(
                                    {
                                        connectionId: uid,
                                    },
                                    `${baseUrl}/generate`
                                );
                                atlassianOnPremiseAppSetupOpenDialogAction$.next({
                                    ...restOfDetails,
                                    uid,
                                    connectionType,
                                    name,
                                    publicKey,
                                });
                            },
                            onCancel: async () => {
                                atlassianOnPremiseManageConnectionAuthorizeLoading$.next(false);
                            },
                        });
                    } else {
                        const { publicCertificate: publicKey } = await generateConnectorCertificate(
                            {
                                connectionId: uid,
                            },
                            `${baseUrl}/generate`
                        );
                        atlassianOnPremiseAppSetupOpenDialogAction$.next({
                            ...restOfDetails,
                            uid,
                            connectionType,
                            name,
                            publicKey,
                        });
                    }
                    atlassianOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    atlassianOnPremiseConnectionCreatedAction$.next(uid);
                    const { publicCertificate: publicKey } = await generateConnectorCertificate(
                        {
                            connectionId: uid,
                        },
                        `${baseUrl}/generate`
                    );
                    atlassianOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
                    atlassianOnPremiseManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    atlassianOnPremiseConnectionSaving$.next(false);
                    atlassianOnPremiseAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                        publicKey,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(
                    e,
                    atlassianOnPremiseManageConnectionDialogErrors$,
                    ATLASSIAN_ON_PREMISE_APP
                );
                atlassianOnPremiseManageConnectionAuthorizeLoading$.next(false);
            }

            atlassianOnPremiseConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(atlassianOnPremiseConnectionSavedAction$);

const saveAtlassianOnPremiseConnection = async (
    uid: string,
    name: string,
    connectionTypeUid: string
): Promise<void> => {
    try {
        await saveConnection(uid, name);

        atlassianOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
        atlassianOnPremiseManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, atlassianOnPremiseManageConnectionDialogErrors$, ATLASSIAN_ON_PREMISE_APP);
    }
};

const ATLASSIAN_ON_PREMISE_APP = 'Atlassian On-Premise';
