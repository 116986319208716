import { styled } from '@mui/material';
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ReportTwoToneIcon from '@mui/icons-material/ReportTwoTone';
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningTwoTone';

interface ConsoleLogTypeIconProps {
    type: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS';
}

const StyledBugReportTwoToneIcon = styled(BugReportTwoToneIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}));
const StyledInfoTwoToneIcon = styled(InfoTwoToneIcon)(({ theme }) => ({
    color: theme.palette.info.main,
}));
const StyledWarningAmberTwoToneIcon = styled(WarningAmberTwoToneIcon)(({ theme }) => ({
    color: theme.palette.warning.main,
}));
const StyledReportTwoToneIcon = styled(ReportTwoToneIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}));
const StyledCheckCircleTwoToneIcon = styled(CheckCircleTwoToneIcon)(({ theme }) => ({
    color: theme.palette.success.main,
}));

export const ConsoleLogTypeIcon: React.FC<ConsoleLogTypeIconProps> = ({ type }) => {
    switch (type) {
        case 'DEBUG':
            return <StyledBugReportTwoToneIcon />;
        case 'INFO':
            return <StyledInfoTwoToneIcon />;
        case 'WARN':
            return <StyledWarningAmberTwoToneIcon />;
        case 'ERROR':
            return <StyledReportTwoToneIcon />;
        case 'SUCCESS':
            return <StyledCheckCircleTwoToneIcon />;
        default:
            return <HelpTwoToneIcon />;
    }
};
