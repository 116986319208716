import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { PropsWithChildren, useState } from 'react';
import { IconButton } from '../common/buttons/IconButton';

interface SetupGuideSummaryBoxProps {
    icon?: JSX.Element;
    paragraphs?: (string | JSX.Element)[];
    title: string;
}

const StyledSummaryBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: theme.constants.borderRadius,
    maxWidth: 688,
    width: '100%',
}));

const StyledSummaryBoxHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: alpha(theme.palette.info.light, 0.2), // Need a new theme colour, info.background
    height: 68,
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1.5),
}));

export const StyledSummaryBoxIconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44,
    backgroundColor: theme.palette.info.main,
    borderRadius: theme.constants.borderRadius,
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
    },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    borderColor: theme.palette.info.light,
}));

const StyledSummaryBoxContent = styled(Box)(({ theme }) => ({
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}));

export const SetupGuideSummaryBox: React.FC<PropsWithChildren<SetupGuideSummaryBoxProps>> = ({
    icon,
    paragraphs = [],
    title,
    children,
}) => {
    const [summaryOpen, setSummaryOpen] = useState(true);

    const displayedParagraphs = paragraphs.map((p, i) =>
        typeof p === 'string' ? (
            <Typography key={`summarybox-paragraph-${i}`} color="text.secondary">
                {p}
            </Typography>
        ) : (
            p
        )
    );

    return (
        <StyledSummaryBox>
            <StyledSummaryBoxHeader>
                {icon && <StyledSummaryBoxIconContainer>{icon}</StyledSummaryBoxIconContainer>}
                <Typography variant="h6">{title}</Typography>
                <IconButton
                    aria-label={summaryOpen ? 'Collapse summary' : 'Expand summary'}
                    icon={summaryOpen ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                    tooltip={summaryOpen ? 'Collapse summary' : 'Expand summary'}
                    onClick={() => setSummaryOpen(!summaryOpen)}
                ></IconButton>
            </StyledSummaryBoxHeader>
            {summaryOpen && (
                <>
                    <StyledDivider />
                    <StyledSummaryBoxContent>
                        {displayedParagraphs}
                        {children}
                    </StyledSummaryBoxContent>
                </>
            )}
        </StyledSummaryBox>
    );
};
