import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import { DialogAlert } from '../for-deprecation/dialog/DialogComponents';
import { Button } from '../common/buttons/Button';
import Box from '@mui/material/Box';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LoopIcon from '@mui/icons-material/Loop';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';
import { Tooltip, styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export interface LegacyPackageDialogProps {
    open: boolean;
    onDialogOpen(isOpen: boolean): void;
    onPackageNameReplacement(): void;
    legacyImports: string[] | null;
    onCopyImports(content: string): void;
    errors?: string;
    saving?: boolean;
}

interface OptionAccordionProps {
    content: JSX.Element;
    title: string;
    description: string;
    icon: JSX.Element;
}

interface StepItemProps {
    stepNumber: number;
    stepDescription: string | JSX.Element;
    stepContent?: JSX.Element;
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper': {
        marginBottom: theme.spacing(4),
    },
    '&:before': {
        display: 'none',
    },
}));

const StyledBoxStepContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    border: `1px solid ${theme.palette.action.selected}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledBoxCircular = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.constants.radiusCircle,
}));

const StyledContentCopyIcon = styled(ContentCopyIcon)({
    cursor: 'pointer',
});

const StyledMultilineTextField = styled(TextField)({
    width: '100%',
    '& .MuiInputBase-root': {
        height: 'auto',
        width: '100%',
    },
    margin: 0,
});

export const OptionAccordion: React.FC<OptionAccordionProps> = ({ content, title, description, icon }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <StyledAccordion expanded={expanded} disableGutters>
            <AccordionSummary
                onClick={() => setExpanded((prev) => !prev)}
                expandIcon={expanded ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Box mr={2}>{icon}</Box>
                <Box display="flex" flexDirection="column">
                    <Typography fontWeight="bold" fontSize="htmlFontSize">
                        {title}
                    </Typography>
                    <Typography>{description}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
        </StyledAccordion>
    );
};

const StepItem: React.FC<StepItemProps> = ({ stepNumber, stepDescription, stepContent }) => {
    return (
        <StyledBoxStepContainer padding={2}>
            <Box display="flex" alignItems="center">
                <StyledBoxCircular
                    display="flex"
                    width={24}
                    height={24}
                    justifyContent="center"
                    alignItems="center"
                    flexShrink={0}
                    mr={1}
                >
                    <Typography fontWeight="bold">{stepNumber}</Typography>
                </StyledBoxCircular>
                <Typography>{stepDescription}</Typography>
            </Box>
            {stepContent && (
                <StyledBoxStepContainer padding={2} mt={2}>
                    {stepContent}
                </StyledBoxStepContainer>
            )}
        </StyledBoxStepContainer>
    );
};

export const LegacyPackageDialog: React.FC<LegacyPackageDialogProps> = ({
    open,
    errors,
    saving = false,
    onDialogOpen,
    onPackageNameReplacement,
    legacyImports,
    onCopyImports,
}) => {
    const newImports = legacyImports?.map((l) => l.replace('stitch-it', 'sr-connect') + ';').join('\n');
    const optionOneContent = (
        <Stack direction="column" spacing={1}>
            <StepItem
                stepNumber={1}
                stepDescription={
                    <>
                        Copy updated <strong>Core</strong> and/or <strong>Managed API</strong> import statements for
                        your script:
                    </>
                }
                stepContent={
                    <>
                        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography fontWeight="bold" lineHeight={1}>
                                Update
                            </Typography>
                            <Tooltip title="Copy updated import statements" placement="top">
                                <StyledContentCopyIcon
                                    color="primary"
                                    onClick={() => onCopyImports(newImports ?? '')}
                                />
                            </Tooltip>
                        </Box>
                        <StyledMultilineTextField
                            placeholder="Enter a message"
                            variant="outlined"
                            multiline
                            value={newImports}
                            required
                            inputProps={{
                                spellCheck: false,
                            }}
                        />
                    </>
                }
            />
            <StepItem stepNumber={2} stepDescription={'Replace outdated imports with the copied ones.'} />
            <StepItem
                stepNumber={3}
                stepDescription={
                    'Save and test the updated script for errors or problems, optionally triggering it if desired.'
                }
            />
            <StepItem stepNumber={4} stepDescription={'Choose "No" to initiate the manual upgrade process.'} />
        </Stack>
    );
    const optionTwoContent = (
        <Stack direction="column" spacing={1}>
            <StepItem
                stepNumber={1}
                stepDescription={
                    'The system will handle the necessary changes to update the import statements from the legacy package.'
                }
            />
            <StepItem
                stepNumber={2}
                stepDescription={
                    'After the migration process, ensure your script runs smoothly without errors or issues.'
                }
            />
            <StepItem stepNumber={3} stepDescription={'Save your script.'} />
            <StepItem stepNumber={4} stepDescription={'Choose "Yes" to initiate the automatic upgrade process.'} />
        </Stack>
    );
    return (
        <Dialog open={open}>
            <DialogContent>
                <Typography variant="h6">Upgrade Legacy Packages</Typography>
                <Typography mb={2} fontSize="htmlFontSize">
                    Choose one of the following options to proceed with the migration:
                </Typography>
                <DialogAlert
                    severity="warning"
                    title="Legacy Package(s) Detected"
                    text="Replace the import statement in your script with a newer version of the package to improve script functionality and benefit from updated features and improvements."
                />
                {errors && <DialogAlert severity="error" title={errors} />}
                <Stack direction="column" spacing={2}>
                    <OptionAccordion
                        title="Option 1: Manual Migration"
                        description="Take control by replacing the import statement to update the legacy package in your script."
                        content={optionOneContent}
                        icon={<EditNoteIcon fontSize="large" />}
                    />
                    <OptionAccordion
                        title="Option 2: Automatic Migration"
                        description="Let the system handle the migration for you, automatically replacing the import statements
                            with the newer version."
                        content={optionTwoContent}
                        icon={<LoopIcon fontSize="large" />}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button busy={saving} variant="outlined" onClick={() => onDialogOpen(false)}>
                    No, Manually Migrate
                </Button>
                <Button busy={saving} onClick={onPackageNameReplacement}>
                    Yes, Automatically Migrate
                </Button>
            </DialogActions>
        </Dialog>
    );
};
