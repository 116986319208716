import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import ListItem from '@mui/material/ListItem';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';

interface SlackEventListenerSetupDialogProps {
    eventTypeCategory: string;
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const SlackEventListenerSetupDialog: React.FC<SlackEventListenerSetupDialogProps> = ({
    eventTypeCategory,
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const appName = 'Slack';
    const isSlashCommand = eventTypeName === 'Slash Command';

    const isInteractiveComponentEvent = eventTypeCategory === 'Interactive Component';

    const isSlackEvent = !isSlashCommand && !isInteractiveComponentEvent;

    const eventPath = isSlashCommand
        ? '/slash-commands'
        : isInteractiveComponentEvent
        ? '/interactive-messages'
        : '/event-subscriptions';

    const eventType = isSlashCommand
        ? 'Slash Commands'
        : isInteractiveComponentEvent
        ? 'Interactivity & Shortcuts'
        : 'Event Subscriptions';

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <>
                            {isSlashCommand && (
                                <Typography color="text.secondary">
                                    Configure the slash command in your Slack app in order to listen to events.
                                </Typography>
                            )}
                            {isInteractiveComponentEvent && (
                                <Typography color="text.secondary">
                                    Enable interactivity in your Slack app in order to listen to interaction and
                                    shortcut events.
                                </Typography>
                            )}
                            {isSlackEvent && (
                                <Typography color="text.secondary">
                                    Enable event subscriptions in your Slack app in order to listen to Slack events.
                                </Typography>
                            )}
                        </>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                {setupBaseUrl && (
                                    <Typography>
                                        Visit the{' '}
                                        <Link target="_blank" href={`${setupBaseUrl}${eventPath}`}>
                                            {eventType}
                                        </Link>{' '}
                                        page.
                                    </Typography>
                                )}
                                <Typography>
                                    {!setupBaseUrl && (
                                        <li>
                                            Open https://api.slack.com/apps/[YOUR_SLACK_APP_ID]{`${eventPath}`} URL in
                                            your browser
                                        </li>
                                    )}
                                </Typography>
                            </ListItem>
                            {isSlashCommand && (
                                <>
                                    <ListItem>
                                        <Typography>
                                            Click Create <strong>New Command</strong> or edit an existing command if the
                                            slash command already exists.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Enter a slash command beginning with <strong>/</strong> into the{' '}
                                            <strong>Command</strong> field.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Paste the copied URL into the <strong>Request URL</strong> field.
                                        </Typography>
                                    </ListItem>
                                    <OutlinedReadOnlyTextField
                                        label="Webhook URL"
                                        value={`${webhookBaseUrl}/${webhookUrlId}`}
                                    />
                                    <ListItem>
                                        <Typography>
                                            Enter a short description of the command in the{' '}
                                            <strong>Short Description</strong> field.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Then click <strong>Save</strong>.
                                        </Typography>
                                    </ListItem>
                                </>
                            )}
                            {isSlackEvent && (
                                <>
                                    <ListItem>
                                        <Typography>
                                            Switch on the <strong>Enable Events</strong> toggle.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Paste the copied URL into the <strong>Request URL</strong> field. Slack will
                                            now verify the URL. If it fails, click on retry. If it fails again, please
                                            contact support.
                                        </Typography>
                                    </ListItem>
                                    <OutlinedReadOnlyTextField
                                        label="Webhook URL"
                                        value={`${webhookBaseUrl}/${webhookUrlId}`}
                                    />
                                    <ListItem>
                                        <Typography>
                                            Scroll down to <strong>Subscribe to bot events</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Click on <strong>Add Bot User Event</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Add the event:{' '}
                                            <strong>{eventTypeName.toLowerCase().replaceAll(' ', '_')}</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Click on <strong>Save Changes</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Re-install the app if you are prompted to do so. This will trigger Slack to
                                            add missing bot permissions to your Slack app
                                        </Typography>
                                    </ListItem>
                                </>
                            )}
                            {isInteractiveComponentEvent && (
                                <>
                                    <ListItem>
                                        <Typography>
                                            Switch on the <strong>Interactivity</strong> toggle.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Paste the copied URL into the <strong>Request URL</strong> field. Slack will
                                            now verify the URL. If it fails, click on retry. If it fails again, please
                                            contact support.
                                        </Typography>
                                    </ListItem>
                                    <OutlinedReadOnlyTextField
                                        label="Webhook URL"
                                        value={`${webhookBaseUrl}/${webhookUrlId}`}
                                    />
                                    <ListItem>
                                        <Typography>
                                            If you want to listen to your Slack apps's shortcut events then scroll down
                                            to <strong>Shortcuts</strong>. Click on <strong>Create New Shortcut</strong>{' '}
                                            and follow the Slack instructions.
                                        </Typography>
                                    </ListItem>
                                </>
                            )}
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
