import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { Button } from '../common/buttons/Button';
import { ButtonGroup } from '../common/buttons/button-groups/ButtonGroup';
import { IconButton } from '../common/buttons/IconButton';
import { TextField } from '../common/inputs/TextField';
import { documentationUrl, supportPortalUrl, setupGuideYoutubeVideoUrl } from '../../utils/documentation';
import { handleKeyDown } from '../../utils/handleKeyDown';

interface SetupGuideHeaderProps {
    readmeOpen: boolean;
    workspaceLocked: boolean;
    workspaceName: string;
    workspaceNameSaving?: boolean;
    onChangeWorkspaceName: (name: string) => void;
    onNavigateToAdvancedView: () => void;
    onExitSetupGuide: () => void;
    onToggleReadme: () => void;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid ' + theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    '&.MuiToolbar-root': {
        padding: theme.spacing(1.5),
    },
}));

const StyledLeftContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flex: '1 0 0',
    gap: theme.spacing(1.5),
}));

const StyledMiddleContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    flex: '1 0 0',
    minWidth: 300,
}));

const StyledRightContainer = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 0 0',
    gap: theme.spacing(1.5),
}));

const StyledEditableArea = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledWorkspaceNameContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    justifyContent: 'center',
    maxWidth: '100%',
}));

export const SetupGuideHeader: React.FC<SetupGuideHeaderProps> = ({
    readmeOpen,
    workspaceLocked,
    workspaceName,
    workspaceNameSaving,
    onChangeWorkspaceName,
    onNavigateToAdvancedView,
    onExitSetupGuide,
    onToggleReadme,
}) => {
    const [currentWorkspaceName, setCurrentWorkspaceName] = useState(workspaceName);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (!workspaceNameSaving) {
            setFocused(false);
        }
    }, [workspaceNameSaving]);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleCancel = (): void => {
        setCurrentWorkspaceName(workspaceName);
        setFocused(false);
    };

    const canApplyWorkspaceNameChange = !!currentWorkspaceName && currentWorkspaceName !== workspaceName;

    const editableWorkspaceName = focused ? (
        <StyledEditableArea>
            <TextField
                autoFocus
                endIcon={
                    <IconButton
                        aria-label="Cancel editing"
                        icon={<CancelIcon />}
                        tooltip="Cancel editing"
                        onClick={() => handleCancel()}
                    />
                }
                inputRef={inputRef}
                label="Workspace name"
                placeholder="Enter new name"
                size="small"
                value={currentWorkspaceName}
                onChange={(event) => setCurrentWorkspaceName(event.target.value)}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canApplyWorkspaceNameChange,
                        enterFn: () => onChangeWorkspaceName(currentWorkspaceName),
                        escFn: handleCancel,
                    })
                }
            />
            <Button
                busy={workspaceNameSaving}
                disabled={!canApplyWorkspaceNameChange}
                onClick={() => onChangeWorkspaceName(currentWorkspaceName)}
            >
                Update
            </Button>
        </StyledEditableArea>
    ) : (
        <StyledWorkspaceNameContainer>
            <Tooltip title={workspaceName}>
                <Typography noWrap variant="h6">
                    {workspaceName}
                </Typography>
            </Tooltip>
            <IconButton
                aria-label="Edit workspace name"
                disabled={workspaceLocked}
                icon={<EditOutlinedIcon />}
                tooltip="Edit workspace name"
                onClick={() => setFocused(true)}
            />
        </StyledWorkspaceNameContainer>
    );

    return (
        <>
            <StyledToolbar>
                <StyledLeftContainer>
                    <Button
                        onClick={onNavigateToAdvancedView}
                        disabled={workspaceLocked}
                        startIcon={<ExitToAppOutlinedIcon />}
                        variant="outlined"
                    >
                        Advanced view
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <IconButton
                        aria-label="Documentation"
                        border
                        icon={<MenuBookOutlinedIcon />}
                        tooltip="Documentation"
                        onClick={() => window.open(documentationUrl)}
                    />
                    <IconButton
                        aria-label="Video tutorials"
                        border
                        icon={<VideoLibraryOutlinedIcon />}
                        tooltip="Video tutorials"
                        onClick={() => window.open(setupGuideYoutubeVideoUrl)}
                    />
                    <IconButton
                        aria-label="Support"
                        border
                        icon={<QuestionAnswerOutlinedIcon />}
                        tooltip="Support"
                        onClick={() => window.open(supportPortalUrl)}
                    />
                </StyledLeftContainer>
                <StyledMiddleContainer>{editableWorkspaceName}</StyledMiddleContainer>
                <StyledRightContainer>
                    <Tooltip title={readmeOpen ? 'Hide README' : 'Show README'}>
                        <Box>
                            <ButtonGroup // Wrap in Box to get rid of REACT warning
                                buttons={[
                                    {
                                        icon: <IntegrationInstructionsOutlinedIcon />,
                                        label: 'README',
                                        value: 'readme',
                                        selected: readmeOpen,
                                        onClick: onToggleReadme,
                                    },
                                ]}
                            />
                        </Box>
                    </Tooltip>
                    <Button onClick={onExitSetupGuide}>Exit setup guide</Button>
                </StyledRightContainer>
            </StyledToolbar>
        </>
    );
};
