import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styled, useTheme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { DialogAlert, DialogCloseButton } from '../../../for-deprecation/dialog/DialogComponents';
import { Button } from '../../../common/buttons/Button';
import { ChangeOrganizationPlanConfirmationContent } from '../change-organization-plan-wizard/ChangeOrganizationPlanConfirmationContent';
import { OrganizationPlan } from '../../../../data/organization';

export interface CancelOrganizationPlanConfirmationDialogProps {
    open?: boolean;
    saving?: boolean;
    currentPlan: OrganizationPlan;
    planEndDate?: Date;
    errors?: string;
    supportPortalUrl: string;
    onCancel?: () => void;
    onProceed?: () => void;
}

const StyledDialogContent = styled(DialogContent)(() => ({
    padding: 0,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
}));

const StyledDialogInstructions = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.text.primary}`,
}));

const StyledCurrentPlanHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    background: theme.palette.text.primary,
    color: theme.palette.background.paper,
}));

const StyledSubtitleSpan = styled('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledContactUsAlert = styled(DialogAlert)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
}));

export const CancelOrganizationPlanConfirmationDialog: React.FC<CancelOrganizationPlanConfirmationDialogProps> = ({
    open = false,
    saving = false,
    currentPlan,
    planEndDate,
    errors,
    supportPortalUrl,
    onCancel,
    onProceed,
}) => {
    const theme = useTheme();

    return (
        <Dialog open={open}>
            <div>
                <DialogCloseButton disabled={saving} onClick={onCancel} />
                <StyledDialogTitle variant="h4">Cancel Plan</StyledDialogTitle>
                <StyledDialogInstructions>Are you sure you want to cancel this plan?</StyledDialogInstructions>
            </div>
            {errors && <DialogAlert severity="error" title={errors} />}
            <StyledDialogContent>
                <StyledCard elevation={1}>
                    <StyledCurrentPlanHeader>
                        <Typography component="h4">Your Plan</Typography>
                    </StyledCurrentPlanHeader>
                    <Divider />
                    <ChangeOrganizationPlanConfirmationContent
                        plan={currentPlan}
                        featureMessage="You will lose access to the following:"
                        listIcon={<HighlightOffIcon />}
                        listIconColor={theme.palette.error.main}
                        cancellationDate={planEndDate}
                        finalAlert={{
                            severity: 'warning',
                            text: 'Workspaces stop running at the end of the plan period rather than immediately.',
                            title: 'Cancellation disables workspace functionality',
                        }}
                    />
                </StyledCard>
                <StyledContactUsAlert
                    severity="info"
                    text={<StyledSubtitleSpan>We can resolve any issues by email, text or phone</StyledSubtitleSpan>}
                    title="How can we help?"
                    icon={false}
                    action={
                        <Button
                            busy={saving}
                            variant="outlined"
                            onClick={() => window.open(supportPortalUrl, '_blank')}
                        >
                            Contact us
                        </Button>
                    }
                />
                <Typography variant="body2" color="text.secondary">
                    Disclaimer: Refunds are carried out on a case by case basis. You can change your plan at any time.
                </Typography>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button busy={saving} variant="outlined" onClick={onCancel}>
                    No, go back
                </Button>
                <Button color="error" busy={saving} onClick={onProceed}>
                    Yes, cancel plan
                </Button>
            </StyledDialogActions>
        </Dialog>
    );
};
