import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../common/buttons/Button';
import { CreateBillingDetailsEvent, CreateBillingEntityModal } from './CreateBillingEntityModal';
import { PageContainer, PageTitle } from '../../app-main/PageComponentsForDeprecation';
import { SavedBillingDetailsList } from './SavedBillingDetailsList';
import { BillingEntities } from '../../../data/billing-details';

interface BillingDetailsProps {
    billingEntities: BillingEntities;
    createBillingEntityError?: string;
    billingEntityIsCreating: boolean;
    createBillingEntityDialogOpen: boolean;
    onEditBillingDetails(uid: string): void;
    onCreateBillingEntity(event: CreateBillingDetailsEvent): void;
    onBillingEntityDialogOpen(open: boolean): void;
}

const StyledUpperContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

export const BillingDetails: React.FC<BillingDetailsProps> = ({
    billingEntities,
    createBillingEntityError,
    billingEntityIsCreating,
    createBillingEntityDialogOpen,
    onCreateBillingEntity,
    onEditBillingDetails,
    onBillingEntityDialogOpen,
}) => {
    const remappedBillingEntities = billingEntities?.map((e) => {
        return {
            ...e,
            name: e.contactName,
            email: e.contactEmail,
            organizations: e.associatedOrganizations,
        };
    });
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledUpperContainer>
                <PageTitle title="Billing details" />
                <Button onClick={() => onBillingEntityDialogOpen(true)} startIcon={<AddIcon />}>
                    Create New
                </Button>
            </StyledUpperContainer>
            {remappedBillingEntities.length > 0 ? (
                <SavedBillingDetailsList
                    billingEntities={remappedBillingEntities}
                    onEditBillingDetails={onEditBillingDetails}
                />
            ) : (
                <Typography>No billing details found.</Typography>
            )}
            <CreateBillingEntityModal
                open={createBillingEntityDialogOpen}
                errors={createBillingEntityError}
                saving={billingEntityIsCreating}
                onSave={onCreateBillingEntity}
                onCancel={() => onBillingEntityDialogOpen(false)}
            />
        </PageContainer>
    );
};
