import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { UserAuditLogsResponse } from '../../../data/reporting';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';

interface AuditLogTableRowProps {
    auditLog: UserAuditLogsResponse['auditLogs'][number];
    basePath: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastPostRef?: (post: any) => void;
}

const StyledTableCell = styled(TableCell)(() => ({
    borderBottom: 'unset',
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AuditLogTableRow: React.FC<AuditLogTableRowProps> = ({ auditLog, basePath, lastPostRef }) => {
    const [open, setOpen] = useState(false);

    const workspaceUrl =
        auditLog.relatedWorkspace && !auditLog.relatedWorkspace.deleted
            ? `${basePath}workspace/${auditLog.relatedWorkspace.uid}`
            : undefined;
    const workspaceWithDefaultEnvUrl =
        auditLog.relatedWorkspace?.selectedEnv && !auditLog.relatedWorkspace.deleted
            ? `${basePath}workspace/${auditLog.relatedWorkspace.uid}/environment/${auditLog.relatedWorkspace.selectedEnv}`
            : undefined;

    const hasRelatedEntities =
        !!auditLog.relatedApiHandler ||
        !!auditLog.relatedBillingEntity ||
        !!auditLog.relatedConnection ||
        !!auditLog.relatedDeployment ||
        !!auditLog.relatedEnvironment ||
        !!auditLog.relatedEnvironmentVariable ||
        !!auditLog.relatedEventListener ||
        !!auditLog.relatedEventListenerTestPayload ||
        !!auditLog.relatedOrganization ||
        !!auditLog.relatedReadmeFile ||
        !!auditLog.relatedScheduledTrigger ||
        !!auditLog.relatedScript ||
        !!auditLog.relatedTemplate ||
        !!auditLog.relatedUser ||
        !!auditLog.relatedWorkspace;

    return (
        <>
            <TableRow ref={lastPostRef} onClick={() => setOpen(!open)}>
                <StyledTableCell>{auditLog.actor.name}</StyledTableCell>
                <StyledTableCell>{auditLog.action}</StyledTableCell>
                <StyledTableCell>{auditLog.actorIp}</StyledTableCell>
                <StyledTableCell>{new Date(auditLog.date).toLocaleString()}</StyledTableCell>
                {hasRelatedEntities || auditLog.metadata ? (
                    <StyledTableCell>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</StyledTableCell>
                ) : (
                    <StyledTableCell />
                )}
            </TableRow>
            {(hasRelatedEntities || auditLog.metadata) && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                {hasRelatedEntities && (
                                    <Typography variant="h6" gutterBottom component="div">
                                        Related Entities
                                    </Typography>
                                )}
                                <List dense>
                                    {auditLog.relatedUser && (
                                        <ListItem>
                                            <ListItemText>Related user: {auditLog.relatedUser.name}</ListItemText>
                                        </ListItem>
                                    )}
                                    {auditLog.relatedWorkspace && (
                                        <ListItem>
                                            {auditLog.relatedWorkspace.deleted ? (
                                                <ListItemText>
                                                    Related workspace: {auditLog.relatedWorkspace.name} (
                                                    {auditLog.relatedWorkspace.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related workspace:{' '}
                                                    <Link href={`${workspaceWithDefaultEnvUrl}`} target="_blank">
                                                        {auditLog.relatedWorkspace.name} (
                                                        {auditLog.relatedWorkspace.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related workspace: {auditLog.relatedWorkspace.name} (
                                                    {auditLog.relatedWorkspace.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedScript && (
                                        <ListItem>
                                            {auditLog.relatedScript.deleted ? (
                                                <ListItemText>
                                                    Related script: {auditLog.relatedScript.name} (
                                                    {auditLog.relatedScript.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related script:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/script/${auditLog.relatedScript.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedScript.name} ({auditLog.relatedScript.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related script: {auditLog.relatedScript.name} (
                                                    {auditLog.relatedScript.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedApiHandler && (
                                        <ListItem>
                                            {auditLog.relatedApiHandler.deleted ? (
                                                <ListItemText>
                                                    Related api connection:{' '}
                                                    {auditLog.relatedApiHandler.path
                                                        ? `${auditLog.relatedApiHandler.path} (${auditLog.relatedApiHandler.uid})`
                                                        : auditLog.relatedApiHandler.uid}{' '}
                                                    (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related api connection:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/api/${auditLog.relatedApiHandler.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedApiHandler.path
                                                            ? `${auditLog.relatedApiHandler.path} (${auditLog.relatedApiHandler.uid})`
                                                            : auditLog.relatedApiHandler.uid}
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related api connection:{' '}
                                                    {auditLog.relatedApiHandler.path
                                                        ? `${auditLog.relatedApiHandler.path} (${auditLog.relatedApiHandler.uid})`
                                                        : auditLog.relatedApiHandler.uid}
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedEventListener && (
                                        <ListItem>
                                            {auditLog.relatedEventListener.deleted ? (
                                                <ListItemText>
                                                    Related event listener: {auditLog.relatedEventListener.name} (
                                                    {auditLog.relatedEventListener.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related event listener:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/event/${auditLog.relatedEventListener.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedEventListener.name} (
                                                        {auditLog.relatedEventListener.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related event listener: {auditLog.relatedEventListener.name} (
                                                    {auditLog.relatedEventListener.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedEventListenerTestPayload && (
                                        <ListItem>
                                            {auditLog.relatedEventListenerTestPayload.deleted ? (
                                                <ListItemText>
                                                    Related event listener test payload:{' '}
                                                    {auditLog.relatedEventListenerTestPayload.name} (
                                                    {auditLog.relatedEventListenerTestPayload.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl && auditLog.relatedEventListener?.uid ? (
                                                <ListItemText>
                                                    Related event listener test payload:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/event/${auditLog.relatedEventListener.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedEventListenerTestPayload.name} (
                                                        {auditLog.relatedEventListenerTestPayload.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related event listener test payload:{' '}
                                                    {auditLog.relatedEventListenerTestPayload.name} (
                                                    {auditLog.relatedEventListenerTestPayload.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedScheduledTrigger && (
                                        <ListItem>
                                            {auditLog.relatedScheduledTrigger.deleted ? (
                                                <ListItemText>
                                                    Related scheduled trigger:{' '}
                                                    {auditLog.relatedScheduledTrigger.cronExpression
                                                        ? `${auditLog.relatedScheduledTrigger.cronExpression} (${auditLog.relatedScheduledTrigger.uid})`
                                                        : auditLog.relatedScheduledTrigger.uid}{' '}
                                                    (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related scheduled trigger:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/scheduled/${auditLog.relatedScheduledTrigger.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedScheduledTrigger.cronExpression
                                                            ? `${auditLog.relatedScheduledTrigger.cronExpression} (${auditLog.relatedScheduledTrigger.uid})`
                                                            : auditLog.relatedScheduledTrigger.uid}
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related scheduled trigger:{' '}
                                                    {auditLog.relatedScheduledTrigger.cronExpression
                                                        ? `${auditLog.relatedScheduledTrigger.cronExpression} (${auditLog.relatedScheduledTrigger.uid})`
                                                        : auditLog.relatedScheduledTrigger.uid}
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedReadmeFile && (
                                        <ListItem>
                                            {auditLog.relatedReadmeFile.deleted ? (
                                                <ListItemText>
                                                    Related readme: {auditLog.relatedReadmeFile.name} (
                                                    {auditLog.relatedReadmeFile.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related readme:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/readme/${auditLog.relatedReadmeFile.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedReadmeFile.name} (
                                                        {auditLog.relatedReadmeFile.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related readme: {auditLog.relatedReadmeFile.name} (
                                                    {auditLog.relatedReadmeFile.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedEnvironment && (
                                        <ListItem>
                                            {auditLog.relatedEnvironment.deleted ? (
                                                <ListItemText>
                                                    Related environment: {auditLog.relatedEnvironment.name} (
                                                    {auditLog.relatedEnvironment.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceUrl ? (
                                                <ListItemText>
                                                    Related environment:{' '}
                                                    <Link
                                                        href={`${workspaceUrl}/environment/${auditLog.relatedEnvironment.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedEnvironment.name} (
                                                        {auditLog.relatedEnvironment.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related environment: {auditLog.relatedEnvironment.name} (
                                                    {auditLog.relatedEnvironment.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedEnvironmentVariable && (
                                        <ListItem>
                                            {auditLog.relatedEnvironmentVariable.deleted ? (
                                                <ListItemText>
                                                    Related parameter: {auditLog.relatedEnvironmentVariable.name} (
                                                    {auditLog.relatedEnvironmentVariable.uid}) (deleted)
                                                </ListItemText>
                                            ) : workspaceWithDefaultEnvUrl ? (
                                                <ListItemText>
                                                    Related parameter:{' '}
                                                    <Link
                                                        href={`${workspaceWithDefaultEnvUrl}/parameters`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedEnvironmentVariable.name} (
                                                        {auditLog.relatedEnvironmentVariable.uid})
                                                    </Link>
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related parameter: {auditLog.relatedEnvironmentVariable.name} (
                                                    {auditLog.relatedEnvironmentVariable.uid})
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedDeployment && (
                                        <ListItem>
                                            <ListItemText>
                                                Related deployment: {auditLog.relatedDeployment.version} (
                                                {auditLog.relatedDeployment.uid})
                                                {auditLog.relatedDeployment.deleted ? ' (deleted)' : ''}
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                    {auditLog.relatedTemplate && (
                                        <ListItem>
                                            {auditLog.relatedTemplate.deleted ? (
                                                <ListItemText>
                                                    Related template: {auditLog.relatedTemplate.name} (
                                                    {auditLog.relatedTemplate.uid}) (deleted)
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related template:{' '}
                                                    <Link
                                                        href={`${basePath}template/${auditLog.relatedTemplate.uid}`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedTemplate.name} ({auditLog.relatedTemplate.uid})
                                                    </Link>
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedOrganization && (
                                        <ListItem>
                                            {auditLog.relatedOrganization.deleted ? (
                                                <ListItemText>
                                                    Related team: {auditLog.relatedOrganization.name} (
                                                    {auditLog.relatedOrganization.uid}) (deleted)
                                                </ListItemText>
                                            ) : (
                                                <ListItemText>
                                                    Related team:{' '}
                                                    <Link
                                                        href={`${basePath}team/${auditLog.relatedOrganization.uid}/usage`}
                                                        target="_blank"
                                                    >
                                                        {auditLog.relatedOrganization.name} (
                                                        {auditLog.relatedOrganization.uid})
                                                    </Link>
                                                </ListItemText>
                                            )}
                                        </ListItem>
                                    )}
                                    {auditLog.relatedBillingEntity && (
                                        <ListItem>
                                            <ListItemText>
                                                Related billing entity: {auditLog.relatedBillingEntity.name} (
                                                {auditLog.relatedBillingEntity.uid})
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                    {auditLog.relatedConnection && (
                                        <ListItem>
                                            <ListItemText>
                                                Related connector: {auditLog.relatedConnection.name} (
                                                {auditLog.relatedConnection.uid})
                                                {auditLog.relatedConnection.deleted ? ' (deleted)' : ''}
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                    {auditLog.metadata && (
                                        <ListItem>
                                            <div>
                                                Metadata:
                                                <pre>{JSON.stringify(JSON.parse(auditLog.metadata), null, 4)}</pre>
                                            </div>
                                        </ListItem>
                                    )}
                                </List>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
