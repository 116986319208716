import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    handleManageConnectionError,
} from '../utils';
import { saveToken } from './save';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface TrelloConnectionDetails extends ConnectionDetails {
    apiKey?: string;
    token?: string;
}

interface TrelloSetupSaveProps {
    apiKey: string;
    token: string;
}

export const trelloManageConnectionOpenDialogAction$ = monitor(
    'trelloManageConnectionOpenDialogAction$',
    new Subject<TrelloConnectionDetails>()
);
export const trelloManageConnectionCloseDialogAction$ = monitor(
    'trelloManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const trelloSetupOpenDialogAction$ = monitor(
    'trelloSetupOpenDialogAction$',
    new Subject<TrelloConnectionDetails>()
);
export const trelloSetupCloseDialogAction$ = monitor('trelloSetupCloseDialogAction$', new Subject<void>());

export const trelloSetupDialogOpen$ = monitor('trelloSetupDialogOpen$', new BehaviorSubject(false));
export const trelloSetupAuthorizeAction$ = monitor('trelloSetupAuthorizeAction$', new Subject<TrelloSetupSaveProps>());
export const trelloSetupDialogErrors$ = monitor(
    'trelloSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const trelloSaveConnectionAction$ = monitor('trelloSaveConnectionAction$', new Subject<string>());
export const trelloConnectionCreatedAction$ = monitor('trelloConnectionCreatedAction$', new Subject<string>());
export const trelloConnectionSavedAction$ = monitor(
    'trelloConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const trelloManageConnectionInitiateSetupAction$ = monitor(
    'trelloManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const trelloManageConnectionDialogOpen$ = monitor(
    'trelloManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const trelloConnectionSaving$ = monitor('trelloConnectionSaving$', new BehaviorSubject(false));
export const trelloManageConnectionAuthorizeLoading$ = monitor(
    'trelloManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const trelloManageConnectionDetails$ = monitor(
    'trelloManageConnectionDetails$',
    new BehaviorSubject<TrelloConnectionDetails | undefined>(undefined)
);
export const trelloManageConnectionDialogErrors$ = monitor(
    'trelloManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const trelloSetupDialogStage$ = monitor('trelloSetupDialogStage$', new BehaviorSubject(0));

trelloSetupOpenDialogAction$.subscribe((details) => {
    trelloSetupDialogErrors$.next(undefined);
    trelloManageConnectionDialogErrors$.next(undefined);
    trelloSetupDialogOpen$.next(true);
    trelloSetupDialogStage$.next(0);
    trelloManageConnectionDetails$.next(details);
});

trelloSetupCloseDialogAction$.subscribe(() => {
    trelloSetupDialogOpen$.next(false);
    trelloManageConnectionAuthorizeLoading$.next(false);
});

trelloManageConnectionOpenDialogAction$.subscribe((details) => {
    trelloSetupDialogErrors$.next(undefined);
    trelloManageConnectionDialogErrors$.next(undefined);
    trelloManageConnectionDialogOpen$.next(true);
    trelloManageConnectionDetails$.next(details);
});

trelloManageConnectionCloseDialogAction$.subscribe(() => {
    trelloManageConnectionDialogOpen$.next(false);
    trelloSetupDialogOpen$.next(false);
    trelloManageConnectionDetails$.next(undefined);
});

trelloConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

trelloSetupAuthorizeAction$
    .pipe(
        map(async ({ apiKey, token }) => {
            trelloConnectionSaving$.next(true);
            trelloSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = trelloManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Trello connector type not defined');
                }

                if (!uid) {
                    throw Error('Trello connector has not been created so cannot configure it');
                }

                await saveToken({ apiKey, token, connectionId: uid });

                trelloConnectionCreatedAction$.next(uid);
                trelloConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                trelloSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Trello connector has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    trelloSetupDialogErrors$.next(e.message);
                } else {
                    trelloSetupDialogErrors$.next(
                        'Failed to save Trello connector details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Trello connector info', e);
                }
            }

            trelloConnectionSaving$.next(false);
            trelloManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

trelloSaveConnectionAction$
    .pipe(
        map(async (name) => {
            trelloConnectionSaving$.next(true);
            trelloManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = trelloManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Trello connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveTrelloConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveTrelloConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                trelloConnectionCreatedAction$.next(uid);
                                trelloConnectionSavedAction$.next({ uid, connectionTypeUid });
                                trelloManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, trelloManageConnectionDialogErrors$, APP.TRELLO.NAME);
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, trelloManageConnectionDialogErrors$, APP.TRELLO.NAME);
            }

            trelloConnectionSaving$.next(false);
        })
    )
    .subscribe();

trelloManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            trelloConnectionSaving$.next(true);
            trelloManageConnectionAuthorizeLoading$.next(true);
            trelloManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = trelloManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Trello connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    trelloConnectionSavedAction$.next({ uid, connectionTypeUid });
                    trelloSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    trelloConnectionCreatedAction$.next(uid);
                    trelloConnectionSavedAction$.next({ uid, connectionTypeUid });

                    trelloManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    trelloConnectionSaving$.next(false);

                    trelloSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, trelloManageConnectionDialogErrors$, APP.TRELLO.NAME);
                trelloManageConnectionAuthorizeLoading$.next(false);
            }

            trelloConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(trelloConnectionSavedAction$);

const saveTrelloConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        trelloConnectionSavedAction$.next({ uid, connectionTypeUid });
        trelloManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, trelloManageConnectionDialogErrors$, APP.TRELLO.NAME);
    }
};
