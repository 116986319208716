import { useObservableState, useSubscription } from 'observable-hooks';
import { Dashboard } from '../../components/dashboard-new';
import { loggedInUserDetails$ } from '../../store/user';
import { getBasePath } from '../../utils/path';
import { useNavigate } from 'react-location';
import { onboardingWizardOpen$, openProfileUpdateDialogAction$ } from '../../store/onboarding';
import { readLocalStorage } from '../../utils/localStorage';
import { useEffect } from 'react';
import { featureFlagsTopic$ } from '../../store/config';
import {
    selectedOrganizationDashboardUsageAction$,
    organizationSwitchedAction$,
    selectedOrganizationPlanLastUpdatedDate$,
    selectedOrganizationUid$,
    selectedOrganizationUsage$,
} from '../../store/organization';
import { loggedInUserWorkspaces$, openNewBlankWorkspaceAction$ } from '../../store/workspaces';
import { appSelectorOpen$, openAppSelectorAction$ } from '../../store/apps';
import { openConnectionDetailsDialogAction$ } from '../../store/connection';

export const DashboardContainer: React.FC = () => {
    const navigate = useNavigate();

    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const loggedInUserWorkspaces = useObservableState(loggedInUserWorkspaces$);
    const isOnboardingWizardOpen = useObservableState(onboardingWizardOpen$);
    const featureFlags = useObservableState(featureFlagsTopic$);
    const selectedOrganizationPlanLastUpdatedDate = useObservableState(selectedOrganizationPlanLastUpdatedDate$);
    const userHasSkippedProfileUpdate: boolean = readLocalStorage('hasSkippedProfileUpdate', false);
    const openProfileUpdate: boolean = readLocalStorage('openProfileUpdate', false);
    const missingScriptingFamiliarity =
        !loggedInUserDetails?.scriptingFamiliarityUid && loggedInUserDetails?.userOrigin !== 'monday.com';
    const showProfileUpdateBanner =
        !!featureFlags.showReturningUserOnboardingFlow && missingScriptingFamiliarity && userHasSkippedProfileUpdate;
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const selectedOrganization = loggedInUserDetails?.organizations?.find((org) => org.uid === selectedOrganizationUid);
    const { connectors, invocations } = useObservableState(selectedOrganizationUsage$) || {};

    useSubscription(organizationSwitchedAction$, (uid) => selectedOrganizationDashboardUsageAction$.next(uid));

    if (featureFlags.showReturningUserOnboardingFlow) {
        useEffect(() => {
            if (
                (!userHasSkippedProfileUpdate && missingScriptingFamiliarity && !isOnboardingWizardOpen) ||
                (openProfileUpdate && missingScriptingFamiliarity)
            ) {
                openProfileUpdateDialogAction$.next();
            }
        }, [loggedInUserDetails?.scriptingFamiliarityUid]);
    }

    return (
        <Dashboard
            displayName={loggedInUserDetails?.firstName ?? loggedInUserDetails?.email ?? 'User'}
            organizationName={selectedOrganization?.name ?? ''}
            plan={selectedOrganization?.planName ?? ''}
            usage={{
                lastUpdated: selectedOrganizationPlanLastUpdatedDate.toLocaleString(),
                connectors: {
                    limit: connectors?.limit && connectors?.limit === -1 ? 'Unlimited' : connectors?.limit ?? 0,
                    consumed: connectors?.consumed ?? 0,
                },
                invocations: {
                    limit: invocations?.limit ?? 0,
                    consumed: invocations?.consumed ?? 0,
                },
                workspaces: {
                    limit: 'Unlimited',
                    consumed: loggedInUserWorkspaces.length,
                },
            }}
            onCreateConnector={() => {
                navigate({ to: `${getBasePath()}connectors` });
                setTimeout(() => {
                    openAppSelectorAction$.next({
                        nextAction: (app) => {
                            openConnectionDetailsDialogAction$.next({
                                connectionType: app.connectionType,
                            });
                            appSelectorOpen$.next(false);
                        },
                        mode: 'CONNECTOR',
                    });
                }, 300);
            }}
            onExploreTemplates={() => navigate({ to: `${getBasePath()}templates` })}
            onStartFromScratch={() => {
                navigate({ to: `${getBasePath()}workspaces` });
                setTimeout(() => {
                    openNewBlankWorkspaceAction$.next('blank');
                }, 300);
            }}
            onViewPlans={() => navigate({ to: `${getBasePath()}team/${selectedOrganizationUid}/plan` })}
            showProfileUpdateBanner={showProfileUpdateBanner}
            onUpdateProfile={() => openProfileUpdateDialogAction$.next()}
        />
    );
};
