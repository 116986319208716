import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';

export enum ZoomEventListenerWizardStage {
    SAVE_TOKEN,
    ADD_EVENT,
}

export interface ZoomWizardStepsProps {
    readonly stage: ZoomEventListenerWizardStage;
}

export const StyledStepper = styled(Stepper)(({ theme }) => ({
    margin: theme.spacing(0, 0, 0 - 2),
    '& .MuiStepConnector-root': {
        display: 'none',
    },
}));

export const ZoomEventListenerWizardSteps: React.FC<ZoomWizardStepsProps> = ({ stage }) => {
    return (
        <StyledStepper activeStep={stage}>
            <Step key={ZoomEventListenerWizardStage.SAVE_TOKEN} />
            <Step key={ZoomEventListenerWizardStage.ADD_EVENT} />
        </StyledStepper>
    );
};
