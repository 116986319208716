import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import {
    Choice,
    EnvironmentVariableChoiceDetails,
    UpdateEnvironmentVariableChoiceEvent,
} from '../EnvironmentVariableChoiceDetails';
import {
    emptyWarning,
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeChoicesContainer,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    StyledReadOnlyRow,
    StyledReadOnlyKeyField,
    StyledReadOnlyValueField,
    StyledReadOnlyMultipleValuesField,
    StyledReadOnlyListItem,
    StyledEditModeVariableHeader,
} from '../EnvironmentVariableComponents';
import { IconButton } from '../../../common/buttons/IconButton';
import { InformationChip } from '../../../common/chips/InformationChip';
import { ToggleButtonGroup } from '../../../common/buttons/button-groups/ToggleButtonGroup';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableChoice, EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';

interface UpdateMultipleChoicesVariableEvent {
    choices?: EnvironmentVariableChoice[];
    defaultValue?: string[];
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: string[];
}

interface EnvironmentVariableMultipleChoicesVariantProps {
    choices?: EnvironmentVariableChoice[];
    defaultValue?: string[];
    description?: string;
    disabled?: boolean;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    readOnlyTemplateMode?: boolean;
    required?: boolean;
    sameLevelKeyNames?: string[];
    setupGuideTemplateMode?: boolean;
    value?: string[];
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateMultipleChoicesVariableEvent): void;
}

const getRemappedChoices = (
    choices: EnvironmentVariableChoice[],
    value?: string[],
    defaultValue?: string[]
): Choice[] => {
    return choices.map((c) => ({
        isDefault: c.value ? defaultValue?.includes(c.value) : c.label ? defaultValue?.includes(c.label) : false,
        label: c.label,
        selected: c.value ? value?.includes(c.value) : c.label ? value?.includes(c.label) : false,
        uid: c.uid,
        type: 'MULTIPLE_CHOICES',
        value: c.value,
    }));
};

export const EnvironmentVariableMultipleChoicesVariant: React.FC<EnvironmentVariableMultipleChoicesVariantProps> = ({
    choices = [],
    defaultValue = [],
    description = '',
    disabled = false,
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    readOnlyTemplateMode = false,
    required = false,
    sameLevelKeyNames = [],
    setupGuideTemplateMode = false,
    value = [],
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);
    const [currentChoices, setCurrentChoices] = useState(getRemappedChoices(choices, value, defaultValue));

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleAddChoice = (): void => {
        const updatedChoiceList = [...currentChoices, { editMode: true, type: 'MULTIPLE_CHOICES' as const }];
        setCurrentChoices(updatedChoiceList);
    };

    const handleDeleteChoice = (index: number): void => {
        const updatedChoiceList = currentChoices.filter((_, ix) => ix !== index);
        setCurrentChoices(updatedChoiceList);
    };

    const handleSelectChoice = (index: number): void => {
        const updatedChoiceList = currentChoices.map((c, ix) => (ix === index ? { ...c, selected: !c.selected } : c));
        setCurrentChoices(updatedChoiceList);
    };

    const handleUpdateChoice = (event: UpdateEnvironmentVariableChoiceEvent, index: number): void => {
        const updatedChoiceList = currentChoices.map((c, ix) =>
            ix === index
                ? {
                      ...c,
                      editMode: false,
                      hasBeenEdited: true,
                      isDefault: event.isDefault,
                      label: event.label,
                      value: event.value,
                  }
                : c
        );
        setCurrentChoices(updatedChoiceList);
    };

    const handleToggleChoiceEditMode = (editMode: boolean, index: number): void => {
        const updatedChoiceList = currentChoices.map((c, ix) => (ix === index ? { ...c, editMode } : c));
        setCurrentChoices(updatedChoiceList);
    };

    const handleUpdateVariable = (): void => {
        onUpdate?.({
            choices: currentChoices.map((c) => ({ label: c.label ?? '', value: c.value })),
            defaultValue: currentChoices.filter((c) => c.isDefault)?.map((cc) => (cc.value || cc.label) ?? ''),
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'MULTIPLE_CHOICES',
            value: currentChoices.filter((c) => c.selected)?.map((cc) => (cc.value || cc.label) ?? ''),
        });
    };

    const hasChanged =
        JSON.stringify(getRemappedChoices(choices, value, defaultValue)) !== JSON.stringify(currentChoices) ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const areCurrentRequiredChoicesMissing = currentRequired && !currentChoices.length;
    const isCurrentRequiredValueSelectionMissing =
        currentRequired && !!currentChoices.length && !currentChoices.some((c) => c.selected);

    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const isRequiredValueMissing = required && !value;
    const areRequiredChoicesMissing = required && !choices.length;
    const missingInformation = !keyName || isRequiredValueMissing || areRequiredChoicesMissing;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const readOnlyListItems = value?.length
        ? value.map((v) => <StyledReadOnlyListItem label={v || emptyWarning(true)} />)
        : emptyWarning(required);

    const readOnlyDefaultListItems = defaultValue?.length
        ? defaultValue.map((dv) => <StyledReadOnlyListItem label={dv || emptyWarning(true)} />)
        : emptyWarning();

    const readOnlyChoicesList = choices?.length
        ? choices.map((c) => <StyledReadOnlyListItem label={c.value || c.label || emptyWarning(true)} />)
        : emptyWarning(true);

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdateVariable,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit parameter' : 'New parameter'}</Typography>
                            <StyledHeaderActions>
                                <ToggleButtonGroup
                                    buttons={[
                                        { label: 'Optional', selected: !currentRequired, value: !currentRequired },
                                        { label: 'Required', selected: currentRequired, value: currentRequired },
                                    ]}
                                    disabled={setupGuideTemplateMode}
                                    exclusive
                                    size="small"
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                />
                                <IconButton
                                    aria-label="Cancel editing"
                                    icon={<CloseIcon />}
                                    size="small"
                                    tooltip="Cancel editing"
                                    onClick={handleCancel}
                                />
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                disabled={hasBeenEdited || setupGuideTemplateMode}
                                inputRef={typeInputRef}
                                items={environmentVariableTypes}
                                label="Type"
                                required
                                selectedItem={'MULTIPLE_CHOICES'}
                                size="small"
                                onSelectItem={(v) =>
                                    onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })
                                }
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            <div>
                                {areCurrentRequiredChoicesMissing ? (
                                    <DialogAlert
                                        sx={{ marginBottom: 1.5 }}
                                        severity="warning"
                                        text="Please add at least one choice"
                                    />
                                ) : isCurrentRequiredValueSelectionMissing ? (
                                    <DialogAlert
                                        sx={{ margin: 0 }}
                                        severity="warning"
                                        text="Please select at least one choice"
                                    />
                                ) : undefined}
                                {!!currentChoices.length && (
                                    <StyledEditModeChoicesContainer>
                                        {currentChoices.map((c, i) => (
                                            <EnvironmentVariableChoiceDetails
                                                key={c.label}
                                                editDisabled={setupGuideTemplateMode}
                                                editMode={c.editMode}
                                                existingValues={currentChoices
                                                    .filter((_, ix) => i !== ix)
                                                    .map((ev) => (ev.value || ev.label) ?? '')}
                                                isDefault={c.isDefault}
                                                label={c.label}
                                                selected={c.selected}
                                                type={'MULTIPLE_CHOICES'}
                                                value={c.value}
                                                onDelete={() => handleDeleteChoice(i)}
                                                onSelect={() => handleSelectChoice(i)}
                                                onToggleEditMode={(mode) => handleToggleChoiceEditMode(mode, i)}
                                                onUpdate={(event) => handleUpdateChoice(event, i)}
                                            />
                                        ))}
                                    </StyledEditModeChoicesContainer>
                                )}
                                <Button
                                    disabled={setupGuideTemplateMode}
                                    startIcon={<AddIcon />}
                                    variant="text"
                                    onClick={handleAddChoice}
                                >
                                    Add choice
                                </Button>
                            </div>
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdateVariable}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    aria-label="Edit parameter"
                                    data-pendo={'editParameter'}
                                    disabled={disabled || readOnlyTemplateMode}
                                    icon={<EditOutlinedIcon />}
                                    size="small"
                                    tooltip="Edit parameter"
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                />
                                <IconButton
                                    aria-label="Delete parameter"
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    icon={<DeleteOutlineIcon />}
                                    size="small"
                                    tooltip="Delete parameter"
                                    onClick={() => onDelete?.({ id, parentId })}
                                />
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {missingInformation && (
                                    <InformationChip label="Missing information" severity="warning" />
                                )}
                                {!readOnlyTemplateMode && (
                                    <InformationChip
                                        label={required ? 'Required' : 'Optional'}
                                        severity={required ? 'primary' : 'grey'}
                                    />
                                )}
                                <StyledReadOnlyExpandButton
                                    aria-label={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    icon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    tooltip={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                >
                                    <Typography>Show all</Typography>
                                </StyledReadOnlyExpandButton>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyMultipleValuesField>
                                {readOnlyTemplateMode ? readOnlyDefaultListItems : readOnlyListItems}
                            </StyledReadOnlyMultipleValuesField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Multiple choices</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Choices</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyMultipleValuesField>
                                        {readOnlyChoicesList}
                                    </StyledReadOnlyMultipleValuesField>
                                </StyledReadOnlyRow>
                                {!readOnlyTemplateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyMultipleValuesField>
                                            {readOnlyDefaultListItems}
                                        </StyledReadOnlyMultipleValuesField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
