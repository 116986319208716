import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { BitbucketIcon } from './product-icons/BitbucketIcon';
import { JiraCloudIcon } from './product-icons/JiraCloudIcon';
import { SlackIcon } from './product-icons/SlackIcon';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ServiceNowIcon } from './product-icons/ServiceNowIcon';
import { JiraOnPremiseIcon } from './product-icons/JiraOnPremiseIcon';
import { ConfluenceIcon } from './product-icons/ConfluenceIcon';
import { JiraServiceManagementIcon } from './product-icons/JiraServiceManagementIcon';
import { GoogleSheetsIcon } from './product-icons/GoogleSheetsIcon';
import { GenericIcon } from './product-icons/GenericIcon';
import { GitlabIcon } from './product-icons/GitlabIcon';
import { GithubIcon } from './product-icons/GithubIcon';
import { MondayIcon } from './product-icons/MondayIcon';
import { TempoIcon } from './product-icons/TempoIcon';
import { GoogleCalendarIcon } from './product-icons/GoogleCalendarIcon';
import { MicrosoftIcon } from './product-icons/MicrosoftIcon';
import { ZoomIcon } from './product-icons/ZoomIcon';
import { SalesforceIcon } from './product-icons/SalesforceIcon';
import { StatuspageIcon } from './product-icons/StatuspageIcon';
import { TrelloIcon } from './product-icons/TrelloIcon';
import { OpsgenieIcon } from './product-icons/OpsgenieIcon';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { NetSuiteIcon } from './product-icons/NetSuiteIcon';
import { AzureDevopsIcon } from './product-icons/AzureDevopsIcon';
import { ZenDeskIcon } from './product-icons/ZenDeskIcon';

interface ProductIconProps extends SvgIconProps {
    readonly name?: string;
}

export const ProductIcon: React.FC<ProductIconProps> = ({ name }) => {
    switch (name) {
        case APP.GENERIC.NAME:
            return <GenericIcon />;
        case APP.JIRA_CLOUD.NAME:
            return <JiraCloudIcon />;
        case APP.BITBUCKET_CLOUD.NAME:
        case APP.BITBUCKET_ON_PREMISE.NAME:
            return <BitbucketIcon />;
        case APP.JIRA_ON_PREMISE.NAME:
            return <JiraOnPremiseIcon />;
        case APP.CONFLUENCE_CLOUD.NAME:
        case APP.CONFLUENCE_ON_PREMISE.NAME:
            return <ConfluenceIcon />;
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD_ASSETS.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE_ASSETS.NAME:
            return <JiraServiceManagementIcon />;
        case APP.TRELLO.NAME:
            return <TrelloIcon />;
        case APP.SERVICENOW.NAME:
            return <ServiceNowIcon />;
        case APP.SLACK.NAME:
            return <SlackIcon />;
        case APP.GOOGLE_SHEETS.NAME:
            return <GoogleSheetsIcon />;
        case APP.GITHUB.NAME:
            return <GithubIcon />;
        case APP.MONDAY.NAME:
            return <MondayIcon />;
        case APP.GITLAB.NAME:
            return <GitlabIcon />;
        case APP.TEMPO_CLOUD.NAME:
        case APP.TEMPO_TIMESHEETS_ON_PREMISE.NAME:
        case APP.TEMPO_PLANNER_ON_PREMISE.NAME:
            return <TempoIcon />;
        case APP.GOOGLE_CALENDAR.NAME:
            return <GoogleCalendarIcon />;
        case APP.MICROSOFT.NAME:
            return <MicrosoftIcon />;
        case APP.AZURE_DEVOPS.NAME:
            return <AzureDevopsIcon />;
        case APP.ZOOM.NAME:
            return <ZoomIcon />;
        case APP.SALESFORCE.NAME:
            return <SalesforceIcon />;
        case APP.STATUSPAGE.NAME:
            return <StatuspageIcon />;
        case APP.OPSGENIE.NAME:
            return <OpsgenieIcon />;
        case APP.NETSUITE.NAME:
            return <NetSuiteIcon />;
        case APP.ZENDESK.NAME:
            return <ZenDeskIcon />;
        default:
            return <HelpOutlinedIcon />;
    }
};
