import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface BitbucketCloudConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface BitbucketCloudAppSetupSaveProps {
    workspaceUrl: string;
    clientId: string;
    clientSecret: string;
}

export const bitbucketCloudManageConnectionOpenDialogAction$ = monitor(
    'bitbucketCloudManageConnectionOpenDialogAction$',
    new Subject<BitbucketCloudConnectionDetails>()
);
export const bitbucketCloudManageConnectionCloseDialogAction$ = monitor(
    'bitbucketCloudManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const bitbucketCloudAppSetupOpenDialogAction$ = monitor(
    'bitbucketCloudAppSetupOpenDialogAction$',
    new Subject<BitbucketCloudConnectionDetails>()
);
export const bitbucketCloudAppSetupCloseDialogAction$ = monitor(
    'bitbucketCloudAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const bitbucketCloudAppSetupDialogOpen$ = monitor(
    'bitbucketCloudAppSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const bitbucketCloudAppSetupAuthorizeAction$ = monitor(
    'bitbucketCloudAppSetupAuthorizeAction$',
    new Subject<BitbucketCloudAppSetupSaveProps>()
);
export const bitbucketCloudAppSetupDialogErrors$ = monitor(
    'bitbucketCloudAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const bitbucketCloudSaveConnectionAction$ = monitor(
    'bitbucketCloudSaveConnectionAction$',
    new Subject<string>()
);
export const bitbucketCloudConnectionCreatedAction$ = monitor(
    'bitbucketCloudConnectionCreatedAction$',
    new Subject<string>()
);
export const bitbucketCloudConnectionSavedAction$ = monitor(
    'bitbucketCloudConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const bitbucketCloudManageConnectionInitiateSetupAction$ = monitor(
    'bitbucketCloudManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const bitbucketCloudManageConnectionDialogOpen$ = monitor(
    'bitbucketCloudManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const bitbucketCloudConnectionSaving$ = monitor('bitbucketCloudConnectionSaving$', new BehaviorSubject(false));
export const bitbucketCloudManageConnectionAuthorizeLoading$ = monitor(
    'bitbucketCloudManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const bitbucketCloudManageConnectionDetails$ = monitor(
    'bitbucketCloudManageConnectionDetails$',
    new BehaviorSubject<BitbucketCloudConnectionDetails | undefined>(undefined)
);
export const bitbucketCloudManageConnectionDialogErrors$ = monitor(
    'bitbucketCloudManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const bitbucketCloudAppSetupDialogStage$ = monitor('bitbucketCloudAppSetupDialogStage$', new BehaviorSubject(0));

bitbucketCloudAppSetupOpenDialogAction$.subscribe((details) => {
    bitbucketCloudAppSetupDialogErrors$.next(undefined);
    bitbucketCloudManageConnectionDialogErrors$.next(undefined);
    bitbucketCloudAppSetupDialogOpen$.next(true);
    bitbucketCloudAppSetupDialogStage$.next(0);
    bitbucketCloudManageConnectionDetails$.next(details);
});

bitbucketCloudAppSetupCloseDialogAction$.subscribe(() => {
    bitbucketCloudAppSetupDialogOpen$.next(false);
    bitbucketCloudManageConnectionAuthorizeLoading$.next(false);
});

bitbucketCloudManageConnectionOpenDialogAction$.subscribe((details) => {
    bitbucketCloudAppSetupDialogErrors$.next(undefined);
    bitbucketCloudManageConnectionDialogErrors$.next(undefined);
    bitbucketCloudManageConnectionDialogOpen$.next(true);
    bitbucketCloudManageConnectionDetails$.next(details);
});

bitbucketCloudManageConnectionCloseDialogAction$.subscribe(() => {
    bitbucketCloudManageConnectionDialogOpen$.next(false);
    bitbucketCloudAppSetupDialogOpen$.next(false);
    bitbucketCloudManageConnectionDetails$.next(undefined);
});

bitbucketCloudConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

bitbucketCloudAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            bitbucketCloudConnectionSaving$.next(true);
            bitbucketCloudAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = bitbucketCloudManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Bitbucket Cloud Connector type not defined');
                }

                if (!uid) {
                    throw Error('Bitbucket Cloud Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.atlassianCloud?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Atlassian Cloud Connector URL configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.workspaceUrl,
                        connectionId: uid,
                        connectionType: 'bitbucket-cloud',
                    },
                    `${baseUrl}/bitbucket/initiate`
                );
                openConsentWindow(response.location, bitbucketCloudManageConnectionAuthorizeLoading$);

                bitbucketCloudConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    bitbucketCloudAppSetupDialogErrors$.next(e.message);
                } else {
                    bitbucketCloudAppSetupDialogErrors$.next(
                        'Failed to save Bitbucket Cloud application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Bitbucket Cloud application info', e);
                }
            }

            bitbucketCloudConnectionSaving$.next(false);
            bitbucketCloudManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

bitbucketCloudSaveConnectionAction$
    .pipe(
        map(async (name) => {
            bitbucketCloudConnectionSaving$.next(true);
            bitbucketCloudManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = bitbucketCloudManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Bitbucket Cloud Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveBitbucketCloudConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveBitbucketCloudConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                bitbucketCloudConnectionCreatedAction$.next(uid);
                                bitbucketCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                                bitbucketCloudManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    bitbucketCloudManageConnectionDialogErrors$,
                                    APP.BITBUCKET_CLOUD.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, bitbucketCloudManageConnectionDialogErrors$, APP.BITBUCKET_CLOUD.NAME);
            }

            bitbucketCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

bitbucketCloudManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            bitbucketCloudConnectionSaving$.next(true);
            bitbucketCloudManageConnectionAuthorizeLoading$.next(true);
            bitbucketCloudManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = bitbucketCloudManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Bitbucket Cloud Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    bitbucketCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    bitbucketCloudAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    bitbucketCloudConnectionCreatedAction$.next(uid);
                    bitbucketCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    bitbucketCloudManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    bitbucketCloudConnectionSaving$.next(false);

                    bitbucketCloudAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, bitbucketCloudManageConnectionDialogErrors$, APP.BITBUCKET_CLOUD.NAME);
                bitbucketCloudManageConnectionAuthorizeLoading$.next(false);
            }

            bitbucketCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(bitbucketCloudConnectionSavedAction$);

const saveBitbucketCloudConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        bitbucketCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
        bitbucketCloudManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, bitbucketCloudManageConnectionDialogErrors$, APP.BITBUCKET_CLOUD.NAME);
    }
};
