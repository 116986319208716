import { Theme } from '@mui/material/styles';

export const remnantStyles = (theme: Theme) =>
    ({
        color: theme.palette.text.disabled,
        fontStyle: 'italic',
        opacity: 0.87,
        '.MuiTypography-root': {
            color: theme.palette.secondary.main,
        },
    } as const);

export const selectedStyles = (theme: Theme) =>
    ({
        backgroundColor: theme.palette.action.selected,
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    } as const);

export const absoluteCenterStyles = {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

export const dashedBorderStyle = (theme: Theme): string => `1px dashed ${theme.palette.action.active}`;

export const resourceTreeIconWrapperSize = 24;
export const resourceTreeIconSize = 20;
export const resourceTreeAccordionSummaryHeight = 32;
export const resourceTreeSideBarWidth = 32;
