import { useObservableState } from 'observable-hooks';
import { navigateToWorkspaceSetupGuideStepAction$, workspaceSetupGuideSteps$ } from '../../store/setup-guide';
import { selectedWorkspace$ } from '../../store/workspace';
import { SetupGuideWelcomeScreen } from '../../components/setup-guide/welcome-screen/SetupGuideWelcomeScreen';

export const SetupGuideWelcomeScreenContainer: React.FC = () => {
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);

    return (
        <SetupGuideWelcomeScreen
            steps={setupGuideSteps}
            workspace={{
                apps: {
                    incoming: selectedWorkspace?.incomingApps ?? [],
                    outgoing: selectedWorkspace?.outgoingApps ?? [],
                },
                name: selectedWorkspace?.name ?? '',
            }}
            onSelectStep={(stepName) => navigateToWorkspaceSetupGuideStepAction$.next(stepName)}
        />
    );
};
