import { styled } from '@mui/material';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface OrderedListProps extends ListProps {
    id: string;
    listItems: (string | JSX.Element)[];
    startCounter?: number;
    counterType?: 'numeric' | 'alpha';
}

export const StyledOrderedList = styled(List, {
    shouldForwardProp: (prop) => prop !== 'startCounter' && prop !== 'counterType',
})<{ startCounter?: number; counterType?: 'numeric' | 'alpha' }>(
    ({ theme, startCounter = 1, counterType = 'numeric' }) => ({
        counterReset: `step-counter ${startCounter - 1}`,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '& .MuiListItem-root': {
            alignItems: 'center',
            counterIncrement: 'step-counter',
            padding: 0,
            '&:before': {
                display: 'inline-flex',
                flexShrink: 0,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], // TODO: Need new color in theme
                borderRadius: theme.constants.radiusCircle,
                content: counterType === 'alpha' ? 'counter(step-counter, upper-alpha)' : 'counter(step-counter)',
                fontSize: theme.typography.subtitle2.fontSize,
                height: theme.spacing(3),
                width: theme.spacing(3),
                lineHeight: '22px',
                marginRight: theme.spacing(1.5),
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    })
);

export const OrderedList: React.FC<OrderedListProps> = ({ id, listItems, startCounter, counterType }) => (
    <StyledOrderedList startCounter={startCounter} counterType={counterType}>
        {listItems.map((li, index) => (
            <ListItem key={id + index}>{li}</ListItem>
        ))}
    </StyledOrderedList>
);
