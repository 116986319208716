import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import { APP } from '@avst-stitch/repository-lib/constants';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeaderSmall } from '../../../common/LayoutComponents';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

interface ZenDeskEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    zendeskUrl?: string;
    webhookUrlId: string;
    onClose: () => void;
}

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '100%',
    '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export const ZenDeskEventListenerSetupDialog: React.FC<ZenDeskEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    webhookBaseUrl,
    zendeskUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.ZENDESK.NAME;

    const [webhookType, setWebhookType] = useState<'events' | 'automation'>('events');

    const webhookListItems = (
        <>
            <ListItem>
                {zendeskUrl ? (
                    <Typography>
                        Visit your {appName}{' '}
                        <Link target="_blank" href={zendeskUrl}>
                            account
                        </Link>{' '}
                        page.
                    </Typography>
                ) : (
                    <Typography>Open https://[YOUR_ZENDESK.COM_ACCOUNT] URL in your browser.</Typography>
                )}
            </ListItem>
            <ListItem>
                <Typography>
                    In <strong>Admin Centre</strong>, click <strong>Apps and integrations</strong> in the sidebar, then
                    select <strong>Webhooks</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click the <strong>Create webhook</strong> button on the <strong>top right</strong> of the page.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Select the <strong>Zendesk events</strong> option.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <strong>Subscribe to</strong> one or more <strong>Zendesk webhook events</strong> from the dropdown
                    options.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click the <strong>Next</strong> button on the <strong>bottom right</strong> of the page.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Enter a <strong>Name</strong> for the webhook.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <strong>Paste the URL</strong> from this page into the <strong>Endpoint URL</strong> field.
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
            <ListItem>
                <Typography>
                    Click on <strong>Create webhook</strong>.
                </Typography>
            </ListItem>
        </>
    );

    const automationListItems = (
        <>
            <ListItem>
                {zendeskUrl ? (
                    <Typography>
                        Visit your {appName}{' '}
                        <Link target="_blank" href={zendeskUrl}>
                            account
                        </Link>{' '}
                        page.
                    </Typography>
                ) : (
                    <Typography>
                        <strong>Open</strong> https://[YOUR_ZENDESK.COM_ACCOUNT] URL in your browser.
                    </Typography>
                )}
            </ListItem>
            <ListItem>
                <Typography>
                    In <strong>Admin Centre</strong>, click <strong>Apps and integrations</strong> in the sidebar, then
                    select <strong>Webhooks</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click the <strong>Create webhook</strong> button on the <strong>top right</strong> of the page.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Select the <strong>Trigger or automation</strong> option.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click the <strong>Next</strong> button on the <strong>bottom right</strong> of the page.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Enter a <strong>Name</strong> for the webhook.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <strong>Paste the URL</strong> from this page into the <strong>Endpoint URL</strong> field.
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
            <ListItem>
                <Typography>
                    Click on <strong>Create webhook</strong>.{' '}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click on{' '}
                    {zendeskUrl ? (
                        <Link target="_blank" href={`${zendeskUrl}/admin/objects-rules/rules/triggers`}>
                            Select a trigger or automation in Admin Centre
                        </Link>
                    ) : (
                        <strong>
                            Objects and Rules {`>`} Business Rules {`>`} Triggers
                        </strong>
                    )}
                    .
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click the <strong>Create Trigger</strong> button on the top right of the page or edit an existing
                    trigger.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Enter a <strong>name</strong> and <strong>category</strong> for the trigger.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Under <strong>Conditions</strong> provide a condition that must be met for the trigger to run.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Under <strong>Actions</strong> click the <strong>Add action</strong> button.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    From the dropdown select the <strong>Notify by</strong> option, then select{' '}
                    <strong>Active webhook</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    From the <strong> second dropdown </strong> select the <strong>webhook created</strong> in the
                    previous steps.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <strong>Edit the JSON body</strong> with events you would like to send.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click on <strong>Create</strong>.
                </Typography>
            </ListItem>
        </>
    );

    return (
        <Dialog
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            icon={<IconCircle icon={<ProductIcon name="Zendesk" />} size="medium" />}
            open={open}
            title={`Event listener setup`}
            loading={loading}
            subtitle={appName}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" mb={1}>
                        How to set up an event listener
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Create a webhook in Zendesk project to listen to events. Select from the following options:
                    </Typography>
                </Box>
                <StyledBorderBox>
                    <StyledBorderBoxHeaderSmall>
                        <StyledLabel
                            control={
                                <Radio checked={webhookType === 'events'} onClick={() => setWebhookType('events')} />
                            }
                            label="Method 1: Zendesk events"
                        />
                    </StyledBorderBoxHeaderSmall>
                    {webhookType === 'events' && (
                        <>
                            <Divider />
                            <StyledBorderBoxContent gap={1.5}>
                                <StyledOrderedList>{webhookListItems}</StyledOrderedList>
                            </StyledBorderBoxContent>
                        </>
                    )}
                </StyledBorderBox>
                <StyledBorderBox>
                    <StyledBorderBoxHeaderSmall>
                        <StyledLabel
                            control={
                                <Radio
                                    checked={webhookType === 'automation'}
                                    onClick={() => setWebhookType('automation')}
                                />
                            }
                            label="Method 2: Trigger or automation"
                        />
                    </StyledBorderBoxHeaderSmall>
                    {webhookType === 'automation' && (
                        <>
                            <Divider />
                            <StyledBorderBoxContent gap={1.5}>
                                <StyledOrderedList>{automationListItems}</StyledOrderedList>
                            </StyledBorderBoxContent>
                        </>
                    )}
                </StyledBorderBox>
            </>
        </Dialog>
    );
};
