import { LoaderFn } from 'react-location';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { LocationGenerics } from '../router';
import { loadApps } from '../store/apps';
import { loadTemplates } from '../store/templates';
import { loadErrorPage } from '../store/error';
import { loggedInUserDetails$ } from '../store/user';
import { loadDashboardUsage, selectedOrganizationUid$ } from '../store/organization';

export const dashboardLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        await Promise.all([loadTemplates(), loadApps(), loadDashboardUsage(selectedOrganizationUid$.value ?? '')]);
        segmentAnalyticsTrack('Dashboard Viewed', {
            userOrigin: loggedInUserDetails$.value?.userOrigin,
        });
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Dashboard',
            genericMessage: 'Failed to load dashboard.',
        });

        throw e;
    }

    return {};
};
