import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { pascalCase } from 'pascal-case';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import { ListItem } from '@mui/material';

interface MondayEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    mondayUrl?: string;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const MondayEventListenerSetupDialog: React.FC<MondayEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    mondayUrl,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.MONDAY.NAME;

    const whenAColumnChanges = 'When a column changes';
    const WHEN_A_COLUMN_CHANGES_EVENTS = [
        'When a person changes',
        'When a date changes',
        'When a status changes',
        whenAColumnChanges,
    ];
    const isWhenAColumnChangesEvent = WHEN_A_COLUMN_CHANGES_EVENTS.includes(eventTypeName);
    const columnName = isWhenAColumnChangesEvent ? eventTypeName.split(' ')[2] : undefined;

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} board to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                {mondayUrl ? (
                                    <Typography>
                                        Visit your {appName}{' '}
                                        <Link target="_blank" href={mondayUrl}>
                                            account
                                        </Link>{' '}
                                        page.
                                    </Typography>
                                ) : (
                                    <Typography>Open https://[YOUR_MONDAY.COM_ACCOUNT] URL in your browser.</Typography>
                                )}
                            </ListItem>
                            <ListItem>Open the board you wish to setup the Event Listener for.</ListItem>
                            <ListItem>
                                <Typography>
                                    On the top right corner, click on <strong>Integrate</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Type in <strong>webhooks</strong> in the search bar and select{' '}
                                    <strong>webhooks</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Select ONLY the following event to listen to:{' '}
                                    <strong>
                                        {isWhenAColumnChangesEvent ? whenAColumnChanges : eventTypeName}, send a webhook
                                    </strong>{' '}
                                    and click <strong>Add to board</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong>Webhook URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            <ListItem>
                                <Typography>
                                    Click <strong>Connect</strong>. At this point, {appName} will test the connection to
                                    this webhook url.
                                </Typography>
                            </ListItem>
                            {isWhenAColumnChangesEvent && columnName ? (
                                <>
                                    <ListItem>
                                        <Typography>
                                            If successful, the selected event will appear on the screen. Click on the{' '}
                                            <strong>a column</strong> dropdown and select{' '}
                                            <strong>
                                                {eventTypeName === whenAColumnChanges
                                                    ? 'a column on the board'
                                                    : pascalCase(columnName)}
                                            </strong>
                                            .
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            To complete the setup, click on the <strong>Add to board</strong> button on
                                            the bottom right corner of the screen.
                                        </Typography>
                                    </ListItem>
                                </>
                            ) : (
                                <ListItem>
                                    <Typography>
                                        If successful, an <strong>Add to board</strong> button will appear on the bottom
                                        right corner of the screen. Click on this to complete the setup.
                                    </Typography>
                                </ListItem>
                            )}
                            <ListItem>
                                <Typography>
                                    (<strong>Optional</strong>) After webhook creation, consider adding a description to
                                    be able to distinguish the webhook you just created.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
