import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { createNetSuiteEventListenerScriptContent } from '../../../../utils/eventListener';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Dialog } from '../../../common/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { EventListenerStepItem } from '../../EventListenerStepItem';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';

interface NetSuiteEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    accountId?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const NetSuiteEventListenerSetupDialog: React.FC<NetSuiteEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    accountId,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const webhookUrl = `${webhookBaseUrl}/${webhookUrlId}`;
    const uploadScriptUrl = `https://${accountId}.app.netsuite.com/app/common/scripting/uploadScriptFile.nl`;
    const fileName = `${webhookUrlId}-src-user-event.js`;
    const appName = 'NetSuite';

    return (
        <Dialog
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title={'Event listener setup'}
            subtitle={appName}
            onClose={onClose}
            loading={loading}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" component="h6" mb={0.5}>
                        How to set up an event listener
                    </Typography>
                    <Typography color="text.secondary">Create a webhook in {appName} to listen to events.</Typography>
                </Box>
                <EventListenerStepItem title="Step 1: Upload Script" expanded={true}>
                    <ListItem>
                        <Typography>
                            Download the script by clicking on this link:{' '}
                            <Link
                                href={URL.createObjectURL(
                                    new Blob([createNetSuiteEventListenerScriptContent(webhookUrl)])
                                )}
                                download={fileName}
                            >
                                Download Script
                            </Link>
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            This script will be executed by NetSuite when a chosen record type is created or updated.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            If you need to customise your script, please see the docs:{' '}
                            <Link
                                target="_blank"
                                href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_1510274245.html"
                            >
                                User Event Scripts
                            </Link>
                        </Typography>
                    </ListItem>
                    {accountId && (
                        <ListItem>
                            <Typography>
                                Visit your instance's{' '}
                                <Link target="_blank" href={uploadScriptUrl}>
                                    Upload a Script
                                </Link>{' '}
                                page.
                            </Typography>
                        </ListItem>
                    )}
                    {!accountId && (
                        <ListItem>
                            <Typography>
                                Open{' '}
                                <strong>
                                    https://[YOUR_ACCOUNT_ID].app.netsuite.com/app/common/
                                    <br />
                                    scripting/uploadScriptFile.nl
                                </strong>{' '}
                                URL in your browser.
                            </Typography>
                        </ListItem>
                    )}
                    <ListItem>
                        <Typography>
                            Click on + to the right of the <strong>SCRIPT FILE</strong> field.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            On the <strong>File</strong> tab that opens, in the <strong>ATTACH FROM</strong> field
                            choose <strong>Computer</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography fontWeight="bold">Copy and paste the file name.</Typography>
                    </ListItem>
                    <OutlinedReadOnlyTextField label="File name" value={fileName} />
                    <ListItem>
                        <Typography>Choose a suitable Folder to upload your script to.</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>SELECT FILE</strong> and upload recently downloaded script. Leave{' '}
                            <strong>CHARACTER ENCODING</strong> as UTF-8.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Then click <strong>Save</strong>.
                        </Typography>
                    </ListItem>
                </EventListenerStepItem>
                <EventListenerStepItem startCounter={11} title="Step 2: Create Script Record and Deploy">
                    <ListItem>
                        <Typography>
                            Click <strong>Create Script Record</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            On the newly opened page make sure that <strong>TYPE</strong> is pre-defined as{' '}
                            <strong>User Event</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Add a meaningful name for the script record and leave all other settings as default unless
                            you know what you're doing.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>Save</strong>. Then click <strong>Deploy Script</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            For the <strong>APPLIES TO</strong> field select the Record Type that will trigger your
                            event listener and leave all other settings as default.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Finally click <strong>Save</strong>.
                        </Typography>
                    </ListItem>
                    <Divider />
                    <Typography>
                        If you require a more customised event, it's possible to use Webhook URL with NetSuite Workflow
                        Custom Actions.{' '}
                        <Link
                            target="_blank"
                            href="https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/chapter_N2725813.html"
                        >
                            More on Workflows.
                        </Link>
                    </Typography>
                    <OutlinedReadOnlyTextField label="Webhook URL" value={webhookUrl} />
                </EventListenerStepItem>
            </>
        </Dialog>
    );
};
