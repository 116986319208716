import { PropsWithChildren, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '../common/buttons/IconButton';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../common/LayoutComponents';
import { StyledOrderedList } from '../common/lists/OrderedList';

export interface EventListenerStepItemProps {
    title: string;
    expanded?: boolean;
    startCounter?: number;
}

const StyledHeader = styled(StyledBorderBoxHeader)(({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 2),
}));

export const EventListenerStepItem: React.FC<PropsWithChildren<EventListenerStepItemProps>> = ({
    title,
    expanded = false,
    startCounter,
    children,
}) => {
    const [open, setOpen] = useState(expanded);

    return (
        <StyledBorderBox>
            <StyledHeader>
                <Typography variant="subtitle2">{title}</Typography>
                <Box display="flex" gap={1}>
                    <IconButton
                        aria-label={open ? 'Collapse' : 'Expand'}
                        icon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        tooltip={open ? 'Collapse' : 'Expand'}
                        onClick={() => setOpen(!open)}
                    />
                </Box>
            </StyledHeader>
            {open && (
                <>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList startCounter={startCounter}>{children}</StyledOrderedList>
                    </StyledBorderBoxContent>
                </>
            )}
        </StyledBorderBox>
    );
};
