import { BehaviorSubject, map, Subject } from 'rxjs';
import { selectedWorkspaceUid$ } from '.';
import { deleteEnvironment, renameEnvironment } from '../../data/environment';
import { InformativeError } from '../../utils/repository';
import { promptQuestion } from '../confirm';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { loadWorkspaceEnvironments } from './utils';

export const environmentManagerOpen$ = monitor('environmentManagerOpen$', new BehaviorSubject(false));
export const environmentManagerErrors$ = monitor(
    'environmentManagerErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const environmentManagerLoading$ = monitor('environmentManagerLoading$', new BehaviorSubject(false));

export const openEnvironmentManagerAction$ = monitor('openEnvironmentManagerAction$', new Subject<void>());
export const closeEnvironmentManagerAction$ = monitor('closeEnvironmentManagerAction$', new Subject<void>());
export const deleteEnvironmentAction$ = monitor('deleteEnvironmentAction$', new Subject<string>());
export const renameEnvironmentAction$ = monitor(
    'renameEnvironmentAction$',
    new Subject<{ uid: string; name: string }>()
);

openEnvironmentManagerAction$.subscribe(() => {
    environmentManagerOpen$.next(true);
    environmentManagerLoading$.next(false);
    environmentManagerErrors$.next(undefined);
});
closeEnvironmentManagerAction$.subscribe(() => environmentManagerOpen$.next(false));

deleteEnvironmentAction$
    .pipe(
        map(async (uid) => {
            promptQuestion({
                title: 'Are you sure you want to delete the Environment?',
                messages: [
                    'Deleting the environment will automatically disable all Event Listeners and Scheduled Triggers in this Environment.',
                ],
                onProceed: async () => {
                    environmentManagerLoading$.next(true);
                    environmentManagerErrors$.next(undefined);

                    try {
                        await deleteEnvironment(uid);
                        await loadWorkspaceEnvironments(selectedWorkspaceUid$.value ?? '');

                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Environment deleted.',
                        });
                    } catch (e) {
                        environmentManagerLoading$.next(false);
                        if (e instanceof InformativeError) {
                            environmentManagerErrors$.next(e.message);
                        } else {
                            environmentManagerErrors$.next(
                                'Failed to delete environment, please try again, if the issue persists please contact support.'
                            );
                        }
                    }

                    environmentManagerLoading$.next(false);
                },
            });
        })
    )
    .subscribe();

renameEnvironmentAction$
    .pipe(
        map(async ({ uid, name }) => {
            environmentManagerLoading$.next(true);
            environmentManagerErrors$.next(undefined);

            try {
                await renameEnvironment(uid, name);
                await loadWorkspaceEnvironments(selectedWorkspaceUid$.value ?? '');

                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Environment renamed.',
                });
            } catch (e) {
                environmentManagerLoading$.next(false);
                if (e instanceof InformativeError) {
                    environmentManagerErrors$.next(e.message);
                } else {
                    environmentManagerErrors$.next(
                        'Failed to rename environment, please try again, if the issue persists please contact support.'
                    );
                }
            }

            environmentManagerLoading$.next(false);
        })
    )
    .subscribe();
