import { alpha, styled, Theme } from '@mui/material';
import Chip, { ChipProps } from '@mui/material/Chip';

type StatusChipSeverity = 'grey' | 'success' | 'info' | 'warning' | 'error';

interface StatusChipsProps extends ChipProps {
    icon: JSX.Element;
    severity?: StatusChipSeverity;
    size?: 'small' | 'medium';
}

const getChipColor = (severity: StatusChipSeverity, theme: Theme): { backgroundColor: string; color: string } => {
    switch (severity) {
        case 'info':
            return {
                backgroundColor: alpha(theme.palette.info.light, 0.1),
                color: theme.palette.info.main,
            };
        case 'success':
            return {
                backgroundColor: alpha(theme.palette.success.light, 0.1),
                color: theme.palette.success.main,
            };
        case 'warning':
            return {
                backgroundColor: alpha(theme.palette.warning.light, 0.1),
                color: theme.palette.warning.main,
            };
        case 'error':
            return {
                backgroundColor: alpha(theme.palette.error.light, 0.1),
                color: theme.palette.error.main,
            };
        default:
        case 'grey':
            return {
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], // Change this
                color: theme.palette.text.primary, // Change this
            };
    }
};

const StyledChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'severity' })<{
    severity: StatusChipSeverity;
    size: 'small' | 'medium';
}>(({ severity, size, theme }) => ({
    ...getChipColor(severity, theme),
    borderRadius: theme.constants.borderRadius,
    fontSize: '14px',
    height: size === 'small' ? 30 : 40,
    padding: theme.spacing(0.25, 0),
    width: 112,
    '& .MuiSvgIcon-root': {
        color: getChipColor(severity, theme).color,
        height: 20,
        width: 20,
    },
}));

export const StatusChip: React.FC<StatusChipsProps> = ({ icon, severity = 'grey', size = 'small', ...props }) => {
    return <StyledChip icon={icon} severity={severity} size={size} {...props} />;
};
