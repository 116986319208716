import React from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '../common/buttons/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import { IconCircle } from '../common/IconCircle';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import {
    StyledIconAndLabelChipContainer,
    StyledInfoIcon,
    StyledSectionContainer,
    StyledSectionHeader,
    StyledTeamChip,
    StyledTimestampBox,
    StyledUsageBlockContainer,
    StyledUsageBlockSubtitleContainer,
    StyledUsageDataText,
} from './DashboardComponents';

export interface DashboardUsageProps {
    plan: string;
    organizationName: string;
    usage: {
        lastUpdated: string;
        connectors: {
            limit?: number | string;
            consumed?: number;
        };
        invocations: {
            limit?: number;
            consumed?: number;
        };
        workspaces: {
            limit: number | string;
            consumed?: number;
        };
    };
    onViewPlans: () => void;
}

export const DashboardUsage: React.FC<DashboardUsageProps> = ({
    plan,
    organizationName,
    usage: { lastUpdated, connectors, invocations, workspaces },
    onViewPlans,
}) => {
    return (
        <StyledSectionContainer>
            <StyledSectionHeader>
                <Typography variant="h6">Usage</Typography>
                <Box display="flex" gap={2}>
                    <StyledTeamChip>
                        <StyledIconAndLabelChipContainer>
                            <Box display="inline-flex" pr={1}>
                                <FolderSharedOutlinedIcon />
                            </Box>
                            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                                {organizationName}
                            </Typography>
                        </StyledIconAndLabelChipContainer>
                    </StyledTeamChip>
                    <StyledTimestampBox>
                        <Typography>{`Last updated: ${lastUpdated}`}</Typography>
                    </StyledTimestampBox>
                    <Button data-pendo={'viewPlans'} size="small" variant="outlined" onClick={onViewPlans}>
                        View plans
                    </Button>
                </Box>
            </StyledSectionHeader>
            <Divider />
            <Box display="flex">
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<StyleOutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Current plan</Typography>
                        <StyledInfoIcon tooltip="The current plan type of your team." />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>{plan}</StyledUsageDataText>
                </StyledUsageBlockContainer>
                <Divider orientation="vertical" flexItem />
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<PowerOutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Connectors</Typography>
                        <StyledInfoIcon tooltip="Connectors link ScriptRunner Connect to third-party services. The limit is based on the plan type of your team." />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>
                        {connectors.consumed} / {connectors.limit}
                    </StyledUsageDataText>
                </StyledUsageBlockContainer>
                <Divider orientation="vertical" flexItem />
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<AllInclusiveOutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Script invocations</Typography>
                        <StyledInfoIcon tooltip="Script invocations are triggered scripts that perform tasks. They can be run manually or automatically." />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>
                        {invocations?.consumed?.toLocaleString()} / {invocations?.limit?.toLocaleString()}
                    </StyledUsageDataText>
                </StyledUsageBlockContainer>
                <Divider orientation="vertical" flexItem />
                <StyledUsageBlockContainer>
                    <IconCircle size="small" icon={<Inventory2OutlinedIcon />} severity="grey" />
                    <StyledUsageBlockSubtitleContainer>
                        <Typography variant="subtitle1">Workspaces</Typography>
                        <StyledInfoIcon tooltip="Workspaces are where all of your integrations live and run." />
                    </StyledUsageBlockSubtitleContainer>
                    <StyledUsageDataText>
                        {workspaces.consumed} / {workspaces.limit}
                    </StyledUsageDataText>
                </StyledUsageBlockContainer>
            </Box>
        </StyledSectionContainer>
    );
};
