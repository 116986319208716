import { styled } from '@mui/material/styles';
import { AppBanners } from './AppBanners';
import { PageContainer, PageTitle } from './PageComponentsForDeprecation';
import { StyledBorderBox, StyledBorderBoxContent } from '../common/LayoutComponents';
import { EmptyState } from '../common/EmptyState';
import { Button } from '../common/buttons/Button';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Box from '@mui/material/Box';

interface ErrorPageProps {
    title?: string;
    background?: 'paper' | 'default';
    error?: string;
    skipRefreshMessage?: boolean;
    skipRefreshMessageOverride?: string;
    showDashboardLink?: boolean;
    showRefreshSessionWarning?: boolean;
    supportPortalUrl: string;
    onLogoutFromApp(): void;
    onSetLoadTokens(loadTokens: boolean): void;
}

export const StyledErrorContainer = styled(Box)(() => ({
    display: 'flex',
    maxWidth: 1040,
    width: '100%',
    margin: '0 auto',
    flex: 1,
    alignItems: 'center',
}));

export const ErrorPage: React.FC<ErrorPageProps> = ({
    error,
    title,
    background = 'default',
    skipRefreshMessage = false,
    skipRefreshMessageOverride,
    showDashboardLink = true,
    showRefreshSessionWarning = false,
    supportPortalUrl,
    onLogoutFromApp,
    onSetLoadTokens,
}) => {
    const message = !skipRefreshMessage ? (
        <>
            {skipRefreshMessageOverride ?? 'Try refreshing your browser to resolve the error.'}
            <br />
            {showDashboardLink ? 'Go to the homepage or contact support.' : 'Contact support if needed.'}
        </>
    ) : undefined;

    return (
        <>
            {showRefreshSessionWarning && (
                <AppBanners showRefreshSessionWarning={showRefreshSessionWarning} onSetLoadTokens={onSetLoadTokens} />
            )}
            <PageContainer
                sx={{
                    backgroundColor: background === 'default' ? 'background.default' : 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {title && <PageTitle title={title} />}
                <StyledErrorContainer>
                    <StyledBorderBox width="100%">
                        <StyledBorderBoxContent>
                            <EmptyState
                                title={error ?? 'Page not found'}
                                subtitle={message}
                                icon={<ReportOutlinedIcon />}
                                buttons={[
                                    <Button
                                        key="log-out"
                                        variant="text"
                                        onClick={onLogoutFromApp}
                                        startIcon={<LogoutOutlinedIcon />}
                                    >
                                        Log out
                                    </Button>,
                                    <Button
                                        variant="outlined"
                                        key="contact-support"
                                        onClick={() => window.open(supportPortalUrl, '_blank')}
                                        startIcon={<QuestionAnswerOutlinedIcon />}
                                    >
                                        Contact support
                                    </Button>,
                                    ...(showDashboardLink
                                        ? [
                                              <Button
                                                  key="dashboard"
                                                  onClick={() =>
                                                      (window.location.href = `${process.env.PUBLIC_URL}/dashboard`)
                                                  }
                                                  startIcon={<CottageOutlinedIcon />}
                                              >
                                                  Go to home
                                              </Button>,
                                          ]
                                        : []),
                                ]}
                            />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </StyledErrorContainer>
            </PageContainer>
        </>
    );
};
