import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '../common/buttons/Button';
import Divider from '@mui/material/Divider';
import {
    StyledQuickActionsBlockContainer,
    StyledQuickActionsBlockImageContainer,
    StyledSectionContainer,
    StyledSectionHeader,
    StyledSectionMain,
} from './DashboardComponents';

interface DashboardQuickActionsProps {
    onExploreTemplates: () => void;
    onStartFromScratch: () => void;
    onCreateConnector: () => void;
}

export const DashboardQuickActions: React.FC<DashboardQuickActionsProps> = ({
    onCreateConnector,
    onExploreTemplates,
    onStartFromScratch,
}) => {
    return (
        <StyledSectionContainer>
            <StyledSectionHeader>
                <Typography variant="h6">Quick actions</Typography>
            </StyledSectionHeader>
            <Divider />
            <StyledSectionMain>
                <StyledQuickActionsBlockContainer>
                    <StyledQuickActionsBlockImageContainer>
                        <CardMedia
                            component="img"
                            image={process.env.PUBLIC_URL + '/scriptrunner-connect-templates-card.png'}
                            alt="Various app logos image"
                        />
                    </StyledQuickActionsBlockImageContainer>
                    <Typography variant="subtitle1" mb={0.5}>
                        Start from a template
                    </Typography>
                    <Typography variant="body1" mb={2} color="text.secondary">
                        Create an integration from a template using our setup guide.
                    </Typography>
                    <Button data-pendo={'exploreTemplates'} onClick={onExploreTemplates} fullWidth>
                        Explore templates
                    </Button>
                </StyledQuickActionsBlockContainer>
                <StyledQuickActionsBlockContainer>
                    <StyledQuickActionsBlockImageContainer>
                        <CardMedia
                            component="img"
                            image={process.env.PUBLIC_URL + '/scriptrunner-connect-start-from-scratch-card.png'}
                            alt="Start from scratch image"
                        />
                    </StyledQuickActionsBlockImageContainer>
                    <Typography variant="subtitle1" mb={0.5}>
                        Start from scratch
                    </Typography>
                    <Typography variant="body1" mb={2} color="text.secondary">
                        Create a custom integration to fit your exact needs.
                    </Typography>
                    <Button data-pendo={'startFromScratch'} onClick={onStartFromScratch} fullWidth>
                        Start from scratch
                    </Button>
                </StyledQuickActionsBlockContainer>
                <StyledQuickActionsBlockContainer>
                    <StyledQuickActionsBlockImageContainer>
                        <CardMedia
                            component="img"
                            image={process.env.PUBLIC_URL + '/scriptrunner-connect-create-connector-card.png'}
                            alt="Create connector image"
                        />
                    </StyledQuickActionsBlockImageContainer>
                    <Typography variant="subtitle1" mb={0.5}>
                        Connect to your favourite apps
                    </Typography>
                    <Typography variant="body1" mb={2} color="text.secondary">
                        Connectors contain all the authentication details for an API connection.
                    </Typography>
                    <Button data-pendo={'createConnector'} onClick={onCreateConnector} fullWidth>
                        Create connector
                    </Button>
                </StyledQuickActionsBlockContainer>
            </StyledSectionMain>
        </StyledSectionContainer>
    );
};
