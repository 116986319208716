import { useEffect, useRef, useState } from 'react';
import { alpha, styled } from '@mui/material';
import cronstrue from 'cronstrue';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { ButtonGroup } from '../../../common/buttons/button-groups/ButtonGroup';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import { IconButton } from '../../../common/buttons/IconButton';
import { IconCircle } from '../../../common/IconCircle';
import { InfoIcon } from '../../../icons/InfoIcon';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxTitleContainer,
} from '../../../common/LayoutComponents';
import {
    StyledBorderBoxContentGrey,
    StyledMainActions,
    StyledStatusController,
    StyledStatusControllerLabelContainer,
    StyledStepContainer,
    StyledSuccessItem,
} from '../../SetupGuideComponents';
import { TextField } from '../../../common/inputs/TextField';
import { SaveScheduledTriggerEvent } from '../../../workspace-resources/scheduled-triggers/ScheduledTriggerDetails';

interface SetupGuideEditScheduledTriggerScreenProps {
    cron?: string;
    cronError?: string;
    cronHasUnsavedChanges?: boolean;
    disabled?: boolean;
    errors?: string;
    nextScheduledDates?: Date[];
    saving?: boolean;
    scripts?: { name: string; uid: string }[];
    selectedScriptUid?: string;
    templateMode?: boolean;
    uid: string;
    workspaceLocked?: boolean;
    onCancel: () => void;
    onChangeCron: (cron: string) => void;
    onOpenScheduleBuilder: () => void;
    onSave: (event: SaveScheduledTriggerEvent) => void;
}

const StyledDateItem = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
    borderRadius: theme.constants.borderRadius,
    color: theme.palette.success.dark,
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const SetupGuideEditScheduledTriggerScreen: React.FC<SetupGuideEditScheduledTriggerScreenProps> = ({
    cron = '',
    cronHasUnsavedChanges = false,
    disabled = false,
    errors,
    nextScheduledDates = [],
    selectedScriptUid = '',
    saving = false,
    scripts = [],
    templateMode = false,
    uid,
    workspaceLocked = false,
    onCancel,
    onChangeCron,
    onOpenScheduleBuilder,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [cronDescription, setCronDescription] = useState('');
    const [cronError, setCronError] = useState('');
    const [currentSelectedScriptUid, setCurrentSelectedScriptUid] = useState(selectedScriptUid);
    const [currentMethod, setCurrentMethod] = useState<'builder' | 'cron'>('builder');
    const [currentDisabled, setCurrentDisabled] = useState(disabled);
    const [disabledSummaryOpen, setDisabledSummaryOpen] = useState(false);

    const selectRef = useRef<HTMLInputElement | null>(null);

    // TODO: Might move into rxjs
    useEffect(() => {
        try {
            if (countSegments() === 5) {
                throw 'Error: 5 segment format is not supported';
            } else if (countSegments() === 7) {
                throw 'Error: 7 segment format is not supported';
            }
            const description = cronstrue.toString(cron);
            if (description.includes('undefined')) {
                throw 'Error: Invalid expression';
            }
            setCronDescription(description);
            setCronError('');
        } catch (e) {
            if (typeof e === 'string') {
                setCronError(e.replace('At least 5 parts are required', 'At least 6 parts are required'));
            } else {
                setCronError('Error: Unknown error occurred');
                console.log(e);
            }
        }
    }, [cron]);

    const countSegments = (): number => cron.split(' ').filter((e) => e).length;

    const hasUnsavedChanges =
        cronHasUnsavedChanges || currentDisabled !== disabled || currentSelectedScriptUid !== selectedScriptUid;

    const canSave = !!cron && !cronError && !!currentSelectedScriptUid && hasUnsavedChanges && !workspaceLocked;

    const typographyColor = templateMode || workspaceLocked ? 'text.disabled' : undefined;

    const displayedDates = nextScheduledDates.map((nsd) => (
        <StyledDateItem>
            <CalendarTodayOutlinedIcon />
            <Typography>{nsd.toLocaleString()}</Typography>
        </StyledDateItem>
    ));

    const actions = (
        <>
            <Button variant="outlined" onClick={onCancel}>
                Back
            </Button>
            <Button
                busy={saving}
                disabled={!canSave}
                onClick={() =>
                    onSave({
                        cronExpression: cron,
                        disabled: currentDisabled,
                        scriptUid: currentSelectedScriptUid,
                        uid,
                    })
                }
            >
                Save
            </Button>
        </>
    );

    return (
        <StyledStepContainer>
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <StyledBorderBoxTitleContainer>
                        <IconCircle icon={<TimerOutlinedIcon />} size="medium" />
                        <Typography variant="h6" component="h4">
                            Configure scheduled trigger
                        </Typography>
                    </StyledBorderBoxTitleContainer>
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {errors && <Alert severity="error" title={errors} />}
                    <>
                        <StyledBorderBoxTitleContainer>
                            <Typography variant="subtitle2">Select method</Typography>
                            <InfoIcon tooltip="Select a scheduling method: Builder or CRON." />
                        </StyledBorderBoxTitleContainer>
                        <ButtonGroup
                            buttons={[
                                {
                                    icon:
                                        currentMethod === 'builder' ? (
                                            <RadioButtonCheckedOutlinedIcon />
                                        ) : (
                                            <RadioButtonUncheckedOutlinedIcon />
                                        ),
                                    label: 'Schedule builder',
                                    selected: currentMethod === 'builder',
                                    sx: { color: 'text.secondary' },
                                    value: 'builder',
                                    onClick: () => setCurrentMethod('builder'),
                                },
                                {
                                    icon:
                                        currentMethod === 'cron' ? (
                                            <RadioButtonCheckedOutlinedIcon />
                                        ) : (
                                            <RadioButtonUncheckedOutlinedIcon />
                                        ),
                                    label: 'CRON format (advanced)',
                                    selected: currentMethod === 'cron',
                                    sx: { color: 'text.secondary' },
                                    value: 'cron',
                                    onClick: () => setCurrentMethod('cron'),
                                },
                            ]}
                        />
                        <StyledBorderBoxTitleContainer>
                            <Typography color={workspaceLocked ? 'text.disabled' : undefined} variant="subtitle2">
                                Set up schedule
                            </Typography>
                            <InfoIcon tooltip="Scheduled triggers allow you to trigger scripts on a schedule." />
                        </StyledBorderBoxTitleContainer>
                        {currentMethod === 'builder' ? (
                            <StyledBorderBox>
                                <StyledBorderBoxContent alignItems="flex-start">
                                    {cron ? (
                                        <Button
                                            disabled={workspaceLocked}
                                            startIcon={<EditOutlinedIcon />}
                                            variant="outlined"
                                            onClick={onOpenScheduleBuilder}
                                        >
                                            Edit schedule
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={workspaceLocked}
                                            startIcon={<AddOutlinedIcon />}
                                            onClick={onOpenScheduleBuilder}
                                        >
                                            Create schedule
                                        </Button>
                                    )}
                                </StyledBorderBoxContent>
                            </StyledBorderBox>
                        ) : (
                            <>
                                <TextField
                                    disabled={workspaceLocked}
                                    error={!!cronError && !workspaceLocked}
                                    fullWidth
                                    helperText={cronError}
                                    label="CRON expression"
                                    placeholder="Enter expression"
                                    required
                                    value={cron}
                                    variant="outlined"
                                    onChange={(e) => onChangeCron(e.target.value)}
                                />
                                <Typography variant="subtitle2">
                                    Use the diagram below to set up your CRON schedule
                                </Typography>
                                <Box
                                    component="img"
                                    alt="CRON diagram"
                                    src={process.env.PUBLIC_URL + '/cron-diagram.png'}
                                />
                            </>
                        )}

                        <StyledBorderBoxTitleContainer>
                            <Typography variant="subtitle2">Review schedule</Typography>
                            <InfoIcon tooltip="Scripts are triggered using the UTC (GMT+0) time zone. Next estimated and last scheduled trigger dates will be displayed in your local time zone based on your operating system or browser settings." />
                        </StyledBorderBoxTitleContainer>
                        <Alert
                            title="Time zones (UTC and local time)"
                            severity="info"
                            text="Schedules are calculated in UTC (GMT+0). Dates and triggers are shown in your local time zone"
                        />
                        {cron && (
                            <StyledBorderBox>
                                <StyledBorderBoxContent>
                                    <Typography variant="subtitle2">What is your proposed trigger schedule?</Typography>
                                    <StyledSuccessItem>
                                        <Typography variant="subtitle2">{cronDescription}</Typography>
                                    </StyledSuccessItem>
                                </StyledBorderBoxContent>
                            </StyledBorderBox>
                        )}
                        {nextScheduledDates.length > 0 && (
                            <StyledBorderBox>
                                <StyledBorderBoxContent>
                                    <Typography variant="subtitle2">
                                        When is the next time your scheduled triggers will occur?
                                    </Typography>
                                    <Typography color="text.secondary" variant="body2">
                                        Here are the upcoming dates and times based on your configured schedule.
                                    </Typography>
                                    <Typography color="text.secondary" variant="body2">
                                        Next-estimated and last-scheduled-trigger dates are shown in your local time
                                        zone based on your OS or browser settings.
                                    </Typography>
                                    {displayedDates}
                                </StyledBorderBoxContent>
                            </StyledBorderBox>
                        )}
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle2">
                                Select script to trigger
                            </Typography>
                            <InfoIcon tooltip="Select a script to trigger on the configured schedule." />
                        </StyledBorderBoxTitleContainer>
                        <Dropdown
                            disabled={templateMode || workspaceLocked}
                            fullWidth
                            inputRef={selectRef}
                            items={scripts.map((s) => ({ name: s.name, value: s.uid }))}
                            label="Select script"
                            required
                            selectedItem={currentSelectedScriptUid}
                            onSelectItem={(value) => setCurrentSelectedScriptUid(value)}
                        />
                        <StyledBorderBox>
                            <StyledBorderBoxHeader>
                                <StyledBorderBoxTitleContainer>
                                    <IconCircle icon={<ReportOutlinedIcon />} severity="error" />
                                    <Typography color={typographyColor} variant="subtitle2">
                                        Disable scheduled trigger
                                    </Typography>
                                    <InfoIcon tooltip="Disable scheduled trigger from being triggered on the configured schedule." />
                                </StyledBorderBoxTitleContainer>
                                <IconButton
                                    aria-label="Open summary"
                                    icon={
                                        disabledSummaryOpen ? (
                                            <KeyboardArrowUpOutlinedIcon />
                                        ) : (
                                            <KeyboardArrowDownOutlinedIcon />
                                        )
                                    }
                                    tooltip="Open summary"
                                    onClick={() => setDisabledSummaryOpen(!disabledSummaryOpen)}
                                />
                            </StyledBorderBoxHeader>
                            {disabledSummaryOpen && (
                                <>
                                    <Divider />
                                    <StyledBorderBoxContentGrey>
                                        <StyledStatusController>
                                            <StyledStatusControllerLabelContainer>
                                                <TimerOutlinedIcon />
                                                <Box>
                                                    <Typography color={typographyColor} fontWeight="bold">
                                                        Current status
                                                    </Typography>
                                                    <Typography color="primary.main" variant="body2">
                                                        {currentDisabled ? 'Disabled' : 'Active'}
                                                    </Typography>
                                                </Box>
                                            </StyledStatusControllerLabelContainer>
                                            <Switch
                                                aria-label={
                                                    currentDisabled
                                                        ? 'Activate scheduled trigger'
                                                        : 'Disable scheduled trigger'
                                                }
                                                checked={!currentDisabled}
                                                disabled={templateMode || workspaceLocked}
                                                onChange={() => setCurrentDisabled(!currentDisabled)}
                                            />
                                        </StyledStatusController>
                                    </StyledBorderBoxContentGrey>
                                </>
                            )}
                        </StyledBorderBox>
                    </>
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
