import { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { getBasePath } from '../../utils/path';
import { Alert } from '../common/alerts/Alert';
import { Button } from '../common/buttons/Button';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { InfoIcon } from '../icons/InfoIcon';
import { QueryInvocationsRequest, ReportingFilters, tableFilters } from './InvocationLogsPage';
import { InvocationRecord } from '../../data/reporting';
import { ReplayInvocationDetails } from '../../store/workspace/replay-invocation';

interface WithWorkspaceDeleted {
    workspaceDeleted?: boolean;
}

interface ReportingPageTableProps {
    errors?: string;
    filters: ReportingFilters;
    impersonating?: boolean;
    invocationPayloadIsLoading?: boolean;
    abortInvocationLoading?: boolean;
    invocations: (InvocationRecord & WithWorkspaceDeleted)[];
    isLoading: boolean;
    nextToken?: string;
    selectedFilters?: string[];
    showLogsLinks?: boolean;
    onQueryInvocations(request: QueryInvocationsRequest): void;
    onReplayInvocation?(details: ReplayInvocationDetails): void;
    onAbortInvocation?(path: string): void;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: 4,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
}));

const StyledTableCell = styled(TableCell)(() => ({
    width: 150,
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    marginBottom: theme.spacing(-0.75),
    marginLeft: theme.spacing(0.5),
}));

export const ReportingPageTable: React.FC<ReportingPageTableProps> = ({
    errors,
    filters,
    impersonating,
    invocationPayloadIsLoading,
    abortInvocationLoading,
    invocations,
    isLoading,
    nextToken,
    selectedFilters,
    showLogsLinks = true,
    onQueryInvocations,
    onReplayInvocation,
    onAbortInvocation,
}) => {
    const intObserver = useRef<IntersectionObserver>();
    const lastPostRef = useCallback(
        (post) => {
            if (isLoading) {
                return;
            }

            if (intObserver.current) {
                intObserver.current.disconnect();
            }

            intObserver.current = new IntersectionObserver((posts) => {
                if (posts[0]?.isIntersecting && !!nextToken) {
                    onQueryInvocations({
                        nextToken,
                        ...filters,
                    });
                }
            });

            if (post) {
                intObserver.current.observe(post);
            }
        },
        [isLoading, nextToken]
    );

    // TODO: find the actual type of the Chip colors and swap with any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExecutionStatusColor = (status: string): string | any => {
        switch (status) {
            case 'ABORTED':
            case 'TIMED_OUT':
            case 'DENIED':
                return 'warning';
            case 'RUNNING':
                return 'info';
            case 'FUNCTION_ERROR':
            case 'RUNTIME_ERROR':
            case 'MALFORMED_PAYLOAD_ERROR':
                return 'error';
            case 'FINISHED':
                return 'success';
            default:
                return 'default';
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const tableRows = invocations.map((invocation, i) => {
        const chainedTrigger = invocation.triggerType === 'CHAINED' ? ` (${invocation.rootTriggerType})` : '';
        const workspaceUrl = `${getBasePath()}workspace/${invocation.workspaceUid}/environment/${
            invocation.environmentUid
        }`;
        const replayableInvocation =
            invocation.triggerType !== 'MANUAL' &&
            invocation.triggerType !== 'SCHEDULED' &&
            invocation.invocationType !== 'MALFORMED_PAYLOAD_EVENT';

        return (
            <TableRow key={i} ref={invocations.length === i + 1 ? lastPostRef : undefined}>
                {selectedFilters ? (
                    <>
                        <TableCell sx={{ minWidth: 150 }}>
                            {invocation.workspaceDeleted ? (
                                `${invocation.workspaceName} (deleted)`
                            ) : (
                                <Link href={workspaceUrl} target="_blank">
                                    {invocation.workspaceName}
                                </Link>
                            )}
                        </TableCell>
                        {selectedFilters.includes('Invocation ID') && (
                            <StyledTableCell>{invocation.invocationId}</StyledTableCell>
                        )}
                        {selectedFilters.includes('Workspace Owner') && (
                            <StyledTableCell>{invocation.workspaceOwnerName}</StyledTableCell>
                        )}
                        {selectedFilters.includes('Environment') && (
                            <StyledTableCell>{invocation.environmentName}</StyledTableCell>
                        )}
                        {selectedFilters.includes('Script') && (
                            <StyledTableCell
                                sx={{
                                    maxWidth: 150,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {invocation.workspaceDeleted ? (
                                    invocation.scriptName
                                ) : (
                                    <Link href={`${workspaceUrl}/script/${invocation.scriptUid}`} target="_blank">
                                        {invocation.scriptName}
                                    </Link>
                                )}
                            </StyledTableCell>
                        )}
                        {selectedFilters.includes('Trigger Type') && (
                            <StyledTableCell>
                                {`${invocation.triggerType.replaceAll('_', ' ')}${chainedTrigger}`}
                            </StyledTableCell>
                        )}
                        {selectedFilters.includes('Duration') && (
                            <StyledTableCell>{invocation.duration}ms</StyledTableCell>
                        )}
                        {selectedFilters.includes('Logs') && (
                            <StyledTableCell>
                                {invocation.nrOfLogs > 0 && showLogsLinks ? (
                                    <Link
                                        href={`${getBasePath()}invocationlogs/workspace/${
                                            invocation.workspaceUid
                                        }/invocation/${invocation.invocationId}`}
                                        target={impersonating ? '_self' : '_blank'}
                                    >
                                        {invocation.nrOfLogs}
                                    </Link>
                                ) : (
                                    invocation.nrOfLogs
                                )}
                            </StyledTableCell>
                        )}
                        {selectedFilters.includes('HTTP Logs') && (
                            <StyledTableCell>
                                {invocation.nrOfHttpLogs > 0 && showLogsLinks ? (
                                    <Link
                                        href={`${getBasePath()}httplogs/workspace/${
                                            invocation.workspaceUid
                                        }/invocation/${invocation.invocationId}`}
                                        target={impersonating ? '_self' : '_blank'}
                                    >
                                        {invocation.nrOfHttpLogs}
                                    </Link>
                                ) : (
                                    invocation.nrOfHttpLogs
                                )}
                            </StyledTableCell>
                        )}
                        {selectedFilters.includes('Invocation started') && (
                            <StyledTableCell>{new Date(`${invocation.starttime}Z`).toLocaleString()}</StyledTableCell>
                        )}
                        {selectedFilters.includes('Execution Status') && (
                            <StyledTableCell>
                                <StyledChip
                                    label={invocation.executionStatus
                                        .replaceAll('_', ' ')
                                        .toLowerCase()
                                        .replace(/\b\w/g, (letter) => letter.toUpperCase())}
                                    size="small"
                                    color={getExecutionStatusColor(invocation.executionStatus)}
                                />
                                {invocation.deniedReason && (
                                    // TODO: Do we need styled tooltip?
                                    <StyledTooltip title={invocation.deniedReason}>
                                        <InfoIcon />
                                    </StyledTooltip>
                                )}
                            </StyledTableCell>
                        )}
                        <TableCell>
                            {invocation.executionStatus === 'RUNNING' && (
                                <Button
                                    size="small"
                                    busy={abortInvocationLoading}
                                    onClick={() =>
                                        onAbortInvocation?.(`/${invocation.scriptUid}/${invocation.invocationId}`)
                                    }
                                >
                                    Abort
                                </Button>
                            )}
                            {invocation.executionStatus !== 'RUNNING' &&
                                replayableInvocation &&
                                !invocation.workspaceDeleted && (
                                    <Button
                                        size="small"
                                        busy={invocationPayloadIsLoading}
                                        onClick={() =>
                                            onReplayInvocation?.({
                                                invocationUid: invocation.invocationId,
                                                workspaceUid: invocation.workspaceUid,
                                                environmentUid: invocation.environmentUid,
                                                scriptUid: invocation.scriptUid,
                                            })
                                        }
                                    >
                                        Replay
                                    </Button>
                                )}
                        </TableCell>
                    </>
                ) : (
                    <>
                        <TableCell>{invocation.invocationId}</TableCell>
                        <TableCell>{invocation.workspaceName}</TableCell>
                        <TableCell>{invocation.workspaceOwnerName}</TableCell>
                        <TableCell>{invocation.environmentName}</TableCell>
                        <TableCell>{invocation.scriptName}</TableCell>
                        <TableCell>{`${invocation.triggerType.replaceAll('_', ' ')}${chainedTrigger}`}</TableCell>
                        <TableCell>{invocation.duration}</TableCell>
                        <TableCell>
                            {invocation.nrOfLogs > 0 && showLogsLinks ? (
                                <Link
                                    href={`${getBasePath()}invocationlogs/workspace/${
                                        invocation.workspaceUid
                                    }/invocation/${invocation.invocationId}`}
                                    target="_blank"
                                >
                                    {invocation.nrOfLogs}
                                </Link>
                            ) : (
                                invocation.nrOfLogs
                            )}
                        </TableCell>
                        <TableCell>
                            {invocation.nrOfHttpLogs > 0 && showLogsLinks ? (
                                <Link
                                    href={`${getBasePath()}httplogs/workspace/${invocation.workspaceUid}/invocation/${
                                        invocation.invocationId
                                    }`}
                                    target="_blank"
                                >
                                    {invocation.nrOfHttpLogs}
                                </Link>
                            ) : (
                                invocation.nrOfHttpLogs
                            )}
                        </TableCell>
                        <TableCell>{new Date(`${invocation.starttime}Z`).toLocaleString()}</TableCell>
                        <TableCell>
                            <Chip
                                label={invocation.executionStatus.replace('_', ' ')}
                                size="small"
                                color={getExecutionStatusColor(invocation.executionStatus)}
                            />
                            {invocation.deniedReason && (
                                // TODO: Do we need styled tooltip?
                                <StyledTooltip title={invocation.deniedReason}>
                                    <InfoIcon />
                                </StyledTooltip>
                            )}
                        </TableCell>
                    </>
                )}
            </TableRow>
        );
    });

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {errors && <Alert severity="error" title={errors} />}
                    {invocations.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {selectedFilters ? (
                                        <>
                                            <TableCell>Workspace</TableCell>
                                            {selectedFilters
                                                .filter((name) => tableFilters.includes(name))
                                                .map((filter, index) => (
                                                    <TableCell key={index}>{filter}</TableCell>
                                                ))}
                                            <TableCell />
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>Invocation ID</TableCell>
                                            <TableCell>Workspace</TableCell>
                                            <TableCell>Workspace Owner</TableCell>
                                            <TableCell>Environment</TableCell>
                                            <TableCell>Script</TableCell>
                                            <TableCell>Trigger Type</TableCell>
                                            <TableCell>Invocation duration (ms)</TableCell>
                                            <TableCell>Logs</TableCell>
                                            <TableCell>HTTP Logs</TableCell>
                                            <TableCell>Invocation started</TableCell>
                                            <TableCell>Execution Status</TableCell>
                                            <TableCell />
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>{tableRows}</TableBody>
                        </Table>
                    ) : (
                        <div>No Invocations found</div>
                    )}
                </>
            )}
        </>
    );
};
