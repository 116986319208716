import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { Workspace } from '../../components/workspace-layouts/Workspace';
import { navigateToApiHandlerAction$ } from '../../store/workspace/api-handler';
import {
    loadingWorkspaceResources$,
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '../../store/workspace';
import { navigateToScriptAction$ } from '../../store/workspace/script';
import { navigateToEventListenerAction$ } from '../../store/workspace/event-listener';
import { getBasePath } from '../../utils/path';
import { navigateToReadmeFileAction$ } from '../../store/workspace/readme';
import { navigateToScheduledTriggerAction$ } from '../../store/workspace/scheduled-trigger';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import {
    newDuplicatedWorkspace$,
    newWorkspaceFromTemplate$,
    openNewBlankWorkspaceAction$,
} from '../../store/workspaces';
import { WorkspaceLanguage } from '../../components/workspace-layouts/resource-tree/types';
import { navigateToEnvironmentVariablesAction$ } from '../../store/workspace/environment-variable';
import { featureFlagsTopic$ } from '../../store/config';

export const ReadOnlyTemplateContainer: React.FC = () => {
    const navigate = useNavigate();

    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const selectedResource = useObservableState(selectedWorkspaceSelectedResource$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    useSubscription(navigateToScriptAction$, (uid) => {
        navigate({
            to: `${getBasePath()}template/${selectedReadOnlyTemplate?.templateUid}/script/${uid}`,
        });
    });
    useSubscription(navigateToApiHandlerAction$, (uid) => navigate({ to: `./api/${uid}` }));
    useSubscription(navigateToEnvironmentVariablesAction$, (overwriteHistory) =>
        navigate({ to: './parameters', replace: overwriteHistory })
    );
    useSubscription(navigateToEventListenerAction$, (uid) => navigate({ to: `./event/${uid}` }));
    useSubscription(navigateToScheduledTriggerAction$, (uid) => navigate({ to: `./scheduled/${uid}` }));
    useSubscription(navigateToReadmeFileAction$, (uid) =>
        navigate({
            to: `${getBasePath()}template/${selectedReadOnlyTemplate?.templateUid}/readme/${uid}`,
        })
    );

    const templateUid = selectedWorkspace?.templateUid;
    const readmeFile = {
        name: workspaceResources.readmeFile?.name ?? '',
        uid: workspaceResources.readmeFile?.uid ?? '',
        unsaved: false,
    };

    return (
        <>
            <Workspace
                readOnlyTemplateMode={true}
                loadingWorkspaceResources={loadingWorkspaceResources}
                workspaceResources={workspaceResources}
                selectedWorkspaceLanguage={selectedWorkspace?.language as WorkspaceLanguage}
                selectedNode={selectedResource}
                readmeFile={readmeFile}
                scriptHasUnsavedChanges={{}}
                scriptExecutionInProgress={{}}
                showEnvironmentVariables={featureFlags.environmentVariables}
                selectedWorkspaceUid={selectedWorkspaceUid}
                isTemplate={!!templateUid}
                selectedEnvironmentUid={selectedEnvironmentUid}
                workspaceName={selectedWorkspace?.name}
                onSelectScript={(uid) => navigateToScriptAction$.next(uid)}
                onSelectReadmeFile={(uid) => navigateToReadmeFileAction$.next(uid ?? '')}
                onSelectEnvironmentVariables={() => navigateToEnvironmentVariablesAction$.next(false)}
                onSelectApiHandler={(uid) => navigateToApiHandlerAction$.next(uid)}
                onSelectEventListener={(uid) => navigateToEventListenerAction$.next(uid)}
                onSelectScheduledTrigger={(uid) => navigateToScheduledTriggerAction$.next(uid)}
                eventListenerExecutionInProgress={{}}
                onCreateWorkspaceFromTemplate={() => {
                    newDuplicatedWorkspace$.next(undefined);
                    newWorkspaceFromTemplate$.next({
                        name: selectedWorkspace?.name ?? '',
                        description: selectedWorkspace?.description,
                        sourceUid: selectedWorkspace?.templateUid,
                    });
                    openNewBlankWorkspaceAction$.next('template');
                }}
            />
        </>
    );
};
