import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Button } from '../../common/buttons/Button';
import { StyledMenuItem, StyledMenuItemContent, StyledOrganizationAvatar } from './SidenavComponents';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';

interface SidenavOrganizationSelectorProps {
    collapsed: boolean;
    organizations?: SidenavOrganization[];
    selectedOrganizationUid?: string;
    onCreateNewOrganization(): void;
    onManageAllOrganizations(): void;
    onSelectOrganization(uid: string): void;
}

const StyledSelectButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'collapsed' && prop !== 'selected',
})<{
    collapsed?: boolean;
    selected: boolean;
}>(({ collapsed, selected, theme }) => ({
    backgroundColor: selected ? theme.palette.action.selected : undefined,
    border: `1px solid ${selected ? theme.constants.borderSelected : theme.palette.divider}`,
    boxShadow: 'none',
    height: 40,
    maxWidth: 194,
    width: '100%',
    display: 'flex',
    justifyContent: collapsed ? 'center' : 'space-between',
    padding: theme.spacing(1),
    '&:focus-visible': {
        backgroundColor: theme.palette.action.focus,
        border: theme.constants.borderSelected,
    },
    '& .MuiTypography-root': {
        color: selected ? theme.palette.primary.main : theme.palette.text.primary,
        fontWeight: selected ? theme.typography.fontWeightBold : undefined,
        lineHeight: 'unset',
        margin: 0,
        maxWidth: 120,
        textTransform: 'none',
    },
    '& .MuiButton-endIcon': {
        margin: 0,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 20,
        width: 20,
    },
}));

const StyledOrganizationName = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    marginRight: theme.spacing(1),
    maxWidth: 220,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    lineHeight: '38px',
    '&:not(:first-of-type)': {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(-0.5),
    },
}));

const StyledSelectMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        border: `1px solid ${theme.palette.divider}`,
        maxHeight: '80vh',
        overflowY: 'hidden',
        width: 300,
    },
    '& .MuiList-root': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        padding: 0,
    },
}));

const StyledMenuItems = styled('div')(() => ({
    flexGrow: 1,
    maxHeight: 'calc(80vh - 116px)',
    overflowY: 'auto',
}));

const StyledButtonArea = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    bottom: 0,
    flexGrow: 1,
    padding: theme.spacing(1),
    position: 'sticky',
    width: '100%',
    '& .MuiButtonBase-root': {
        width: '100%',
        '&:first-of-type': {
            marginBottom: theme.spacing(1),
        },
    },
}));

export const SidenavOrganizationSelector: React.FC<SidenavOrganizationSelectorProps> = ({
    collapsed,
    organizations = [],
    selectedOrganizationUid,
    onCreateNewOrganization,
    onManageAllOrganizations,
    onSelectOrganization,
}) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const [focusedIndex, setFocusedIndex] = useState<number>(0);

    const menuItemRefs = useRef<(HTMLLIElement | HTMLButtonElement | null)[]>([]);

    useEffect(() => {
        if (anchor && menuItemRefs.current[focusedIndex]) {
            menuItemRefs.current[focusedIndex]?.focus();
        }
    }, [focusedIndex, anchor]);

    const handleMenuKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'ArrowDown') {
            setFocusedIndex((prevIndex) => (prevIndex + 1 < menuItemRefs.current.length ? prevIndex + 1 : 0));
        } else if (event.key === 'ArrowUp') {
            setFocusedIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : menuItemRefs.current.length - 1));
        } else if (event.key === 'Enter') {
            const item = menuItemRefs.current[focusedIndex];
            item?.click();
        }
    };

    const handleManageAllTeams = (): void => {
        setAnchor(null);
        onManageAllOrganizations();
    };

    const handleCreateNewOrganization = (): void => {
        setAnchor(null);
        onCreateNewOrganization();
    };

    const handleSelectOrganization = (uid: string): void => {
        setAnchor(null);
        onSelectOrganization(uid);
    };

    const selectedOrganization = organizations.find((org) => org.uid === selectedOrganizationUid);

    const planOrder = ['free', 'silver', 'gold', 'platinum', 'custom'];

    const sortedOrganizations = organizations.sort((a, b) => {
        const planComparison = planOrder.indexOf(a.plan ?? '') - planOrder.indexOf(b.plan ?? '');
        if (planComparison === 0) {
            return a.name.localeCompare(b.name);
        }
        return planComparison;
    });

    const menuItems: JSX.Element[] = [];
    let currentPlan: string | undefined;

    sortedOrganizations.forEach((org, index) => {
        if (org.plan && org.plan !== currentPlan) {
            currentPlan = org.plan;
            menuItems.push(
                <StyledListSubheader key={currentPlan}>
                    {currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1)}
                </StyledListSubheader>
            );
        }

        menuItems.push(
            <StyledMenuItem
                key={org.uid}
                ref={(el) => (menuItemRefs.current[index] = el)}
                selected={org.uid === selectedOrganizationUid}
                onClick={() => handleSelectOrganization(org.uid)}
            >
                <Tooltip title={org.name}>
                    <StyledMenuItemContent>
                        <StyledOrganizationAvatar ownedDefaultOrganization={org.ownedDefaultOrganization}>
                            {org.name.slice(0, 2).toLocaleUpperCase()}
                        </StyledOrganizationAvatar>
                        <StyledOrganizationName>{org.name}</StyledOrganizationName>
                    </StyledMenuItemContent>
                </Tooltip>
                {org.uid === selectedOrganizationUid && <CheckIcon />}
            </StyledMenuItem>
        );
    });

    const buttons = (
        <StyledButtonArea>
            <Button
                sx={(theme) => ({ '&:focus': { backgroundColor: theme.palette.action.focus } })} // Remove after adding a solution on theme level
                ref={(el) => (menuItemRefs.current[organizations.length] = el)}
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={handleCreateNewOrganization}
            >
                New team
            </Button>
            <Button
                sx={(theme) => ({ '&:focus': { backgroundColor: theme.palette.action.focus } })} // Remove after adding a solution on theme level
                ref={(el) => (menuItemRefs.current[organizations.length + 1] = el)}
                variant="text"
                onClick={handleManageAllTeams}
            >
                Manage all teams
            </Button>
        </StyledButtonArea>
    );

    return (
        <>
            <StyledSelectButton
                collapsed={collapsed}
                selected={!!anchor}
                variant="outlined"
                size="small"
                aria-label="Select team"
                value={selectedOrganizationUid} // Necessary to render the component
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
                endIcon={collapsed ? null : <UnfoldMoreIcon />}
            >
                <Tooltip title={selectedOrganization?.name}>
                    <StyledMenuItemContent>
                        <StyledOrganizationAvatar
                            ownedDefaultOrganization={selectedOrganization?.ownedDefaultOrganization}
                        >
                            {selectedOrganization?.name.slice(0, 2).toLocaleUpperCase()}
                        </StyledOrganizationAvatar>
                        {!collapsed && <StyledOrganizationName>{selectedOrganization?.name}</StyledOrganizationName>}
                    </StyledMenuItemContent>
                </Tooltip>
            </StyledSelectButton>
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledSelectMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            marginLeft: '-8px',
                        },
                    }}
                    onKeyDown={(event) => handleMenuKeyDown(event)}
                    onClose={() => setAnchor(null)}
                    data-pendo={'teamSelector'}
                >
                    <StyledMenuItems>{menuItems}</StyledMenuItems>
                    {buttons}
                </StyledSelectMenu>
            </ClickAwayListener>
        </>
    );
};
