import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconCircle } from '../../common/IconCircle';
import { ProductIcon } from '../../icons/ProductIcon';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import { SetupGuideWelcomeScreenStep } from './SetupGuideWelcomeScreenStep';
import { StyledIconContainer, StyledStepContainer } from '../SetupGuideComponents';
import { SetupGuideStep, SetupGuideStepName, SetupGuideWorkspace } from '../types';

export interface SetupGuideWelcomeScreenProps {
    steps: SetupGuideStep[];
    workspace: SetupGuideWorkspace;
    onSelectStep(name: SetupGuideStepName): void;
}

const StyledSteps = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
}));

export const SetupGuideWelcomeScreen: React.FC<SetupGuideWelcomeScreenProps> = ({ steps, workspace, onSelectStep }) => {
    const appNames = [...new Set([...workspace.apps.incoming, ...workspace.apps.outgoing])].join(' · ');
    const icons = [...new Set([...workspace.apps.incoming, ...workspace.apps.outgoing])].map((a) => (
        <IconCircle key={a} icon={<ProductIcon name={a} />} />
    ));

    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox title="Summary">
                {icons.length > 0 && <StyledIconContainer>{icons}</StyledIconContainer>}
                <Box>
                    <Typography color="text.secondary" mb={1}>
                        {appNames}
                    </Typography>
                    <Typography sx={{ textWrap: 'auto' }} variant="subtitle1">
                        {workspace.name}
                    </Typography>
                </Box>
            </SetupGuideSummaryBox>
            <StyledSteps>
                {steps.map((s) => (
                    <SetupGuideWelcomeScreenStep
                        key={s.name}
                        completed={s.completed}
                        description={s.description}
                        disabled={s.disabled}
                        name={s.name}
                        number={s.number}
                        required={s.required}
                        title={s.title}
                        uid={s.uid}
                        onSelect={onSelectStep}
                    />
                ))}
            </StyledSteps>
        </StyledStepContainer>
    );
};
