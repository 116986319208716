import { RefObject } from 'react';
import { Theme } from '@mui/material';
import { EnvironmentVariableTypeSelection, FullEnvironmentVariable } from './types';
import { ListValue, MapValue } from '@avst-stitch/repository-lib/lib/types';

export const environmentVariableDivider = (theme: Theme): string => `1px solid ${theme.palette.divider}`; // Add to theme
export const dragOverlayBoxShadow = `0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.20)`;
export const invalidKeyNameHelperText = 'Key name can only contain alphanumeric characters and underscores';
export const duplicateKeyNameHelperText = (inFolder?: boolean): string =>
    `This name is already used by another ${!inFolder ? 'folder or' : ''} parameter on this level`;

export const environmentVariableTypes: EnvironmentVariableTypeSelection[] = [
    { name: 'Text', value: 'TEXT' },
    { name: 'Password', value: 'PASSWORD' },
    { name: 'Number', value: 'NUMBER' },
    { name: 'Boolean', value: 'BOOLEAN' },
    { name: 'Date', value: 'DATE' },
    { name: 'Multiline text', value: 'MULTILINE_TEXT' },
    { name: 'Single choice', value: 'SINGLE_CHOICE' },
    { name: 'Multiple choices', value: 'MULTIPLE_CHOICES' },
    { name: 'List', value: 'LIST' },
    { name: 'Map', value: 'MAP' },
];

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getEnvironmentVariablesMissingInformation = (variables: FullEnvironmentVariable[]): boolean => {
    if (variables.some((v) => !v.keyName)) {
        return true;
    }
    for (const variable of variables) {
        if (
            (variable.type === 'SINGLE_CHOICE' || variable.type === 'MULTIPLE_CHOICES') &&
            variable.choices?.some((c) => !c.label)
        ) {
            return true;
        }
        if (
            variable.type === 'MAP' &&
            (Object.keys(variable.value as MapValue)?.some((k) => !k) ||
                Object.keys(variable.defaultValue as MapValue)?.some((k) => !k))
        ) {
            return true;
        }
        if (
            variable.type === 'LIST' &&
            ((variable.value as ListValue)?.some((v) => !v) || (variable.defaultValue as ListValue)?.some((dv) => !dv))
        ) {
            return true;
        }
        if (variable.required) {
            switch (variable.type) {
                case 'BOOLEAN':
                    break;
                case 'MAP':
                    if (!Object.keys(variable.value as MapValue)?.length) {
                        return true;
                    }
                    break;
                case 'MULTIPLE_CHOICES':
                case 'LIST':
                    if (!(variable.value as ListValue)?.length) {
                        return true;
                    }
                    break;
                case 'PASSWORD':
                    if (!variable.passwordValueFilled && !variable.value) {
                        return true;
                    }
                    break;
                case 'NUMBER':
                    if (variable.value === undefined) {
                        return true;
                    }
                    break;
                case 'SINGLE_CHOICE':
                case 'DATE':
                case 'TEXT':
                case 'MULTILINE_TEXT':
                default:
                    if (!variable.value) {
                        return true;
                    }
            }
        }
    }
    return false;
};

export const DESCRIPTION_MAX_LENGTH = 2000;

export const isDescriptionTooLong = (description: string): boolean => description.length > DESCRIPTION_MAX_LENGTH;

export const getVariableHeight = (ref: RefObject<HTMLDivElement> | null): number => {
    const rect = ref?.current?.getBoundingClientRect();
    return rect?.height ?? 0;
};

export const filterVariablesBySearchTerm = (
    variables: FullEnvironmentVariable[],
    searchTerm: string
): FullEnvironmentVariable[] => {
    return searchTerm
        ? variables
              .filter(
                  (v) =>
                      v.keyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      v.childVariables?.some((cv) => cv.keyName?.toLowerCase().includes(searchTerm.toLowerCase()))
              )
              .map((v) => ({
                  ...v,
                  childVariables: v.keyName?.toLowerCase().includes(searchTerm.toLowerCase())
                      ? v.childVariables
                      : v.childVariables?.filter((cv) => cv.keyName?.toLowerCase().includes(searchTerm.toLowerCase())),
              }))
        : variables;
};
