import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../../common/buttons/IconButton';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import { StatusChip } from '../../../common/chips/StatusChip';
import {
    StyledListItem,
    StyledListItemActions,
    StyledListItemBottomTextContainer,
    StyledListItemLeftContainer,
    StyledListItemRightContainer,
    StyledListItemWarningContainer,
} from '../../SetupGuideComponents';
import { SetupGuideScheduledTrigger } from '../../types';

export interface SetupGuideScheduledTriggerListItemProps extends SetupGuideScheduledTrigger {
    templateMode: boolean;
    workspaceLocked: boolean;
    onDelete: (uid: string) => void;
    onEdit: (uid: string) => void;
}

export const SetupGuideScheduledTriggerListItem: React.FC<SetupGuideScheduledTriggerListItemProps> = ({
    cron,
    deleting = false,
    needsSetup = false,
    scriptName,
    templateMode,
    uid,
    workspaceLocked,
    onEdit,
    onDelete,
}) => {
    return (
        <StyledListItem needsSetup={needsSetup}>
            <StyledListItemLeftContainer>
                <SetupGuideIndicator left={{ icon: <TimerOutlinedIcon /> }} />
                <Box>
                    {cron && <Typography>{cron}</Typography>}
                    <StyledListItemBottomTextContainer>
                        <CodeOutlinedIcon />
                        <Typography>Script:</Typography>
                        <Typography color="primary.main">{scriptName}</Typography>
                    </StyledListItemBottomTextContainer>
                    {needsSetup && (
                        <StyledListItemWarningContainer>
                            <WarningAmberOutlinedIcon />
                            <Typography>Complete Scheduled Trigger setup</Typography>
                        </StyledListItemWarningContainer>
                    )}
                </Box>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    <IconButton
                        aria-label="Edit scheduled trigger"
                        disabled={deleting}
                        icon={<EditOutlinedIcon />}
                        size="small"
                        tooltip="Edit scheduled trigger"
                        onClick={() => onEdit(uid)}
                    />
                    {!templateMode && (
                        <IconButton
                            aria-label="Delete scheduled trigger"
                            busy={deleting}
                            disabled={workspaceLocked}
                            icon={<DeleteOutlineOutlinedIcon />}
                            size="small"
                            tooltip="Delete scheduled trigger"
                            onClick={() => onDelete(uid)}
                        />
                    )}
                </StyledListItemActions>
                {needsSetup && <StatusChip icon={<UnpublishedOutlinedIcon />} label="Incomplete" />}
            </StyledListItemRightContainer>
        </StyledListItem>
    );
};
