// Add styles to theme when implemented across the app
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CustomButtonProps } from './buttons/Button';
import { IconButton } from './buttons/IconButton';
import { PropsWithChildren } from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import { Alert } from './alerts/Alert';
import LinearProgress from '@mui/material/LinearProgress';

interface CustomDialogProps extends DialogProps {
    buttons?: CustomButtonProps[];
    leftButton?: CustomButtonProps;
    errors?: string;
    icon?: JSX.Element;
    loading?: boolean;
    messages?: string[];
    size?: 'small' | 'medium';
    subtitle?: string;
    title: string;
    progress?: number;
    onClose?: () => void;
}

// Overwriting theme styles
// TODO: Add small and large sizes
const StyledDialog = styled(MuiDialog)<{ size: 'small' | 'medium' }>(({ size, theme }) => ({
    '& .MuiDialog-paper': {
        width: size === 'small' ? 600 : 1000,
        minHeight: 'unset',
        minWidth: 444,
        padding: theme.spacing(0),
        maxWidth: '100%',
    },
}));

const StyledDialogHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

const StyledDialogTitleContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minHeight: 60,
    padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    margin: 0, // Overwriting the theme
    padding: theme.spacing(2),
}));

const StyledProgressBar = styled(LinearProgress)(() => ({
    height: 4,
    minHeight: 4,
}));

export const Dialog: React.FC<PropsWithChildren<CustomDialogProps>> = ({
    buttons = [],
    errors,
    icon,
    leftButton,
    loading = false,
    messages = [],
    size = 'small',
    subtitle,
    title,
    progress,
    onClose,
    children,
    ...props
}) => {
    const displayedButtons = buttons.map((b, i) => ({ ...b, key: 'Button' + i }));
    return (
        <StyledDialog size={size} {...props}>
            <StyledDialogHeader>
                <StyledDialogTitleContainer>
                    {icon}
                    <Box>
                        <DialogTitle>{title}</DialogTitle>
                        {subtitle && <Typography>{subtitle}</Typography>}
                    </Box>
                </StyledDialogTitleContainer>
                {onClose && (
                    <IconButton
                        aria-label="Close dialog"
                        icon={<CloseOutlinedIcon />}
                        sx={{ alignSelf: 'flex-start' }}
                        tooltip="Close dialog"
                        onClick={onClose}
                    />
                )}
            </StyledDialogHeader>
            {messages.length || children ? ( // Always display after Dialog overhaul
                <>
                    <Divider />
                    {!loading && progress && <StyledProgressBar variant="determinate" value={progress} />}
                    <StyledDialogContent>
                        {loading ? (
                            <LoadingSpinner />
                        ) : (
                            <>
                                {errors && (
                                    <Alert
                                        title={errors}
                                        severity="error"
                                        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                                    />
                                )}
                                {messages.map((m, i) => (
                                    <Typography
                                        key={'message' + i}
                                        color="text.secondary"
                                        mb={i < messages.length - 1 ? 2 : 0}
                                    >
                                        {m}
                                    </Typography>
                                ))}
                                {children}
                            </>
                        )}
                    </StyledDialogContent>
                </>
            ) : null}
            {(buttons.length > 0 || leftButton) && (
                <>
                    <Divider />
                    <StyledDialogActions>
                        <Box display="flex" flex={1}>
                            {leftButton}
                        </Box>
                        <Box display="flex" gap={2} justifyContent="flex-end">
                            {displayedButtons}
                        </Box>
                    </StyledDialogActions>
                </>
            )}
        </StyledDialog>
    );
};
