import { styled } from '@mui/material';
import MuiToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';

interface CustomToggleButtonProps extends ToggleButtonProps {
    icon?: JSX.Element;
    label: string;
}

export interface CustomToggleButtonGroupProps extends ToggleButtonGroupProps {
    buttons: CustomToggleButtonProps[];
    size?: 'small' | 'medium';
}

export const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'center',
    width: '100%',
}));

export const StyledToggleButton = styled(ToggleButton, { shouldForwardProp: (prop) => prop !== 'buttonSize' })<{
    buttonSize: 'small' | 'medium';
}>(({ buttonSize, theme }) => ({
    color: theme.palette.text.secondary,
    gap: theme.spacing(0.5),
    padding: buttonSize === 'small' ? theme.spacing(0.25, 1) : undefined,
    textTransform: 'none',
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
    '&.Mui-disabled.Mui-selected': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled,
    },
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

export const ToggleButtonGroup: React.FC<CustomToggleButtonGroupProps> = ({ buttons, size = 'medium', ...props }) => {
    const toggleButtons = buttons.map((b, i) => (
        <StyledToggleButton buttonSize={size} key={`toggle-button-${i}`} {...b}>
            {b.icon}
            {b.label}
        </StyledToggleButton>
    ));

    return (
        <StyledToggleButtonGroup size={size} {...props}>
            {toggleButtons}
        </StyledToggleButtonGroup>
    );
};
