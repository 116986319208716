import { useObservableState } from 'observable-hooks';
import {
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideProgressAction$,
    selectedWorkspaceSetupGuideStep$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../../store/workspace';
import { SetupGuideScriptScreen } from '../../../components/setup-guide/scripts/SetupGuideScriptScreen';
import { createNewScriptAction$, deleteScriptAction$, scriptsBeingDeleted$ } from '../../../store/workspace/script';
import { Language } from '../../../components/workspace-dialogs/language-selector';
import { getPreviousEnabledStep } from '../../../utils/setupGuide';
import { useNavigate } from 'react-location';

export const SetupGuideScriptScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const errors = useObservableState(workspaceSetupGuideStepError$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const { scripts } = useObservableState(selectedWorkspaceResources$);
    const scriptsBeingDeleted = useObservableState(scriptsBeingDeleted$);

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    return (
        <SetupGuideScriptScreen
            errors={errors}
            language={selectedWorkspace?.language as Language}
            nextStepName={nextStep?.title}
            saving={saving}
            scripts={scripts.map((s) => ({ deleting: scriptsBeingDeleted[s.uid], path: s.name, uid: s.uid }))}
            stepRequired={selectedSetupGuideStep?.required}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            workspaceLocked={!!selectedWorkspace?.locked}
            onContinue={() =>
                saveWorkspaceSetupGuideProgressAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onCreateNew={() => createNewScriptAction$.next()}
            onDeleteScript={(uid) => deleteScriptAction$.next({ uid })}
            onEditScript={(uid) => navigate({ to: `./${uid}` })}
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
        />
    );
};
