import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledEmptyStateBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: theme.spacing(3),
}));

export const StyledBorderBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

export const StyledBorderBoxHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

export const StyledBorderBoxHeaderSmall = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(1),
}));

export const StyledBorderBoxTitleContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
}));

export const StyledBorderBoxContent = styled(Box)<{ gap?: number }>(({ theme, gap = 2 }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(gap),
    padding: theme.spacing(2),
}));

export const StyledBorderBoxSubHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    gap: theme.spacing(0.5),
    flexDirection: 'column',
}));
