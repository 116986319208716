import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { ProductIcon } from '../../../icons/ProductIcon';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import { SetupGuideReviewScreenItem } from '../SetupGuideReviewScreenItem';
import {
    StyledListItemBottomTextContainer,
    StyledListItemLeftContainer,
    StyledSetupGuideReviewScreenListContainer,
} from '../../SetupGuideComponents';

import { SetupGuideApiHandler } from '../../types';

export interface SetupGuideReviewScreenApiHandlersProps {
    apiHandlers: SetupGuideApiHandler[];
    onEdit: () => void;
}

export const SetupGuideReviewScreenApiHandlers: React.FC<SetupGuideReviewScreenApiHandlersProps> = ({
    apiHandlers = [],
    onEdit,
}) => {
    return (
        <SetupGuideReviewScreenItem itemCount={apiHandlers.length} itemName="API connections" onEdit={onEdit}>
            {apiHandlers.map((c) => {
                return (
                    <StyledSetupGuideReviewScreenListContainer key={c.uid}>
                        <StyledListItemLeftContainer>
                            <SetupGuideIndicator
                                left={{ icon: <PowerOutlinedIcon /> }}
                                right={{ icon: <ProductIcon name={c.appName} /> }}
                            />
                            <Box>
                                <Typography>{c.appName}</Typography>
                                <Tooltip title={`./api/${c.path}`} placement="top">
                                    <StyledListItemBottomTextContainer>
                                        <LinkOutlinedIcon />
                                        <Typography>API Path:</Typography>
                                        <Typography color="primary.main">{c.path}</Typography>
                                    </StyledListItemBottomTextContainer>
                                </Tooltip>
                            </Box>
                        </StyledListItemLeftContainer>
                    </StyledSetupGuideReviewScreenListContainer>
                );
            })}
        </SetupGuideReviewScreenItem>
    );
};
