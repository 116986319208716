/* eslint-disable sonarjs/no-duplicate-string */
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { StyledReflexContainer, StyledReflexElement } from '../reflex/ReflexComponents';

export interface DefaultWorkspaceViewProps {
    blank?: boolean;
    loading?: boolean;
    onCreateNewApiConnection?: () => void;
    onCreateNewEventListener?: () => void;
    onCreateNewScript?: () => void;
}

const minColWidth = 60;
const addBtnSize = '20px';

const StyledArrow = styled('img')(({ theme }) => ({
    bottom: theme.spacing(1),
    height: 52,
    position: 'absolute',
    left: theme.spacing(-1.5),
}));

const StyledAddButton = styled(IconButton)(({ theme }) => ({
    height: addBtnSize + ' !important',
    width: addBtnSize + ' !important',
    '&:hover': {
        backgroundColor: 'initial',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.dark,
        height: 16,
        width: 16,
    },
}));

const StyledContent = styled('div')(() => ({
    marginLeft: 228,
    whiteSpace: 'nowrap',
}));

const StyledImg = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(6),
    marginLeft: 168,
    width: 321,
}));

const StyledList = styled(List)(() => ({
    padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
    '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
    },
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
    minWidth: 0 + ' !important',
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 0, 1),
}));

const StyledMedia = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: 397,
    left: '50%',
    padding: theme.spacing(5, 0),
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 808,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    marginLeft: `calc(${addBtnSize} + ${theme.spacing(1)})`,
    marginBottom: theme.spacing(0.75),
}));

const StyledTitleContainer = styled('div')(() => ({
    position: 'relative',
}));

export const DefaultWorkspaceView: React.FC<DefaultWorkspaceViewProps> = ({
    blank,
    loading = false,
    onCreateNewApiConnection,
    onCreateNewEventListener,
    onCreateNewScript,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    return (
        <StyledReflexContainer orientation="vertical" data-test-id="blank-workspace-view">
            <StyledReflexElement flex={8} minSize={minColWidth}>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <StyledMedia>
                        <StyledImg
                            src={process.env.PUBLIC_URL + '/blank-workspace-img.png'}
                            alt="Blank workspace upper"
                        />
                        <StyledContent>
                            <StyledTitleContainer>
                                <StyledArrow src={process.env.PUBLIC_URL + '/curved-arrow.png'} alt="Curved arrow" />
                                <StyledTitle variant="subtitle1">
                                    {blank ? 'Start' : 'Continue'} building your Workspace
                                </StyledTitle>
                            </StyledTitleContainer>
                            <StyledList>
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <StyledAddButton title="New API Connection" onClick={onCreateNewApiConnection}>
                                            <AddCircleIcon sx={{ opacity: '50%' }} />
                                        </StyledAddButton>
                                    </StyledListItemIcon>
                                    <StyledListItemText>
                                        Call APIs of your apps using <strong>API Connections</strong>
                                    </StyledListItemText>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <StyledAddButton title="New Event Listener" onClick={onCreateNewEventListener}>
                                            <AddCircleIcon sx={{ opacity: '75%' }} />
                                        </StyledAddButton>
                                    </StyledListItemIcon>
                                    <StyledListItemText>
                                        React to events triggered from your apps using <strong>Event Listeners</strong>
                                    </StyledListItemText>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <StyledAddButton title="New Script" onClick={onCreateNewScript}>
                                            <AddCircleIcon />
                                        </StyledAddButton>
                                    </StyledListItemIcon>
                                    <StyledListItemText>
                                        Write your integration logic using <strong>Scripts</strong>
                                    </StyledListItemText>
                                </StyledListItem>
                            </StyledList>
                        </StyledContent>
                    </StyledMedia>
                )}
            </StyledReflexElement>
        </StyledReflexContainer>
    );
};
