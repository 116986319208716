import { useObservableState } from 'observable-hooks';
import { SetupGuideEditScriptScreen } from '../../../components/setup-guide/scripts/SetupGuideEditScriptScreen';
import {
    saveScriptAction$,
    scriptHasUnsavedChanges$,
    scriptHelperPopupOpen$,
    scriptsBeingSaved$,
    selectedScriptContentChangedAction$,
    selectedScriptUid$,
    triggerScriptAction$,
    unsavedScriptDetails$,
} from '../../../store/workspace/script';
import { openApiConnectionImportDialogAction$ } from '../../../store/workspace/api-handler';
import { selectedEnvironmentUid$, selectedWorkspace$ } from '../../../store/workspace';
import { useNavigate } from 'react-location';
import { Language } from '../../../components/workspace-dialogs/language-selector';

export const SetupGuideEditScriptScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const environmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const scriptUid = useObservableState(selectedScriptUid$);
    const hasUnsavedChanges = useObservableState(scriptHasUnsavedChanges$);
    const unsavedScriptDetails = useObservableState(unsavedScriptDetails$);
    const scriptSaving = useObservableState(scriptsBeingSaved$);
    const popupOpen = useObservableState(scriptHelperPopupOpen$);

    const scriptKey = `${scriptUid ?? ''}_${environmentUid ?? ''}`;
    const selectedScript = unsavedScriptDetails[scriptKey];
    const selectedScriptHasUnsavedChanges = hasUnsavedChanges[scriptUid ?? ''];
    const selectedScriptSaving = scriptSaving[scriptUid ?? ''];

    return (
        <SetupGuideEditScriptScreen
            content={selectedScript?.content ?? ''}
            hasUnsavedChanges={selectedScriptHasUnsavedChanges}
            language={selectedWorkspace?.language as Language}
            path={selectedScript?.name ?? ''}
            saving={selectedScriptSaving}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            workspaceLocked={!!selectedWorkspace?.locked}
            onCancel={() => navigate({ to: '../' })}
            onChangeContent={(content) => selectedScriptContentChangedAction$.next(content)}
            onOpenImportApiConnectionDialog={() => openApiConnectionImportDialogAction$.next()}
            onOpenRenameScriptDialog={() => alert('Nope')}
            onSave={() => saveScriptAction$.next()}
            onToggleHelperPopup={() => scriptHelperPopupOpen$.next(popupOpen)}
            onTrigger={() => triggerScriptAction$.next(scriptUid ?? '')}
        />
    );
};
