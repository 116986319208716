import { configTopic$, stitchSession$ } from '../store/config';
import { getFetchOptions } from './fetch';
import { InformativeError } from './repository';
import { SESSION_ID } from '..';

export const executeEventListener = async (
    environmentUid: string,
    eventListenerUid: string,
    request = {}
): Promise<void> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    const triggerUrl = configTopic$.value.trigger?.listener?.executeUrl;

    if (!triggerUrl) {
        throw new Error('No trigger service url configured in meta');
    }

    const response = await fetch(`${triggerUrl}/${environmentUid}/${eventListenerUid}`, fetchOptions);

    if (!response.ok) {
        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.json();
    if (respBody?.statusCode && respBody.statusCode >= 400) {
        if (respBody.value) {
            throw new InformativeError(respBody.value);
        } else {
            throw new Error(`Unexpected error while communicating with trigger service: ${respBody.statusCode}`);
        }
    }
};

export const executeInvocationReplay = async (invocationUid: string, payload = {}): Promise<void> => {
    const fetchOptions = getFetchOptions(
        {
            Authorization: stitchSession$.value?.jwt ?? '',
            'x-stitch-session-id': SESSION_ID,
        },
        payload
    );
    const replayUrl = configTopic$.value.trigger?.retriggerUrl;

    if (!replayUrl) {
        throw new Error('No replay service url configured in meta');
    }

    const response = await fetch(`${replayUrl}/${invocationUid}`, fetchOptions);

    if (!response.ok) {
        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.json();
    if (respBody?.statusCode && respBody.statusCode >= 400) {
        if (respBody.value) {
            throw new InformativeError(respBody.value);
        } else {
            throw new Error(`Unexpected error while communicating with trigger service: ${respBody.statusCode}`);
        }
    }
};

export const createNetSuiteEventListenerScriptContent = (url: string): string => {
    return `/**
    * @NApiVersion 2.x
    * @NScriptType UserEventScript
    */
   define(['N/https', 'N/record'], function (https, record) {
       function afterSubmit(context) {
           var webhookUrl = '${url}';
   
           https.post({
               url: webhookUrl,
               body: JSON.stringify(context),
               headers: {
                   'Content-Type': 'application/json'
               }
           });
       }
   
       return {
           afterSubmit: afterSubmit
       };
   });`;
};
