import Dialog from '@mui/material/Dialog';
import { Button } from '../common/buttons/Button';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FullLengthDivider } from './OnboardingComponents';
import { styled } from '@mui/material';
import { InfoIcon } from '../icons/InfoIcon';
import { StyledMondayDialogTitle, MondayDialogSubtitle } from '../monday/MondayDialogComponents';
import { ProductIcon } from '../icons/ProductIcon';
import { DialogCloseButton } from '../for-deprecation/dialog/DialogComponents';

export interface OnboardingTemplateSelectorProps {
    open?: boolean;
    templates: {
        name: string;
        appsUsed: string[];
        onSelect: () => void;
    }[];
    onBrowse: () => void;
    onClose: () => void;
}

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paperScrollPaper': {
        minWidth: 900,
    },
}));

const StyledTooltipContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}));

const StyledTemplatesContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
}));

const StyledTemplateContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    alignItems: 'flex-start',
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: 280,
}));

const StyledProductIconsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
}));

const StyledProductIconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    gap: theme.spacing(2),
    borderRadius: theme.constants.radiusCircle,
    width: 40,
    height: 40,
    background: 'rgba(0, 0, 0, 0.04)',
}));

const StyledTemplateTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    minHeight: 75,
}));

const StyledTemplateApps = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
    WebkitLineClamp: 1,
}));

const StyledTemplateName = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowBlock,
    WebkitLineClamp: 2,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(2, 0),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'center !important',
    gap: theme.spacing(2),
}));

export const OnboardingTemplateSelector: React.FC<OnboardingTemplateSelectorProps> = ({
    open = false,
    templates = [],
    onClose,
    onBrowse,
}) => {
    return (
        <StyledDialog open={open}>
            <DialogCloseButton onClick={onClose} />
            <StyledMondayDialogTitle alignSelf="center" variant="h5">
                Get started with a template
            </StyledMondayDialogTitle>
            <MondayDialogSubtitle alignSelf="center" variant="body2" title="Build your first integration in minutes" />
            <FullLengthDivider />
            <StyledDialogContent>
                <StyledTooltipContainer>
                    <Typography variant="subtitle1">Suggested templates</Typography>
                    <InfoIcon tooltip="Templates based on your chosen apps" tooltipPlacement="right" />
                </StyledTooltipContainer>
                <StyledTemplatesContainer>
                    {templates.slice(0, 3).map((template, idx) => (
                        <StyledTemplateContainer key={idx}>
                            <StyledProductIconsContainer>
                                {template.appsUsed.slice(0, 3).map((app, index) => (
                                    <StyledProductIconContainer key={index}>
                                        <ProductIcon name={app} />
                                    </StyledProductIconContainer>
                                ))}
                            </StyledProductIconsContainer>
                            <StyledTemplateTitleContainer>
                                <StyledTemplateApps variant="body2" color="text.secondary">
                                    {template.appsUsed.join(' · ')}
                                </StyledTemplateApps>
                                <StyledTemplateName variant="subtitle2">{template.name}</StyledTemplateName>
                            </StyledTemplateTitleContainer>
                            <Button onClick={template.onSelect} fullWidth>
                                Try template
                            </Button>
                        </StyledTemplateContainer>
                    ))}
                </StyledTemplatesContainer>
            </StyledDialogContent>
            <FullLengthDivider />
            <StyledDialogActions>
                <Typography variant="subtitle2">Need help getting started?</Typography>
                <Button variant="outlined" onClick={onBrowse}>
                    Browse more templates
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};
