import React from 'react';
import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { ProductIcon } from '../icons/ProductIcon';
import {
    StyledListItem,
    StyledListItemButton,
    StyledSectionContainer,
    StyledSectionHeader,
    StyledSectionMain,
} from './DashboardComponents';
import { slackCommunityUrl, documentationUrl, supportPortalUrl, youtubeChannelUrl } from '../../utils/documentation';

const StyledLink = styled('a')(() => ({
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
}));

export const DashboardResources: React.FC = () => {
    return (
        <StyledSectionContainer>
            <StyledSectionHeader>
                <Typography variant="h6">Resources</Typography>
            </StyledSectionHeader>
            <Divider />
            <StyledSectionMain component="ul" m={0}>
                <StyledListItem>
                    <StyledLink data-pendo={'videos'} href={youtubeChannelUrl} target="_blank">
                        <StyledListItemButton>
                            <Tooltip title="Video tutorials">
                                <OndemandVideoOutlinedIcon />
                            </Tooltip>
                            <Typography>Video tutorials</Typography>
                            <ArrowForwardIosOutlinedIcon />
                        </StyledListItemButton>
                    </StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <StyledLink data-pendo={'docs'} href={documentationUrl} target="_blank">
                        <StyledListItemButton>
                            <Tooltip title="Documentation">
                                <MenuBookOutlinedIcon />
                            </Tooltip>
                            <Typography>Documentation</Typography>
                            <ArrowForwardIosOutlinedIcon />
                        </StyledListItemButton>
                    </StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <StyledLink data-pendo={'support'} href={supportPortalUrl} target="_blank">
                        <StyledListItemButton>
                            <Tooltip title="Contact support">
                                <QuestionAnswerOutlinedIcon />
                            </Tooltip>
                            <Typography>Contact support</Typography>
                            <ArrowForwardIosOutlinedIcon />
                        </StyledListItemButton>
                    </StyledLink>
                </StyledListItem>
                <StyledListItem>
                    <StyledLink data-pendo={'slackCommunity'} href={slackCommunityUrl} target="_blank">
                        <StyledListItemButton>
                            <Tooltip title="Slack community" describeChild>
                                <Box height={24}>
                                    <ProductIcon name="Slack" />
                                </Box>
                            </Tooltip>
                            <Typography>Slack community</Typography>
                            <ArrowForwardIosOutlinedIcon />
                        </StyledListItemButton>
                    </StyledLink>
                </StyledListItem>
            </StyledSectionMain>
        </StyledSectionContainer>
    );
};
