import { useObservableState } from 'observable-hooks';
import {
    askNewApiKeyNameDialogErrors$,
    askNewApiKeyNameDialogOpen$,
    askNewApiKeyNameDialogSaving$,
    closeAskNewApiKeyNameAction$,
    generateNewApiKeyAction$,
} from '../../../store/user';
import { AskNewApiKeyNameDialog } from '../../../components/profile/api-keys/AskNewApiKeyNameDialog';

export const AskNewApiKeyNameDialogContainer: React.FC = () => {
    const errors = useObservableState(askNewApiKeyNameDialogErrors$);
    const saving = useObservableState(askNewApiKeyNameDialogSaving$);
    const open = useObservableState(askNewApiKeyNameDialogOpen$);

    return (
        <AskNewApiKeyNameDialog
            errors={errors}
            loading={saving}
            open={open}
            onClose={() => closeAskNewApiKeyNameAction$.next()}
            onCreate={(apiKeyName) => generateNewApiKeyAction$.next(apiKeyName)}
        />
    );
};
