import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { ProductIcon } from '../../icons/ProductIcon';
import { SetupGuideIndicator } from '../SetupGuideIndicator';
import { StatusChip } from '../../common/chips/StatusChip';
import {
    StyledListItem,
    StyledListItemRightContainer,
    StyledListItemActions,
    StyledListItemLeftContainer,
    StyledListItemBottomTextContainer,
    StyledListItemWarningContainer,
} from '../SetupGuideComponents';
import { SetupGuideApiHandler } from '../types';

export interface SetupGuideApiHandlerListItemProps extends SetupGuideApiHandler {
    templateMode: boolean;
    workspaceLocked: boolean;
    onDelete: (uid: string) => void;
    onEdit: (uid: string) => void;
}

export const SetupGuideApiHandlerListItem: React.FC<SetupGuideApiHandlerListItemProps> = ({
    appName,
    deleting,
    group,
    needsSetup = false,
    path,
    templateMode,
    uid,
    workspaceLocked,
    onEdit,
    onDelete,
}) => {
    return (
        <StyledListItem needsSetup={needsSetup}>
            <StyledListItemLeftContainer>
                <SetupGuideIndicator
                    left={{ icon: <ApiOutlinedIcon /> }}
                    right={{ icon: <ProductIcon name={appName} />, state: needsSetup ? 'needsSetup' : 'active' }}
                />
                <Box>
                    <Typography>{appName + (group ? ` (${group})` : '')}</Typography>
                    {path && (
                        <Tooltip title={`./api/${path}`} placement="top" role="tooltip">
                            <StyledListItemBottomTextContainer>
                                <LinkOutlinedIcon />
                                <Typography>API Path:</Typography>
                                <Typography color="primary.main">{path}</Typography>
                            </StyledListItemBottomTextContainer>
                        </Tooltip>
                    )}
                    {needsSetup && (
                        <StyledListItemWarningContainer>
                            <WarningAmberOutlinedIcon />
                            <Typography>Complete API connection setup</Typography>
                        </StyledListItemWarningContainer>
                    )}
                </Box>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    {templateMode ? (
                        <IconButton
                            aria-label="Review API connection"
                            icon={<PreviewOutlinedIcon />}
                            size="small"
                            tooltip="Review API connection"
                            onClick={() => onEdit(uid)}
                        />
                    ) : (
                        <>
                            <IconButton
                                aria-label="Edit API connection"
                                disabled={deleting}
                                icon={<EditOutlinedIcon />}
                                size="small"
                                tooltip="Edit API connection"
                                onClick={() => onEdit(uid)}
                            />
                            <IconButton
                                aria-label="Delete API connection"
                                busy={deleting || workspaceLocked}
                                icon={<DeleteOutlineOutlinedIcon />}
                                size="small"
                                tooltip="Delete API connection"
                                onClick={() => onDelete(uid)}
                            />
                        </>
                    )}
                </StyledListItemActions>
                {needsSetup && <StatusChip icon={<UnpublishedOutlinedIcon />} label="Incomplete" />}
            </StyledListItemRightContainer>
        </StyledListItem>
    );
};
