import {
    Box,
    Button,
    FormControl,
    Link,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { Organisations, OrganisationsRequest } from '../../data/report/organisations';
import { getBasePath } from '../../utils/path';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';
import { useEffect, useState } from 'react';

type UserType = 'all' | 'adaptavist' | 'non-adaptavist' | 'non-stitch' | 'monday';

export interface OrganisationsReportPageProps extends OrganisationsRequest {
    organisations: Organisations;
    status: 'all' | 'active' | 'inactive';
    plan: 'all' | 'silver' | 'gold' | 'platinum' | 'free' | 'custom';
    planPeriod: 'all' | 'monthly' | 'annually';
    onSearch(userType: UserType, status: string, plan: string, planPeriod: string): void;
}

export const OrganisationsReportPage: React.FC<OrganisationsReportPageProps> = ({
    organisations,
    userType = 'all',
    status = 'all',
    plan = 'all',
    planPeriod = 'all',
    onSearch,
}) => {
    const [userTypeFilterValue, setUserTypeFilterValue] = useState<UserType>('all');
    const [statusFilterValue, setStatusFilterValue] = useState('all');
    const [planFilterValue, setPlanFilterValue] = useState('all');
    const [planPeriodFilterValue, setPlanPeriodFilterValue] = useState('all');

    const teamPlans = ['Free', 'Silver', 'Gold', 'Platinum', 'Custom'];

    useEffect(() => {
        setUserTypeFilterValue(userType);
        setStatusFilterValue(status);
        setPlanFilterValue(plan);
        setPlanPeriodFilterValue(planPeriod);
    }, []);

    const handleSearch = (): void =>
        onSearch(userTypeFilterValue, statusFilterValue, planFilterValue, planPeriodFilterValue);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Teams</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by User Type"
                            value={userTypeFilterValue}
                            onChange={(event) => setUserTypeFilterValue(event.target.value as UserType)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                            <MenuItem value="adaptavist">Adaptavist Only</MenuItem>
                            <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                            <MenuItem value="monday">monday.com</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Status"
                            value={statusFilterValue}
                            onChange={(event) => setStatusFilterValue(event.target.value)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Plan"
                            value={planFilterValue}
                            onChange={(event) => setPlanFilterValue(event.target.value)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="silver">Silver</MenuItem>
                            <MenuItem value="gold">Gold</MenuItem>
                            <MenuItem value="platinum">Platinum</MenuItem>
                            <MenuItem value="free">Free</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Plan Period"
                            value={planPeriodFilterValue}
                            onChange={(event) => setPlanPeriodFilterValue(event.target.value)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="annually">Annually</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            <Box sx={{ width: '30%', paddingBottom: 2 }}>
                {organisations.length > 0 && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Plan Type</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Total Active</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamPlans.map((teamPlan, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{teamPlan}</TableCell>
                                    <TableCell>
                                        {organisations.filter((org) => org.plan === teamPlan.toLowerCase()).length}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            organisations.filter(
                                                (org) => org.plan === teamPlan.toLowerCase() && org.status === 'Active'
                                            ).length
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
            <Box>
                <Typography>Total Teams: {organisations.length}</Typography>
            </Box>

            {organisations.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Team ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Billing Contact</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Plan Period</TableCell>
                            <TableCell>Plan Version</TableCell>
                            <TableCell>Subscription Status</TableCell>
                            <TableCell>Workspaces</TableCell>
                            <TableCell>Connectors</TableCell>
                            <TableCell>Members</TableCell>
                            <TableCell>Member Invites</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organisations.map((organisation) => (
                            <TableRow id={organisation.uid} key={organisation.uid}>
                                <TableCell>
                                    <Link href={`${getBasePath()}reports/team/${organisation.uid}`} target="_blank">
                                        {organisation.uid}
                                    </Link>
                                </TableCell>
                                <TableCell>{organisation.name}</TableCell>
                                <TableCell>{organisation.createdBy}</TableCell>
                                <TableCell>{organisation.billingContact ?? ''}</TableCell>
                                <TableCell>{organisation.plan ?? ''}</TableCell>
                                <TableCell>{organisation.planPeriod ?? ''}</TableCell>
                                <TableCell>{organisation.planVersion ?? ''}</TableCell>
                                <TableCell>{organisation.subscriptionStatus ?? ''}</TableCell>
                                <TableCell>{organisation.workspaces}</TableCell>
                                <TableCell>{organisation.connectors}</TableCell>
                                <TableCell>{organisation.members}</TableCell>
                                <TableCell>{organisation.memberInvites}</TableCell>
                                <TableCell>{organisation.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>No Teams found</div>
            )}
        </PageContainer>
    );
};
