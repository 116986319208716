import { useObservableState, useSubscription } from 'observable-hooks';
import {
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideEnvironmentVariablesAction$,
    saveWorkspaceSetupGuideProgressAction$,
    selectedWorkspaceSetupGuideStep$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceUid$ } from '../../../store/workspace';

import { SetupGuideEnvironmentVariableScreen } from '../../../components/setup-guide/environment-variables/SetupGuideEnvironmentVariableScreen';
import {
    changeEnvironmentVariableTypeAction$,
    createEnvironmentVariableAction$,
    createEnvironmentVariableFolderAction$,
    deleteEnvironmentVariableAction$,
    environmentVariablesHaveMissingInformation$,
    environmentVariablesSavedAction$,
    toggleEnvironmentVariableEditModeAction$,
    toggleEnvironmentVariableExpandAction$,
    unsavedEnvironmentVariables$,
    updateEnvironmentVariableAction$,
} from '../../../store/workspace/environment-variable';
import { getPreviousEnabledStep } from '../../../utils/setupGuide';

export const SetupGuideEnvironmentVariableScreenContainer: React.FC = () => {
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const variables = useObservableState(unsavedEnvironmentVariables$);
    const variablesMissingInformation = useObservableState(environmentVariablesHaveMissingInformation$);
    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const errors = useObservableState(workspaceSetupGuideStepError$);

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    useSubscription(environmentVariablesSavedAction$, () => {
        saveWorkspaceSetupGuideProgressAction$.next({
            stepUid: selectedSetupGuideStep?.uid ?? '',
            workspaceUid: selectedWorkspaceUid ?? '',
        });
    });

    return (
        <SetupGuideEnvironmentVariableScreen
            environmentVariables={variables}
            errors={errors}
            nextStepName={nextStep?.title}
            saving={saving}
            stepRequired={selectedSetupGuideStep?.required}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            variablesMissingInformation={variablesMissingInformation}
            workspaceLocked={!!selectedWorkspace?.locked}
            onContinue={() =>
                saveWorkspaceSetupGuideEnvironmentVariablesAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onChangeVariableType={(event) => changeEnvironmentVariableTypeAction$.next(event)}
            onCreateFolder={() => createEnvironmentVariableFolderAction$.next()}
            onCreateVariable={(parentId) => createEnvironmentVariableAction$.next(parentId)}
            onDeleteVariable={(parentId) => deleteEnvironmentVariableAction$.next(parentId)}
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
            onSkip={() =>
                saveWorkspaceSetupGuideProgressAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onToggleVariableEditMode={(event) => toggleEnvironmentVariableEditModeAction$.next(event)}
            onToggleVariableExpand={(event) => toggleEnvironmentVariableExpandAction$.next(event)}
            onUpdateVariable={(event) => updateEnvironmentVariableAction$.next(event)}
        />
    );
};
