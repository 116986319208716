import React, { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Button } from '../../common/buttons/Button';
import { InfoIcon } from '../../icons/InfoIcon';
import { OrgAvatarContainer } from '../../common/Avatars';
import { PageContainer } from '../../app-main/PageComponentsForDeprecation';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';

export interface EditBillingDetailsProps {
    uid: string;
    email: string;
    name: string;
    errors?: string;
    organizations?: {
        uid: string;
        name: string;
        avatar?: string;
    }[];
    onCancel(): void;
    onUpdate(event: UpdateBillingEntityEvent): void;
    updating: boolean;
}

export interface UpdateBillingEntityEvent {
    uid: string;
    name: string;
    email: string;
}

const StyledDetailsContainer = styled(Box)(({ theme }) => ({
    maxWidth: 300,
    paddingTop: theme.spacing(2),
}));

const StyledOrgImageBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: 12,
    overflow: 'hidden',
    height: 52,
    width: 52,
}));

const StyledLineBox = styled(Box)(({ theme }) => ({
    alignSelf: 'center',
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
    margin: theme.spacing(3, 0),
    width: '100%',
}));

const StyledMicrocopyBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
    },
}));

const StyledOrgContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
}));

const StyledOrgNameTypography = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

export const EditBillingDetails: React.FC<EditBillingDetailsProps> = ({
    email,
    errors,
    name,
    onCancel,
    onUpdate,
    organizations = [],
    uid,
    updating,
}) => {
    const [currentEmail, setCurrentEmail] = useState(email);
    const [currentName, setCurrentName] = useState(name);

    const isChanged = currentEmail !== email || currentName !== name;

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            {errors && <DialogAlert severity="error" title={errors} />}
            <StyledDetailsContainer>
                <TextField
                    id="billing-name-text-field"
                    onChange={(e) => setCurrentName(e.target.value)}
                    variant="outlined"
                    label="Billing contact name"
                    value={currentName}
                    fullWidth
                    required
                />
                <TextField
                    id="billing-email-text-field"
                    onChange={(e) => setCurrentEmail(e.target.value)}
                    variant="outlined"
                    label="Email"
                    value={currentEmail}
                    fullWidth
                    required
                />
                <DialogActions>
                    <Button variant="outlined" disabled={updating} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onUpdate({ uid, email: currentEmail, name: currentName })}
                        disabled={!isChanged || updating || !currentName || !currentEmail}
                        busy={updating}
                    >
                        Update
                    </Button>
                </DialogActions>
            </StyledDetailsContainer>
            {organizations.length > 0 && (
                <>
                    <StyledLineBox />
                    <StyledMicrocopyBox>
                        <InfoIcon />
                        <Typography variant="body1" color="text.secondary">
                            The following teams are associated with these billing details:
                        </Typography>
                    </StyledMicrocopyBox>
                </>
            )}
            {organizations.map((org, i) => (
                <StyledOrgContainer key={i}>
                    {org.avatar ? (
                        <StyledOrgImageBox>
                            <img
                                src={`data:image/jpeg;base64,${org.avatar}`}
                                width={50}
                                height={50}
                                alt="Associated organisation avatar image"
                            />
                        </StyledOrgImageBox>
                    ) : (
                        <OrgAvatarContainer style="square" name={org.name}></OrgAvatarContainer>
                    )}
                    <StyledOrgNameTypography fontWeight={500}>{org.name}</StyledOrgNameTypography>
                </StyledOrgContainer>
            ))}
        </PageContainer>
    );
};
