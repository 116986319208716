import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import ListItem from '@mui/material/ListItem';
import { EventListenerStepItem } from '../../EventListenerStepItem';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';

interface ServiceNowEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const ServiceNowEventListenerSetupDialog: React.FC<ServiceNowEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = 'ServiceNow';

    const createRecord = (
        <>
            {setupBaseUrl ? (
                <ListItem>
                    <Typography>
                        Visit your{' '}
                        <Link target="_blank" href={`${setupBaseUrl}/sys_rest_message_list.do`}>
                            REST messages
                        </Link>{' '}
                        page.
                    </Typography>
                </ListItem>
            ) : (
                <ListItem>
                    <Typography>
                        Open https://[YOUR_SERVICENOW_INSTANCE]/sys_rest_message_list.do in your browser
                    </Typography>
                </ListItem>
            )}
            <ListItem>
                <Typography>
                    Click <strong>New</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Enter a meaningful name into the <strong>Name</strong> field.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Paste the copied URL into the <strong>Endpoint</strong> field.
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
            <ListItem>
                <Typography>
                    Click <strong>Submit</strong>.
                </Typography>
            </ListItem>
        </>
    );

    const createHttpMethod = (
        <>
            <ListItem>
                <Typography>
                    Select the newly created REST message from the list to continue the configuration.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Scroll down to <strong>HTTP Methods</strong> and click <strong>New</strong> to create a new HTTP
                    method.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Enter a meaningful name into the <strong>Name</strong> field.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Select <strong>POST</strong> from the <strong>HTTP method</strong> dropdown.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Paste the copied URL into the <strong>Endpoint</strong> field.
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
        </>
    );

    const configureHttpMethod = (
        <>
            <ListItem>
                <Typography>
                    Click on the <strong>HTTP Request</strong> tab.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    In the <strong>HTTP Headers</strong> section, click on <strong>Insert a new row...</strong> Copy and
                    paste values to the <strong>Name</strong> and <strong>Value</strong> inputs respectively
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField label="HTTP Header Name" value="Content-type" />
            <OutlinedReadOnlyTextField label="HTTP Header Value" value="application/json; charset=UTF-8" />
            <ListItem>
                <Typography>
                    Copy the JSON and paste it to the <strong>Content</strong> field under the{' '}
                    <strong>HTTP Query Parameters</strong> section. This will be the event payload that is sent to
                    ScriptRunner Connect.
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField
                label="Content"
                value={
                    '{"title": "${title}", "eventType": "generic_triggered", "user": "${user}", "trigger": "${trigger}"}'
                }
            />
            <ListItem>
                <Typography>
                    Optionally you can add more variables to your event payload, by editing the provided JSON. Note that
                    the values for these variables will need to be specified later under <strong>Business rules</strong>
                    .
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click on <strong>Submit</strong>.
                </Typography>
            </ListItem>
        </>
    );

    const generateVariables = (
        <>
            <ListItem>
                <Typography>Click on the newly created HTTP method.</Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Scroll down to <strong>Related Links</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click on the <strong>Auto-generate variables</strong> link.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click on the <strong>Preview Script Usage</strong> to generate the code required to send a request
                    to the webhook.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>Copy the generated code.</Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Close the modal and click on <strong>Update</strong>.
                </Typography>
            </ListItem>
        </>
    );

    const createBusinessRule = (
        <>
            {setupBaseUrl ? (
                <ListItem>
                    <Typography>
                        Navigate to{' '}
                        <Link target="_blank" href={`${setupBaseUrl}/sys_script_list.do`}>
                            Business Rules
                        </Link>
                    </Typography>
                </ListItem>
            ) : (
                <ListItem>
                    <Typography>Open https://[YOUR_SERVICENOW_INSTANCE]/sys_script_list.do in your browser</Typography>
                </ListItem>
            )}
            <ListItem>
                <Typography>
                    Click on <strong>New</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Enter a meaningful name into the <strong>Name</strong> field.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Select the table for which you wish to set up the event listener, for example{' '}
                    <strong>{'Incident[incident]'}</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Check the <strong>Advanced</strong> checkbox.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Check one or more appropriate checkboxes on the right depending on which events you wish to listen
                    to.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Optionally, you can change the timing of when exactly the rule will be run by selecting a preferred
                    option from the <strong>When</strong> dropdown.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    If you wish to add advanced conditions to when your event will fire, you can do so under{' '}
                    <strong>Filter Conditions</strong> and <strong>Role Conditions</strong>.
                </Typography>
            </ListItem>
        </>
    );

    const configureScript = (
        <>
            <ListItem>
                <Typography>
                    Click on the <strong>Advanced</strong> tab and paste the code you had copied in the preview step
                    below the <strong>{'//Add your code here'}</strong> line.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    In the code, update the <strong>r.execute()</strong> function call to{' '}
                    <strong>r.executeAsync()</strong>.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Replace <strong>r.setStringParameterNoEscape('user', '');</strong> with:
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField
                label="User"
                value={`r.setStringParameterNoEscape('user', gs.getUser().getID());`}
            />
            <ListItem>
                <Typography>
                    Replace <strong>r.setStringParameterNoEscape('trigger', '');</strong> with:
                </Typography>
            </ListItem>
            <OutlinedReadOnlyTextField
                label="Trigger"
                value={`r.setStringParameterNoEscape('trigger', current.getDisplayValue());`}
            />
            <ListItem>
                <Typography>
                    Optionally replace the empty string in <strong>r.setStringParameterNoEscape('title', '');</strong>{' '}
                    with an appropriate title in order to give the event object a name.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Optionally if you defined more variables in step 14, they will show up here as well, but you will
                    need to provide the data yourself. Referencing the <strong>current</strong> object might be helpful.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    For advanced use cases you can optionally specify your filter conditions using JavaScript. In order
                    to do that, write your condition into the <strong>Condition</strong> field, by referencing the{' '}
                    <strong>current</strong> object.
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    Click <strong>Submit</strong>.
                </Typography>
            </ListItem>
        </>
    );

    return (
        <Dialog
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title={'Event listener setup'}
            subtitle={appName}
            onClose={onClose}
            loading={loading}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" component="h6" mb={0.5}>
                        How to set up an event listener
                    </Typography>
                    <Typography color="text.secondary">
                        Create a webhook in {appName} instance to listen to events.
                    </Typography>
                </Box>
                <EventListenerStepItem title="Step 1: Create REST message record" expanded={true}>
                    {createRecord}
                </EventListenerStepItem>
                <EventListenerStepItem title="Step 2: Create HTTP Method" startCounter={6}>
                    {createHttpMethod}
                </EventListenerStepItem>
                <EventListenerStepItem title="Step 3: Configure HTTP method" startCounter={11}>
                    {configureHttpMethod}
                </EventListenerStepItem>
                <EventListenerStepItem title="Step 4: Generate variables for event payload" startCounter={16}>
                    {generateVariables}
                </EventListenerStepItem>
                <EventListenerStepItem title="Step 5: Create business rule" startCounter={22}>
                    {createBusinessRule}
                </EventListenerStepItem>
                <EventListenerStepItem title="Step 6: Configure business rule script" startCounter={30}>
                    {configureScript}
                </EventListenerStepItem>
            </>
        </Dialog>
    );
};
