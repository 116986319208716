import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type SetupGuideIndicatorState = 'active' | 'authorized' | 'needsSetup';

interface SetupGuideIndicatorProps {
    left: {
        icon: JSX.Element;
    };
    right?: {
        icon: JSX.Element;
        state?: SetupGuideIndicatorState;
    };
}

const StyledIndicatorContainer = styled(Box)(() => ({
    alignSelf: 'stretch',
    display: 'flex',
}));

const StyledIndicator = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 40,
    justifyContent: 'center',
    width: 40,
    '& img': {
        height: 24,
        width: 24,
    },
}));

const StyledLeftIndicator = styled(StyledIndicator)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: `${theme.constants.borderRadius}px 0 0 ${theme.constants.borderRadius}px`,
}));

const StyledCenterIndicator = styled(StyledIndicator)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledRightIndicator = styled(StyledIndicator, {
    shouldForwardProp: (prop) => prop !== 'state',
})<{ state: SetupGuideIndicatorState }>(({ state, theme }) => {
    const authorized = state === 'authorized';
    const needsSetup = state === 'needsSetup';
    return {
        ...theme.typography.flexAlignCenter,
        backgroundColor: authorized
            ? alpha(theme.palette.success.light, 0.1)
            : needsSetup
            ? alpha(theme.palette.warning.light, 0.1)
            : theme.palette.background.paper,
        borderRadius: `0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px 0`,
        border: authorized
            ? `1px solid ${theme.palette.success.main}`
            : needsSetup
            ? `1px solid ${theme.palette.warning.main}`
            : `1px solid ${theme.palette.divider}`,
    };
});

export const SetupGuideIndicator: React.FC<SetupGuideIndicatorProps> = ({ left, right }) => {
    return (
        <StyledIndicatorContainer>
            {right ? (
                <>
                    <StyledLeftIndicator>{left.icon}</StyledLeftIndicator>
                    <StyledRightIndicator state={right.state ?? 'active'}>{right.icon}</StyledRightIndicator>
                </>
            ) : (
                <StyledCenterIndicator>{left.icon}</StyledCenterIndicator>
            )}
        </StyledIndicatorContainer>
    );
};
