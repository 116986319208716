import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import { Button } from '../../buttons/Button';
import { ButtonProps } from '@mui/material/Button';
import ClickAwayListener from '@mui/base/ClickAwayListener';

interface DropdownMenuProps extends ButtonProps {
    dropdownItems: DropdownActionItem[];
}
export interface DropdownActionItem {
    name: string;
    label: string;
    disabled?: boolean;
    busy?: boolean;
    action?: () => void;
    startIcon?: JSX.Element;
}

const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
    pointerEvents: 'auto',
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    pointerEvents: 'none',
    top: theme.spacing(2),
    '& .MuiPaper-root': {
        boxShadow: theme.constants.boxShadow,
    },
    '& .MuiButton-root': {
        pointerEvents: 'auto',
    },
}));

const StyledSelectionButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    justifyContent: 'left',
    padding: theme.spacing(0.5, 2),
    textTransform: 'none',
    width: '100%',
    '& .MuiSvgIcon-root': {
        height: 20,
        marginRight: theme.spacing(1),
        width: 20,
    },
}));

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ dropdownItems, ...props }) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchor);

    return (
        <>
            <StyledButton
                {...props}
                ref={undefined}
                variant="text"
                onClick={(event) => {
                    event.stopPropagation();
                    if (!anchor) {
                        setAnchor(event.currentTarget);
                    } else {
                        setAnchor(null);
                    }
                }}
            />
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledPopover
                    open={open}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    <StyledPaper>
                        {dropdownItems.map((item) => {
                            return (
                                <StyledSelectionButton
                                    key={item.name}
                                    variant="text"
                                    disabled={!!item.disabled}
                                    busy={!!item.busy}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        item.action?.();
                                        if (item.busy === undefined) {
                                            setAnchor(null);
                                        }
                                    }}
                                    startIcon={item.startIcon}
                                >
                                    {item.label}
                                </StyledSelectionButton>
                            );
                        })}
                    </StyledPaper>
                </StyledPopover>
            </ClickAwayListener>
        </>
    );
};
