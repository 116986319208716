import { BehaviorSubject, map, Subject } from 'rxjs';
import { selectedWorkspace$ } from '.';
import { updateWorkspaceLanguage } from '../../data/workspace';
import { InformativeError } from '../../utils/repository';
import { initEditorAction$ } from '../editor/editor';
import { monitor } from '../monitor';
import { Language } from '../../components/workspace-dialogs/language-selector';

interface UpdateWorkspaceLanguageEvent {
    workspaceUid: string;
    language: Language;
}

export const languageSelectorOpen$ = monitor('languageSelectorOpen$', new BehaviorSubject(false));
export const languageSelectorSaving$ = monitor('languageSelectorSaving$', new BehaviorSubject(false));
export const languageSelectorError$ = monitor(
    'languageSelectorError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const updateWorkspaceLanguageAction$ = monitor(
    'updateWorkspaceLanguageAction$',
    new Subject<UpdateWorkspaceLanguageEvent>()
);

updateWorkspaceLanguageAction$
    .pipe(
        map(async (event) => {
            languageSelectorError$.next(undefined);
            languageSelectorSaving$.next(true);
            const workspace = selectedWorkspace$.value;

            try {
                await updateWorkspaceLanguage(event.workspaceUid, event.language);
                if (workspace) {
                    selectedWorkspace$.next({ ...workspace, language: event.language });
                }
                languageSelectorOpen$.next(false);
                initEditorAction$.next({ language: event.language });
            } catch (e) {
                if (e instanceof InformativeError) {
                    languageSelectorError$.next(e.message);
                } else {
                    console.error('Failed to update workspace language', e);
                    languageSelectorError$.next(
                        'Failed to update workspace language, please try again, if the issue persists please contact support.'
                    );
                }
            }

            languageSelectorSaving$.next(false);
        })
    )
    .subscribe();
