import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    selectedEnvironmentUid$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from '.';
import { SaveEventListenerEvent } from '../../components/workspace-resources/event-listeners/EventListenerDetails';
import { createEventListener, deleteEventListener, EventListener, saveEventListener } from '../../data/event-listener';
import { executeEventListener } from '../../utils/eventListener';
import { InformativeError } from '../../utils/repository';
import { saveScriptBundledOutput } from '../../utils/script';
import { apps$, appSelectorOpen$, loadingAppSelector$ } from '../apps';
import { promptQuestion } from '../confirm';
import { loggedInUserConnections$ } from '../connections';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { newScriptCreatedAction$ } from './script';
import { DeletionRequest } from './types';
import { loadWorkspaceResourcesWhenEventIsEmitted } from './utils';
import { APP } from '@avst-stitch/repository-lib/constants';
import { genericEventListenerSetupDialogOpenAction$ } from '../connection/generic/setup-event-listener';
import { atlassianCloudEventListenerSetupDialogOpenAction$ } from '../connection/atlassian-cloud/setup-event-listener';
import { bitbucketCloudEventListenerSetupDialogOpenAction$ } from '../connection/bitbucket-cloud/setup-event-listener';
import { serviceNowEventListenerSetupDialogOpenAction$ } from '../connection/servicenow/setup-event-listener';
import { slackEventListenerSetupDialogOpenAction$ } from '../connection/slack/setup-event-listener';
import { atlassianOnPremiseEventListenerSetupDialogOpenAction$ } from '../connection/atlassian-on-premise/setup-event-listener';
import { mondayEventListenerSetupDialogOpenAction$ } from '../connection/monday/setup-event-listener';
import { gitlabEventListenerSetupDialogOpenAction$ } from '../connection/gitlab/setup-event-listener';
import { githubEventListenerSetupDialogOpenAction$ } from '../connection/github/setup-event-listener';
import { bitbucketOnPremiseEventListenerSetupDialogOpenAction$ } from '../connection/bitbucket-on-premise/setup-event-listener';
import { microsoftEventListenerSetupDialogOpenAction$ } from '../connection/microsoft/setup-event-listener';
import { azureDevopsEventListenerSetupDialogOpenAction$ } from '../connection/azure-devops/setup-event-listener';
import { zoomEventListenerSetupDialogOpenAction$ } from '../connection/zoom/setup-event-listener';
import { salesforceEventListenerSetupDialogOpenAction$ } from '../connection/salesforce/setup-event-listener';
import { opsgenieEventListenerSetupDialogOpenAction$ } from '../connection/opsgenie/setup-event-listener';
import { statuspageEventListenerSetupDialogOpenAction$ } from '../connection/statuspage/setup-event-listener';
import { netSuiteEventListenerSetupDialogOpenAction$ } from '../connection/netsuite/setup-event-listener';
import { zenDeskEventListenerSetupDialogOpenAction$ } from '../connection/zendesk/setup-event-listener';

export const createEventListenerAction$ = monitor('createEventListenerAction$', new Subject<CreateEventListener>());
export const eventListenerCreatedAction$ = monitor('eventListenerCreatedAction$', new Subject<string>());
export const navigateToEventListenerAction$ = monitor('navigateToEventListenerAction$', new Subject<string>());
export const saveEventListenerAction$ = monitor('saveEventListenerAction$', new Subject<SaveEventListenerEvent>());
export const eventListenerSavedAction$ = monitor(
    'eventListenerSavedAction$',
    new Subject<{ eventListenerUid: string; scriptUid?: string }>()
);
export const deleteEventListenerAction$ = monitor('deleteEventListenerAction$', new Subject<DeletionRequest>());
export const eventListenerDeletedAction$ = monitor('eventListenerDeletedAction$', new Subject<string>());

export const selectedEventListenerErrors$ = monitor(
    'selectedEventListenerErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const selectedEventListenerDetails$ = monitor(
    'electedEventListenerDetails$',
    new BehaviorSubject<EventListener | undefined>(undefined)
);
export const savingSelectedEventListener$ = monitor('savingSelectedEventListener$', new BehaviorSubject(false));
export const eventListenersBeingDeleted$ = monitor(
    'eventListenerBeingDeleted$',
    new BehaviorSubject<Record<string, boolean>>({})
);
export const triggerEventListenerAction$ = monitor('triggerEventListenerAction$', new Subject<string>());
export const eventListenerExecutionInProgress$ = monitor(
    'eventListenerExecutionInProgress$',
    new BehaviorSubject<Record<string, boolean>>({})
);

createEventListenerAction$
    .pipe(
        map(async (event) => {
            loadingAppSelector$.next(true);

            try {
                const { uid } = await createEventListener(
                    selectedWorkspaceUid$.value ?? '',
                    event.appUid,
                    event.eventListenerTypeUid
                );
                eventListenerCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while creating Event Listener', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to create Event Listener, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            loadingAppSelector$.next(false);
            appSelectorOpen$.next(false);
        })
    )
    .subscribe();

saveEventListenerAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (event) => {
            const connection = loggedInUserConnections$.value.find((el) => el.uid === event.connectionUid);

            const initiateSelectedEventListenersSave = async (): Promise<void> => {
                savingSelectedEventListener$.next(true);
                selectedEventListenerErrors$.next(undefined);

                try {
                    const { urlId, scriptUid, eventTypeCategory } = await saveEventListener(
                        event.uid,
                        selectedEnvironmentUid$.value ?? '',
                        event.eventTypeUid,
                        event.connectionUid,
                        event.scriptUid,
                        event.scriptName,
                        event.urlId,
                        event.disabled
                    );

                    eventListenerSavedAction$.next({
                        eventListenerUid: event.uid,
                        scriptUid,
                    });
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Event Listener saved.',
                    });

                    const details = selectedEventListenerDetails$.value;
                    if (details && urlId) {
                        selectedEventListenerDetails$.next({
                            ...details,
                            eventTypeCategory,
                            eventTypeUid: event.eventTypeUid,
                            urlId,
                            disabled: event.disabled,
                            connectionUid: event.connectionUid,
                        });
                    }
                    const hasNewConnection =
                        event.connectionUid !== undefined &&
                        details?.connectionUid !== undefined &&
                        event.connectionUid !== details.connectionUid;

                    openEventListenerSetupDialogIfNeeded(details?.appUid, urlId, hasNewConnection);

                    if (scriptUid) {
                        newScriptCreatedAction$.next({ uid: scriptUid });
                    }
                } catch (e) {
                    if (e instanceof InformativeError) {
                        selectedEventListenerErrors$.next(e.message);
                    } else {
                        selectedEventListenerErrors$.next(
                            'Failed to save Event Listener, please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while saving Event Listener', e);
                    }
                }

                savingSelectedEventListener$.next(false);
            };

            if ((connection && connection.authorized) || !connection) {
                await initiateSelectedEventListenersSave();
            } else {
                promptQuestion({
                    title: 'Are you sure you want to save the Event Listener without having authorized the related connection?',
                    onProceed: async () => await initiateSelectedEventListenersSave(),
                });
            }
        })
    )
    .subscribe();

const updateEventListenerExecutionStatus = (eventListenerUid: string, isInProgress: boolean): void => {
    eventListenerExecutionInProgress$.next({
        ...eventListenerExecutionInProgress$.value,
        [eventListenerUid]: isInProgress,
    });
};

deleteEventListenerAction$
    .pipe(
        map(async (event) => {
            const initiateEventListenerDelete = async (): Promise<void> => {
                eventListenersBeingDeleted$.next({
                    ...eventListenersBeingDeleted$.value,
                    [event.uid]: true,
                });

                try {
                    const response = await deleteEventListener(event.uid, event.ignoreWarnings);

                    if (!event.ignoreWarnings && response.warning) {
                        promptQuestion({
                            title: response.warning,
                            onProceed: () =>
                                deleteEventListenerAction$.next({
                                    ...event,
                                    ignoreWarnings: true,
                                }),
                        });
                    } else if (!response.warning) {
                        eventListenerDeletedAction$.next(event.uid);
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Event Listener deleted.',
                        });
                    }
                } catch (e) {
                    console.error('Error while deleting Event Listener', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to delete Event Listener, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }

                eventListenersBeingDeleted$.next({
                    ...eventListenersBeingDeleted$.value,
                    [event.uid]: false,
                });
            };

            if (!event.ignoreWarnings) {
                promptQuestion({
                    title: 'Are you sure you want to delete the Event Listener?',
                    onProceed: async () => await initiateEventListenerDelete(),
                });
            } else {
                await initiateEventListenerDelete();
            }
        })
    )
    .subscribe();

eventListenerCreatedAction$.subscribe((uid) => {
    navigateToEventListenerAction$.next(uid);
});

triggerEventListenerAction$
    .pipe(
        map(async (eventListenerUid) => {
            await triggerEventListener(selectedEnvironmentUid$.value ?? '', eventListenerUid);
        })
    )
    .subscribe();

loadWorkspaceResourcesWhenEventIsEmitted(eventListenerCreatedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(eventListenerSavedAction$);

eventListenerSavedAction$.subscribe(() => {
    const details = selectedEventListenerDetails$.value;
    if (details) {
        selectedEventListenerDetails$.next({
            ...details,
            selectedSharedConnection: undefined,
        });
    }
});

const openEventListenerSetupDialogIfNeeded = (appUid?: string, urlId?: string, newConnection?: boolean): void => {
    if (urlId || newConnection) {
        const details = selectedEventListenerDetails$.value;

        if (details) {
            openEventListenerSetupDialog(
                {
                    connectionUid: details.connectionUid,
                    eventListenerTypeUid: details.eventListenerTypeUid,
                    eventTypeCategory: details.eventTypeCategory,
                    eventTypeUid: details.eventTypeUid,
                    urlId: urlId ?? details.urlId,
                    uid: details.uid,
                },
                appUid
            );
        }
    }
};

const triggerEventListener = async (environmentUid: string, eventListenerUid: string): Promise<void> => {
    const eventListener = selectedWorkspaceResources$.value.eventListeners.find(
        (eventListener) => eventListener.uid === eventListenerUid
    );

    if (eventListener?.script) {
        try {
            updateEventListenerExecutionStatus(eventListenerUid, true);
            if (!selectedWorkspaceEnvironment$.value?.deployment) {
                await saveScriptBundledOutput(eventListener.script.uid, [], true);
            }
            await executeEventListener(environmentUid, eventListenerUid);
        } catch (e) {
            if (e instanceof InformativeError) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: e.message,
                    noToast: true,
                });
            } else {
                console.error('Error while executing Event Listener', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to execute the Event Listener, try executing the Event Listener again, if the issue persists please contact support.`,
                    noToast: true,
                });
            }
        }
    }

    updateEventListenerExecutionStatus(eventListenerUid, false);
};

export type CreateEventListener = {
    appUid: string;
    eventListenerTypeUid: string;
};

export const openEventListenerSetupDialog = (
    eventListener: {
        connectionUid?: string;
        eventListenerTypeUid?: string;
        eventTypeCategory?: string;
        eventTypeUid?: string;
        uid: string;
        urlId?: string;
    },
    appUid?: string
    // eslint-disable-next-line sonarjs/cognitive-complexity
): void => {
    const app = apps$.value.find((a) => a.uid === appUid);

    const eventListenerType = app?.connectionType.eventListenerTypes.find(
        (elt) => elt.uid === eventListener?.eventListenerTypeUid
    );

    const eventTypeName =
        (eventListenerType?.eventTypes ?? []).find((et) => et.uid === eventListener.eventTypeUid)?.name ??
        'UNKNOWN_EVENT';

    if (app?.name) {
        switch (app?.name) {
            case APP.GENERIC.NAME:
                genericEventListenerSetupDialogOpenAction$.next({
                    urlPath: eventListener.urlId ?? '',
                });
                break;
            case APP.JIRA_CLOUD.NAME:
                atlassianCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira',
                });
                break;
            case APP.CONFLUENCE_CLOUD.NAME:
                atlassianCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Confluence',
                });
                break;
            case APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME:
                atlassianCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira Service Management',
                });
                break;
            case APP.BITBUCKET_CLOUD.NAME:
                bitbucketCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.SLACK.NAME:
                slackEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                    eventTypeName,
                    eventTypeCategory: eventListener.eventTypeCategory ?? '',
                });
                break;
            case APP.SERVICENOW.NAME:
                serviceNowEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.NETSUITE.NAME:
                netSuiteEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionUid: eventListener?.connectionUid,
                });
                break;
            case APP.JIRA_ON_PREMISE.NAME:
                atlassianOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira',
                });
                break;
            case APP.MONDAY.NAME:
                mondayEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.GITLAB.NAME:
                gitlabEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.CONFLUENCE_ON_PREMISE.NAME:
                atlassianOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Confluence',
                });
                break;
            case APP.GITHUB.NAME:
                githubEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.BITBUCKET_ON_PREMISE.NAME:
                bitbucketOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE.NAME:
                atlassianOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira Service Management',
                });
                break;
            case APP.MICROSOFT.NAME:
                microsoftEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                    token: '', //we don't really need them so won't get them from db
                });
                break;
            case APP.AZURE_DEVOPS.NAME:
                azureDevopsEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                    token: '',
                });
                break;
            case APP.ZOOM.NAME:
                zoomEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    token: '',
                });
                break;
            case APP.SALESFORCE.NAME:
                salesforceEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.OPSGENIE.NAME:
                opsgenieEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.STATUSPAGE.NAME:
                statuspageEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.ZENDESK.NAME:
                zenDeskEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            default:
                console.error('No setup instructions available for App: ' + app?.name);
                alert('Event Listener setup instructions not available');
                break;
        }
    } else {
        console.error('App name not found so no setup instructions available');
        alert('Setup instructions not available');
    }
};
