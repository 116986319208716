import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { ProductIcon } from '../../../icons/ProductIcon';
import { IconCircle } from '../../../common/IconCircle';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { EventListenerStepItem } from '../../EventListenerStepItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ListItem } from '@mui/material';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';

interface SalesforceEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const SalesforceEventListenerSetupDialog: React.FC<SalesforceEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = 'Salesforce';
    const lightningUrl = (
        setupBaseUrl && setupBaseUrl.endsWith('/')
            ? setupBaseUrl.substring(0, setupBaseUrl.length - 1)
            : setupBaseUrl ?? ''
    ).replace('.my.salesforce.com', '.lightning.force.com');

    return (
        <Dialog
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title={'Event listener setup'}
            subtitle={appName}
            onClose={onClose}
            loading={loading}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" component="h6" mb={0.5}>
                        How to set up an event listener
                    </Typography>
                    <Typography color="text.secondary">
                        Create a webhook in {appName} instance to listen to events.
                    </Typography>
                </Box>
                <EventListenerStepItem title="Step 1: Create outbound message" expanded={true}>
                    {setupBaseUrl && (
                        <ListItem>
                            <Typography>
                                Visit your{' '}
                                <Link
                                    target="_blank"
                                    href={`${lightningUrl}/lightning/setup/WorkflowOutboundMessaging/home`}
                                >
                                    Outbound Messages
                                </Link>{' '}
                                page.
                            </Typography>
                        </ListItem>
                    )}
                    {!setupBaseUrl && (
                        <ListItem>
                            <Typography>
                                Open https://[LIGHTNING_SALESFORCE_INSTANCE]/lightning/setup/
                                <br />
                                WorkflowOutboundMessaging/home URL in your browser
                            </Typography>
                        </ListItem>
                    )}
                    <ListItem>
                        <Typography>
                            Click <strong>New Outbound Message</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Select the object that has the fields you want to be included in your message and click{' '}
                            <strong>Next</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>Give your message a meaningful name. </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Paste the copied URL into the <strong>Endpoint URL</strong> field.
                        </Typography>
                    </ListItem>
                    <OutlinedReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                    <ListItem>
                        <Typography>
                            Under <strong>fields to send</strong> add fields that you want to include in the event.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>Save</strong>.
                        </Typography>
                    </ListItem>
                </EventListenerStepItem>
                <EventListenerStepItem title="Step 2: Create flow for outbound message" startCounter={8}>
                    {setupBaseUrl && (
                        <ListItem>
                            <Typography>
                                Visit your{' '}
                                <Link target="_blank" href={`${lightningUrl}/lightning/setup/Flows/home`}>
                                    Flows
                                </Link>{' '}
                                page.
                            </Typography>
                        </ListItem>
                    )}
                    {!setupBaseUrl && (
                        <ListItem>
                            <Typography>
                                Open https://[LIGHTNING_SALESFORCE_INSTANCE]/lightning/setup/Flows/home URL in your
                                browser
                            </Typography>
                        </ListItem>
                    )}
                    <ListItem>
                        <Typography>
                            Click <strong>New Flow</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Select <strong>Record-Triggered Flow</strong> and click <strong>Create</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Select the object whose records trigger the flow when they're created, updated, or deleted.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>Configure the trigger.</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>Set Entry Conditions are optional. </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Under <strong>Optimize the Flow for</strong> select{' '}
                            <strong>Actions and Related Records</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>Done</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>+</strong> (plus) icon under <strong>Run immediately</strong> label.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Select <strong>Action</strong>, then select <strong>Outbound Message</strong> from the list
                            of actions to the left.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click on Action search field and select newly created outbound message from the dropdown.
                            Give it meaningful Label name and click <strong>Done</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>Save</strong> on the top right. Give it meaningful Label name and click{' '}
                            <strong>Save</strong>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Click <strong>Activate</strong>.
                        </Typography>
                    </ListItem>
                </EventListenerStepItem>
            </>
        </Dialog>
    );
};
