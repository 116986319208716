import { EnvironmentVariableBooleanVariant } from './environment-variable-variants/EnvironmentVariableBooleanVariant';
import { EnvironmentVariableDateVariant } from './environment-variable-variants/EnvironmentVariableDateVariant';
import { EnvironmentVariableFolderVariant } from './environment-variable-variants/EnvironmentVariableFolderVariant';
import { EnvironmentVariableListVariant } from './environment-variable-variants/EnvironmentVariableListVariant';
import { EnvironmentVariableMapVariant } from './environment-variable-variants/EnvironmentVariableMapVariant';
import { EnvironmentVariableMultilineTextVariant } from './environment-variable-variants/EnvironmentVariableMultilineTextVariant';
import { EnvironmentVariableMultipleChoicesVariant } from './environment-variable-variants/EnvironmentVariableMultipleChoicesVariant';
import { EnvironmentVariableNumberVariant } from './environment-variable-variants/EnvironmentVariableNumberVariant';
import { EnvironmentVariablePasswordVariant } from './environment-variable-variants/EnvironmentVariablePasswordVariant';
import { EnvironmentVariableSingleChoiceVariant } from './environment-variable-variants/EnvironmentVariableSingleChoiceVariant';
import { EnvironmentVariableTextVariant } from './environment-variable-variants/EnvironmentVariableTextVariant';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    FullEnvironmentVariable,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
    UpdateVariableEvent,
} from './types';

export interface EnvironmentVariableProps extends FullEnvironmentVariable {
    disabled?: boolean;
    dragOverlay?: boolean;
    parentId?: string;
    sameLevelKeyNames?: string[];
    readOnlyTemplateMode?: boolean;
    setupGuideTemplateMode?: boolean;
    onAddVariable?(parentId?: string): void;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateVariableEvent): void;
}

export const EnvironmentVariable: React.FC<EnvironmentVariableProps> = ({
    editMode,
    expanded,
    childVariables,
    choices,
    defaultValue,
    description,
    disabled,
    dragOverlay,
    hasBeenEdited,
    id,
    keyName,
    parentId,
    passwordValueFilled,
    required,
    sameLevelKeyNames,
    readOnlyTemplateMode,
    setupGuideTemplateMode,
    type,
    value,
    onAddVariable,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
}) => {
    switch (type) {
        case 'BOOLEAN':
            return (
                <EnvironmentVariableBooleanVariant
                    defaultValue={defaultValue as boolean}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as boolean}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'DATE':
            return (
                <EnvironmentVariableDateVariant
                    defaultValue={defaultValue as string | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'FOLDER':
            return (
                <EnvironmentVariableFolderVariant
                    childVariables={childVariables}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    onAddVariable={onAddVariable}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'LIST':
            return (
                <EnvironmentVariableListVariant
                    defaultValue={defaultValue as string[] | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string[] | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'MAP':
            return (
                <EnvironmentVariableMapVariant
                    defaultValue={defaultValue as Record<string, string> | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as Record<string, string> | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'MULTILINE_TEXT':
            return (
                <EnvironmentVariableMultilineTextVariant
                    defaultValue={defaultValue as string | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'MULTIPLE_CHOICES':
            return (
                <EnvironmentVariableMultipleChoicesVariant
                    choices={choices}
                    defaultValue={defaultValue as string[] | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string[] | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'NUMBER':
            return (
                <EnvironmentVariableNumberVariant
                    defaultValue={(defaultValue as number | undefined)?.toString()}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={(value as number | undefined)?.toString()}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'PASSWORD':
            return (
                <EnvironmentVariablePasswordVariant
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    passwordValueFilled={passwordValueFilled}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'SINGLE_CHOICE':
            return (
                <EnvironmentVariableSingleChoiceVariant
                    choices={choices}
                    defaultValue={defaultValue as string | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
        case 'TEXT':
        default:
            return (
                <EnvironmentVariableTextVariant
                    defaultValue={defaultValue as string | undefined}
                    description={description}
                    disabled={disabled}
                    dragOverlay={dragOverlay}
                    editMode={editMode}
                    expanded={expanded}
                    hasBeenEdited={hasBeenEdited}
                    id={id}
                    keyName={keyName}
                    parentId={parentId}
                    readOnlyTemplateMode={readOnlyTemplateMode}
                    required={required}
                    sameLevelKeyNames={sameLevelKeyNames}
                    setupGuideTemplateMode={setupGuideTemplateMode}
                    value={value as string | undefined}
                    onChangeType={onChangeType}
                    onDelete={onDelete}
                    onToggleEditMode={onToggleEditMode}
                    onToggleExpand={onToggleExpand}
                    onUpdate={onUpdate}
                />
            );
    }
};
