import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button } from '../common/buttons/Button';
import Box from '@mui/material/Box';
import { DashboardQuickActions } from './DashboardQuickActions';
import { DashboardUsage, DashboardUsageProps } from './DashboardUsage';
import { DashboardResources } from './DashboardResources';
import { Alert } from '../common/alerts/Alert';

interface DashboardProps {
    displayName: string;
    organizationName: string;
    plan: string;
    usage: DashboardUsageProps['usage'];
    showProfileUpdateBanner: boolean;
    onUpdateProfile?: () => void;
    onExploreTemplates: () => void;
    onStartFromScratch: () => void;
    onCreateConnector: () => void;
    onViewPlans: () => void;
}

const StyledOuterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minHeight: '100%',
    overflow: 'auto',
    padding: theme.spacing(4, 4, 0, 4),
}));

const StyledInnerContainer = styled(Box)(() => ({
    width: '100%',
    maxWidth: 1040,
    minWidth: 800,
}));

export const Dashboard: React.FC<DashboardProps> = ({
    displayName,
    organizationName,
    plan,
    usage,
    showProfileUpdateBanner = false,
    onUpdateProfile,
    onCreateConnector,
    onExploreTemplates,
    onStartFromScratch,
    onViewPlans,
}) => {
    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <Typography variant="h4" pb={3} component={'h5'}>
                    Hello, {displayName}!
                </Typography>
                {showProfileUpdateBanner && (
                    <Box mb={3}>
                        <Alert
                            severity="warning"
                            title="Tell us about yourself"
                            action={<Button onClick={onUpdateProfile}>Update profile</Button>}
                            text={'Please answer a few questions to help us improve our service'}
                        />
                    </Box>
                )}
                <DashboardQuickActions
                    onCreateConnector={onCreateConnector}
                    onExploreTemplates={onExploreTemplates}
                    onStartFromScratch={onStartFromScratch}
                />
                <DashboardUsage
                    organizationName={organizationName}
                    usage={usage}
                    plan={plan}
                    onViewPlans={onViewPlans}
                />
                <DashboardResources />
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
