import { LoaderFn } from 'react-location';
import { acceptOrganizationMemberInvite, loadLoggedInUserDetails } from '../data/user';
import { LocationGenerics } from '../router';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { loggedInUserDetails$ } from '../store/user';
import { InformativeError } from '../utils/repository';
import { promptMessage } from '../store/confirm';

export const appLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        const inviteId = new URLSearchParams(window.location.search).get('inviteId');
        if (inviteId && loggedInUserDetails$.value?.termsAccepted) {
            const { organizationName } = await acceptOrganizationMemberInvite(inviteId);
            await loadLoggedInUserDetails();
            promptMessage({ title: `Successfully joined team: ${organizationName}` });
        }
    } catch (e) {
        const errorMessage = e instanceof InformativeError ? `. ${e.message}` : '';

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                `Could not join team ${errorMessage}. Click on the invite link again ` +
                'and if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });

        throw e;
    }

    return {};
};
