import { useEffect, useState } from 'react';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Button } from '../../../common/buttons/Button';
import { ConnectionModalSecretTextField } from '../../../common/textfield/ConnectionModalTextField';
import { ZoomEventListenerWizardStage, ZoomEventListenerWizardSteps } from './ZoomEventListenerWizardSteps';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Alert } from '../../../common/alerts/Alert';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import Link from '@mui/material/Link';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';

interface ZoomEventListenerSetupDialogProps {
    errors?: string;
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    saving?: boolean;
    stage: ZoomEventListenerWizardStage;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
    onSave: (token: string) => void;
    onSetStage: (stage: ZoomEventListenerWizardStage) => void;
}

const StyledFieldContainer = styled(Box)(() => ({
    '& > :first-of-type': {
        minWidth: '100%',
        '& .MuiInputBase-root': {
            width: '100%',
        },
    },
}));

export const ZoomEventListenerSetupDialog: React.FC<ZoomEventListenerSetupDialogProps> = ({
    errors,
    eventTypeName,
    loading = false,
    open = false,
    saving = false,
    stage,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
    onSave,
    onSetStage,
}) => {
    const appName = APP.ZOOM.NAME;

    const [token, setToken] = useState('');

    useEffect(() => {
        setToken('');
    }, [open]);

    const canSaveToken = !!token && !loading && !saving;

    switch (stage) {
        case ZoomEventListenerWizardStage.SAVE_TOKEN:
            return (
                <Dialog
                    buttons={[
                        <Button disabled={loading} onClick={onClose} variant="outlined">
                            Cancel
                        </Button>,
                        <Button busy={saving} disabled={saving || !token} onClick={() => onSave(token)}>
                            Save and continue
                        </Button>,
                    ]}
                    leftButton={
                        <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                            Learn more
                        </Button>
                    }
                    icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                    open={open}
                    title={'Event listener setup'}
                    subtitle={appName}
                    onClose={onClose}
                    loading={loading}
                    onKeyDown={(event) =>
                        handleKeyDown({
                            event,
                            enterCondition: canSaveToken,
                            enterFn: () => onSave(token),
                            escFn: onClose,
                        })
                    }
                    progress={50}
                >
                    <ZoomEventListenerWizardSteps stage={stage} />
                    {errors && <Alert severity="error" title={errors} />}
                    <Box>
                        <Typography variant="subtitle1" mb={0.5}>
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} instance to listen to events.
                        </Typography>
                    </Box>
                    <StyledBorderBox>
                        <StyledBorderBoxSubHeader>
                            <Typography variant="subtitle2">Step 1: Save Secret Token</Typography>
                        </StyledBorderBoxSubHeader>
                        <Divider />
                        <StyledBorderBoxContent gap={1.5}>
                            <StyledOrderedList>
                                <ListItem>
                                    <Typography>
                                        Visit the{' '}
                                        <Link target="_blank" href="https://marketplace.zoom.us/user/build">
                                            Zoom Apps Marketplace
                                        </Link>{' '}
                                        in your browser. If you are not signed in, please sign in first.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Select the {appName} app you want to setup the event listener for.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Click on <strong>Feature</strong>.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Copy the <strong>Secret Token</strong> token and paste it in the{' '}
                                        <strong>Secret Token</strong> field below.
                                    </Typography>
                                </ListItem>
                                <StyledFieldContainer>
                                    <ConnectionModalSecretTextField
                                        label="Secret Token"
                                        value={token}
                                        onUpdate={(e) => setToken(e.target.value.trim())}
                                    />
                                </StyledFieldContainer>
                                <ListItem>
                                    <Typography>
                                        Click <strong>Save</strong> below.
                                    </Typography>
                                </ListItem>
                            </StyledOrderedList>
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </Dialog>
            );

        case ZoomEventListenerWizardStage.ADD_EVENT:
            return (
                <Dialog
                    buttons={[
                        <Button
                            disabled={loading}
                            onClick={() => onSetStage(ZoomEventListenerWizardStage.SAVE_TOKEN)}
                            variant="outlined"
                        >
                            Back
                        </Button>,
                        <Button onClick={onClose}>Save changes</Button>,
                    ]}
                    leftButton={
                        <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                            Learn more
                        </Button>
                    }
                    icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                    open={open}
                    title={'Event listener setup'}
                    subtitle={appName}
                    onClose={onClose}
                    loading={loading}
                    onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
                    progress={100}
                >
                    <ZoomEventListenerWizardSteps stage={stage} />
                    <Box>
                        <Typography variant="subtitle1" mb={0.5}>
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} instance to listen to events.
                        </Typography>
                    </Box>
                    <StyledBorderBox>
                        <StyledBorderBoxSubHeader>
                            <Typography variant="subtitle2">Step 2: Add Event</Typography>
                        </StyledBorderBoxSubHeader>
                        <Divider />
                        <StyledBorderBoxContent gap={1.5}>
                            <StyledOrderedList startCounter={6}>
                                <ListItem>
                                    <Typography>
                                        Scroll down to <strong>General Features</strong> and enable{' '}
                                        <strong>Event Subscriptions</strong>.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Click on <strong>Add Event Subscription</strong>.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Enter a meaningful name into the <strong>Subscription name</strong> field.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Paste the copied URL into the <strong>Event notification endpoint URL</strong>{' '}
                                        field.
                                    </Typography>
                                </ListItem>
                                <OutlinedReadOnlyTextField
                                    label="Webhook URL"
                                    value={`${webhookBaseUrl}/${webhookUrlId}`}
                                />
                                <ListItem>
                                    <Typography>
                                        Click on <strong>Validate</strong> so {appName} can validate the URL.{' '}
                                        <em>Note that you must have saved the token in the previous step</em>.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Click on <strong>Add Events</strong>.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>
                                        Click <strong>Save</strong>.
                                    </Typography>
                                </ListItem>
                            </StyledOrderedList>
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </Dialog>
            );
    }
};
