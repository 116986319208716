import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface ZoomConnectionDetails extends ConnectionDetails {
    clientId?: string;
    clientSecret?: string;
}

interface ZoomAppSetupSaveProps {
    clientId: string;
    clientSecret: string;
}

export const zoomManageConnectionOpenDialogAction$ = monitor(
    'zoomManageConnectionOpenDialogAction$',
    new Subject<ZoomConnectionDetails>()
);
export const zoomManageConnectionCloseDialogAction$ = monitor(
    'zoomManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const zoomAppSetupOpenDialogAction$ = monitor(
    'zoomAppSetupOpenDialogAction$',
    new Subject<ZoomConnectionDetails>()
);
export const zoomAppSetupCloseDialogAction$ = monitor('zoomAppSetupCloseDialogAction$', new Subject<void>());
export const zoomAppSetupDialogOpen$ = monitor('zoomAppSetupDialogOpen$', new BehaviorSubject(false));
export const zoomAppSetupAuthorizeAction$ = monitor(
    'zoomAppSetupAuthorizeAction$',
    new Subject<ZoomAppSetupSaveProps>()
);
export const zoomAppSetupDialogErrors$ = monitor(
    'zoomAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const zoomSaveConnectionAction$ = monitor('zoomSaveConnectionAction$', new Subject<string>());
export const zoomConnectionCreatedAction$ = monitor('zoomConnectionCreatedAction$', new Subject<string>());
export const zoomConnectionSavedAction$ = monitor(
    'zoomConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const zoomManageConnectionInitiateSetupAction$ = monitor(
    'zoomManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const zoomManageConnectionDialogOpen$ = monitor('zoomManageConnectionDialogOpen$', new BehaviorSubject(false));
export const zoomConnectionSaving$ = monitor('zoomConnectionSaving$', new BehaviorSubject(false));
export const zoomManageConnectionAuthorizeLoading$ = monitor(
    'zoomManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const zoomManageConnectionDetails$ = monitor(
    'zoomManageConnectionDetails$',
    new BehaviorSubject<ZoomConnectionDetails | undefined>(undefined)
);
export const zoomManageConnectionDialogErrors$ = monitor(
    'zoomManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const zoomAppSetupDialogStage$ = monitor('zoomAppSetupDialogStage$', new BehaviorSubject(0));

zoomAppSetupOpenDialogAction$.subscribe((details) => {
    zoomAppSetupDialogErrors$.next(undefined);
    zoomManageConnectionDialogErrors$.next(undefined);
    zoomAppSetupDialogOpen$.next(true);
    zoomAppSetupDialogStage$.next(0);
    zoomManageConnectionDetails$.next(details);
});

zoomAppSetupCloseDialogAction$.subscribe(() => {
    zoomAppSetupDialogOpen$.next(false);
    zoomManageConnectionAuthorizeLoading$.next(false);
});

zoomManageConnectionOpenDialogAction$.subscribe((details) => {
    zoomAppSetupDialogErrors$.next(undefined);
    zoomManageConnectionDialogErrors$.next(undefined);
    zoomManageConnectionDialogOpen$.next(true);
    zoomManageConnectionDetails$.next(details);
});

zoomManageConnectionCloseDialogAction$.subscribe(() => {
    zoomManageConnectionDialogOpen$.next(false);
    zoomAppSetupDialogOpen$.next(false);
    zoomManageConnectionDetails$.next(undefined);
});

zoomConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

const CONNECTION_TYPE_UNDEFINED = 'Zoom Connector type not defined';

zoomAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            zoomConnectionSaving$.next(true);
            zoomAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = zoomManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error(CONNECTION_TYPE_UNDEFINED);
                }
                if (!uid) {
                    throw Error('Zoom Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.zoom?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Zoom Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        connectionId: uid,
                        connectionType: 'zoom',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, zoomManageConnectionAuthorizeLoading$);

                zoomConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    zoomAppSetupDialogErrors$.next(e.message);
                } else {
                    zoomAppSetupDialogErrors$.next(
                        'Failed to save Zoom application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Zoom application info', e);
                }
            }

            zoomConnectionSaving$.next(false);
            zoomManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

zoomSaveConnectionAction$
    .pipe(
        map(async (name) => {
            zoomConnectionSaving$.next(true);
            zoomManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = zoomManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error(CONNECTION_TYPE_UNDEFINED);
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveZoomConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveZoomConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                zoomConnectionCreatedAction$.next(uid);
                                zoomConnectionSavedAction$.next({ uid, connectionTypeUid });
                                zoomManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, zoomManageConnectionDialogErrors$, APP.ZOOM.NAME);
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, zoomManageConnectionDialogErrors$, APP.ZOOM.NAME);
            }

            zoomConnectionSaving$.next(false);
        })
    )
    .subscribe();

zoomManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            zoomConnectionSaving$.next(true);
            zoomManageConnectionAuthorizeLoading$.next(true);
            zoomManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = zoomManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error(CONNECTION_TYPE_UNDEFINED);
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    zoomConnectionSavedAction$.next({ uid, connectionTypeUid });
                    zoomAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    zoomConnectionCreatedAction$.next(uid);
                    zoomConnectionSavedAction$.next({ uid, connectionTypeUid });
                    zoomManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    zoomConnectionSaving$.next(false);

                    zoomAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, zoomManageConnectionDialogErrors$, APP.ZOOM.NAME);
                zoomManageConnectionAuthorizeLoading$.next(false);
            }

            zoomConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(zoomConnectionSavedAction$);

const saveZoomConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        zoomConnectionSavedAction$.next({ uid, connectionTypeUid });
        zoomManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, zoomManageConnectionDialogErrors$, APP.ZOOM.NAME);
    }
};
