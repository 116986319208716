import Authenticator from './auth/Authenticator';
import { Outlet, Router } from 'react-location';
import { AppTheme } from './theme';
import { getRoutes, location } from './router';
import loader from '@monaco-editor/loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { styled } from '@mui/material/styles';
import { useObservableState } from 'observable-hooks';
import { themeMode$ } from './store/theme';

const MONACO_EDITOR_VERSION = '0.50.0';

const StyledToastWrapper = styled('div')(({ theme }) => ({
    '& .Toastify__toast-container': {
        bottom: theme.spacing(2),
        minHeight: 67,
        padding: 0,
        overflow: 'visible',
        width: 428,
    },
    '& .Toastify__toast': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.constants.borderRadius,
        dropShadow: '0px 15px 25px rgba(0, 0, 0, 0.1)',
        margin: theme.spacing(2, 0, 0),
        padding: theme.spacing(1),
        '& .Toastify__close-button': {
            opacity: 1,
            '&:hover svg': {
                color: theme.palette.primary.main,
            },
            svg: {
                color: theme.palette.text.secondary,
                height: 20,
                width: 20,
            },
        },
    },
}));

export const App: React.FC = () => {
    const routes = getRoutes();
    const themeMode = useObservableState(themeMode$);

    loader.config({
        paths: {
            // vs: `https://cdn.jsdelivr.net/npm/monaco-editor@${MONACO_EDITOR_VERSION}/min/vs`,
            vs: `https://fastly.jsdelivr.net/npm/monaco-editor@${MONACO_EDITOR_VERSION}/min/vs`,
        },
    });

    return (
        <AppTheme stylesInject={true} themeMode={themeMode}>
            <StyledToastWrapper>
                <ToastContainer />
            </StyledToastWrapper>
            <Authenticator>
                <Router location={location} routes={routes}>
                    <Outlet />
                    {/* <ReactLocationDevtools position="bottom-right" /> */}
                </Router>
            </Authenticator>
        </AppTheme>
    );
};

export default App;
