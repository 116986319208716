import { Button } from '../../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { Dialog } from '../../../common/Dialog';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import { ListItem } from '@mui/material';

interface AzureDevopsEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const AzureDevopsEventListenerSetupDialog: React.FC<AzureDevopsEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.AZURE_DEVOPS.NAME;

    return (
        <Dialog
            fullWidth
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title={`Event listener setup`}
            subtitle={appName}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
            loading={loading}
        >
            <>
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} project to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                <Typography>
                                    Open <strong>Azure DevOps</strong> and navigate to the organisation you want to
                                    setup the event listener for.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    On the bottom left, click on <strong>Organisation Settings.</strong>
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click on <strong>projects</strong> and click on the project you wish to setup an
                                    event listener for.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Under General, click on the <strong>service hooks</strong> menu item.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click the <strong>+</strong> icon in the top bar.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    From the services menu, select <strong>Web Hooks</strong> and then next.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Select the conditions on which the webhook will trigger, and then next.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the URL from this page into the <strong>URL</strong> field, and then finish.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                        <OutlinedReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </>
        </Dialog>
    );
};
