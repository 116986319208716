import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import { SetupGuideReviewScreenItem } from '../SetupGuideReviewScreenItem';
import {
    StyledListItemBottomTextContainer,
    StyledListItemLeftContainer,
    StyledSetupGuideReviewScreenListContainer,
} from '../../SetupGuideComponents';
import { SetupGuideScheduledTrigger } from '../../types';

export interface SetupGuideReviewScreenScheduledTriggersProps {
    scheduledTriggers: SetupGuideScheduledTrigger[];
    onEdit: () => void;
}

export const SetupGuideReviewScreenScheduledTriggers: React.FC<SetupGuideReviewScreenScheduledTriggersProps> = ({
    scheduledTriggers,
    onEdit,
}) => {
    return (
        <SetupGuideReviewScreenItem itemCount={scheduledTriggers.length} itemName="Scheduled triggers" onEdit={onEdit}>
            {scheduledTriggers.map((st) => {
                return (
                    <StyledSetupGuideReviewScreenListContainer key={st.uid}>
                        <StyledListItemLeftContainer>
                            <SetupGuideIndicator left={{ icon: <TimerOutlinedIcon /> }} />
                            <Box>
                                <Typography>{st.cron}</Typography>
                                <StyledListItemBottomTextContainer>
                                    <CodeOutlinedIcon />
                                    <Typography>Script:</Typography>
                                    <Typography color="primary.main">{st.scriptName}</Typography>
                                </StyledListItemBottomTextContainer>
                            </Box>
                        </StyledListItemLeftContainer>
                    </StyledSetupGuideReviewScreenListContainer>
                );
            })}
        </SetupGuideReviewScreenItem>
    );
};
