import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { UserActivationRequest, Users } from '../../data/report/userActivation';
import { styled } from '@mui/material/styles';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';

export interface UserActivationReportPageProps extends UserActivationRequest {
    users: Users;
    onSearch(userType: UserType, status: string): void;
}

type UserType = 'all' | 'adaptavist' | 'non-adaptavist' | 'non-stitch' | 'monday.com';

export const StyledTitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(3),
}));

export const UserActivationReportPage: React.FC<UserActivationReportPageProps> = ({
    users,
    userType = 'all',
    status = 'all',
    onSearch,
}) => {
    const [userTypeFilterValue, setUserTypeFilterValue] = useState<UserType>('all');
    const [statusFilterValue, setStatusFilterValue] = useState('all');

    useEffect(() => {
        setUserTypeFilterValue(userType);
        setStatusFilterValue(status);
    }, []);

    const handleSearch = (): void => onSearch(userTypeFilterValue, statusFilterValue);

    const calcActivation = (registrationDate: Date, firstInvocationDate: Date): number => {
        const first = new Date(firstInvocationDate).setHours(0, 0, 0, 0);
        const reg = new Date(registrationDate).setHours(0, 0, 0, 0);
        return Math.floor((first - reg) / 86400000);
    };
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">User Activation</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by User Type"
                            value={userTypeFilterValue}
                            onChange={(event) => setUserTypeFilterValue(event.target.value as UserType)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                            <MenuItem value="adaptavist">Adaptavist Only</MenuItem>
                            <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                            <MenuItem value="monday.com">monday.com</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Status"
                            value={statusFilterValue}
                            onChange={(event) => setStatusFilterValue(event.target.value)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="registered">Registered</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>Total Users: {users.length}</Typography>
                </Box>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            {users.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Registered Date</TableCell>
                            <TableCell>First Invocation Date</TableCell>
                            <TableCell>Activation (in days)</TableCell>
                            <TableCell>Script Name</TableCell>
                            <TableCell>Workspace Name</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.uid}>
                                <TableCell>{user.uid}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{new Date(user.registeredDate).toLocaleString()}</TableCell>
                                <TableCell>
                                    {user.firstInvocationDate && new Date(user.firstInvocationDate).toLocaleString()}
                                </TableCell>
                                <TableCell>
                                    {user.firstInvocationDate &&
                                        calcActivation(user.registeredDate, user.firstInvocationDate)}
                                </TableCell>
                                <TableCell>{user.scriptName}</TableCell>
                                <TableCell>{user.workspaceName}</TableCell>
                                <TableCell>{user.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>No Users found</div>
            )}
        </PageContainer>
    );
};
