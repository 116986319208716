import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import { ProductIcon } from '../../../icons/ProductIcon';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import { SetupGuideReviewScreenItem } from '../SetupGuideReviewScreenItem';
import {
    StyledListItemBottomTextContainer,
    StyledListItemLeftContainer,
    StyledSetupGuideReviewScreenListContainer,
} from '../../SetupGuideComponents';
import { SetupGuideEventListener } from '../../types';

export interface SetupGuideReviewScreenEventListenersProps {
    eventListeners: SetupGuideEventListener[];
    onEdit: () => void;
}

export const SetupGuideReviewScreenEventListeners: React.FC<SetupGuideReviewScreenEventListenersProps> = ({
    eventListeners,
    onEdit,
}) => {
    return (
        <SetupGuideReviewScreenItem itemCount={eventListeners.length} itemName="Event listeners" onEdit={onEdit}>
            {eventListeners.map((e) => {
                return (
                    <StyledSetupGuideReviewScreenListContainer key={e.uid}>
                        <StyledListItemLeftContainer>
                            <SetupGuideIndicator
                                left={{ icon: <HearingOutlinedIcon /> }}
                                right={{ icon: <ProductIcon name={e.appName} /> }}
                            />
                            <Box>
                                <Typography>{e.appName}</Typography>
                                <StyledListItemBottomTextContainer>
                                    <CodeOutlinedIcon />
                                    <Typography>Script:</Typography>
                                    <Typography color="primary.main">{e.scriptName}</Typography>
                                </StyledListItemBottomTextContainer>
                            </Box>
                        </StyledListItemLeftContainer>
                    </StyledSetupGuideReviewScreenListContainer>
                );
            })}
        </SetupGuideReviewScreenItem>
    );
};
