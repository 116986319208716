import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from '../../../common/buttons/Button';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import {
    emptyWarning,
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    StyledReadOnlyRow,
    StyledReadOnlyKeyField,
    StyledReadOnlyValueField,
    StyledEditModeDivider,
    StyledEditModeVariableHeader,
} from '../EnvironmentVariableComponents';
import { IconButton } from '../../../common/buttons/IconButton';
import { InformationChip } from '../../../common/chips/InformationChip';
import { ToggleButtonGroup } from '../../../common/buttons/button-groups/ToggleButtonGroup';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';
import dayjs, { Dayjs } from 'dayjs';

interface UpdateDateVariableEvent {
    defaultValue?: string;
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: string;
}

interface EnvironmentVariableDateVariantProps {
    defaultValue?: string;
    description?: string;
    disabled?: boolean;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    readOnlyTemplateMode?: boolean;
    required?: boolean;
    sameLevelKeyNames?: string[];
    setupGuideTemplateMode?: boolean;
    value?: string;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateDateVariableEvent): void;
}

const getDate = (dateString?: string): Dayjs | null => {
    return dateString ? dayjs(dateString) : null;
};

const getISOString = (date: Dayjs | null): string | undefined => {
    const offset = date?.utcOffset() ?? 0;
    return date?.add(offset, 'minute').toISOString().split('T')[0];
};

const isDateValid = (date?: Dayjs | null): boolean => {
    try {
        date?.toISOString();
        return true;
    } catch (e) {
        return false;
    }
};
const isDateOutOfRange = (date: Dayjs | null): boolean => {
    if (date?.isBefore('1 Jan 1900') || date?.isAfter('31 Dec 2099')) {
        return true;
    }
    return false;
};

export const EnvironmentVariableDateVariant: React.FC<EnvironmentVariableDateVariantProps> = ({
    defaultValue,
    description = '',
    disabled = false,
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    readOnlyTemplateMode = false,
    required = false,
    sameLevelKeyNames = [],
    setupGuideTemplateMode = false,
    value,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentValue, setCurrentValue] = useState(getDate(value));
    const [currentDefaultValue, setCurrentDefaultValue] = useState(getDate(defaultValue));
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentValue(getDate(value));
        setCurrentDefaultValue(getDate(defaultValue));
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleUpdate = (): void => {
        onUpdate?.({
            defaultValue: getISOString(currentDefaultValue),
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'DATE',
            value: getISOString(currentValue),
        });
    };

    const hasChanged =
        (isDateValid(currentValue) && getISOString(currentValue) !== value) ||
        (isDateValid(currentDefaultValue) && getISOString(currentDefaultValue) !== defaultValue) ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const isCurrentRequiredValueMissing = currentRequired && !currentValue;

    const canUpdate =
        isDateValid(currentValue) &&
        isDateValid(currentDefaultValue) &&
        !isDateOutOfRange(currentValue) &&
        !isDateOutOfRange(currentDefaultValue) &&
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const isSavedRequiredValueMissing = required && !value;
    const missingInformation = !keyName || isSavedRequiredValueMissing;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const displayedValue = readOnlyTemplateMode ? defaultValue : value;

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdate,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit parameter' : 'New parameter'}</Typography>
                            <StyledHeaderActions>
                                <ToggleButtonGroup
                                    buttons={[
                                        { label: 'Optional', selected: !currentRequired, value: !currentRequired },
                                        { label: 'Required', selected: currentRequired, value: currentRequired },
                                    ]}
                                    disabled={setupGuideTemplateMode}
                                    exclusive
                                    size="small"
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                />
                                <IconButton
                                    aria-label="Cancel editing"
                                    icon={<CloseIcon />}
                                    size="small"
                                    tooltip="Cancel editing"
                                    onClick={handleCancel}
                                />
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                disabled={hasBeenEdited || setupGuideTemplateMode}
                                inputRef={typeInputRef}
                                items={environmentVariableTypes}
                                label="Type"
                                required
                                selectedItem={'DATE'}
                                size="small"
                                onSelectItem={(v) =>
                                    onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })
                                }
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <DatePicker
                                renderInput={(props) => (
                                    <TextField
                                        size="small"
                                        required={currentRequired}
                                        error={!isDateValid(currentValue)}
                                        helperText={
                                            isCurrentRequiredValueMissing
                                                ? 'Please enter key value'
                                                : !isDateValid(currentValue)
                                                ? 'Invalid date'
                                                : isDateOutOfRange(currentValue)
                                                ? 'Date is outside of the permitted range'
                                                : undefined
                                        }
                                        sx={{ '& .MuiSvgIcon-root': { marginRight: 1 } }}
                                        {...props}
                                    />
                                )}
                                label="Value"
                                value={currentValue}
                                inputFormat="DD-MM-YYYY"
                                onChange={(e) => setCurrentValue(e)}
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            <StyledEditModeDivider>
                                <Typography>Developer Options</Typography>
                            </StyledEditModeDivider>
                            <DatePicker
                                renderInput={(props) => (
                                    <TextField
                                        size="small"
                                        disabled={setupGuideTemplateMode}
                                        error={
                                            !isDateValid(currentDefaultValue) || isDateOutOfRange(currentDefaultValue)
                                        }
                                        helperText={
                                            !isDateValid(currentDefaultValue)
                                                ? 'Invalid date'
                                                : isDateOutOfRange(currentDefaultValue)
                                                ? 'Date is outside of the permitted range'
                                                : undefined
                                        }
                                        sx={{ '& .MuiSvgIcon-root': { marginRight: 1 } }}
                                        {...props}
                                    />
                                )}
                                label="Default value"
                                value={currentDefaultValue}
                                inputFormat="DD-MM-YYYY"
                                onChange={(e) => setCurrentDefaultValue(e)}
                            />
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdate}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    aria-label="Edit parameter"
                                    data-pendo={'editParameter'}
                                    disabled={disabled || readOnlyTemplateMode}
                                    icon={<EditOutlinedIcon />}
                                    size="small"
                                    tooltip="Edit parameter"
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                />
                                <IconButton
                                    aria-label="Delete parameter"
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    icon={<DeleteOutlineIcon />}
                                    size="small"
                                    tooltip="Delete parameter"
                                    onClick={() => onDelete?.({ id, parentId })}
                                />
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {missingInformation && (
                                    <InformationChip label="Missing information" severity="warning" />
                                )}
                                {!readOnlyTemplateMode && (
                                    <InformationChip
                                        label={required ? 'Required' : 'Optional'}
                                        severity={required ? 'primary' : 'grey'}
                                    />
                                )}
                                <StyledReadOnlyExpandButton
                                    aria-label={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    icon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    tooltip={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                >
                                    <Typography>Show all</Typography>
                                </StyledReadOnlyExpandButton>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {displayedValue ? (
                                    <Typography>{displayedValue.split('T')[0]}</Typography>
                                ) : (
                                    emptyWarning(!readOnlyTemplateMode && required)
                                )}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Date</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                {!readOnlyTemplateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyValueField>
                                            {defaultValue ? (
                                                <Typography>{defaultValue.split('T')[0]}</Typography>
                                            ) : (
                                                emptyWarning()
                                            )}
                                        </StyledReadOnlyValueField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
