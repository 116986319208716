import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button } from '../../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../for-deprecation/dialog/DialogComponents';
import { Dropdown } from '../../common/inputs/dropdown/Dropdown';
import { EventListenerSelect } from './EventListenerSelect';
import { InfoIcon } from '../../icons/InfoIcon';
import { PageContainer } from '../../app-main/PageComponentsForDeprecation';
import { APP } from '@avst-stitch/repository-lib/constants';

interface ReadOnlyEventListenerScreenProps {
    connectionRequired?: boolean;
    eventTypes: {
        uid: string;
        name: string;
        category?: string | null;
    }[];
    scripts: {
        uid: string;
        name: string;
    }[];
    selectedAppName: string;
    selectedEventTypeUid?: string;
    selectedScriptUid?: string;
}

const StyledContainer = styled('div')(({ theme }) => ({
    maxWidth: '340px',
    paddingTop: theme.spacing(1),
}));

const StyledFlexWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const StyledFlexWrapperRow = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'row',
    justifyContent: 'start',
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
}));

export const ReadOnlyEventListenerDetails: React.FC<ReadOnlyEventListenerScreenProps> = ({
    connectionRequired = false,
    eventTypes,
    scripts,
    selectedAppName,
    selectedEventTypeUid,
    selectedScriptUid,
}) => {
    const [currentEventType, setCurrentEventType] = useState(selectedEventTypeUid);
    const [triggerScriptOption, setTriggerScriptOption] = useState(selectedScriptUid ? 'saved' : 'initial');
    const [currentScript, setCurrentScript] = useState(selectedScriptUid);

    useEffect(() => setCurrentEventType(selectedEventTypeUid), [selectedEventTypeUid]);
    useEffect(() => setCurrentScript(selectedScriptUid), [selectedScriptUid]);
    useEffect(() => setTriggerScriptOption('saved'), [selectedScriptUid]);

    const selectedEventTypeName = eventTypes.find((et) => et.uid === currentEventType)?.name;

    const connectionTooltip = connectionRequired
        ? 'A Connector is required to listen to events originating from external services.'
        : 'To which Connector this Event Listener logically belongs to. This is not required for the Event Listener to function properly, but will enable to you to logically attach an Event Listener to a Connection.';

    return (
        <PageContainer
            data-test-id="event-listener-details"
            sx={{ minWidth: 500, backgroundColor: 'background.paper', p: 0 }}
        >
            <DialogTitleMain title="Event Listener" />
            {selectedAppName === APP.GENERIC.NAME && selectedEventTypeName === 'Async HTTP Event' && (
                <DialogAlert
                    title="Information"
                    severity="info"
                    text={
                        <>
                            Asynchronous HTTP Events gets processed in fire and forget manner, meaning that you won't be
                            able to send back a HTTP response, but invocations can run for much longer than synchronous
                            events. <strong>This is the recommended option.</strong>
                        </>
                    }
                />
            )}
            {selectedAppName === APP.GENERIC.NAME && selectedEventTypeName === 'Sync HTTP Event' && (
                <DialogAlert
                    title="Information"
                    severity="info"
                    text={
                        <>
                            Synchronous HTTP Events allow you to send back a HTTP response after function invocation has
                            finished, <strong>but the maximum invocation time will be capped to 25 seconds.</strong>{' '}
                            Consider using Async HTTP Event if you don't need to send back a response.
                        </>
                    }
                />
            )}
            <StyledContainer>
                <StyledFlexWrapper>
                    <FormControl>
                        <EventListenerSelect
                            labelName="Listens Event Type"
                            optionList={eventTypes}
                            selectedOption={currentEventType}
                            disabled={true}
                        />
                    </FormControl>
                    {triggerScriptOption === 'saved' && (
                        <FormControl>
                            <Dropdown
                                disabled={true}
                                items={scripts.map((s) => ({ name: s.name, value: s.uid }))}
                                label="Uses Script"
                                required
                                selectedItem={currentScript}
                            />
                        </FormControl>
                    )}
                </StyledFlexWrapper>
                <FormControl sx={{ mt: 0 }}>
                    {selectedAppName !== APP.GENERIC.NAME ? (
                        <>
                            <StyledFlexWrapperRow>
                                <Dropdown
                                    disabled={true}
                                    items={[]}
                                    label="Uses Connector"
                                    required={connectionRequired}
                                />
                                <StyledInfoIcon tooltip={connectionTooltip} />
                            </StyledFlexWrapperRow>
                        </>
                    ) : (
                        <StyledFlexWrapperRow>
                            <TextField
                                variant="outlined"
                                label="URL Path"
                                disabled={true}
                                value={'URL Path'}
                                required
                            />
                            <StyledInfoIcon tooltip="URL Path is a unique identifier that makes up the URL for the Event Listener." />
                        </StyledFlexWrapperRow>
                    )}
                </FormControl>
                <DialogActions>
                    <Button disabled={true} variant="outlined">
                        Cancel
                    </Button>
                    <Button disabled={true}>Save</Button>
                </DialogActions>
            </StyledContainer>
        </PageContainer>
    );
};
