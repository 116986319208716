import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../../router';
import { loadErrorPage } from '../../../store/error';
import { loadWorkspaceSetupGuideStep } from '../../../store/setup-guide';
import { selectedEnvironmentUid$ } from '../../../store/workspace';
import { loadWorkspaceEnvironments, loadWorkspaceResources } from '../../../store/workspace/utils';
import { getApiHandler } from '../../../data/api-handler';
import { selectedApiHandlerDetails$ } from '../../../store/workspace/api-handler';
import { loadLoggedInUserConnections } from '../../../store/connection/utils';
import { loggedInUserConnections$ } from '../../../store/connections';

export const setupGuideApiHandlerStepLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'API_HANDLERS');

        if (!loggedInUserConnections$.value.length) {
            await loadLoggedInUserConnections();
        }

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load API connections step.',
        });

        throw e;
    }

    return {};
};

export const setupGuideEditApiHandlerScreenLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const apiHandlerUid = routeMatch.params.apiHandlerUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'API_HANDLERS');

        if (!loggedInUserConnections$.value.length) {
            await loadLoggedInUserConnections();
        }

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }

        await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');

        const apiHandler = await getApiHandler(apiHandlerUid, selectedEnvironmentUid$.value ?? '');
        selectedApiHandlerDetails$.next(apiHandler);
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load API connection.',
        });

        throw e;
    }

    return {};
};
