import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import { ServiceNowIcon } from '../../../icons/product-icons/ServiceNowIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import {
    ConnectionModalTextField,
    ConnectionModalSecretTextField,
} from '../../../common/textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../../common/textfield/ReadOnlyTextField';
import { ServiceNowWizardStage, ServiceNowWizardSteps, StageType } from './ServiceNowWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { testUrl } from '../../../../utils/connection';

export interface ServiceNowClientInfo {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export interface ServiceNowConnection {
    clientInfo: ServiceNowClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: ServiceNowWizardStage;
    setStage: (stage: ServiceNowWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);

    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);

    const SERVICENOW_PATH = '/nav_to.do?uri=%2Foauth_entity_list.do';

    const SERVICENOW_APP_LOGO = `${window.location.origin}/scriptrunner-connect-stacker.png`;

    const generateSettingsUrl = (): string => {
        return instanceUrl.endsWith(SERVICENOW_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + SERVICENOW_PATH
            : instanceUrl + SERVICENOW_PATH;
    };

    switch (props.stage) {
        case ServiceNowWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>This connector requires ServiceNow instance url.</DialogContentText>
                        <ConnectionModalTextField
                            label="Enter the ServiceNow URL"
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                if (await testUrl(instanceUrl)) {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(ServiceNowWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError('Please enter a valid URL starting with "https://"');
                                    setBadUrlAttempted(true);
                                }
                            }}
                            disabled={!instanceUrl}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case ServiceNowWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an application, skip the steps below and click next"
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl()}>
                                    ServiceNow OAuth application registry
                                </OpenInNewLink>{' '}
                                page, click <strong>New</strong> then select{' '}
                                <strong>Create an OAuth API endpoint for external clients</strong>.
                            </li>
                            <li>Copy the values below into the form.</li>

                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Redirect URL" value={props.callbackUrl} />
                            <ReadOnlyTextField label="Refresh Token Lifespan" value="2000000000" />
                            <ReadOnlyTextField label="Logo URL" value={SERVICENOW_APP_LOGO} />
                            <li>
                                Click <strong>Submit</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(ServiceNowWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(ServiceNowWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case ServiceNowWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Click on the newly created client (or an existing one if you skipped the previous step).
                            </li>
                            <li>
                                Copy the <strong>Client ID</strong> into the form below.
                            </li>
                            <li>
                                Click on the <strong>lock</strong> icon next to the <strong>Client Secret</strong> and
                                copy the value into the form below.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Client ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Client Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ServiceNowWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ServiceNowWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case ServiceNowWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in ServiceNow you need to authorize our app to be able to make
                            requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ServiceNowWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            data-pendo={'connectorAuthorised'}
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case ServiceNowWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const ServiceNowConnectionAppConfigureDialog: React.FC<ServiceNowConnection> = (props) => {
    const error = <DialogAlert severity="error" title={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<ServiceNowIcon />} />
            {props.errors && error}
            <ServiceNowWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
