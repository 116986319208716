import { BehaviorSubject, map, Subject } from 'rxjs';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const azureDevopsEventListenerSetupDialogOpenAction$ = monitor(
    'azureDevopsEventListenerSetupDialogOpenAction$',
    new Subject<AzureDevopsEventListenerSetupBaseDetails>()
);
export const azureDevopsEventListenerSetupDialogCloseAction$ = monitor(
    'azureDevopsEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const azureDevopsEventListenerSetupDialogLoading$ = monitor(
    'azureDevopsEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const azureDevopsEventListenerSetupDialogOpen$ = monitor(
    'azureDevopsEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const azureDevopsEventListenerSetupDialogDetails$ = monitor(
    'azureDevopsEventListenerSetupDialogDetails$',
    new BehaviorSubject<AzureDevopsEventListenerSetupBaseDetails | undefined>(undefined)
);

export type AzureDevopsEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    token: string;
};

azureDevopsEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            azureDevopsEventListenerSetupDialogLoading$.next(true);
            azureDevopsEventListenerSetupDialogOpen$.next(true);

            try {
                azureDevopsEventListenerSetupDialogDetails$.next(details);
                azureDevopsEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                azureDevopsEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

azureDevopsEventListenerSetupDialogCloseAction$.subscribe(() => {
    azureDevopsEventListenerSetupDialogOpen$.next(false);
    azureDevopsEventListenerSetupDialogDetails$.next(undefined);
});
