import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { IconButton } from '../../../common/buttons/IconButton';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { InformationChip } from '../../../common/chips/InformationChip';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import { InfoIcon } from '../../../icons/InfoIcon';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxTitleContainer,
} from '../../../common/LayoutComponents';
import { StyledMainActions, StyledStepContainer } from '../../SetupGuideComponents';
import { TextField } from '../../../common/inputs/TextField';
import { SetupGuideConnection } from '../../types';
import {
    getScriptNameFromEventType,
    SaveEventListenerEvent,
} from '../../../workspace-resources/event-listeners/EventListenerDetails';
import { autoFocus } from '../../../../utils/autoFocus';
import { APP } from '@avst-stitch/repository-lib/constants';

export interface OpenSetupInstructionsEvent {
    connectionUid?: string;
    eventTypeUid?: string;
    uid: string;
    urlId?: string;
}

interface SetupGuideEditEventListenerScreenProps {
    appName: string;
    connectionRequired?: boolean;
    connections?: SetupGuideConnection[];
    disabled?: boolean;
    errors?: string;
    eventTypes?: { name: string; uid: string }[];
    saving?: boolean;
    scripts?: { name: string; uid: string }[];
    selectedConnectionUid?: string;
    selectedEventTypeUid?: string;
    selectedScriptUid?: string;
    templateMode?: boolean;
    uid: string;
    urlId?: string;
    webhookSetup?: boolean;
    workspaceLocked?: boolean;
    onCancel: () => void;
    onOpenSetupInstructions: (event: OpenSetupInstructionsEvent) => void;
    onSave: (event: SaveEventListenerEvent) => void;
}

const StyledBorderBoxHeaderSmall = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 54,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
}));

const StyledBorderBoxContentGrey = styled(StyledBorderBoxContent)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
}));

const StyledStatusController = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
}));

const StyledStatusControllerLabelContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
        height: 24,
        weight: 24,
    },
}));

export const SetupGuideEditEventListenerScreen: React.FC<SetupGuideEditEventListenerScreenProps> = ({
    appName,
    connectionRequired = false,
    connections = [],
    disabled = false,
    errors,
    eventTypes = [],
    selectedConnectionUid = '',
    selectedEventTypeUid = '',
    selectedScriptUid = '',
    saving = false,
    scripts = [],
    templateMode = false,
    uid,
    urlId,
    webhookSetup = false,
    workspaceLocked = false,
    onCancel,
    onOpenSetupInstructions,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [scriptAttachMode, setScriptAttachMode] = useState<'new' | 'existing'>(templateMode ? 'existing' : 'new');
    const [currentSelectedConnectionUid, setCurrentSelectedConnectionUid] = useState(selectedConnectionUid);
    const [currentSelectedScriptUid, setCurrentSelectedScriptUid] = useState(selectedScriptUid);
    const [currentSelectedEventTypeUid, setCurrentSelectedEventTypeUid] = useState(selectedEventTypeUid);
    const [currentScriptName, setCurrentScriptName] = useState(
        templateMode || !currentSelectedEventTypeUid
            ? ''
            : getScriptNameFromEventType(appName, eventTypes, selectedEventTypeUid ?? '')
    );
    const [currentDisabled, setCurrentDisabled] = useState(disabled);
    const [disabledSummaryOpen, setDisabledSummaryOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const selectRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (scriptAttachMode === 'new') {
            if (currentSelectedEventTypeUid) {
                const newScriptName = getScriptNameFromEventType(appName, eventTypes, currentSelectedEventTypeUid);
                setCurrentScriptName(newScriptName);
            }
            setCurrentSelectedScriptUid('');
            autoFocus(inputRef);
        }
    }, [scriptAttachMode, currentSelectedEventTypeUid]);

    useEffect(() => {
        if (scriptAttachMode === 'existing') {
            setCurrentScriptName('');
            autoFocus(selectRef);
        }
    }, [scriptAttachMode]);

    const hasScript = scriptAttachMode === 'new' ? !!currentScriptName : !!currentSelectedScriptUid;

    const selectedConnection = connections.find((c) => c.uid === currentSelectedConnectionUid);
    const hasRequiredConnection = connectionRequired || templateMode ? selectedConnection?.authorized : true;
    const hasUnsavedChanges =
        currentDisabled !== disabled ||
        currentSelectedConnectionUid !== selectedConnectionUid ||
        currentSelectedEventTypeUid !== selectedEventTypeUid ||
        currentSelectedScriptUid !== selectedScriptUid;

    const canSave =
        hasRequiredConnection && !!currentSelectedEventTypeUid && hasScript && hasUnsavedChanges && !workspaceLocked;

    const typographyColor = templateMode || workspaceLocked ? 'text.disabled' : undefined;

    const actions = (
        <>
            <Button variant="outlined" onClick={onCancel}>
                Back
            </Button>
            {!templateMode ? (
                <Button
                    data-pendo={'eventListenerSaved'}
                    busy={saving}
                    disabled={!canSave}
                    onClick={() =>
                        onSave({
                            connectionUid: currentSelectedConnectionUid,
                            disabled: false,
                            eventTypeUid: currentSelectedEventTypeUid,
                            scriptName: currentScriptName,
                            scriptUid: currentSelectedScriptUid,
                            uid,
                            urlId,
                        })
                    }
                >
                    Save
                </Button>
            ) : (
                <Button
                    onClick={() =>
                        onOpenSetupInstructions({
                            connectionUid: currentSelectedConnectionUid,
                            eventTypeUid: currentSelectedEventTypeUid,
                            uid,
                            urlId,
                        })
                    }
                >
                    {webhookSetup ? 'View webhook instructions' : 'Continue to webhook'}
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <StyledBorderBoxTitleContainer>
                        <IconCircle icon={<ProductIcon name={appName} />} size="medium" />
                        <Typography variant="h6" component="h4">
                            {`Configure ${appName} event listener`}
                        </Typography>
                    </StyledBorderBoxTitleContainer>
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {errors && <Alert severity="error" title={errors} />}
                    {appName !== APP.GENERIC.NAME && (
                        <>
                            <StyledBorderBoxTitleContainer>
                                <Typography color={typographyColor} variant="subtitle2">
                                    Select connector
                                </Typography>
                                <InfoIcon tooltip="Select a connector to link your event listener with a third-party service." />
                            </StyledBorderBoxTitleContainer>
                            <Dropdown
                                disabled={templateMode || workspaceLocked}
                                fullWidth
                                items={connections.map((c) => ({
                                    icon: <ProductIcon name={appName} />,
                                    name: c.name,
                                    value: c.uid,
                                }))}
                                label="Uses connector"
                                required={connectionRequired || templateMode}
                                selectedItem={currentSelectedConnectionUid}
                                onSelectItem={(value) => setCurrentSelectedConnectionUid(value)}
                            />
                        </>
                    )}
                    <>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle2">
                                Select listener event type
                            </Typography>
                            <InfoIcon tooltip="Select the event type you want to listen to." />
                        </StyledBorderBoxTitleContainer>
                        <Dropdown
                            disabled={templateMode || workspaceLocked}
                            fullWidth
                            items={eventTypes.map((et) => ({
                                name: et.name,
                                value: et.uid,
                            }))}
                            label="Listens event type"
                            required
                            selectedItem={currentSelectedEventTypeUid}
                            onSelectItem={(value) => setCurrentSelectedEventTypeUid(value)}
                        />
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle2">
                                Select script
                            </Typography>
                            <InfoIcon
                                tooltip={
                                    templateMode
                                        ? 'Select a script to link with your event listener.'
                                        : 'Select if you wish to generate a new script with the correct event type for this event listener or enable it to trigger an existing script instead.' // TODO: Verify microcopy for blank workspace flow
                                }
                            />
                        </StyledBorderBoxTitleContainer>
                        {!templateMode ? (
                            <>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={scriptAttachMode === 'new'}
                                                    disabled={workspaceLocked}
                                                    onClick={() => setScriptAttachMode('new')}
                                                />
                                            }
                                            label="Create new script"
                                        />
                                        <InformationChip label="Recommended" severity="success" />
                                    </StyledBorderBoxHeaderSmall>
                                    <Divider />
                                    <StyledBorderBoxContent>
                                        <TextField
                                            disabled={scriptAttachMode === 'existing' || workspaceLocked}
                                            fullWidth
                                            inputRef={inputRef}
                                            label="New script name"
                                            placeholder="Enter script name"
                                            required={scriptAttachMode === 'existing'}
                                            value={currentScriptName}
                                            onChange={(e) => setCurrentScriptName(e.target.value)}
                                        />
                                    </StyledBorderBoxContent>
                                </StyledBorderBox>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={scriptAttachMode === 'existing'}
                                                    disabled={workspaceLocked}
                                                    onClick={() => setScriptAttachMode('existing')}
                                                />
                                            }
                                            label="Use existing script"
                                        />
                                    </StyledBorderBoxHeaderSmall>
                                    <Divider />
                                    <StyledBorderBoxContent>
                                        <Dropdown
                                            disabled={scriptAttachMode === 'new' || workspaceLocked}
                                            fullWidth
                                            inputRef={selectRef}
                                            items={scripts.map((s) => ({ name: s.name, value: s.uid }))}
                                            label="Select script"
                                            required={scriptAttachMode === 'new'}
                                            selectedItem={currentSelectedScriptUid}
                                            onSelectItem={(value) => setCurrentSelectedScriptUid(value)}
                                        />
                                    </StyledBorderBoxContent>
                                    {!selectedScriptUid && (
                                        <Alert
                                            severity="warning"
                                            text="If you choose to use an existing script, you may need to make some changes to it if it uses a different type of event."
                                        />
                                    )}
                                </StyledBorderBox>
                            </>
                        ) : (
                            <Dropdown
                                disabled={true}
                                fullWidth
                                items={scripts.map((s) => ({ name: s.name, value: s.uid }))}
                                label="Select script"
                                required={true}
                                selectedItem={selectedScriptUid}
                            />
                        )}
                        <StyledBorderBox>
                            <StyledBorderBoxHeader>
                                <StyledBorderBoxTitleContainer>
                                    <IconCircle icon={<ReportOutlinedIcon />} severity="error" />
                                    <Typography color={typographyColor} variant="subtitle2">
                                        Disable event listener
                                    </Typography>
                                    <InfoIcon tooltip="Disable an event listener in order to stop it from processing external events." />
                                </StyledBorderBoxTitleContainer>
                                <IconButton
                                    aria-label="Open summary"
                                    icon={
                                        disabledSummaryOpen ? (
                                            <KeyboardArrowUpOutlinedIcon />
                                        ) : (
                                            <KeyboardArrowDownOutlinedIcon />
                                        )
                                    }
                                    tooltip="Open summary"
                                    onClick={() => setDisabledSummaryOpen(!disabledSummaryOpen)}
                                />
                            </StyledBorderBoxHeader>
                            {disabledSummaryOpen && (
                                <>
                                    <Divider />
                                    <StyledBorderBoxContentGrey>
                                        <StyledStatusController>
                                            <StyledStatusControllerLabelContainer>
                                                <HearingOutlinedIcon />
                                                <Box>
                                                    <Typography color={typographyColor} fontWeight="bold">
                                                        Current status
                                                    </Typography>
                                                    <Typography color="primary.main" variant="body2">
                                                        {currentDisabled ? 'Disabled' : 'Active'}
                                                    </Typography>
                                                </Box>
                                            </StyledStatusControllerLabelContainer>
                                            <Switch
                                                aria-label={
                                                    currentDisabled
                                                        ? 'Activate event listener'
                                                        : 'Disable event listener'
                                                }
                                                checked={!currentDisabled}
                                                disabled={templateMode || workspaceLocked}
                                                onChange={() => setCurrentDisabled(!currentDisabled)}
                                            />
                                        </StyledStatusController>
                                    </StyledBorderBoxContentGrey>
                                </>
                            )}
                        </StyledBorderBox>
                    </>
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
