import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import { ListItem } from '@mui/material';
import { StyledOrderedList } from '../../../common/lists/OrderedList';

interface AtlassianOnPremiseEventListenerSetupDialogProps {
    connectionType?: 'Jira' | 'Confluence' | 'Jira Service Management';
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const AtlassianOnPremiseEventListenerSetupDialog: React.FC<AtlassianOnPremiseEventListenerSetupDialogProps> = ({
    connectionType,
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const isIssueTransitionedEvent = eventTypeName === 'Issue Transitioned';

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={`${connectionType} On-Premise`} />} size="medium" />}
                open={open}
                title={`Event Listener setup`}
                subtitle={loading ? '' : `${connectionType} On-Premise`}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionType} On-Premise instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                {setupBaseUrl ? (
                                    <Typography>
                                        Visit your{' '}
                                        <Link target="_blank" href={`${setupBaseUrl}/plugins/servlet/webhooks`}>
                                            WebHooks
                                        </Link>{' '}
                                        page.
                                    </Typography>
                                ) : (
                                    <Typography>
                                        Open https://[YOUR{connectionType ? `_${connectionType.toUpperCase()}` : ''}
                                        _INSTANCE]/plugins/servlet/webhooks URL in your browser
                                    </Typography>
                                )}
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click <strong>Create a WebHook</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Enter a meaningful name into the <strong>Name</strong> field.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong>URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            {!isIssueTransitionedEvent ? (
                                <>
                                    <ListItem>
                                        <Typography>
                                            Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Then click <strong>Create</strong>.
                                        </Typography>
                                    </ListItem>
                                </>
                            ) : (
                                <>
                                    <ListItem>
                                        <Typography>
                                            Then click <strong>Create</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        {setupBaseUrl ? (
                                            <Typography>
                                                Visit your{' '}
                                                <Link
                                                    target="_blank"
                                                    href={`${setupBaseUrl}/secure/admin/workflows/ListWorkflows.jspa`}
                                                >
                                                    Workflows
                                                </Link>{' '}
                                                page.
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                Open https://[YOUR
                                                {connectionType ? `_${connectionType.toUpperCase()}` : ''}
                                                _INSTANCE]/secure/admin/workflows/ListWorkflows.jspa URL in your browser
                                            </Typography>
                                        )}
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Click <strong>Edit</strong> next to the relevant workflow.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            In the Workflow Designer, select the transition and click{' '}
                                            <strong>Post functions</strong> in the properties panel.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Click <strong>Add post function</strong>, select{' '}
                                            <strong>Trigger a Webhook</strong> and click <strong>Add</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Select the created webhook and click <strong>Add</strong>.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Repeat steps <strong>8 through 10</strong> for each transition you want to
                                            get an event.
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>
                                            Click <strong>Publish</strong> to publish the workflow.
                                        </Typography>
                                    </ListItem>
                                </>
                            )}
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
