import { styled } from '@mui/material/styles';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface BulletListProps extends ListProps {
    id: string;
    listItems: (string | JSX.Element)[];
}

const StyledBulletListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    '&::before': {
        content: '"•"',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
    },
}));

export const BulletList: React.FC<BulletListProps> = ({ id, listItems }) => (
    <List>
        {listItems.map((li, index) => (
            <StyledBulletListItem key={id + index}>{li}</StyledBulletListItem>
        ))}
    </List>
);
