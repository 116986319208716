import {
    getOrganizationViewDetails as getOrganizationViewDetailsRpc,
    getOrganizationBillingEntities as getOrganizationBillingEntitiesRpc,
    changeOrganizationBillingEntity as changeOrganizationBillingEntityRpc,
    getOrganizationUsage as getOrganizationUsageRpc,
    getOrganizationMembers as getOrganizationMembersRpc,
    sendOrganizationMemberInvite as sendOrganizationMemberInviteRpc,
    resendOrganizationMemberInvite as resendOrganizationMemberInviteRpc,
    getOrganizationDetails as getOrganizationDetailsRpc,
    saveOrganizationDetails as saveOrganizationDetailsRpc,
    getOrganizationPlan as getOrganizationPlanRpc,
    updateOrganizationMemberPermissions as updateOrganizationMemberPermissionsRpc,
    updateOrganizationInvitedUserPermissions as updateOrganizationInvitedUserPermissionsRpc,
    removeOrganizationMember as removeOrganizationMemberRpc,
    removeOrganizationInvite as removeOrganizationInviteRpc,
    getOrganizationWorkspacesWithUserConnections as getOrganizationWorkspacesWithUserConnectionsRpc,
} from '@avst-stitch/repository-lib/lib';
import { Response as OrganizationViewDetails } from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationViewDetails';
import { Response as OrganizationBillingDetails } from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationBillingEntities';
import { Request as UpdateOrganizationBillingDetails } from '@avst-stitch/repository-lib/lib/rpcs/changeOrganizationBillingEntity';
import { Response as OrganizationUsage } from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationUsage';
import { Response as OrganizationMembers } from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationMembers';
import { Request as MemberInvitationRequest } from '@avst-stitch/repository-lib/lib/rpcs/sendOrganizationMemberInvite';
import { Response as OrganizationDetails } from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationDetails';
import { Request as SaveOrganizationDetailsRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveOrganizationDetails';
import {
    Response as OrganizationPlan,
    Request as GetOrganizationPlanRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationPlan';
import {
    changeOrganizationPlanV2 as changeOrganizationPlanV2Rpc,
    Request as ChangeOrganizationPlanRequestV2,
    Response as ChangeOrganizationPlanResponseV2,
} from '@avst-stitch/repository-lib/lib/rpcs/changeOrganizationPlanV2';
import { Request as OrganizationMemberPermissionsUpdateRequest } from '@avst-stitch/repository-lib/lib/rpcs/updateOrganizationMemberPermissions';
import { Request as OrganizationInvitedUserPermissionsUpdateRequest } from '@avst-stitch/repository-lib/lib/rpcs/updateOrganizationInvitedUserPermissions';
import { Request as OrganizationMemberRemovalRequest } from '@avst-stitch/repository-lib/lib/rpcs/removeOrganizationMember';
import { Request as OrganizationInviteRemovalRequest } from '@avst-stitch/repository-lib/lib/rpcs/removeOrganizationInvite';
import { Request as OrganizationWorkspacesWithUserConnectionsRequest } from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationWorkspacesWithUserConnections';
import {
    cancelOrganizationPlan as cancelOrganizationPlanRpc,
    Request as CancelOrganizationPlanRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/cancelOrganizationPlan';
import {
    saveCancellationFeedback as saveCancellationFeedbackRpc,
    Request as SaveCancellationFeedbackRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/saveCancellationFeedback';
import {
    reactivateOrganizationPlan as reactivateOrganizationPlanRpc,
    Request as ReactivateOrganizationPlanRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/reactivateOrganizationPlan';
import {
    getOrganizationPlanCustomerPortalUrl as getOrganizationPlanCustomerPortalUrlRpc,
    Response as CustomerPortalUrl,
} from '@avst-stitch/repository-lib/lib/rpcs/getOrganizationPlanCustomerPortalUrl';
import { repositoryInvoker } from '../utils/repository';
export type {
    OrganizationViewDetails,
    OrganizationDetails,
    SaveOrganizationDetailsRequest,
    OrganizationPlan,
    OrganizationBillingDetails,
    UpdateOrganizationBillingDetails,
    OrganizationUsage,
    OrganizationMembers,
    MemberInvitationRequest,
    OrganizationMemberPermissionsUpdateRequest,
    OrganizationInvitedUserPermissionsUpdateRequest,
    OrganizationMemberRemovalRequest,
    OrganizationInviteRemovalRequest,
    ChangeOrganizationPlanRequestV2,
    CancelOrganizationPlanRequest,
    SaveCancellationFeedbackRequest,
    ReactivateOrganizationPlanRequest,
};

export const getOrganizationViewDetails = async (orgUid: string): Promise<OrganizationViewDetails> => {
    return await getOrganizationViewDetailsRpc(repositoryInvoker, { organizationUid: orgUid });
};

export const getOrganizationBillingEntities = async (orgUid: string): Promise<OrganizationBillingDetails> => {
    return await getOrganizationBillingEntitiesRpc(repositoryInvoker, { organizationUid: orgUid });
};

export const changeOrganizationBillingEntity = async (updateData: UpdateOrganizationBillingDetails): Promise<void> => {
    return await changeOrganizationBillingEntityRpc(repositoryInvoker, updateData);
};

export const getOrganizationUsage = async (orgUid: string): Promise<OrganizationUsage> => {
    return await getOrganizationUsageRpc(repositoryInvoker, { organizationUid: orgUid });
};

export const getOrganizationMembers = async (orgUid: string): Promise<OrganizationMembers> => {
    return await getOrganizationMembersRpc(repositoryInvoker, { organizationUid: orgUid });
};

export const sendOrganizationMemberInvite = async (invite: MemberInvitationRequest): Promise<void> => {
    await sendOrganizationMemberInviteRpc(repositoryInvoker, invite);
};

export const resendOrganizationMemberInvite = async (uid: string): Promise<void> => {
    await resendOrganizationMemberInviteRpc(repositoryInvoker, { uid });
};

export const updateOrganizationMemberPermissions = async (
    data: OrganizationMemberPermissionsUpdateRequest
): Promise<void> => {
    await updateOrganizationMemberPermissionsRpc(repositoryInvoker, data);
};

export const updateOrganizationInvitedUserPermissions = async (
    data: OrganizationInvitedUserPermissionsUpdateRequest
): Promise<void> => {
    await updateOrganizationInvitedUserPermissionsRpc(repositoryInvoker, data);
};

export const removeOrganizationMember = async (data: OrganizationMemberRemovalRequest): Promise<void> => {
    await removeOrganizationMemberRpc(repositoryInvoker, data);
};

export const removeOrganizationInvite = async (data: OrganizationInviteRemovalRequest): Promise<void> => {
    await removeOrganizationInviteRpc(repositoryInvoker, data);
};

export const getOrganizationDetails = async (orgUid: string): Promise<OrganizationDetails> => {
    return await getOrganizationDetailsRpc(repositoryInvoker, { organizationUid: orgUid });
};

export const saveOrganizationDetails = async (
    name: string,
    organizationUid: string,
    description?: string
): Promise<void> => {
    return await saveOrganizationDetailsRpc(repositoryInvoker, {
        name,
        organizationUid,
        description,
    });
};

export const getOrganizationPlan = async (event: GetOrganizationPlanRequest): Promise<OrganizationPlan> => {
    return await getOrganizationPlanRpc(repositoryInvoker, event);
};

export const cancelOrganizationPlan = async (event: CancelOrganizationPlanRequest): Promise<void> => {
    return await cancelOrganizationPlanRpc(repositoryInvoker, event);
};

export const saveCancellationFeedback = async (event: SaveCancellationFeedbackRequest): Promise<void> => {
    return await saveCancellationFeedbackRpc(repositoryInvoker, event);
};

export const reactivateOrganizationPlan = async (event: ReactivateOrganizationPlanRequest): Promise<void> => {
    return await reactivateOrganizationPlanRpc(repositoryInvoker, event);
};

export const getOrganizationPlanCustomerPortalUrl = async (orgUid: string): Promise<CustomerPortalUrl> => {
    return await getOrganizationPlanCustomerPortalUrlRpc(repositoryInvoker, { organizationUid: orgUid });
};

export const getOrganizationWorkspacesWithUserConnections = async (
    event: OrganizationWorkspacesWithUserConnectionsRequest
): Promise<{ uid: string; name: string }[]> => {
    return await getOrganizationWorkspacesWithUserConnectionsRpc(repositoryInvoker, event);
};

export const changeOrganizationPlanV2 = async (
    request: ChangeOrganizationPlanRequestV2
): Promise<ChangeOrganizationPlanResponseV2> => {
    return await changeOrganizationPlanV2Rpc(repositoryInvoker, request);
};
