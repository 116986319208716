import { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../../for-deprecation/IconCircle';
import { InfoIcon } from '../../icons/InfoIcon';
import { LoadingSpinner } from '../../common/LoadingSpinner';
import { Package } from './Package';
import { searchByName } from '../../../utils/searchByName';
import { handleKeyDown } from '../../../utils/handleKeyDown';
import { packageManagerDocumentationUrl, runtimeDocumentationUrl } from '../../../utils/documentation';
import { NPM_PACKAGE_URL } from '../../../npm/npm';
import { isFocused } from '../../../utils/autoFocus';
import { TextField } from '../../common/inputs/TextField';

export interface BasePackage {
    name: string;
    selectedVersion: string;
}

export interface Package extends BasePackage {
    childPackage?: Pick<
        Package,
        'name' | 'selectedVersion' | 'description' | 'link' | 'latestVersion' | 'versions' | 'selected' | 'repository'
    >;
    description?: string;
    latestVersion?: string;
    link?: string;
    locked?: boolean;
    repository?: {
        type?: string;
        url?: string;
    };
    saved?: boolean;
    selected?: boolean;
    thirdParty?: boolean;
    versions: string[];
}

export interface ThirdPartyPackage extends Package {
    verified: boolean;
}

interface PackageManagerProperties {
    corePackages: Package[];
    errors?: string;
    loading?: boolean;
    managedAPIs: Package[];
    open?: boolean;
    saving?: boolean;
    thirdPartyPackages: ThirdPartyPackage[];
    workspaceLocked?: boolean;
    importLoading?: boolean;
    onAddThirdPartyPackage(name: string): void;
    onCancel(): void;
    onSave(corePackages: BasePackage[], managedAPIs: BasePackage[], thirdPartyPackages: BasePackage[]): void;
    onCopyImport(packageName: string, isCorePackage: boolean): void;
}

export const RUNTIME_PACKAGE = '@stitch-it/runtime';

export const PACKAGE_MANAGER_CORE_PACKAGES = [
    RUNTIME_PACKAGE,
    '@sr-connect/convert',
    '@sr-connect/record-storage',
    '@sr-connect/trigger',
];

export const PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES = [
    'dayjs',
    'validator',
    'ulid',
    'fast-xml-parser',
    'gql-query-builder',
    'yaml',
    'entities',
    'promise-throttle-all',
    'slack-block-builder',
    'chai',
    '@types/validator',
    '@types/chai',
];

const StyledAddArea = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paperScrollPaper': {
        backgroundColor: theme.palette.background.default,
        maxWidth: 'unset',
        minHeight: 240,
        width: 920,
    },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    marginRight: theme.spacing(3),
    '& .MuiButton-root:not(:first-of-type)': {
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

const StyledPanelHeader = styled('div')(({ theme }) => ({
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
}));

const StyledResults = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    height: '22px',
    fontSize: theme.typography.body2.fontSize,
    padding: 0,
}));

const StyledSearchBoxContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    '& .MuiButton-root': {
        marginLeft: theme.spacing(1),
    },
}));

const StyledSearchPackageArea = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.constants.boxShadow,
    display: 'inline-block',
    padding: theme.spacing(2),
    '& h5': {
        marginBottom: theme.spacing(0.5),
    },
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
    height: '26px',
    minHeight: '26px',
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.secondary,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: '24px',
    minHeight: 'unset',
    padding: 0,
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledTabPanel = styled(TabPanel)(() => ({}));

const StyledDiv = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const PackageManager: React.FC<PackageManagerProperties> = ({
    corePackages = [],
    errors,
    loading = false,
    managedAPIs = [],
    open = false,
    saving = false,
    thirdPartyPackages = [],
    workspaceLocked = false,
    importLoading = false,
    onAddThirdPartyPackage,
    onCancel,
    onSave,
    onCopyImport,
}) => {
    const [tabValue, setTabValue] = useState('1');
    const [currentCorePackages, setCurrentCorePackages] = useState(corePackages);
    const [selectedCorePackages, setSelectedCorePackages] = useState(
        corePackages.filter((pkg) => pkg.selected).map((pkg) => pkg.name)
    );
    const [currentManagedAPIs, setCurrentManagedAPIs] = useState(managedAPIs);
    const [selectedManagedAPIs, setSelectedManagedAPIs] = useState(
        managedAPIs.filter((pkg) => pkg.selected).map((pkg) => pkg.name)
    );
    const [currentThirdPartyPackages, setCurrentThirdPartyPackages] = useState(thirdPartyPackages);
    const [selectedThirdPartyPackages, setSelectedThirdPartyPackages] = useState(
        thirdPartyPackages.filter((pkg) => pkg.selected).map((pkg) => pkg.name)
    );
    const [searchTerm, setSearchTerm] = useState('');
    const [newPackageSearchTerm, setNewPackageSearchTerm] = useState('');

    const theme = useTheme();

    useEffect(() => {
        setCurrentCorePackages(corePackages);
        setSelectedCorePackages(corePackages.filter((pkg) => pkg.selected).map((pkg) => pkg.name));
    }, [corePackages]);

    useEffect(() => {
        setCurrentManagedAPIs(managedAPIs);
        setSelectedManagedAPIs(managedAPIs.filter((pkg) => pkg.selected).map((pkg) => pkg.name));
    }, [managedAPIs]);

    useEffect(() => {
        setCurrentThirdPartyPackages(thirdPartyPackages);
        setSelectedThirdPartyPackages(thirdPartyPackages.filter((pkg) => pkg.selected).map((pkg) => pkg.name));
    }, [thirdPartyPackages]);

    const searchBoxRef = useRef<HTMLInputElement | null>(null);

    const handleSelectCorePackage = (selected: boolean, selectedVersion: string, name: string): void => {
        const newPackages = currentCorePackages.map((cp) => {
            if (cp.name === name) {
                return {
                    name: name,
                    selectedVersion: selectedVersion,
                    versions: cp.versions,
                    description: cp.description,
                    latestVersion: cp.latestVersion,
                    link: cp.link,
                    locked: cp.locked,
                    selected: selected,
                };
            }
            return cp;
        });
        setCurrentCorePackages(newPackages);

        if (selected) {
            setSelectedCorePackages((prev) => Array.from(new Set([...prev, name])));
        } else {
            setSelectedCorePackages((prev) => prev.filter((pkg) => pkg !== name));
        }
    };

    const handleSelectManagedAPI = (selected: boolean, selectedVersion: string, name: string): void => {
        const newPackages = currentManagedAPIs.map((ma) => {
            if (ma.name === name) {
                return {
                    name: name,
                    selectedVersion: selectedVersion,
                    versions: ma.versions,
                    description: ma.description,
                    latestVersion: ma.latestVersion,
                    link: ma.link,
                    locked: ma.locked,
                    selected: selected,
                };
            }
            return ma;
        });
        setCurrentManagedAPIs(newPackages);

        if (selected) {
            setSelectedManagedAPIs((prev) => Array.from(new Set([...prev, name])));
        } else {
            setSelectedManagedAPIs((prev) => prev.filter((pkg) => pkg !== name));
        }
    };

    const handleSelectThirdPartyPackage = (selected: boolean, selectedVersion: string, name: string): void => {
        setCurrentThirdPartyPackages((thirdPartyPackages) =>
            thirdPartyPackages.map((tpp) => {
                if (tpp.name === name) {
                    return {
                        ...tpp,
                        name: name,
                        selectedVersion: selectedVersion,
                        selected: selected,
                        thirdParty: true,
                    };
                } else if (tpp.childPackage?.name === name) {
                    return {
                        ...tpp,
                        childPackage: {
                            ...tpp.childPackage,
                            selectedVersion: selectedVersion,
                            selected: selected,
                            thirdParty: true,
                        },
                    };
                }
                return tpp;
            })
        );

        if (selected) {
            setSelectedThirdPartyPackages((prev) => Array.from(new Set([...prev, name])));
        } else {
            setSelectedThirdPartyPackages((prev) => prev.filter((pkg) => pkg !== name));
        }
    };

    const handleSave = (): void => {
        const saveCorePackages = currentCorePackages
            .filter((cp) => selectedCorePackages.includes(cp.name))
            .map((cp) => ({
                name: cp.name,
                selectedVersion: cp.selectedVersion,
            }));

        const saveManagedAPIs = currentManagedAPIs
            .filter((ma) => selectedManagedAPIs.includes(ma.name))
            .map((ma) => ({
                name: ma.name,
                selectedVersion: ma.selectedVersion,
            }));

        const saveThirdPartyPackages = currentThirdPartyPackages
            .filter((tpp) => selectedThirdPartyPackages.includes(tpp.name))
            .flatMap((tpp) => {
                const returnPackages = [
                    { name: tpp.name, selectedVersion: tpp.selectedVersion, verified: tpp.verified },
                ];
                if (tpp.childPackage?.selected) {
                    returnPackages.push({
                        name: tpp.childPackage.name,
                        selectedVersion: tpp.childPackage.selectedVersion,
                        verified: tpp.verified,
                    });
                }
                return returnPackages;
            });

        onSave(saveCorePackages, saveManagedAPIs, saveThirdPartyPackages);
    };

    const handleAddThirdPartyPackage = (): void => {
        onAddThirdPartyPackage(newPackageSearchTerm);
        setNewPackageSearchTerm('');
    };

    const createLabel = (): string => {
        let currentResultsLength: number;

        switch (tabValue) {
            case '1':
                currentResultsLength = corePackagesList.length;
                break;
            case '2':
                currentResultsLength = filteredManagedAPIs.length;
                break;
            case '3':
                currentResultsLength = filteredThirdPartyPackages.length;
                break;
            default:
                currentResultsLength = 0;
                break;
        }

        return currentResultsLength === 1 ? currentResultsLength + ' result' : currentResultsLength + ' results';
    };

    const filteredCorePackages = searchByName(currentCorePackages, searchTerm);
    const filteredManagedAPIs = searchByName(currentManagedAPIs, searchTerm);
    const filteredThirdPartyPackages = searchByName(currentThirdPartyPackages, searchTerm);

    const corePackagesList = filteredCorePackages
        .filter((cp) => cp.name !== '@stitch-it/runtime')
        .sort((cp1, cp2) => {
            if (PACKAGE_MANAGER_CORE_PACKAGES.includes(cp1.name) && !PACKAGE_MANAGER_CORE_PACKAGES.includes(cp2.name)) {
                return -1;
            } else if (
                PACKAGE_MANAGER_CORE_PACKAGES.includes(cp2.name) &&
                !PACKAGE_MANAGER_CORE_PACKAGES.includes(cp1.name)
            ) {
                return 1;
            }
            return cp1.name.localeCompare(cp2.name);
        })
        .map((cp) => {
            return (
                <Package
                    {...cp}
                    selectedVersion={
                        cp.selectedVersion === 'latest' && cp.latestVersion ? cp.latestVersion : cp.selectedVersion
                    }
                    locked={cp.locked || workspaceLocked}
                    link={`${NPM_PACKAGE_URL}/${cp.name}`}
                    key={cp.name}
                    onSelect={handleSelectCorePackage}
                    onCopyImport={onCopyImport}
                    importLoading={importLoading}
                    isCorePackage={true}
                />
            );
        });

    const managedAPIsList = filteredManagedAPIs
        .sort((ma1, ma2) => ma1.name.localeCompare(ma2.name))
        .map((ma) => {
            return (
                <Package
                    {...ma}
                    selectedVersion={
                        ma.selectedVersion === 'latest' && ma.latestVersion ? ma.latestVersion : ma.selectedVersion
                    }
                    locked={ma.locked || workspaceLocked}
                    link={`${NPM_PACKAGE_URL}/${ma.name}`}
                    key={ma.name}
                    onSelect={handleSelectManagedAPI}
                    onCopyImport={onCopyImport}
                    importLoading={importLoading}
                />
            );
        });

    const thirdPartyPackagesList = filteredThirdPartyPackages
        .sort((tpp1, tpp2) => {
            if (tpp1.verified && !tpp2.verified) {
                return -1;
            } else if (!tpp1.verified && tpp2.verified) {
                return 1;
            }
            return tpp1.name.localeCompare(tpp2.name);
        })
        .map((tpp) => {
            const childPackage = tpp.childPackage
                ? { ...tpp.childPackage, link: `${NPM_PACKAGE_URL}/${tpp.childPackage.name}` }
                : undefined;
            return (
                <Package
                    {...tpp}
                    locked={tpp.locked || workspaceLocked}
                    childPackage={childPackage}
                    link={`${NPM_PACKAGE_URL}/${tpp.name}`}
                    key={tpp.name}
                    thirdParty={true}
                    onSelect={handleSelectThirdPartyPackage}
                    onCopyImport={onCopyImport}
                    importLoading={importLoading}
                />
            );
        });

    const canSave = !saving && !loading && !workspaceLocked;
    const canAddPackage = !!newPackageSearchTerm && !saving && !workspaceLocked;
    const infoDisclaimer = (
        <>
            Adding an unverified third party package may not work because of API incompatibilities with the ScriptRunner
            Connect runtime. You can read more about it{' '}
            <Link target="_blank" href={runtimeDocumentationUrl}>
                here
            </Link>
            .
        </>
    );

    return (
        <StyledDialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: isFocused(searchBoxRef) ? canAddPackage : canSave,
                    enterFn: isFocused(searchBoxRef) ? handleAddThirdPartyPackage : handleSave,
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleMain
                title="Package Manager"
                icon={<IconCircle icon={<AllInboxOutlinedIcon />} background={theme.palette.background.paper} />}
                tooltipElement={
                    <InfoIcon href={packageManagerDocumentationUrl} tooltip="Learn more about package manager" />
                }
            />

            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {workspaceLocked && (
                        <DialogAlert
                            severity="warning"
                            title="This workspace is currently locked and packages cannot be edited"
                            sx={{ marginTop: -2 }}
                        />
                    )}
                    <TabContext value={tabValue}>
                        <StyledTabList onChange={(_, value: string) => setTabValue(value)}>
                            <StyledTab label="Core Packages" value="1" />
                            <StyledTab label="Managed APIs" value="2" />
                            <StyledTab label="Third Party Packages" value="3" />
                        </StyledTabList>
                        <StyledPanelHeader>
                            <StyledResults label={createLabel()} />
                            <TextField
                                aria-label="Search"
                                label={null}
                                placeholder="Search package"
                                size="small"
                                startIcon={<SearchOutlinedIcon />}
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                            />
                        </StyledPanelHeader>
                        <StyledDialogContent>
                            <StyledTabPanel value="1">{corePackagesList}</StyledTabPanel>
                            <StyledTabPanel value="2">{managedAPIsList}</StyledTabPanel>
                            <StyledTabPanel value="3">{thirdPartyPackagesList}</StyledTabPanel>
                        </StyledDialogContent>
                    </TabContext>
                    {tabValue === '3' ? (
                        <StyledAddArea>
                            {errors && (
                                <StyledDiv>
                                    <Alert severity="error" title={errors} />
                                </StyledDiv>
                            )}
                            <DialogAlert text={infoDisclaimer} severity="info" />
                            <StyledSearchPackageArea>
                                <Typography variant="subtitle2">Add an unverified NPM Package</Typography>
                                <StyledSearchBoxContainer>
                                    <TextField
                                        aria-label="Search"
                                        inputRef={searchBoxRef}
                                        label={null}
                                        placeholder="Search third party package"
                                        startIcon={<SearchOutlinedIcon />}
                                        value={newPackageSearchTerm}
                                        onKeyDown={(event) => {
                                            if (
                                                newPackageSearchTerm &&
                                                !saving &&
                                                !workspaceLocked &&
                                                event.key === 'Enter'
                                            ) {
                                                handleAddThirdPartyPackage();
                                            }
                                        }}
                                        onChange={(event) => setNewPackageSearchTerm(event.target.value)}
                                    />
                                    <Button
                                        disabled={!canAddPackage}
                                        busy={saving}
                                        onClick={handleAddThirdPartyPackage}
                                    >
                                        Add
                                    </Button>
                                </StyledSearchBoxContainer>
                            </StyledSearchPackageArea>
                        </StyledAddArea>
                    ) : null}
                    <StyledDialogActions>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleSave} disabled={!canSave} busy={saving}>
                            Save
                        </Button>
                    </StyledDialogActions>
                </>
            )}
        </StyledDialog>
    );
};
