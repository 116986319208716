/* eslint-disable sonarjs/no-duplicate-string */
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { StyledBorderBoxContent } from '../common/LayoutComponents';

export const StyledStepContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: 688,
    width: '100%',
}));

export const StyledBorderBoxHeaderSmall = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 54,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
}));

export const StyledBorderBoxContentGrey = styled(StyledBorderBoxContent)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
}));

// Eventually switch to an app-wide Actions component
export const StyledMainActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
}));

export const StyledListItem = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'needsSetup' && prop !== 'selected',
})<{ needsSetup?: boolean; selected?: boolean }>(({ needsSetup = false, selected = false, theme }) => ({
    alignItems: 'flex-start',
    border: needsSetup
        ? `2px solid ${theme.palette.warning.main}`
        : selected
        ? theme.constants.borderSelected
        : `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 688,
    padding: theme.spacing(1.5),
}));

export const StyledListItemLeftContainer = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
}));

export const StyledListItemRightContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 220,
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    justifyContent: 'center',
    gap: theme.spacing(1),
}));

export const StyledListItemActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

export const StyledListItemBottomTextContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    display: 'flex',
    gap: theme.spacing(0.5),
}));

export const StyledListItemWarningContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.warning.main,
    },
}));

export const StyledInstructionsBoxHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: alpha(theme.palette.info.light, 0.2), // TODO: Need a new theme colour, info.background
    height: 68,
    padding: theme.spacing(1, 1.5),
}));

export const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(0.5),
}));

export const StyledRunScreenListItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 688,
    padding: theme.spacing(1.5, 0),
}));

export const StyledRunScreenListHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledSetupGuideReviewScreenListContainer = styled(Box)(({ theme }) => ({
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
}));

export const StyledStatusController = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
}));

export const StyledStatusControllerLabelContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
        height: 24,
        weight: 24,
    },
}));

export const StyledSuccessItem = styled(Box)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.success.light, 0.1),
    borderRadius: theme.constants.borderRadius,
    color: theme.palette.success.dark,
    padding: theme.spacing(2),
}));
