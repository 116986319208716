import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { ResourceTreeScript } from './ResourceTreeScript';
import {
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledResourceIcon,
} from './ResourceTreeComponents';
import { WorkspaceLanguage, TreeResource } from './types';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { dashedBorderStyle, resourceTreeIconSize } from './resourceTreeStyles';

interface ResourceTreeFolderProps {
    deployedEnvironmentMode?: boolean;
    fullPath: string;
    language?: WorkspaceLanguage;
    name: string;
    readOnlyMode?: boolean;
    selectedNode?: string;
    subItems: TreeResource[];
    workspaceUid: string;
    onDelete(uid: string): void;
    onOpen(uid: string): void;
    onTrigger?(uid: string): void;
}

const StyledFolderName = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiSvgIcon-root': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.action.active,
    },
    '&:after': {
        borderTop: dashedBorderStyle(theme),
        content: '""',
        height: 1,
        left: resourceTreeIconSize * 2,
        position: 'absolute',
        top: theme.spacing(2),
        width: theme.spacing(1),
        zIndex: 10,
    },
}));

export const ResourceTreeFolder: React.FC<ResourceTreeFolderProps> = ({
    deployedEnvironmentMode = false,
    fullPath,
    language,
    name,
    readOnlyMode = false,
    selectedNode,
    subItems = [],
    workspaceUid,
    onDelete,
    onOpen,
    onTrigger,
}) => {
    const expandedKey = `isExpanded-${workspaceUid}-${fullPath}/`;

    const [expanded, setExpanded] = useState(readLocalStorage(expandedKey, false));

    useEffect(() => {
        saveLocalStorage(expandedKey, expanded);
    }, [expanded]);

    return (
        <>
            <StyledAccordion expanded={expanded} data-test-id={`resource-tree-${name}`}>
                <StyledAccordionSummary
                    className="folder"
                    onClick={() => setExpanded(!expanded)}
                    expandIcon={
                        expanded ? (
                            <Tooltip title="Collapse folder" placement="top">
                                <IconButton>
                                    <IndeterminateCheckBoxOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Expand folder" placement="left-end">
                                <IconButton>
                                    <AddBoxOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                >
                    <StyledFolderName>
                        <StyledResourceIcon>
                            <FolderIcon />
                        </StyledResourceIcon>
                        <Typography>{name}</Typography>
                    </StyledFolderName>
                </StyledAccordionSummary>
                <StyledAccordionDetails className="folder">
                    {subItems.map((si, i) => {
                        if (si.type === 'FOLDER') {
                            return (
                                <ResourceTreeFolder
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    fullPath={si.fullPath}
                                    key={'folder' + i}
                                    language={language}
                                    name={si.name}
                                    readOnlyMode={readOnlyMode}
                                    selectedNode={selectedNode}
                                    subItems={si.subItems}
                                    workspaceUid={workspaceUid}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    onTrigger={onTrigger}
                                />
                            );
                        } else {
                            return (
                                <ResourceTreeScript
                                    deleting={si.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    executing={si.executing}
                                    key={si.uid}
                                    language={language}
                                    name={si.name}
                                    readOnlyMode={readOnlyMode}
                                    selectedNode={selectedNode}
                                    uid={si.uid}
                                    unsaved={si.unsaved}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    onTrigger={onTrigger}
                                />
                            );
                        }
                    })}
                </StyledAccordionDetails>
            </StyledAccordion>
        </>
    );
};
