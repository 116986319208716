import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { Dropdown } from '../../common/inputs/dropdown/Dropdown';
import { IconCircle } from '../../common/IconCircle';
import { InformationChip } from '../../common/chips/InformationChip';
import { InfoIcon } from '../../icons/InfoIcon';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxHeaderSmall,
    StyledBorderBoxTitleContainer,
} from '../../common/LayoutComponents';
import { StyledMainActions, StyledStepContainer } from '../SetupGuideComponents';
import { TextField } from '../../common/inputs/TextField';
import { SaveApiHandlerEvent, VendorApiVersion } from '../../workspace-resources/api-connections/ApiConnectionDetails';
import { autoFocus } from '../../../utils/autoFocus';

// Move outside of this file, rename, and replace the corresponding interface in advanced view as well
interface SetupGuideApiHandlerConnection {
    authorized?: boolean;
    name: string;
    uid: string;
}

interface SetupGuideEditApiHandlerScreenProps {
    appName: string;
    connections?: SetupGuideApiHandlerConnection[];
    errors?: string;
    path?: string;
    saving?: boolean;
    selectedApiVersionUid?: string;
    selectedConnectionUid?: string;
    templateMode?: boolean;
    uid: string;
    vendorApiVersions: VendorApiVersion[];
    workspaceLocked?: boolean;
    onCancel: () => void;
    onSave: (event: SaveApiHandlerEvent) => void;
}

const StyledTextAdornment = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
}));

export const SetupGuideEditApiHandlerScreen: React.FC<SetupGuideEditApiHandlerScreenProps> = ({
    appName,
    connections = [],
    errors,
    path,
    saving = false,
    selectedApiVersionUid = '',
    selectedConnectionUid = '',
    templateMode = false,
    uid,
    vendorApiVersions,
    workspaceLocked = false,
    onCancel,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [vendorApiSelectionMode, setVendorApiSelectionMode] = useState<'default' | 'custom'>(
        templateMode ? 'custom' : 'default'
    );
    const [currentSelectedConnectionUid, setCurrentSelectedConnectionUid] = useState(selectedConnectionUid ?? '');
    const [currentSelectedApiVersionUid, setCurrentSelectedApiVersionUid] = useState(selectedApiVersionUid ?? '');
    const [currentPath, setCurrentPath] = useState(path);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const selectRef = useRef<HTMLInputElement | null>(null);

    const multipleVendorApiVersions = vendorApiVersions.length > 1;
    const recommendedVendorApiVersion = vendorApiVersions.find((v) => v.recommended);

    const hasUnsavedChanges =
        currentPath !== path ||
        currentSelectedApiVersionUid !== selectedApiVersionUid ||
        currentSelectedConnectionUid !== selectedConnectionUid;

    const canSave =
        !!path && !!selectedApiVersionUid && !!selectedConnectionUid && hasUnsavedChanges && !workspaceLocked;

    const typographyColor = templateMode || workspaceLocked ? 'text.disabled' : undefined;

    useEffect(() => {
        autoFocus(inputRef);
    }, []);

    useEffect(() => {
        if (vendorApiSelectionMode === 'custom') {
            autoFocus(selectRef);
        }
    }, [vendorApiSelectionMode]);

    const actions = (
        <>
            <Button variant="outlined" onClick={onCancel}>
                Back
            </Button>
            {!templateMode && (
                <Button
                    busy={saving}
                    disabled={!canSave}
                    onClick={() =>
                        onSave({
                            apiHandlerLibraryUid:
                                vendorApiSelectionMode === 'default'
                                    ? recommendedVendorApiVersion?.value
                                    : currentSelectedApiVersionUid,
                            connectionUid: currentSelectedConnectionUid,
                            path: currentPath,
                            uid,
                        })
                    }
                >
                    Save
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <StyledBorderBoxTitleContainer>
                        <IconCircle icon={<ProductIcon name={appName} />} size="medium" />
                        <Typography variant="h6" component="h4">
                            {`Configure ${appName} API connection`}
                        </Typography>
                    </StyledBorderBoxTitleContainer>
                </StyledBorderBoxHeader>
                <Divider />
                {errors && <Alert severity="error" title={errors} />}
                <StyledBorderBoxContent>
                    <StyledBorderBoxTitleContainer>
                        <Typography color={typographyColor} variant="subtitle2">
                            Select connector
                        </Typography>
                        <InfoIcon tooltip="Connector is used to make sure proper authentication headers are passed along when you use API connection." />
                    </StyledBorderBoxTitleContainer>
                    <Dropdown
                        disabled={templateMode || workspaceLocked}
                        fullWidth
                        items={connections.map((c) => ({
                            icon: <ProductIcon name={appName} />,
                            name: c.name,
                            value: c.uid,
                        }))}
                        label="Uses connector"
                        required
                        selectedItem={currentSelectedConnectionUid}
                        onSelectItem={(value) => setCurrentSelectedConnectionUid(value)}
                    />

                    <StyledBorderBoxTitleContainer>
                        <Typography color={typographyColor} variant="subtitle2">
                            Define API path
                        </Typography>
                        <InfoIcon tooltip="Path is a unique identifier and is also used to import API connection in scripts for consumption." />
                    </StyledBorderBoxTitleContainer>
                    <TextField
                        disabled={templateMode || workspaceLocked}
                        fullWidth
                        inputRef={inputRef}
                        label="Path"
                        placeholder="Enter import path"
                        required
                        startIcon={
                            <>
                                <LinkOutlinedIcon />
                                <StyledTextAdornment>./api/</StyledTextAdornment>
                            </>
                        }
                        value={currentPath}
                        onChange={(e) => setCurrentPath(e.target.value)}
                    />

                    {multipleVendorApiVersions ? (
                        !templateMode ? (
                            <>
                                <StyledBorderBoxTitleContainer>
                                    <Typography color={typographyColor} variant="subtitle2">
                                        Define vendor API version
                                    </Typography>
                                    <InfoIcon tooltip="Choose the vendor API version you want the API connection to use." />
                                </StyledBorderBoxTitleContainer>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={vendorApiSelectionMode === 'default'}
                                                    onClick={() => setVendorApiSelectionMode('default')}
                                                />
                                            }
                                            label="Use default vendor API version"
                                        />
                                        {!templateMode && <InformationChip label="Recommended" severity="success" />}
                                    </StyledBorderBoxHeaderSmall>
                                </StyledBorderBox>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={vendorApiSelectionMode === 'custom'}
                                                    disabled={workspaceLocked}
                                                    onClick={() => setVendorApiSelectionMode('custom')}
                                                />
                                            }
                                            label="Use custom vendor API version"
                                        />
                                        <InformationChip label="Advanced" severity="info" />
                                    </StyledBorderBoxHeaderSmall>
                                    <Divider />
                                    <StyledBorderBoxContent>
                                        <Dropdown
                                            disabled={vendorApiSelectionMode === 'default' || workspaceLocked}
                                            inputRef={selectRef}
                                            items={vendorApiVersions.map((v) => ({ name: v.name, value: v.value }))}
                                            label="Select vendor API version"
                                            selectedItem={currentSelectedApiVersionUid}
                                            onSelectItem={(value) => setCurrentSelectedApiVersionUid(value)}
                                        />
                                    </StyledBorderBoxContent>
                                </StyledBorderBox>
                            </>
                        ) : (
                            <>
                                <StyledBorderBoxTitleContainer>
                                    <Typography color={typographyColor} variant="subtitle2">
                                        Define vendor API version
                                    </Typography>
                                    <InfoIcon tooltip="Choose the vendor API version you want the API connection to use." />
                                </StyledBorderBoxTitleContainer>
                                <Dropdown
                                    disabled
                                    fullWidth
                                    items={vendorApiVersions.map((v) => ({ name: v.name, value: v.value }))}
                                    label="Select vendor API version"
                                    selectedItem={selectedApiVersionUid}
                                />
                            </>
                        )
                    ) : null}
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
