import { useObservableState } from 'observable-hooks';
import {
    scheduleBuilderDialogOpen$,
    changeUnsavedCronExpressionAction$,
} from '../../../store/workspace/scheduled-trigger';
import { CronBuilderDialog } from '../../../components/setup-guide/triggers/scheduled-triggers/CronBuilderDialog';

export const CronBuilderDialogContainer: React.FC = () => {
    const open = useObservableState(scheduleBuilderDialogOpen$);

    return (
        <CronBuilderDialog
            open={open}
            onCancel={() => scheduleBuilderDialogOpen$.next(false)}
            onConfirm={(cron) => changeUnsavedCronExpressionAction$.next(cron)}
        />
    );
};
