/* eslint-disable sonarjs/no-duplicate-string */
import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CustomIconButtonProps, IconButton } from '../../common/buttons/IconButton';
import { dragOverlayBoxShadow, environmentVariableDivider } from './utils';
import { useDroppable } from '@dnd-kit/core';

type DragButtonProps = Omit<CustomIconButtonProps, 'aria-label' | 'border' | 'icon' | 'tooltip'>;

export const StyledVariableWrapper = styled('div')<{ isDragging?: boolean; nested?: boolean }>(
    ({ isDragging, nested, theme }) => ({
        height: isDragging ? 0 : 'auto',
        marginTop: isDragging ? (nested ? theme.spacing(-1) : theme.spacing(-1.5)) : 0,
        maxWidth: 1000,
        visibility: isDragging ? 'hidden' : 'visible',
        width: '100%',
        '& *': {
            visibility: isDragging ? 'hidden' : 'visible',
        },
    })
);

export const StyledDropArea = styled('div')<{ height: number; nested?: boolean; visible?: boolean }>(
    ({ height = 0, nested, visible, theme }) => ({
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.constants.borderRadius,
        height: visible ? height : 0,
        marginBottom: visible ? (nested ? theme.spacing(1) : theme.spacing(1.5)) : 0,
        width: '100%',
    })
);

export const StyledVariable = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: environmentVariableDivider(theme),
    borderRadius: theme.constants.borderRadius,
    '&.dragOverlay': {
        boxShadow: dragOverlayBoxShadow,
    },
}));

export const StyledHeaderActions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

export const StyledReadOnlyVariableHeader = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 1.5),
    '&>.MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const StyledReadOnlyExpandButton = styled(IconButton)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(0.5, 1),
}));

export const StyledReadOnlyRow = styled('div')(({ theme }) => ({
    alignItems: 'stretch',
    borderTop: environmentVariableDivider(theme),
    display: 'flex',
    gap: theme.spacing(1.5),
    justifyContent: 'space-between',
    minHeight: 30,
    width: '100%',
}));

const StyledReadOnlyField = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: theme.spacing(0.5, 1),
    wordBreak: 'break-word',
}));

export const StyledReadOnlyKeyField = styled(StyledReadOnlyField)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    flex: '100px 0 0',
}));

export const StyledReadOnlyValueField = styled(StyledReadOnlyField)(() => ({
    flexGrow: '1',
}));

export const StyledReadOnlyMultipleValuesField = styled(StyledReadOnlyField)(({ theme }) => ({
    display: 'flex',
    flexGrow: '1',
    gap: theme.spacing(1),
}));

export const StyledReadOnlyListItem = styled(Chip)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.action.selected,
    borderRadius: theme.constants.borderRadius,
    fontSize: theme.typography.body1.fontSize,
    height: 'unset',
    padding: theme.spacing(0.1, 0),
}));

export const StyledEditModeVariableHeader = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    '&>.MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const StyledEditModeRequiredButton = styled(ToggleButton)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0, 1),
    textTransform: 'none',
    '&:not(.Mui-selected)': {
        color: theme.palette.text.secondary,
    },
}));

export const StyledEditModeContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    '& .MuiFormControl-root': {
        margin: 0,
        width: '100%',
        '& .MuiInputBase-root, & .MuiButtonBase-root': {
            maxWidth: 'unset',
            width: '100%',
        },
    },
}));

export const StyledEditModeDivider = styled(Divider)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    '&:before': {
        width: 0,
    },
    '& span': {
        paddingLeft: 0,
    },
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
        textTransform: 'uppercase',
    },
}));

export const StyledEditModeChoicesContainer = styled('div')(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 0),
    width: '100%',
}));

export const StyledEditModeActions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderTop: environmentVariableDivider(theme),
    gap: theme.spacing(1.5),
    justifyContent: 'flex-end',
    padding: theme.spacing(1.5),
}));

export const StyledGhostVariable = styled('div')(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    height: 100,
    width: '100%',
}));

const StyledBorderedArea = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
}));

const StyledEmptyWarning = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.warning.main,
        height: 20,
        marginRight: theme.spacing(1),
        width: 20,
    },
}));

const StyledDragButton = styled(IconButton)(() => ({
    cursor: 'all-scroll',
}));

export const emptyWarning = (required = false, folder = false): JSX.Element => (
    <StyledEmptyWarning>
        {required && <WarningAmberIcon />}
        <Typography>{folder ? 'FOLDER NAME MISSING' : 'EMPTY'}</Typography>
    </StyledEmptyWarning>
);

export const GhostVariable: React.FC<{
    id: string;
    nested?: boolean;
}> = ({ id, nested }) => {
    const { active, isOver, setNodeRef } = useDroppable({ id });

    const height = (active?.data.current?.height ?? 0) as number;

    return (
        <StyledVariableWrapper ref={setNodeRef}>
            <StyledDropArea height={height} nested={nested} visible={isOver} />
            <StyledGhostVariable>
                {nested && (
                    <StyledBorderedArea>
                        <DragIndicatorIcon />
                        <Typography>Drag parameters into folder</Typography>
                    </StyledBorderedArea>
                )}
            </StyledGhostVariable>
        </StyledVariableWrapper>
    );
};

export const DragButton: React.FC<DragButtonProps> = (props) => {
    return (
        <StyledDragButton
            aria-label="Drag parameter"
            icon={<DragIndicatorIcon />}
            tooltip="Drag parameter"
            {...props}
        />
    );
};
