import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '../../../common/buttons/IconButton';
import { ProductIcon } from '../../../icons/ProductIcon';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import { StatusChip } from '../../../common/chips/StatusChip';
import {
    StyledListItem,
    StyledListItemActions,
    StyledListItemBottomTextContainer,
    StyledListItemLeftContainer,
    StyledListItemRightContainer,
    StyledListItemWarningContainer,
} from '../../SetupGuideComponents';
import { SetupGuideEventListener } from '../../types';

export interface SetupGuideEventListenerListItemProps extends SetupGuideEventListener {
    templateMode: boolean;
    workspaceLocked: boolean;
    onDelete: (uid: string) => void;
    onEdit: (uid: string) => void;
}

export const SetupGuideEventListenerListItem: React.FC<SetupGuideEventListenerListItemProps> = ({
    appName,
    deleting,
    group,
    needsSetup = false,
    scriptName,
    templateMode,
    uid,
    workspaceLocked,
    onEdit,
    onDelete,
}) => {
    return (
        <StyledListItem needsSetup={needsSetup}>
            <StyledListItemLeftContainer>
                <SetupGuideIndicator
                    left={{ icon: <HearingOutlinedIcon /> }}
                    right={{ icon: <ProductIcon name={appName} />, state: needsSetup ? 'needsSetup' : 'active' }}
                />
                <Box>
                    <Typography>{appName + (group ? ` (${group})` : '')}</Typography>
                    <StyledListItemBottomTextContainer>
                        <CodeOutlinedIcon />
                        <Typography>Script:</Typography>
                        <Typography color="primary.main">{scriptName}</Typography>
                    </StyledListItemBottomTextContainer>
                    {needsSetup && (
                        <StyledListItemWarningContainer>
                            <WarningAmberOutlinedIcon />
                            <Typography>Complete Event Listener setup</Typography>
                        </StyledListItemWarningContainer>
                    )}
                </Box>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    {templateMode ? (
                        <IconButton
                            aria-label={needsSetup ? 'Edit' : 'Review' + ' event listener'}
                            disabled={deleting}
                            icon={needsSetup ? <EditOutlinedIcon /> : <PreviewOutlinedIcon />}
                            size="small"
                            tooltip={needsSetup ? 'Edit' : 'Review' + ' event listener'}
                            onClick={() => onEdit(uid)}
                        />
                    ) : (
                        <>
                            <IconButton
                                aria-label="Edit event listener"
                                disabled={deleting}
                                icon={<EditOutlinedIcon />}
                                size="small"
                                tooltip="Edit event listener"
                                onClick={() => onEdit(uid)}
                            />
                            <IconButton
                                aria-label="Delete event listener"
                                busy={deleting || workspaceLocked}
                                icon={<DeleteOutlineOutlinedIcon />}
                                size="small"
                                tooltip="Delete event listener"
                                onClick={() => onDelete(uid)}
                            />
                        </>
                    )}
                </StyledListItemActions>
                {needsSetup && <StatusChip icon={<UnpublishedOutlinedIcon />} label="Incomplete" />}
            </StyledListItemRightContainer>
        </StyledListItem>
    );
};
