import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

interface LoadingSpinnerProps extends CircularProgressProps {
    size?: 'small' | 'medium';
}

const StyledWrapper = styled(Box)<{ size: 'small' | 'medium' }>(({ size }) => {
    const dimensions = size === 'small' ? 24 : 40;

    return {
        height: dimensions,
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: dimensions,
        '& span': {
            height: dimensions,
            width: dimensions,
        },
    };
});

const StyledSpinner = styled(CircularProgress)<{ size: 'small' | 'medium' }>(({ size }) => {
    const dimensions = size === 'small' ? 24 : 40;

    return {
        height: dimensions,
        width: dimensions,
    };
});

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 'medium', ...props }) => {
    return (
        <StyledWrapper size={size}>
            <StyledSpinner {...props} aria-label="loading" role="progressbar" size={size} />
        </StyledWrapper>
    );
};
