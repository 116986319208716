import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../../router';
import { loadErrorPage } from '../../../store/error';
import { loadWorkspaceSetupGuideStep } from '../../../store/setup-guide';
import { selectedEnvironmentUid$ } from '../../../store/workspace';
import { loadWorkspaceEnvironments, loadWorkspaceResources } from '../../../store/workspace/utils';

export const setupGuideRunStepLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'RUN');

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load run step.',
        });

        throw e;
    }

    return {};
};
