import { useObservableState } from 'observable-hooks';
import {
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideProgressAction$,
    selectedWorkspaceSetupGuideStep$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../../store/workspace';
import { Language } from '../../../components/workspace-dialogs/language-selector';
import { getPreviousEnabledStep } from '../../../utils/setupGuide';
import { SetupGuideRunScreen } from '../../../components/setup-guide/run-screen/SetupGuideRunScreen';
import {
    eventListenerExecutionInProgress$,
    triggerEventListenerAction$,
} from '../../../store/workspace/event-listener';
import { scriptExecutionInProgress$, triggerScriptAction$ } from '../../../store/workspace/script';

export const SetupGuideRunScreenContainer: React.FC = () => {
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const runningEventListeners = useObservableState(eventListenerExecutionInProgress$);
    const runningScripts = useObservableState(scriptExecutionInProgress$);
    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const errors = useObservableState(workspaceSetupGuideStepError$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);

    const { eventListeners, scheduledTriggers, scripts } = useObservableState(selectedWorkspaceResources$);

    const eventListenerScriptUids = eventListeners.map((el) => el.script?.uid);
    const scheduledTriggerScriptUids = scheduledTriggers.map((st) => st.script?.uid);

    const filteredScripts = scripts.filter(
        (s) => !eventListenerScriptUids.includes(s.uid) && !scheduledTriggerScriptUids.includes(s.uid)
    );

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    return (
        <SetupGuideRunScreen
            errors={errors}
            eventListeners={eventListeners.map((el) => ({
                appName: el.appName,
                running: runningEventListeners[el.uid],
                scriptName: el.script?.name,
                uid: el.uid,
            }))}
            language={selectedWorkspace?.language as Language}
            nextStepName={nextStep?.title}
            saving={saving}
            scheduledTriggers={scheduledTriggers.map((st) => ({
                cron: st.cronExpression,
                running: runningScripts[st.script?.uid ?? ''],
                scriptName: st.script?.name,
                scriptUid: st.script?.uid,
            }))}
            scripts={filteredScripts.map((s) => ({
                path: s.name,
                running: runningScripts[s.uid],
                uid: s.uid,
            }))}
            onEdit={(stepName) => navigateToWorkspaceSetupGuideStepAction$.next(stepName)}
            workspace={{
                apps: {
                    incoming: selectedWorkspace?.incomingApps ?? [],
                    outgoing: selectedWorkspace?.outgoingApps ?? [],
                },
                name: selectedWorkspace?.name ?? '',
            }}
            workspaceLocked={!!selectedWorkspace?.locked}
            onContinue={() =>
                saveWorkspaceSetupGuideProgressAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
            onRunEventListener={(uid) => triggerEventListenerAction$.next(uid)}
            onRunScheduledTrigger={(scriptUid) => triggerScriptAction$.next(scriptUid ?? '')}
            onRunScript={(uid) => triggerScriptAction$.next(uid)}
        />
    );
};
