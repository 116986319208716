import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { IconButton } from '../../common/buttons/IconButton';
import { SetupGuideIndicator } from '../SetupGuideIndicator';
import {
    StyledListItem,
    StyledListItemActions,
    StyledListItemLeftContainer,
    StyledListItemRightContainer,
} from '../SetupGuideComponents';
import { Language } from '../../workspace-dialogs/language-selector';
import { SetupGuideScript } from '../types';

export interface SetupGuideScriptListItemProps extends SetupGuideScript {
    language: Language;
    templateMode: boolean;
    workspaceLocked: boolean;
    onDelete: (uid: string) => void;
    onEdit: (uid: string) => void;
}

export const SetupGuideScriptListItem: React.FC<SetupGuideScriptListItemProps> = ({
    deleting = false,
    language,
    path,
    templateMode,
    uid,
    workspaceLocked,
    onEdit,
    onDelete,
}) => {
    return (
        <StyledListItem>
            <StyledListItemLeftContainer>
                <SetupGuideIndicator
                    left={{ icon: <CodeOutlinedIcon /> }}
                    right={{
                        icon: (
                            <Box
                                alt={(language ? language : 'Typescript') + ' icon'}
                                component="img"
                                src={process.env.PUBLIC_URL + `/${language !== 'js' ? 'ts' : 'js'}.png`}
                            />
                        ),
                    }}
                />
                <Typography>{path}</Typography>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    {templateMode ? (
                        <IconButton
                            aria-label="Review script"
                            data-pendo={'viewScript'}
                            icon={<PreviewOutlinedIcon />}
                            size="small"
                            tooltip="Review script"
                            onClick={() => onEdit(uid)}
                        />
                    ) : (
                        <>
                            <IconButton
                                aria-label="Edit script"
                                data-pendo={'editScript'}
                                icon={<EditOutlinedIcon />}
                                size="small"
                                tooltip="Edit script"
                                onClick={() => onEdit(uid)}
                            />

                            <IconButton
                                aria-label="Delete script"
                                busy={deleting}
                                disabled={workspaceLocked}
                                icon={<DeleteOutlineOutlinedIcon />}
                                size="small"
                                tooltip="Delete script"
                                onClick={() => onDelete(uid)}
                            />
                        </>
                    )}
                </StyledListItemActions>
            </StyledListItemRightContainer>
        </StyledListItem>
    );
};
