import { DialogTitle, styled, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import { Button } from '../../../common/buttons/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { ChangeOrganizationPlanConfirmationContent } from '../change-organization-plan-wizard/ChangeOrganizationPlanConfirmationContent';
import { OrganizationPlan } from '../../../../data/organization';

interface CancelOrganizationPlanSuccessStateDialogProps {
    open?: boolean;
    plan: OrganizationPlan;
    endDate?: Date;
    supportPortalUrl: string;
    onContinue?: () => void;
}

const StyledDialogActions = styled(DialogActions)(() => ({
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    padding: theme.spacing(2, 0),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.background.default,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
}));

const StyledCheckCircleRoundedIcon = styled(CheckCircleRoundedIcon)(({ theme }) => ({
    color: theme.palette.success.light,
    fontSize: 72,
}));

const StyledContactUsAlert = styled(DialogAlert)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
}));

const StyledSubtitleSpan = styled('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(2),
    },
}));

export const CancelOrganizationPlanSuccessStateDialog: React.FC<CancelOrganizationPlanSuccessStateDialogProps> = ({
    open = false,
    plan,
    endDate,
    supportPortalUrl,
    onContinue,
}) => {
    const theme = useTheme();

    return (
        <StyledDialog open={open}>
            <StyledTitleContainer>
                <StyledCheckCircleRoundedIcon />
                <StyledDialogTitle variant="h4">Your plan has been cancelled</StyledDialogTitle>
                <DialogTitle variant="body1">We successfully cancelled your team plan</DialogTitle>
            </StyledTitleContainer>
            <DialogContent>
                <StyledCard elevation={1}>
                    <ChangeOrganizationPlanConfirmationContent
                        plan={plan}
                        listIcon={<HighlightOffIcon />}
                        listIconColor={theme.palette.text.primary}
                        cancellationDateIconColor={theme.palette.text.primary}
                        cancellationDate={endDate}
                    />
                </StyledCard>
                <StyledContactUsAlert
                    severity="info"
                    text={<StyledSubtitleSpan>We can resolve any issues by email, text or phone</StyledSubtitleSpan>}
                    title="How can we help?"
                    icon={false}
                    action={
                        <Button variant="outlined" onClick={() => window.open(supportPortalUrl, '_blank')}>
                            Contact us
                        </Button>
                    }
                />
            </DialogContent>
            <StyledDialogActions>
                <Button variant="contained" onClick={onContinue}>
                    Continue
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};
