import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AnonymousUsers, AnonymousUsersReportRequest } from '../../data/report/users';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';

export interface AnonymousUsersReportPageProps extends AnonymousUsersReportRequest {
    users: AnonymousUsers;
    onSearch(
        referrerSite: string,
        referrerSiteComparator: string,
        ip: string,
        origin: string,
        utmMedium: string,
        utmSource: string,
        utmCampaign: string,
        orderByField: string,
        orderByDirection: string
    ): void;
}

const DEFAULT_COMPARATOR = 'equals';

export const AnonymousUsersReportPage: React.FC<AnonymousUsersReportPageProps> = ({
    users,
    referrerSite = '',
    referrerSiteComparator = 'equals',
    ip = '',
    origin = '',
    utmMedium = '',
    utmSource = '',
    utmCampaign = '',
    orderByField = 'registeredDate',
    orderByDirection = 'asc',
    onSearch,
}) => {
    const [referrerSiteFilterValue, setReferrerSiteFilterValue] = useState('');
    const [referrerSiteFilterComparator, setReferrerSiteComparator] = useState('equals');

    const [ipFilterValue, setIpFilterValue] = useState('');
    const [originFilterValue, setOriginFilterValue] = useState('');
    const [utmMediumFilterValue, setUtmMediumFilterValue] = useState('');
    const [utmSourceFilterValue, setUtmSourceFilterValue] = useState('');
    const [utmCampaignFilterValue, setUtmCampaignFilterValue] = useState('');

    const [orderByFieldValue, setOrderByFieldValue] = useState('registeredDate');
    const [orderByDirectionValue, setOrderByDirectionValue] = useState('asc');

    useEffect(() => {
        setReferrerSiteFilterValue(referrerSite);
        setReferrerSiteComparator(referrerSiteComparator);
        setIpFilterValue(ip);
        setOriginFilterValue(origin);
        setUtmMediumFilterValue(utmMedium);
        setUtmSourceFilterValue(utmSource);
        setUtmCampaignFilterValue(utmCampaign);
        setOrderByFieldValue(orderByField);
        setOrderByDirectionValue(orderByDirection);
    }, []);

    const handleSearch = (): void =>
        onSearch(
            referrerSiteFilterValue,
            referrerSiteFilterComparator,
            ipFilterValue,
            originFilterValue,
            utmMediumFilterValue,
            utmSourceFilterValue,
            utmCampaignFilterValue,
            orderByFieldValue,
            orderByDirectionValue
        );

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Anonymous Users</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl size="small">
                        <TextField
                            label="ReferrerSite"
                            variant="outlined"
                            value={referrerSiteFilterValue}
                            onChange={(event) => setReferrerSiteFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="ReferrerSite Comparator"
                            value={referrerSiteFilterComparator}
                            onChange={(event) => setReferrerSiteComparator(event.target.value)}
                        >
                            <MenuItem value="equals">Equals</MenuItem>
                            <MenuItem value="contains">Contains</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Ip"
                            variant="outlined"
                            value={ipFilterValue}
                            onChange={(event) => setIpFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField select variant="outlined" label="Ip Comparator" value={DEFAULT_COMPARATOR}>
                            <MenuItem value="equals">Equals</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Origin"
                            variant="outlined"
                            value={originFilterValue}
                            onChange={(event) => setOriginFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField select variant="outlined" label="Origin Comparator" value={DEFAULT_COMPARATOR}>
                            <MenuItem value="equals">Equals</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Medium"
                            variant="outlined"
                            value={utmMediumFilterValue}
                            onChange={(event) => setUtmMediumFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField select variant="outlined" label="Utm Medium Comparator" value={DEFAULT_COMPARATOR}>
                            <MenuItem value="equals">Equals</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Source"
                            variant="outlined"
                            value={utmSourceFilterValue}
                            onChange={(event) => setUtmSourceFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField select variant="outlined" label="Utm Source Comparator" value={DEFAULT_COMPARATOR}>
                            <MenuItem value="equals">Equals</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Campaign"
                            variant="outlined"
                            value={utmCampaignFilterValue}
                            onChange={(event) => setUtmCampaignFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField select variant="outlined" label="Utm Campaign Comparator" value={DEFAULT_COMPARATOR}>
                            <MenuItem value="equals">Equals</MenuItem>
                        </TextField>
                    </FormControl>
                </div>

                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Order By"
                            value={orderByFieldValue}
                            onChange={(event) => setOrderByFieldValue(event.target.value)}
                        >
                            <MenuItem value="registeredDate">Registered Date</MenuItem>
                            <MenuItem value="ip">IP Address</MenuItem>
                            <MenuItem value="origin">Origin</MenuItem>
                            <MenuItem value="utmMedium">Utm Medium</MenuItem>
                            <MenuItem value="utmSource">Utm Source</MenuItem>
                            <MenuItem value="utmCampaign">Utm Campaign</MenuItem>
                            <MenuItem value="referrerSite">ReferrerSite</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Order By Direction"
                            value={orderByDirectionValue}
                            onChange={(event) => setOrderByDirectionValue(event.target.value)}
                        >
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="desc">Descending</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>Total Anonymous Users: {users.length}</Typography>
                </Box>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            {users.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell sx={{ minWidth: 160 }}>Registered Date</TableCell>
                            <TableCell>Origin</TableCell>
                            <TableCell>Referred From</TableCell>
                            <TableCell>IP Address</TableCell>
                            <TableCell>Campaign</TableCell>
                            <TableCell>Medium</TableCell>
                            <TableCell>Source</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.uid} id={user.uid}>
                                <TableCell>{user.uid}</TableCell>
                                <TableCell>{new Date(user.registeredDate).toLocaleString()}</TableCell>
                                <TableCell>{user.origin}</TableCell>
                                <TableCell>{user.referrerSite}</TableCell>
                                <TableCell>{user.ip}</TableCell>
                                <TableCell>{user.utmCampaign}</TableCell>
                                <TableCell>{user.utmMedium}</TableCell>
                                <TableCell>{user.utmSource}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>No Anonymous Users found</div>
            )}
        </PageContainer>
    );
};
