import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { InfoIcon } from '../icons/InfoIcon';

export const StyledSectionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    marginBottom: theme.spacing(3),
}));

export const StyledSectionHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
}));

export const StyledSectionMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
}));

export const StyledQuickActionsBlockContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    padding: theme.spacing(2),
    width: 325,
    height: 331,
}));

export const StyledQuickActionsBlockImageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 293,
}));

export const StyledUsageBlockContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: 260,
    height: 135,
}));

export const StyledUsageBlockSubtitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignContent: 'center',
    padding: theme.spacing(1.5, 0, 1, 0),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    '& .MuiTypography-root': {
        lineHeight: 1.5,
    },
}));

export const StyledUsageDataText = styled(Typography)({
    fontSize: 16,
    lineHeight: 1.2,
});

export const StyledTeamChip = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    height: 36,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], // TODO: add missing color to a theme
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 1),
}));

export const StyledIconAndLabelChipContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 300,
    '& .MuiSvgIcon-root': {
        height: 24,
    },
});

export const StyledTimestampBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    placeItems: 'center',
    border: '1px solid ' + theme.palette.divider,
    borderRadius: theme.constants.borderRadius,
    height: 36,
    padding: theme.spacing(0, 1),
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(0),
    width: '100%',
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(1),
    width: '100%',
    padding: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
        '&:last-of-type:not(.productIcon)': {
            height: 12,
            width: 12,
            marginLeft: 'auto',
        },
    },
    whiteSpace: 'nowrap',
}));
