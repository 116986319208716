import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { EmptyState } from '../../../common/EmptyState';
import { IconButton } from '../../../common/buttons/IconButton';
import { SetupGuideScheduledTriggerListItem } from './SetupGuideScheduledTriggerListItem';
import { StyledStepContainer } from '../../SetupGuideComponents';
import { SetupGuideSummaryBox } from '../../SetupGuideSummaryBox';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../../../common/LayoutComponents';
import { SetupGuideScheduledTrigger } from '../../types';
import { cronFormatDocumentationUrl } from '../../../../utils/documentation';

interface SetupGuideScheduledTriggerScreenProps {
    errors?: string;
    scheduledTriggers: SetupGuideScheduledTrigger[];
    templateMode?: boolean;
    workspaceLocked: boolean;
    onCreateNewScheduledTrigger: () => void;
    onDeleteScheduledTrigger: (uid: string) => void;
    onEditScheduledTrigger: (uid: string) => void;
}

export const SetupGuideScheduledTriggerScreen: React.FC<SetupGuideScheduledTriggerScreenProps> = ({
    errors,
    scheduledTriggers = [],
    templateMode = false,
    workspaceLocked,
    onCreateNewScheduledTrigger,
    onDeleteScheduledTrigger,
    onEditScheduledTrigger,
}) => {
    const needsSetup = scheduledTriggers?.some((st) => st.needsSetup);
    const paragraphs = [
        'To automate recurring tasks, you can use a scheduled trigger to execute actions at specific time intervals or on a predefined schedule.',
        <Typography key="paragraph-with-link" color="text.secondary">
            When you create a new scheduled trigger, you'll be asked to provide a schedule using the schedule builder or
            using a{' '}
            <Link href={cronFormatDocumentationUrl} target="_blank">
                CRON format
            </Link>
            , as well as a script to trigger.
        </Typography>,
    ];
    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox paragraphs={paragraphs} title="What is a scheduled trigger?" />
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        Scheduled Triggers
                    </Typography>
                    {!templateMode && (
                        <IconButton
                            aria-label="Create a new scheduled trigger"
                            icon={<AddOutlinedIcon />}
                            tooltip="Create a new scheduled trigger"
                            onClick={() => onCreateNewScheduledTrigger()}
                        />
                    )}
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {scheduledTriggers.length ? (
                        <>
                            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
                            {needsSetup && (
                                <Alert
                                    severity="warning"
                                    text={
                                        <strong>
                                            One or more elements are incomplete. Please review the following:
                                        </strong>
                                    }
                                />
                            )}
                            {scheduledTriggers.map((el) => (
                                <SetupGuideScheduledTriggerListItem
                                    key={el.uid}
                                    cron={el.cron}
                                    deleting={el.deleting}
                                    needsSetup={el.needsSetup}
                                    scriptName={el.scriptName}
                                    templateMode={templateMode}
                                    uid={el.uid}
                                    workspaceLocked={workspaceLocked}
                                    onDelete={onDeleteScheduledTrigger}
                                    onEdit={onEditScheduledTrigger}
                                />
                            ))}
                        </>
                    ) : (
                        <EmptyState
                            buttons={
                                !templateMode
                                    ? [
                                          <Button
                                              key="create-scheduled-trigger"
                                              disabled={workspaceLocked}
                                              onClick={onCreateNewScheduledTrigger}
                                          >
                                              Create scheduled trigger
                                          </Button>,
                                      ]
                                    : undefined
                            }
                            icon={<TimerOutlinedIcon />}
                            iconState={templateMode ? 'complete' : 'incomplete'}
                            subtitle={
                                !templateMode
                                    ? 'Create a scheduled trigger to start building your integration.'
                                    : 'Click on Continue to proceed to the next step.'
                            }
                            title={
                                templateMode ? 'No scheduled triggers in this template' : 'No scheduled triggers found'
                            }
                        />
                    )}
                </StyledBorderBoxContent>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
