import { useRef } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { IconCopyButton } from '../IconCopyButton';

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& .MuiButtonBase-root': {
        padding: theme.spacing(3),
    },
}));
const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

export const OutlinedReadOnlyTextField: React.FC<TextFieldProps> = (props) => {
    const input = useRef<HTMLInputElement>();

    return (
        <StyledTextFieldContainer>
            <StyledTextField {...props} inputRef={input} fullWidth margin="normal" variant="outlined" />
            <IconCopyButton value={`${props.value}`} />
        </StyledTextFieldContainer>
    );
};
