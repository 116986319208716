import { SetupGuideCongratulationsScreen } from '../../components/setup-guide/SetupGuideCongratulationsScreen';
import { useNavigate } from 'react-location';
import { getBasePath } from '../../utils/path';
import { useObservableState, useSubscription } from 'observable-hooks';
import { selectedEnvironmentUid$, selectedWorkspace$, selectedWorkspaceUid$ } from '../../store/workspace';
import { navigateToAdvancedViewAction$, workspaceSetupGuideModeUpdatedAction$ } from '../../store/setup-guide';
import { workspaceEditControlAssumedAction$ } from '../../store/workspaces';

export const SetupGuideCongratulationsScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);

    useSubscription(workspaceSetupGuideModeUpdatedAction$, (setupGuideType) => {
        if (!setupGuideType) {
            navigate({ to: `${getBasePath()}workspace/${selectedWorkspaceUid}/environment/${selectedEnvironmentUid}` });
        }
    });

    useSubscription(workspaceEditControlAssumedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: getBasePath() });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}guide/complete/workspace/${selectedWorkspaceUid}`,
                }),
            100
        );
    });

    return (
        <SetupGuideCongratulationsScreen
            workspaceLocked={!!selectedWorkspace?.locked}
            onBackToWorkspaces={() => navigate({ to: `${getBasePath()}workspaces` })}
            onContinue={() =>
                navigateToAdvancedViewAction$.next({ workspaceUid: selectedWorkspaceUid ?? '', ignoreWarnings: true })
            }
        />
    );
};
