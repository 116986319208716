import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Button } from '../../../common/buttons/Button';
import { SetupGuideIndicator } from '../../SetupGuideIndicator';
import {
    StyledListItemActions,
    StyledListItemBottomTextContainer,
    StyledListItemLeftContainer,
    StyledListItemRightContainer,
    StyledRunScreenListItem,
} from '../../SetupGuideComponents';
import { SetupGuideRunScreenScheduledTrigger } from '../../types';

export interface SetupGuideRunScreenScheduledTriggerListItemProps extends SetupGuideRunScreenScheduledTrigger {
    onRun: (scriptUid?: string) => void;
}

export const SetupGuideRunScreenScheduledTriggerListItem: React.FC<
    SetupGuideRunScreenScheduledTriggerListItemProps
> = ({ cron, disabled, running, scriptName, scriptUid, onRun }) => {
    return (
        <StyledRunScreenListItem>
            <StyledListItemLeftContainer>
                <SetupGuideIndicator left={{ icon: <TimerOutlinedIcon /> }} />
                <Box>
                    {cron && <Typography>{cron}</Typography>}
                    <StyledListItemBottomTextContainer>
                        <CodeOutlinedIcon />
                        <Typography>Script:</Typography>
                        <Typography color="primary.main">{scriptName}</Typography>
                    </StyledListItemBottomTextContainer>
                </Box>
            </StyledListItemLeftContainer>
            <StyledListItemRightContainer>
                <StyledListItemActions>
                    <Button
                        busy={running}
                        disabled={disabled}
                        startIcon={<PlayCircleOutlineIcon />}
                        variant="text"
                        onClick={() => onRun(scriptUid)}
                    >
                        Run Scheduled Trigger
                    </Button>
                </StyledListItemActions>
            </StyledListItemRightContainer>
        </StyledRunScreenListItem>
    );
};
