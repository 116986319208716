import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
    TimeoutError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface BitbucketOnPremiseConnectionDetails extends ConnectionDetails {
    url?: string;
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface BitbucketOnPremiseAppSetupSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export const bitbucketOnPremiseManageConnectionOpenDialogAction$ = monitor(
    'bitbucketOnPremiseManageConnectionOpenDialogAction$',
    new Subject<BitbucketOnPremiseConnectionDetails>()
);
export const bitbucketOnPremiseManageConnectionCloseDialogAction$ = monitor(
    'bitbucketOnPremiseManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const bitbucketOnPremiseAppSetupOpenDialogAction$ = monitor(
    'bitbucketOnPremiseAppSetupOpenDialogAction$',
    new Subject<BitbucketOnPremiseConnectionDetails>()
);
export const bitbucketOnPremiseAppSetupCloseDialogAction$ = monitor(
    'bitbucketOnPremiseAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const bitbucketOnPremiseAppSetupDialogOpen$ = monitor(
    'bitbucketOnPremiseAppSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const bitbucketOnPremiseAppSetupAuthorizeAction$ = monitor(
    'bitbucketOnPremiseAppSetupAuthorizeAction$',
    new Subject<BitbucketOnPremiseAppSetupSaveProps>()
);
export const bitbucketOnPremiseAppSetupDialogErrors$ = monitor(
    'bitbucketOnPremiseAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const bitbucketOnPremiseSaveConnectionAction$ = monitor(
    'bitbucketOnPremiseSaveConnectionAction$',
    new Subject<string>()
);
export const bitbucketOnPremiseConnectionCreatedAction$ = monitor(
    'bitbucketOnPremiseConnectionCreatedAction$',
    new Subject<string>()
);
export const bitbucketOnPremiseConnectionSavedAction$ = monitor(
    'bitbucketOnPremiseConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const bitbucketOnPremiseManageConnectionInitiateSetupAction$ = monitor(
    'bitbucketOnPremiseManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const bitbucketOnPremiseManageConnectionDialogOpen$ = monitor(
    'bitbucketOnPremiseManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const bitbucketOnPremiseConnectionSaving$ = monitor(
    'bitbucketOnPremiseConnectionSaving$',
    new BehaviorSubject(false)
);
export const bitbucketOnPremiseManageConnectionAuthorizeLoading$ = monitor(
    'bitbucketOnPremiseManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const bitbucketOnPremiseManageConnectionDetails$ = monitor(
    'bitbucketOnPremiseManageConnectionDetails$',
    new BehaviorSubject<BitbucketOnPremiseConnectionDetails | undefined>(undefined)
);
export const bitbucketOnPremiseManageConnectionDialogErrors$ = monitor(
    'bitbucketOnPremiseManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const bitbucketOnPremiseAppSetupDialogStage$ = monitor(
    'bitbucketOnPremiseAppSetupDialogStage$',
    new BehaviorSubject(0)
);

bitbucketOnPremiseAppSetupOpenDialogAction$.subscribe((details) => {
    bitbucketOnPremiseAppSetupDialogErrors$.next(undefined);
    bitbucketOnPremiseManageConnectionDialogErrors$.next(undefined);
    bitbucketOnPremiseAppSetupDialogOpen$.next(true);
    bitbucketOnPremiseAppSetupDialogStage$.next(0);
    bitbucketOnPremiseManageConnectionDetails$.next(details);
});

bitbucketOnPremiseAppSetupCloseDialogAction$.subscribe(() => {
    bitbucketOnPremiseAppSetupDialogOpen$.next(false);
    bitbucketOnPremiseManageConnectionAuthorizeLoading$.next(false);
});

bitbucketOnPremiseManageConnectionOpenDialogAction$.subscribe((details) => {
    bitbucketOnPremiseAppSetupDialogErrors$.next(undefined);
    bitbucketOnPremiseManageConnectionDialogErrors$.next(undefined);
    bitbucketOnPremiseManageConnectionDialogOpen$.next(true);
    bitbucketOnPremiseManageConnectionDetails$.next(details);
});

bitbucketOnPremiseManageConnectionCloseDialogAction$.subscribe(() => {
    bitbucketOnPremiseManageConnectionDialogOpen$.next(false);
    bitbucketOnPremiseAppSetupDialogOpen$.next(false);
    bitbucketOnPremiseManageConnectionDetails$.next(undefined);
});

bitbucketOnPremiseConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

bitbucketOnPremiseAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            bitbucketOnPremiseConnectionSaving$.next(true);
            bitbucketOnPremiseAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = bitbucketOnPremiseManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Bitbucket On-Premise Connector type not defined');
                }
                if (!uid) {
                    throw Error('Bitbucket On-Premise Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.bitbucketOnPremise?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Bitbucket On-premise Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'bitbucket-on-premise',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, bitbucketOnPremiseManageConnectionAuthorizeLoading$);

                bitbucketOnPremiseConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof TimeoutError) {
                    bitbucketOnPremiseAppSetupDialogErrors$.next(e.message);
                } else {
                    bitbucketOnPremiseAppSetupDialogErrors$.next(
                        'Failed to save Bitbucket On-Premise application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Bitbucket On-Premise application info', e);
                }
            }

            bitbucketOnPremiseConnectionSaving$.next(false);
            bitbucketOnPremiseManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

bitbucketOnPremiseSaveConnectionAction$
    .pipe(
        map(async (name) => {
            bitbucketOnPremiseConnectionSaving$.next(true);
            bitbucketOnPremiseManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = bitbucketOnPremiseManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Bitbucket On-Premise Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveBitbucketOnPremiseConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveBitbucketOnPremiseConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                bitbucketOnPremiseConnectionCreatedAction$.next(uid);
                                bitbucketOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
                                bitbucketOnPremiseManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    bitbucketOnPremiseManageConnectionDialogErrors$,
                                    APP.BITBUCKET_ON_PREMISE.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(
                    e,
                    bitbucketOnPremiseManageConnectionDialogErrors$,
                    APP.BITBUCKET_ON_PREMISE.NAME
                );
            }

            bitbucketOnPremiseConnectionSaving$.next(false);
        })
    )
    .subscribe();

bitbucketOnPremiseManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            bitbucketOnPremiseConnectionSaving$.next(true);
            bitbucketOnPremiseManageConnectionAuthorizeLoading$.next(true);
            bitbucketOnPremiseManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = bitbucketOnPremiseManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Bitbucket On-Premise Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    bitbucketOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
                    bitbucketOnPremiseAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    bitbucketOnPremiseConnectionCreatedAction$.next(uid);
                    bitbucketOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
                    bitbucketOnPremiseManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    bitbucketOnPremiseConnectionSaving$.next(false);

                    bitbucketOnPremiseAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(
                    e,
                    bitbucketOnPremiseManageConnectionDialogErrors$,
                    APP.BITBUCKET_ON_PREMISE.NAME
                );
                bitbucketOnPremiseManageConnectionAuthorizeLoading$.next(false);
            }

            bitbucketOnPremiseConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(bitbucketOnPremiseConnectionSavedAction$);

const saveBitbucketOnPremiseConnection = async (
    uid: string,
    name: string,
    connectionTypeUid: string
): Promise<void> => {
    try {
        await saveConnection(uid, name);

        bitbucketOnPremiseConnectionSavedAction$.next({ uid, connectionTypeUid });
        bitbucketOnPremiseManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, bitbucketOnPremiseManageConnectionDialogErrors$, APP.BITBUCKET_ON_PREMISE.NAME);
    }
};
