import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import ListItem from '@mui/material/ListItem';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';

interface StatuspageEventListenerSetupDialogProps {
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const StatuspageEventListenerSetupDialog: React.FC<StatuspageEventListenerSetupDialogProps> = ({
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Statuspage';

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={connectionTypeName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={connectionTypeName}
                onClose={onClose}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                <Typography>
                                    Log into your
                                    <Link target="_blank" href="https://manage.statuspage.io/">
                                        {' '}
                                        {connectionTypeName} instance
                                    </Link>
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    On the left side menu, make sure that you have selected the organization and page
                                    which you want to subscribe to.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click <strong>Subscribers</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Make sure that you have webhooks enabled. To do that click on{' '}
                                    <strong>Options</strong> to the right of the search box, select{' '}
                                    <strong>Settings</strong> and on the appearing modal, make sure that you have the{' '}
                                    <strong>Webhook</strong> option checked.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    To create a new webhook, click <strong>Options</strong> again and select{' '}
                                    <strong>Add subscriber</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Under <strong>Subscriber type</strong> select <strong>Webhook</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong>Endpoint URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            <ListItem>
                                <Typography>
                                    Enter any email into the <strong>Email address</strong> field. This is where{' '}
                                    {connectionTypeName} will send notifications should the endpoint fail.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Click <strong>Add subscriber</strong>.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
