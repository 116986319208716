import { styled } from '@mui/material';
import MuiButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { PropsWithChildren } from 'react';

interface CustomToggleButtonProps extends ToggleButtonProps {
    icon?: JSX.Element;
    fullWidth?: boolean;
    label: string;
}

export interface CustomButtonGroupProps extends ButtonGroupProps {
    buttons: CustomToggleButtonProps[];
    size?: 'small' | 'medium';
}

const StyledButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
    gap: theme.spacing(2),
}));

const StyledToggleButton = styled(MuiToggleButton, {
    shouldForwardProp: (prop) => prop !== 'buttonSize',
})<{
    buttonSize: 'small' | 'medium';
    fullWidth: boolean;
}>(({ buttonSize, fullWidth, theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    width: fullWidth ? '100%' : undefined,
    gap: theme.spacing(1),
    height: buttonSize === 'small' ? 30 : 40,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
}));

export const ButtonGroup: React.FC<PropsWithChildren<CustomButtonGroupProps>> = ({
    buttons,
    fullWidth = false,
    size = 'medium',
    ...props
}) => {
    return (
        <StyledButtonGroup fullWidth={fullWidth} size={size} {...props}>
            {buttons.map((b, i) => (
                <StyledToggleButton key={`button-group-button-${i}`} buttonSize={size} fullWidth={fullWidth} {...b}>
                    {b.icon}
                    {b.label}
                </StyledToggleButton>
            ))}
        </StyledButtonGroup>
    );
};
