import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../../router';
import { loadErrorPage } from '../../../store/error';
import { loadWorkspaceSetupGuideStep } from '../../../store/setup-guide';
import { selectedEnvironmentUid$ } from '../../../store/workspace';
import { loadEnvironmentVariables, loadWorkspaceEnvironments } from '../../../store/workspace/utils';

export const setupGuideEnvironmentVariableStepLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'ENVIRONMENT_VARIABLES');

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
        }

        await loadEnvironmentVariables(workspaceUid, selectedEnvironmentUid$.value ?? '');
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load parameters step.',
        });

        throw e;
    }

    return {};
};
