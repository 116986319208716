/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useRef, useState } from 'react';
import { Typography, styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button } from '../../common/buttons/Button';
import { StyledEditModeDivider, emptyWarning } from './EnvironmentVariableComponents';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { autoFocus } from '../../../utils/autoFocus';
import { handleKeyDown } from '../../../utils/handleKeyDown';
import { environmentVariableDivider } from './utils';

export interface UpdateEnvironmentVariableChoiceEvent {
    isDefault?: boolean;
    label?: string;
    value?: string;
}

export interface Choice {
    editDisabled?: boolean;
    editMode?: boolean;
    hasBeenEdited?: boolean;
    isDefault?: boolean;
    label?: string;
    selected?: boolean;
    type: 'MULTIPLE_CHOICES' | 'SINGLE_CHOICE';
    value?: string;
    variableReadOnlyMode?: boolean;
}

interface EnvironmentVariableChoiceDetailsProps extends Choice {
    existingValues?: string[];
    onDelete?(): void;
    onSelect?(): void;
    onToggleEditMode?(editMode: boolean): void;
    onUpdate?(event: UpdateEnvironmentVariableChoiceEvent): void;
}

const StyledChoice = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flex: '300px 1 1',
    minHeight: 40,
    maxWidth: 300,
    overflow: 'hidden',
}));

const StyledReadOnlyChoiceContent = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.spacing(0.5, 1.5),
    width: '100%',
}));

const StyledReadOnlyChoiceActions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
}));

const StyledReadOnlyChoiceLabel = styled('div')(({ theme }) => ({
    marginRight: 0,
    wordBreak: 'break-word',
    '& .MuiTypography-root:not(:last-of-type)': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.action.disabledBackground,
}));

const StyledEditModeChoiceContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    height: '100%',
    width: '100%',
    '&>.MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiFormControl-root': {
        margin: 0,
        width: '100%',
    },
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

const StyledDefaultValueControl = styled(FormControlLabel)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: environmentVariableDivider(theme),
    borderRadius: theme.constants.borderRadius,
    height: 40,
    margin: 0,
    '& .MuiCheckbox-root': {
        margin: 0,
    },
}));

const StyledEditModeActions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1.5, 1.5),
}));

export const EnvironmentVariableChoiceDetails: React.FC<EnvironmentVariableChoiceDetailsProps> = ({
    editDisabled = false,
    editMode = false,
    existingValues = [],
    hasBeenEdited = false,
    isDefault = false,
    label = '',
    selected = false,
    type,
    value = '',
    variableReadOnlyMode = false,
    onDelete,
    onSelect,
    onToggleEditMode,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentLabel, setCurrentLabel] = useState(label);
    const [currentValue, setCurrentValue] = useState(value);
    const [currentDefault, setCurrentDefault] = useState(isDefault);

    const labelInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        autoFocus(labelInputRef);
    }, [editMode]);

    useEffect(() => {
        setCurrentDefault(isDefault);
    }, [isDefault]);

    const handleCancel = (): void => {
        setCurrentLabel(label);
        setCurrentValue(value);
        setCurrentDefault(isDefault);
        onToggleEditMode?.(false);
    };

    const handleUpdate = (event?: React.MouseEvent | React.KeyboardEvent): void => {
        event?.stopPropagation();
        onUpdate?.({
            isDefault: currentDefault,
            label: currentLabel,
            value: currentValue,
        });
    };

    const hasChanged = currentDefault !== isDefault || currentLabel !== label || currentValue !== value;
    const isValueDuplicate = !!existingValues.length && !!currentValue && existingValues.includes(currentValue);

    const isLabelDuplicate =
        !!existingValues.length && !currentValue && !!currentLabel && existingValues.includes(currentLabel);

    const defaultValueSelectionLabel =
        type === 'MULTIPLE_CHOICES' ? 'Also use as a default value' : 'Also use as the default value';

    const canUpdate = !isValueDuplicate && !isLabelDuplicate && (!hasBeenEdited || hasChanged);

    return (
        <StyledChoice
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canUpdate && editMode,
                    enterFn: () => handleUpdate(event),
                    escFn: handleCancel,
                })
            }
        >
            {editMode && !variableReadOnlyMode ? (
                <>
                    <StyledEditModeChoiceContent>
                        <Typography>{`${hasBeenEdited ? 'Edit' : 'Create'} Choice`}</Typography>
                        <DialogAlert
                            sx={{ minWidth: 0 }}
                            scrollIntoViewDisabled
                            severity="warning"
                            text="If no value is specified, the label will be used as value instead."
                        />
                        <TextField
                            label="Label"
                            inputRef={labelInputRef}
                            required
                            placeholder="Enter label"
                            error={isLabelDuplicate}
                            helperText={
                                isLabelDuplicate
                                    ? 'This label is already used as a value for another choice'
                                    : !currentLabel
                                    ? 'Please enter label'
                                    : undefined
                            }
                            size="small"
                            value={currentLabel}
                            onChange={(e) => setCurrentLabel(e.target.value)}
                        />
                        <TextField
                            sx={{ marginBottom: 1 }}
                            label="Value"
                            placeholder="Enter value"
                            error={isValueDuplicate}
                            helperText={isValueDuplicate && 'Choice with this value already exists'}
                            size="small"
                            value={currentValue}
                            onChange={(e) => setCurrentValue(e.target.value)}
                        />
                        <StyledEditModeDivider>
                            <Typography>Developer Options</Typography>
                        </StyledEditModeDivider>
                        <StyledDefaultValueControl
                            control={
                                <Checkbox
                                    checked={currentDefault}
                                    onChange={() => setCurrentDefault(!currentDefault)}
                                />
                            }
                            label={defaultValueSelectionLabel}
                        />
                    </StyledEditModeChoiceContent>
                    <StyledEditModeActions>
                        <Button variant="outlined" size="small" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={!canUpdate} size="small" onClick={handleUpdate}>
                            {hasBeenEdited ? 'Update' : 'Create'}
                        </Button>
                    </StyledEditModeActions>
                </>
            ) : (
                <StyledReadOnlyChoiceContent>
                    {variableReadOnlyMode ? (
                        <FormControlLabel
                            disabled={true}
                            control={
                                type === 'SINGLE_CHOICE' ? (
                                    <Radio checked={selected} />
                                ) : (
                                    <Checkbox checked={selected} />
                                )
                            }
                            label={
                                !label && !value ? (
                                    emptyWarning(true)
                                ) : (
                                    <StyledReadOnlyChoiceLabel>
                                        <Typography>{label}</Typography>
                                        {value && <Typography>{value}</Typography>}
                                    </StyledReadOnlyChoiceLabel>
                                )
                            }
                        />
                    ) : (
                        <FormControlLabel
                            control={
                                type === 'SINGLE_CHOICE' ? (
                                    <Radio
                                        disabled={!value && !label}
                                        checked={selected}
                                        onChange={() => onSelect?.()}
                                    />
                                ) : (
                                    <Checkbox
                                        disabled={!value && !label}
                                        checked={selected}
                                        onChange={() => onSelect?.()}
                                    />
                                )
                            }
                            label={
                                !currentLabel && !currentValue ? (
                                    emptyWarning(true)
                                ) : (
                                    <StyledReadOnlyChoiceLabel>
                                        <Typography>{currentLabel}</Typography>
                                        {currentValue && <Typography>{currentValue}</Typography>}
                                    </StyledReadOnlyChoiceLabel>
                                )
                            }
                        />
                    )}
                    {isDefault && <StyledChip label="Default" />}
                    {!variableReadOnlyMode && (
                        <StyledReadOnlyChoiceActions>
                            <IconButton disabled={editDisabled} onClick={() => onToggleEditMode?.(true)}>
                                <EditOutlinedIcon />
                            </IconButton>
                            <IconButton disabled={editDisabled} onClick={() => onDelete?.()}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </StyledReadOnlyChoiceActions>
                    )}
                </StyledReadOnlyChoiceContent>
            )}
        </StyledChoice>
    );
};
