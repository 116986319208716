import { useObservableState } from 'observable-hooks';
import {
    closeNewApiKeyGeneratedDialogAction$,
    generatedApiKey$,
    loggedInUserDetails$,
    newApiKeyGeneratedDialogOpen$,
} from '../../../store/user';
import { NewApiKeyGeneratedDialog } from '../../../components/profile/api-keys/NewApiKeyGeneratedDialog';

export const NewApiKeyGeneratedDialogContainer: React.FC = () => {
    const open = useObservableState(newApiKeyGeneratedDialogOpen$);
    const userDetails = useObservableState(loggedInUserDetails$);
    const apiKey = useObservableState(generatedApiKey$);

    return (
        <NewApiKeyGeneratedDialog
            email={userDetails?.email ?? ''}
            apiKey={apiKey}
            open={open}
            onClose={() => closeNewApiKeyGeneratedDialogAction$.next()}
        />
    );
};
