import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { SetupGuideStepName } from './types';

export const getSetupGuideStepIcon = (step: SetupGuideStepName): JSX.Element => {
    switch (step) {
        case 'CONNECTIONS':
            return <PowerOutlinedIcon />;
        case 'API_HANDLERS':
            return <ApiOutlinedIcon />;
        case 'TRIGGERS':
            return <HearingOutlinedIcon />;
        case 'ENVIRONMENT_VARIABLES':
            return <TokenOutlinedIcon />;
        case 'SCRIPTS':
            return <CodeOutlinedIcon />;
        case 'REVIEW':
            return <PublishedWithChangesOutlinedIcon />;
        case 'RUN':
            return <TroubleshootOutlinedIcon />;
        default:
            return <HelpOutlinedIcon />;
    }
};
