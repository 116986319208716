import { useObservableState } from 'observable-hooks';

import {
    readmeFileHasUnsavedChanges$,
    revertReadmeFileChangesAction$,
    saveReadmeFileAction$,
    selectedReadmeFileContentChangedAction$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../../store/workspace/readme';
import { selectedEnvironmentUid$, selectedWorkspace$ } from '../../../store/workspace';
import { FullScreenReadme } from '../../../components/workspace-resources/readme/FullScreenReadme';

export const FullScreenReadmeContainer: React.FC = () => {
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const unsavedReadmeDetails = useObservableState(unsavedReadmeFileDetails$);
    const readmeHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);

    const readmeKey = `${selectedReadmeFileUid}_${selectedEnvironmentUid}`;

    return (
        <FullScreenReadme
            content={unsavedReadmeDetails[readmeKey]?.content ?? ''}
            hasUnsavedChanges={readmeHasUnsavedChanges[selectedReadmeFileUid ?? '']}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            onCancel={() => revertReadmeFileChangesAction$.next()}
            onChangeContent={(content) => selectedReadmeFileContentChangedAction$.next(content)}
            onSave={() => saveReadmeFileAction$.next()}
        />
    );
};
