import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../common/buttons/Button';
import { AppCard } from '../../common/AppCard';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import {
    PageContainer,
    PageUpperContainer,
    PageTitle,
    PageUpperGroup,
    PageViewButtonGroup,
    PageGridContainer,
} from '../../app-main/PageComponentsForDeprecation';
import { SelectorViewButton, viewSelectedStyle, SelectorTable } from '../../app-main/SelectorComponents';
import { Box, TableBody, TableHead, TableRow } from '@mui/material';
import {
    ConnectorSelectorTableItem,
    StyledIconCell,
    StyledShortCell,
    StyledTextCell,
} from './ConnectionSelectorTableItem';
import { EmptyState } from '../../common/EmptyState';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { StyledBorderBox, StyledBorderBoxContent } from '../../common/LayoutComponents';

interface ConnectionSelectorProps {
    onCreateNew(): void;
    onDelete(connectionUid: string): void;
    onSelect(connectionUid: string): void;
    connections?: {
        authorized?: boolean;
        description?: string;
        title: string;
        type: string;
        uid: string;
    }[];
    docsUrl: string;
}

export const ConnectionSelector: React.FC<ConnectionSelectorProps> = ({
    onCreateNew,
    onDelete,
    onSelect,
    connections = [],
    docsUrl,
}) => {
    const [view, setView] = useState<'grid' | 'list'>(readLocalStorage('connectorsView', 'grid'));

    const handleViewChange = (view: 'grid' | 'list'): void => {
        setView(view);
        saveLocalStorage('connectorsView', view);
    };

    const items = connections.map((connection) => {
        if (view === 'grid') {
            return (
                <AppCard
                    key={connection.uid}
                    authorized={connection.authorized}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    type={connection.type}
                    title={connection.title}
                    uid={connection.uid}
                    pendoAttribute="connectorTypeSelected"
                />
            );
        } else if (view === 'list') {
            return (
                <ConnectorSelectorTableItem
                    key={connection.uid}
                    authorized={connection.authorized}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    type={connection.type}
                    title={connection.title}
                    uid={connection.uid}
                />
            );
        }
    });

    const tableTitles = (
        <TableRow>
            <StyledTextCell>Title</StyledTextCell>
            <StyledIconCell>App</StyledIconCell>
            <StyledIconCell>Authorized</StyledIconCell>
            <StyledShortCell sx={{ textAlign: 'end' }}>Actions</StyledShortCell>
        </TableRow>
    );

    const createLabel = (): string => {
        const currentResultsLength = connections.length ?? 0;
        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    return (
        <PageContainer>
            <PageUpperContainer>
                <PageTitle title="Connectors" label={connections.length > 0 ? createLabel() : undefined} />
                {items.length > 0 && (
                    <PageUpperGroup>
                        <PageViewButtonGroup>
                            <SelectorViewButton
                                icon="grid"
                                onClick={() => {
                                    if (view === 'list') {
                                        handleViewChange('grid');
                                    }
                                }}
                                sx={(theme) => {
                                    return view === 'grid' ? viewSelectedStyle(theme) : null;
                                }}
                            />
                            <SelectorViewButton
                                icon="list"
                                onClick={() => {
                                    if (view === 'grid') {
                                        handleViewChange('list');
                                    }
                                }}
                                sx={(theme) => {
                                    return view === 'list' ? viewSelectedStyle(theme) : null;
                                }}
                            />
                        </PageViewButtonGroup>
                        <Button onClick={onCreateNew} startIcon={<AddIcon />}>
                            Create New
                        </Button>
                    </PageUpperGroup>
                )}
            </PageUpperContainer>
            {view === 'grid' && items.length > 0 && <PageGridContainer>{items}</PageGridContainer>}
            {view === 'list' && items.length > 0 && (
                <SelectorTable>
                    <TableHead>{tableTitles}</TableHead>
                    <TableBody className="padme">{items}</TableBody>
                </SelectorTable>
            )}
            {items.length === 0 && (
                <Box display="flex" justifyContent="center" mt={3}>
                    <StyledBorderBox maxWidth={1040} width="100%">
                        <StyledBorderBoxContent>
                            <EmptyState
                                title="No connectors created"
                                subtitle="Connectors contain all the authentication details for an API connection."
                                icon={<PowerOutlinedIcon />}
                                iconState="incomplete"
                                buttons={[
                                    <Button
                                        variant="outlined"
                                        key="learn-more"
                                        onClick={() => window.open(docsUrl, '_blank')}
                                    >
                                        Learn more
                                    </Button>,
                                    <Button key="create-connector" onClick={onCreateNew}>
                                        Create connector
                                    </Button>,
                                ]}
                            />
                        </StyledBorderBoxContent>
                    </StyledBorderBox>
                </Box>
            )}
        </PageContainer>
    );
};
