import { styled, Theme } from '@mui/material';
import Chip, { ChipProps } from '@mui/material/Chip';

interface CustomChipProps extends ChipProps {
    label: string;
    severity?: ChipSeverity;
}

type ChipSeverity = 'primary' | 'grey' | 'success' | 'info' | 'warning' | 'error';

const getChipColor = (severity: ChipSeverity, theme: Theme): { backgroundColor: string; color: string } => {
    switch (severity) {
        case 'grey':
            return {
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], // Change this
                color: theme.palette.text.primary,
            };
        case 'info':
            return {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
            };
        case 'success':
            return {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
            };
        case 'warning':
            return {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
            };
        case 'error':
            return {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
            };
        default:
        case 'primary':
            return {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            };
    }
};

const StyledChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'severity' })<{ severity: ChipSeverity }>(
    ({ severity, theme }) => ({
        ...getChipColor(severity, theme),
        height: 28,
        padding: theme.spacing(0.25, 0),
    })
);

export const InformationChip: React.FC<CustomChipProps> = ({ severity = 'primary', label, ...props }) => {
    return <StyledChip label={label} severity={severity} {...props} />;
};
