import {
    getCompletedWorkspaceSetupGuideSteps as getCompletedWorkspaceSetupGuideStepsRpc,
    getWorkspaceSetupGuideSteps as getWorkspaceSetupGuideStepsRpc,
    saveWorkspaceSetupGuideProgress as saveWorkspaceSetupGuideProgressRpc,
    toggleWorkspaceSetupGuide as toggleWorkspaceSetupGuideRpc,
} from '@avst-stitch/repository-lib/lib';
import { repositoryInvoker } from '../utils/repository';
import { WorkspaceSetupGuideStep } from '@avst-stitch/repository-lib/lib/types';
import { SetupGuideType } from '@avst-stitch/repository-lib/lib/models';

export const getWorkspaceSetupGuideSteps = async (): Promise<WorkspaceSetupGuideStep[]> => {
    return await getWorkspaceSetupGuideStepsRpc(repositoryInvoker);
};

export const getCompletedWorkspaceSetupGuideSteps = async (
    workspaceUid: string
): Promise<WorkspaceSetupGuideStep[]> => {
    return await getCompletedWorkspaceSetupGuideStepsRpc(repositoryInvoker, { workspaceUid });
};

export const saveWorkspaceSetupGuideProgress = async (steps: string[], workspaceUid: string): Promise<void> => {
    return await saveWorkspaceSetupGuideProgressRpc(repositoryInvoker, { steps, workspaceUid });
};

export const toggleWorkspaceSetupGuide = async (workspaceUid: string, setupGuide?: SetupGuideType): Promise<void> => {
    return await toggleWorkspaceSetupGuideRpc(repositoryInvoker, { setupGuide, workspaceUid });
};
