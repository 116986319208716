import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ReactHtmlParser from 'react-html-parser';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { Button } from '../../common/buttons/Button';
import { StyledMainActions } from '../../setup-guide/SetupGuideComponents';
import { ToggleButtonGroup } from '../../common/buttons/button-groups/ToggleButtonGroup';
import { Editor } from '@monaco-editor/react';
import { getSanitizedParsedMarkdown } from '../../../utils/readme';

export interface FullScreenReadmeProps {
    content: string;
    hasUnsavedChanges?: boolean;
    templateMode?: boolean;
    onCancel: () => void;
    onChangeContent: (content: string) => void;
    onSave: () => void;
}

const StyledReadme = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
}));

const StyledReadmeHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 66,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledReadmeMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(2),
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledReadmeContent = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2),
    overflow: 'auto',
}));

const StyledReadmePreviewContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledReadmeEditModeContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(2, 2, 2, 0),
}));

export const FullScreenReadme: React.FC<FullScreenReadmeProps> = ({
    content,
    hasUnsavedChanges = false,
    templateMode = false,
    onChangeContent,
    onCancel,
    onSave,
}) => {
    const [readmeMode, setReadmeMode] = useState<'edit' | 'preview'>('preview');

    const theme = useTheme();
    const editorTheme = theme.palette.mode === 'dark' ? 'vs-dark' : 'vs';

    const toggleButtons = [
        { icon: <PreviewOutlinedIcon />, label: 'Preview', value: 'preview' },
        { icon: <EditOutlinedIcon />, label: 'Edit', value: 'edit' },
    ];

    return (
        <StyledReadme>
            <StyledReadmeHeader>
                <Typography variant="h5">README</Typography>
            </StyledReadmeHeader>
            <Divider />
            <StyledReadmeMain>
                {!templateMode && (
                    <ToggleButtonGroup
                        buttons={toggleButtons}
                        exclusive
                        fullWidth
                        value={readmeMode}
                        onChange={(_, value) => setReadmeMode(value)}
                    />
                )}
                {readmeMode === 'edit' ? (
                    <StyledReadmeEditModeContent>
                        <Editor
                            defaultValue=""
                            language="markdown"
                            options={{
                                minimap: { enabled: false },
                                readOnly: templateMode,
                            }}
                            theme={editorTheme}
                            value={content}
                            onChange={(value) => onChangeContent(value ?? '')}
                        />
                    </StyledReadmeEditModeContent>
                ) : (
                    <StyledReadmePreviewContent>
                        {ReactHtmlParser(getSanitizedParsedMarkdown(content))}
                    </StyledReadmePreviewContent>
                )}
            </StyledReadmeMain>
            {readmeMode === 'edit' && (
                <>
                    <Divider />
                    <StyledMainActions>
                        <Button onClick={onCancel} variant="outlined">
                            Cancel
                        </Button>
                        <Button disabled={!hasUnsavedChanges} onClick={onSave}>
                            Save changes
                        </Button>
                    </StyledMainActions>
                </>
            )}
        </StyledReadme>
    );
};
