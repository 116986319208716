import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { loggedInUserDetails$ } from '../../store/user';
import {
    cancelNewBlankWorkspaceAction$,
    loadingWorkspaceFormDetails$,
    newBlankWorkspaceDialogOpen$,
    newDuplicatedWorkspace$,
    newWorkspaceFromTemplate$,
    openWorkspacePreviewDialogErrors$,
    saveNewBlankWorkspaceAction$,
    savingNewBlankWorkspace$,
    workspaceCreatedAction$,
} from '../../store/workspaces';
import { getBasePath } from '../../utils/path';
import { selectedOrganizationUid$ } from '../../store/organization';
import { NewWorkspaceDialog, NewWorkspaceSaveEvent } from '../../components/workspace-dialogs/NewWorkspaceDialog';

export const CreateNewWorkspaceDialogContainer: React.FC = () => {
    const navigate = useNavigate();

    const createNewBlankWorkspaceDialogOpen = useObservableState(newBlankWorkspaceDialogOpen$); //Name misleading
    const savingNewBlankWorkspace = useObservableState(savingNewBlankWorkspace$); //Name misleading
    const newBlankWorkspaceErrors = useObservableState(openWorkspacePreviewDialogErrors$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const newWorkspaceFromTemplate = useObservableState(newWorkspaceFromTemplate$);
    const newDuplicatedWorkspace = useObservableState(newDuplicatedWorkspace$);
    const loadingWorkspaceFormDetails = useObservableState(loadingWorkspaceFormDetails$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);

    const handleCancel = (): void => {
        cancelNewBlankWorkspaceAction$.next();
    };

    const handleSave = ({ editorType, description, name, template }: NewWorkspaceSaveEvent): void => {
        const setupGuide =
            editorType === 'beginner'
                ? newWorkspaceFromTemplate || newDuplicatedWorkspace?.setupGuide === 'TEMPLATE'
                    ? 'TEMPLATE'
                    : 'BLANK_WORKSPACE'
                : undefined;

        saveNewBlankWorkspaceAction$.next({
            name,
            description,
            template,
            templateUid: newWorkspaceFromTemplate?.sourceUid,
            setupGuide,
            sourceWorkspaceUid: newDuplicatedWorkspace?.sourceUid,
            organizationUid: selectedOrganizationUid ?? '',
        });
    };

    // TODO: Find a way to reuse
    const navigateToWorkspace = (workspaceUid: string, environmentUid: string, setupGuide?: boolean): void => {
        if (setupGuide) {
            navigate({ to: `${getBasePath()}guide/workspace/${workspaceUid}` });
        } else {
            navigate({ to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}` });
        }
    };

    const duplicatedWorkspaceName = newDuplicatedWorkspace && newDuplicatedWorkspace.name + ' - copy';

    useSubscription(workspaceCreatedAction$, ({ workspaceUid, environmentUid, setupGuide }) => {
        navigateToWorkspace(workspaceUid, environmentUid, !!setupGuide);
    });

    const templateOption =
        !!loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates && !newWorkspaceFromTemplate;

    return (
        <NewWorkspaceDialog
            description={newWorkspaceFromTemplate?.description ?? newDuplicatedWorkspace?.description}
            errors={newBlankWorkspaceErrors}
            loading={loadingWorkspaceFormDetails}
            name={newWorkspaceFromTemplate?.name ?? duplicatedWorkspaceName}
            open={createNewBlankWorkspaceDialogOpen}
            saving={savingNewBlankWorkspace}
            templateMode={!!newWorkspaceFromTemplate || newDuplicatedWorkspace?.setupGuide === 'TEMPLATE'}
            templateOption={templateOption}
            onCancel={handleCancel}
            onSave={handleSave}
        />
    );
};
