import { Dialog } from '../../common/Dialog';
import { IconCircle } from '../../common/IconCircle';
import { Button } from '../../common/buttons/Button';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import { handleKeyDown } from '../../../utils/handleKeyDown';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../common/LayoutComponents';
import { Divider, Typography } from '@mui/material';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { useEffect, useState } from 'react';
import { TextField } from '../../common/inputs/TextField';

interface AskNewApiKeyNameDialogProps {
    loading?: boolean;
    open?: boolean;
    errors?: string;
    onClose(): void;
    onCreate(apiKeyName: string): void;
}

export const AskNewApiKeyNameDialog: React.FC<AskNewApiKeyNameDialogProps> = ({
    loading = false,
    open = false,
    errors,
    onClose,
    onCreate,
}) => {
    const docsUrl = 'https://docs.adaptavist.com/src/latest'; // TODO: fix URL
    const [apiKeyName, setApiKeyName] = useState('');

    useEffect(() => {
        setApiKeyName('');
    }, [open]);

    const onCreateNew = (): void => {
        onCreate(apiKeyName);
    };

    return (
        <Dialog
            fullWidth
            leftButton={
                <Button onClick={() => window.open(docsUrl)} variant="text">
                    Learn more
                </Button>
            }
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onCreateNew} color="primary">
                    Create New
                </Button>,
            ]}
            icon={<IconCircle icon={<VpnKeyOutlined />} size="medium" />}
            open={open}
            title={'Create new API key'}
            onClose={onClose}
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: !loading, enterFn: onCreateNew, escFn: onClose })
            }
            loading={loading}
        >
            <>
                <StyledBorderBox>
                    {errors && <DialogAlert severity="error" title="Error" text={errors} />}
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            Name your API key
                        </Typography>
                        <Typography color="text.secondary">
                            Naming your API key to match the activity you're going to use it for allows you to delete
                            this key easily if you want to disable this activity.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <TextField
                            variant="outlined"
                            label="API Key Name"
                            placeholder="Enter API key name"
                            required
                            onChange={(event) => setApiKeyName(event.target.value)}
                            value={apiKeyName}
                        />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </>
        </Dialog>
    );
};
