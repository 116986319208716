import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Invocations, InvocationsRequest, InvocationWorkspace } from '../../data/reporting';
import { ReportingPageFilters } from './ReportingPageFilters';
import { ReportingPageTable } from './ReportingPageTable';
import { PageContainer, PageTitle } from '../app-main/PageComponentsForDeprecation';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import { ReplayInvocationDetails } from '../../store/workspace/replay-invocation';

export type QueryInvocationsRequest = Omit<InvocationsRequest, 'organizationUid'>;
export type ReportingFilters = Omit<InvocationsRequest, 'nextToken' | 'organizationUid'>;

interface InvocationLogsPageProps {
    errors?: string;
    filters: ReportingFilters;
    impersonating?: boolean;
    invocationPayloadIsLoading: boolean;
    abortInvocationLoading?: boolean;
    invocations: Invocations;
    isLoading: boolean;
    nextToken?: string;
    workspaces?: Record<string, InvocationWorkspace>;
    onQueryInvocations(request: QueryInvocationsRequest): void;
    onReplayInvocation(details: ReplayInvocationDetails): void;
    onAbortInvocation?(path: string): void;
    onSearch(request: QueryInvocationsRequest): void;
    onSearchInvocations(request: QueryInvocationsRequest): void;
}

export const tableFilters = [
    'Invocation ID',
    'Workspace Owner',
    'Environment',
    'Script',
    'Trigger Type',
    'Duration',
    'Logs',
    'HTTP Logs',
    'Invocation started',
    'Execution Status',
];

export const InvocationLogsPage: React.FC<InvocationLogsPageProps> = ({
    errors,
    filters,
    impersonating,
    invocationPayloadIsLoading,
    abortInvocationLoading,
    invocations,
    isLoading,
    nextToken,
    workspaces,
    onQueryInvocations,
    onReplayInvocation,
    onAbortInvocation,
    onSearch,
    onSearchInvocations,
}) => {
    const defaultFilters = [
        'Invocation ID',
        'Script',
        'Trigger Type',
        'Duration',
        'Logs',
        'HTTP Logs',
        'Invocation started',
        'Execution Status',
    ];

    const [selectedFilters, setSelectedFilters] = useState<string[]>(
        readLocalStorage('selectedFilters', defaultFilters)
    );

    const handleUpdateFilters = (e: SelectChangeEvent<unknown>): void => {
        const filters = e.target.value as string[];
        filters.sort((a, b) => tableFilters.indexOf(a) - tableFilters.indexOf(b));
        setSelectedFilters(filters);
    };

    useEffect(() => {
        saveLocalStorage('selectedFilters', selectedFilters);
    }, [selectedFilters]);

    const invocationsWithWorkspaceDeleted = invocations.map((invocation) => ({
        ...invocation,
        workspaceDeleted: !!workspaces?.[invocation.workspaceUid]?.deleted,
    }));

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <PageTitle title="Reporting" />
            <ReportingPageFilters
                filters={filters}
                invocationsLength={invocations?.length || 0}
                selectedFilters={selectedFilters}
                tableFilters={tableFilters}
                workspaces={workspaces}
                onSearch={onSearch}
                onSearchInvocations={onSearchInvocations}
                onUpdate={handleUpdateFilters}
            />
            <ReportingPageTable
                errors={errors}
                filters={filters}
                impersonating={impersonating}
                invocationPayloadIsLoading={invocationPayloadIsLoading}
                abortInvocationLoading={abortInvocationLoading}
                isLoading={isLoading}
                invocations={invocationsWithWorkspaceDeleted}
                nextToken={nextToken}
                selectedFilters={selectedFilters}
                onQueryInvocations={onQueryInvocations}
                onReplayInvocation={onReplayInvocation}
                onAbortInvocation={onAbortInvocation}
            />
        </PageContainer>
    );
};
