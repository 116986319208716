import { styled } from '@mui/material';
import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { ProductIcon } from '../../../components/icons/ProductIcon';
import { EventListenerDetails } from '../../../components/workspace-resources/event-listeners/EventListenerDetails';
import { EventListenerPayloads } from '../../../components/workspace-resources/event-listeners/event-listener-payloads';
import { apps$ } from '../../../store/apps';
import { connectionCreatedAction$, openConnectionDetailsDialogAction$ } from '../../../store/connection';
import { loggedInUserConnections$ } from '../../../store/connections';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '../../../store/workspace';
import {
    openEventListenerSetupDialog,
    saveEventListenerAction$,
    savingSelectedEventListener$,
    selectedEventListenerDetails$,
    selectedEventListenerErrors$,
} from '../../../store/workspace/event-listener';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import {
    createEventListenerPayloadDialogOpen$,
    newBlankEventListenerPayloadErrors$,
    savingNewBlankEventListenerPayload$,
    saveEventListenerPayloadAction$,
    selectedEventListenerPayloads$,
    openCreateEventListenerPayloadAction$,
    loadingEventListenerPayloadContent$,
    saveNewBlankEventListenerPayloadAction$,
    cancelCreateEventListenerPayloadAction$,
    selectedEventListenerSelectedPayloadUid$,
    changeEventListenerPayloadContentAction$,
    loadEventListenerPayloadContentAction$,
    selectedEventListenerSelectedPayloadErrors$,
    savingSelectedEventListenerSelectedPayload$,
    storedEventListenerPayloadContent$,
    savedEventListenerPayloadContent$,
} from '../../../store/workspace/event-listener-payload';
import { getUserDisplayName } from '../../../utils/user';
import { newDeploymentCreatedAction$ } from '../../../store/workspace/deployment';
import { getBasePath } from '../../../utils/path';
import { NewEventListenerPayloadDialog } from '../../../components/workspace-resources/event-listeners/event-listener-payloads/NewEventListenerPayloadDialog';

const StyledContainer = styled('div')(({ theme }) => ({
    height: '100%',
    padding: theme.spacing(2, 4),
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
    height: 26,
    marginBottom: theme.spacing(3),
    minHeight: 26,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.secondary,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: '24px',
    minHeight: 'unset',
    padding: 0,
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(4.25)})`,
    padding: 0,
}));

export const EventListenerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const errors = useObservableState(selectedEventListenerErrors$);
    const details = useObservableState(selectedEventListenerDetails$);
    const saving = useObservableState(savingSelectedEventListener$);
    const connections = useObservableState(loggedInUserConnections$);
    const apps = useObservableState(apps$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);

    const payloadErrors = useObservableState(selectedEventListenerSelectedPayloadErrors$);
    const payloads = useObservableState(selectedEventListenerPayloads$);
    const savingPayload = useObservableState(savingSelectedEventListenerSelectedPayload$);
    const loadingPayloadContent = useObservableState(loadingEventListenerPayloadContent$);
    const storedPayloadContent = useObservableState(storedEventListenerPayloadContent$);
    const savedPayloadContent = useObservableState(savedEventListenerPayloadContent$);
    const selectedPayloadUid = useObservableState(selectedEventListenerSelectedPayloadUid$);

    const createEventListenerPayloadDialogOpen = useObservableState(createEventListenerPayloadDialogOpen$);
    const newBlankEventListenerPayloadErrors = useObservableState(newBlankEventListenerPayloadErrors$);
    const savingNewBlankEventListenerPayload = useObservableState(savingNewBlankEventListenerPayload$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);

    const [selectedTab, setSelectedTab] = useState('details');
    const [createdConnection, setCreatedConnection] = useState<string | undefined>(undefined);

    useSubscription(connectionCreatedAction$, (connectionUid) => {
        setCreatedConnection(connectionUid);
    });

    useSubscription(newDeploymentCreatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../../' });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}/workspace/${selectedWorkspaceUid$.value}/environment/${
                        selectedEnvironmentUid$.value
                    }/event/${details?.uid}`,
                }),
            100
        );
    });
    const selectedAppName = apps.find((a) => a.uid === details?.appUid)?.name ?? '';
    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            value: c.uid,
            name: c.name,
            authorized: c.authorized,
            icon: <ProductIcon name={c.connectionType.name} />,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            value: details.selectedSharedConnection.uid,
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            authorized: details.selectedSharedConnection.authorized,
            icon: <ProductIcon name={details.selectedSharedConnection.connectionType.name} />,
        });
    }

    const currentApp = apps.find((a) => a.uid === details?.appUid);
    const filteredEventTypes =
        currentApp?.connectionType.eventListenerTypes.find((elt) => elt.uid === details?.eventListenerTypeUid)
            ?.eventTypes ?? [];

    const handleCancel = (): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    const handleNewConnection = (): void => {
        const connectionType = (apps ?? [])
            .flatMap((app) => app.connectionType)
            .find((ct) => ct.uid === details?.connectionTypeUid);

        if (connectionType) {
            openConnectionDetailsDialogAction$.next({
                connectionType,
            });
        } else {
            console.error('Connection type not found');
            alert('Connection type not found');
        }
    };

    const existingEventListeners = workspaceResources?.eventListeners.map((el) => el.eventType?.name);
    const existingInteractiveComponent = filteredEventTypes.some(
        (et) => existingEventListeners.includes(et.name) && et.category === 'Interactive Component'
    );

    //eslint-disable-next-line sonarjs/cognitive-complexity
    const handleViewSetupInstructions = (): void => {
        if (details) {
            openEventListenerSetupDialog(
                {
                    connectionUid: details.connectionUid,
                    eventListenerTypeUid: details.eventListenerTypeUid,
                    eventTypeCategory: details.eventTypeCategory,
                    eventTypeUid: details.eventTypeUid,
                    uid: details.uid,
                    urlId: details.urlId,
                },
                details.appUid
            );
        }
    };

    const storedContent = selectedPayloadUid ? storedPayloadContent[selectedPayloadUid] ?? '' : '';
    const savedContent = selectedPayloadUid ? savedPayloadContent[selectedPayloadUid] ?? '' : '';

    const hasUnsavedChanges = storedContent !== savedContent;

    return (
        <>
            <StyledContainer>
                <TabContext value={selectedTab}>
                    <Box>
                        <StyledTabList onChange={(_event, value) => setSelectedTab(value)}>
                            <StyledTab label="Details" value="details" />
                            <StyledTab label="Test Event Payloads" value="testEventPayloads" />
                        </StyledTabList>
                    </Box>
                    <StyledTabPanel value="details">
                        <EventListenerDetails
                            uid={details?.uid ?? ''}
                            createdConnectionUid={createdConnection}
                            saving={saving}
                            errors={errors}
                            loading={false}
                            selectedAppName={selectedAppName}
                            scripts={workspaceResources.scripts}
                            selectedEventTypeUid={details?.eventTypeUid}
                            selectedConnectionUid={details?.connectionUid}
                            selectedScriptUid={details?.scriptUid}
                            connectionRequired={details?.connectionRequired ?? true}
                            urlId={details?.urlId ?? ''}
                            connections={filteredConnections}
                            remnantEnvironments={details?.remnantEnvironments ?? []}
                            selectedEnvironment={selectedWorkspaceEnvironment}
                            eventTypes={filteredEventTypes}
                            hasSetupInstructions={!!details?.urlId}
                            onCancel={handleCancel}
                            onNewConnection={handleNewConnection}
                            onSave={(event) => saveEventListenerAction$.next(event)}
                            onViewSetupInstructions={handleViewSetupInstructions}
                            existingEvent={existingInteractiveComponent}
                            workspaceLocked={!!selectedWorkspace?.locked}
                            hasImplicitlySharedConnectionAttached={!!details?.selectedSharedConnection}
                            warnings={details?.warnings}
                            disabled={details?.disabled}
                        />
                    </StyledTabPanel>
                    <StyledTabPanel value="testEventPayloads">
                        <EventListenerPayloads
                            activePayloadUid={payloads?.selectedTestPayloadUid}
                            content={storedContent}
                            errors={payloadErrors}
                            eventListenerPayloads={payloads?.testPayloads}
                            hasUnsavedChanges={hasUnsavedChanges}
                            loadingContent={loadingPayloadContent}
                            requiresSetup={!details?.urlId}
                            saving={savingPayload}
                            selectedPayloadUid={selectedPayloadUid ?? ''}
                            workspaceLocked={!!selectedWorkspace?.locked}
                            onCancel={handleCancel}
                            onContentChange={(payload) => changeEventListenerPayloadContentAction$.next(payload)}
                            onCreateNewPayload={() => openCreateEventListenerPayloadAction$.next()}
                            onSave={(payload) => saveEventListenerPayloadAction$.next(payload)}
                            onSelectTestPayload={(uid) => loadEventListenerPayloadContentAction$.next(uid)}
                        />
                    </StyledTabPanel>
                </TabContext>
            </StyledContainer>
            <NewEventListenerPayloadDialog
                open={createEventListenerPayloadDialogOpen}
                saving={savingNewBlankEventListenerPayload}
                errors={newBlankEventListenerPayloadErrors}
                onCancel={() => cancelCreateEventListenerPayloadAction$.next()}
                onSave={(name) => {
                    saveNewBlankEventListenerPayloadAction$.next({
                        name,
                        environmentUid: selectedEnvironmentUid ?? '',
                        eventListenerUid: details?.uid ?? '',
                    });
                }}
            />
        </>
    );
};
