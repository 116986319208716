import React, { useState } from 'react';
import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Button } from '../../common/buttons/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import Chip from '@mui/material/Chip';
import { TextField } from '../../common/inputs/TextField';

export interface ConsoleHeaderProps {
    columns?: ColumnDropdownProps[];
    filterByEnvironment: boolean;
    isFullScreen: boolean;
    logLevels?: LogLevel[];
    logs: number;
    searchValue: string;
    onClearLog(): void;
    onColumnsChecked(columns: ColumnDropdownProps[]): void;
    onExpand(fullScreen: boolean): void;
    onFilterByEnvironment?(checked: boolean): void;
    onLiveFeedback?(checked: boolean): void;
    onLogLevelChecked(logs: LogLevel[]): void;
    onSearch: (query: string) => void;
}

export interface LogLevel {
    name: string;
    level: string;
    checked: boolean;
}

interface LogLevelTypeIconProps {
    type: string;
}

export interface ColumnDropdownProps {
    name: string;
    checked: boolean;
}

const iconStyle1 = (theme: Theme) =>
    ({
        marginRight: theme.spacing(1),
    } as const);

const iconStyle2 = (theme: Theme) =>
    ({
        ...iconStyle1(theme),
        margin: theme.spacing(0, 0.5),
    } as const);

const StyledFlexContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 46,
    paddingLeft: theme.spacing(0.5),
    width: '100%',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginRight: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
        height: 46,
        margin: 0,
        '&:hover': {
            color: theme.palette.primary.dark,
        },
        '& .MuiCheckbox-root': {
            height: 20,
            width: 20,
        },
    },
}));

const StyledFormGroupDropdown = styled(FormGroup)(({ theme }) => ({
    display: 'flex',
    justifyItems: 'center',
    '& .MuiFormControlLabel-root': {
        height: 40,
        margin: theme.spacing(0, 1),
    },
    '& .MuiButtonBase-root': {
        height: 20,
        width: 20,
        margin: theme.spacing(0, 1),
    },
    ':hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    cursor: 'pointer',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const StyledLine = styled(Divider)(({ theme }) => ({
    height: 40,
    margin: theme.spacing(0, 0.5),
}));

const StyledDropdownBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    boxShadow: theme.constants.boxShadow,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 46,
    width: 200,
    zIndex: '1',
}));

const StyledSearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0.5),
    gap: theme.spacing(1),
    '& .MuiInputBase-root': {
        height: 40,
    },
    '& .MuiChip-root': {
        height: 35,
    },
}));

const LeftContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root.MuiIconButton-root': {
        width: 40,
        height: 40,
    },
}));

const StyledBugReportOutlinedIcon = styled(BugReportOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledWarningAmberOutlinedIcon = styled(WarningAmberOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledReportGmailerrorredOutlinedIcon = styled(ReportGmailerrorredOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledCheckCircleOutlineOutlinedIcon = styled(CheckCircleOutlineOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const LogLevelTypeIcon: React.FC<LogLevelTypeIconProps> = ({ type }) => {
    switch (type) {
        case 'Debug':
            return <StyledBugReportOutlinedIcon />;
        case 'Information':
            return <StyledInfoOutlinedIcon color="info" />;
        case 'Warning':
            return <StyledWarningAmberOutlinedIcon color="warning" />;
        case 'Error':
            return <StyledReportGmailerrorredOutlinedIcon color="error" />;
        case 'Success':
            return <StyledCheckCircleOutlineOutlinedIcon color="success" />;
        default:
            return <HelpTwoToneIcon />;
    }
};

export const ConsoleHeader: React.FC<ConsoleHeaderProps> = ({
    columns = [],
    filterByEnvironment,
    isFullScreen = false,
    logLevels = [],
    logs,
    searchValue,
    onClearLog,
    onColumnsChecked,
    onExpand,
    onFilterByEnvironment,
    onLiveFeedback,
    onLogLevelChecked,
    onSearch,
}) => {
    const [logLevelDropdownOpen, setLogLevelDropdownOpen] = useState(false);
    const [columnDropdownOpen, setColumnDropdownOpen] = useState(false);
    const [consoleFullScreen, setConsoleFullScreen] = useState(isFullScreen);
    const [searchTerm, setSearchTerm] = useState(searchValue);

    const handleLogLevelChange = (updatedLevel: string): void => {
        const updatedLogLevels = logLevels.map((logType) => {
            if (logType.level === updatedLevel) {
                return { ...logType, checked: !logType.checked };
            }
            return logType;
        });

        onLogLevelChecked(updatedLogLevels);
    };

    const handleColumnChange = (updatedColumn: string): void => {
        const updatedColumns = columns.map((column) => {
            if (column.name === updatedColumn) {
                return { ...column, checked: !column.checked };
            }
            return column;
        });

        onColumnsChecked(updatedColumns);
    };

    const handleLogLevelDropdown = (): void => {
        setLogLevelDropdownOpen(!logLevelDropdownOpen);
    };

    const handleColumnDropdown = (): void => {
        setColumnDropdownOpen(!columnDropdownOpen);
    };

    const logLevelDropdownIsVisible = { display: logLevelDropdownOpen ? 'flex' : 'none' };
    const columnDropdownIsVisible = { display: columnDropdownOpen ? 'flex' : 'none' };

    const createLabel = (): string => {
        const currentResultsLength = logs ?? 0;
        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    return (
        <StyledFlexContainer>
            <LeftContainer>
                <Tooltip
                    placement="top"
                    title={!consoleFullScreen ? 'Expand to full screen' : 'Exit full screen'}
                    describeChild
                >
                    <IconButton
                        onClick={() => {
                            setConsoleFullScreen((prev) => {
                                onExpand(!prev);
                                return !prev;
                            });
                        }}
                    >
                        {!consoleFullScreen ? <OpenInFullOutlinedIcon /> : <CloseFullscreenOutlinedIcon />}
                    </IconButton>
                </Tooltip>
                <StyledLine orientation="vertical" />
                <ClickAwayListener onClickAway={() => setColumnDropdownOpen(false)}>
                    <Box>
                        <Tooltip placement="top" title="Manage columns">
                            <IconButton onClick={handleColumnDropdown}>
                                <ViewColumnOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <StyledDropdownBox sx={columnDropdownIsVisible}>
                            {columns.map((c, i) => (
                                <StyledFormGroupDropdown key={i}>
                                    <FormControlLabel
                                        control={
                                            <StyledCheckbox
                                                checked={c.checked}
                                                onChange={() => handleColumnChange(c.name)}
                                            />
                                        }
                                        label={
                                            <StyledBox>
                                                <Typography>{c.name}</Typography>
                                            </StyledBox>
                                        }
                                    />
                                    {i !== columns.length - 1 && <Divider />}
                                </StyledFormGroupDropdown>
                            ))}
                        </StyledDropdownBox>
                    </Box>
                </ClickAwayListener>
                <StyledLine orientation="vertical" />
                <ClickAwayListener onClickAway={() => setLogLevelDropdownOpen(false)}>
                    <Box>
                        <Tooltip placement="top" title="Customise log levels" describeChild>
                            <IconButton onClick={handleLogLevelDropdown}>
                                <MenuOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <StyledDropdownBox sx={logLevelDropdownIsVisible}>
                            {logLevels.map((l, i) => (
                                <StyledFormGroupDropdown key={i}>
                                    <FormControlLabel
                                        control={
                                            <StyledCheckbox
                                                checked={l.checked}
                                                onChange={() => handleLogLevelChange(l.level)}
                                            />
                                        }
                                        label={
                                            <StyledBox>
                                                <LogLevelTypeIcon type={l.name} />
                                                <Typography>{l.name}</Typography>
                                            </StyledBox>
                                        }
                                    />
                                    {i !== logLevels.length - 1 && <Divider />}
                                </StyledFormGroupDropdown>
                            ))}
                        </StyledDropdownBox>
                    </Box>
                </ClickAwayListener>
                <StyledLine orientation="vertical" />
                <StyledFormGroup>
                    <Tooltip
                        placement="top"
                        title="When disabled console will only display feedback that originates from manually triggered invocations."
                        describeChild
                    >
                        <FormControlLabel
                            sx={{ whiteSpace: 'nowrap' }}
                            control={
                                <StyledCheckbox
                                    defaultChecked
                                    onChange={(event) => onLiveFeedback?.(event.target.checked)}
                                />
                            }
                            label="Live Feedback"
                        />
                    </Tooltip>
                </StyledFormGroup>
                <StyledLine orientation="vertical" />
                <StyledFormGroup>
                    <Tooltip
                        placement="top"
                        title="Feedback is only displayed for the environment that you have currently selected."
                        describeChild
                    >
                        <FormControlLabel
                            sx={{ whiteSpace: 'nowrap' }}
                            control={
                                <StyledCheckbox
                                    checked={filterByEnvironment}
                                    onChange={(event) => onFilterByEnvironment?.(event.target.checked)}
                                />
                            }
                            label="Filter by Environment"
                        />
                    </Tooltip>
                </StyledFormGroup>
                <StyledLine orientation="vertical" />
                <Button
                    color="error"
                    variant="text"
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={onClearLog}
                    sx={{ minWidth: 136 }}
                >
                    Clear Log
                </Button>
            </LeftContainer>
            <StyledSearchContainer>
                <Chip label={createLabel()} />
                <TextField
                    aria-label="Search by keyword"
                    label={null}
                    placeholder="Search by keyword"
                    startIcon={<SearchOutlinedIcon />}
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        onSearch(e.target.value);
                    }}
                />
            </StyledSearchContainer>
        </StyledFlexContainer>
    );
};
