import { Key } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomButtonProps } from '../common/buttons/Button';
import { IconCircle } from '../common/IconCircle';
import { StyledEmptyStateBox } from '../common/LayoutComponents';

interface EmptyStateButtonProps extends CustomButtonProps {
    key: Key | null;
}

interface EmptyStateProps {
    buttons?: EmptyStateButtonProps[];
    icon: JSX.Element;
    iconState?: 'incomplete' | 'complete';
    subtitle?: string | JSX.Element;
    title: string;
}

const StyledContent = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const StyledUpperContent = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
    bottom: 0,
    color: theme.palette.success.main,
    height: 20,
    position: 'absolute',
    right: 0,
    width: 20,
}));

const StyledButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
}));

const StyledTinyCircle = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.constants.radiusCircle,
    bottom: 0,
    height: 20,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: 20,
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

export const EmptyState: React.FC<EmptyStateProps> = ({ buttons = [], icon, iconState, subtitle, title }) => {
    return (
        <StyledEmptyStateBox>
            <StyledContent>
                <StyledUpperContent>
                    <Box position="relative">
                        <IconCircle icon={icon} size="medium" />
                        {iconState &&
                            (iconState === 'complete' ? (
                                <StyledCheckCircleIcon />
                            ) : (
                                <StyledTinyCircle>
                                    <AddOutlinedIcon />
                                </StyledTinyCircle>
                            ))}
                    </Box>
                    <Box textAlign="center" maxWidth={520}>
                        <Typography variant="h6" pb={0.5}>
                            {title}
                        </Typography>
                        <Typography color="text.secondary">{subtitle}</Typography>
                    </Box>
                </StyledUpperContent>
                {buttons.length > 0 && <StyledButtonContainer>{buttons}</StyledButtonContainer>}
            </StyledContent>
        </StyledEmptyStateBox>
    );
};
