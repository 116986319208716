import { useSubscription } from 'observable-hooks';
import { navigateToWorkspaceFromTemplateEditAction$ } from '../../store/templates';
import { getBasePath } from '../../utils/path';
import { useNavigate } from 'react-location';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';

export const EditTemplateContainer: React.FC = () => {
    const navigate = useNavigate();

    useSubscription(navigateToWorkspaceFromTemplateEditAction$, (template) =>
        navigate({ to: `${getBasePath()}workspace/${template.workspaceUid}/environment/${template.environmentUid}` })
    );

    return <LoadingSpinner />;
};
