import { useObservableState } from 'observable-hooks';
import {
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideProgressAction$,
    selectedWorkspaceSetupGuideStep$,
    selectedWorkspaceSetupGuideTriggerScreenType$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../../store/workspace';
import { loggedInUserConnections$ } from '../../../store/connections';
import { openAppSelectorAction$ } from '../../../store/apps';
import { SetupGuideTriggerScreen } from '../../../components/setup-guide/triggers/SetupGuideTriggerScreen';
import {
    createEventListenerAction$,
    deleteEventListenerAction$,
    eventListenersBeingDeleted$,
} from '../../../store/workspace/event-listener';
import {
    createScheduledTriggerAction$,
    deleteScheduledTriggerAction$,
    scheduledTriggersBeingDeleted$,
} from '../../../store/workspace/scheduled-trigger';
import { EventListener } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceResources';
import { getPreviousEnabledStep } from '../../../utils/setupGuide';
import { useNavigate } from 'react-location';
import { readLocalStorage } from '../../../utils/localStorage';
import { getBasePath } from '../../../utils/path';
import { APP } from '@avst-stitch/repository-lib/constants';

export const WEBHOOK_SETUP_COMPLETE_KEY = 'webhookSetupCompleted';

export const SetupGuideTriggerScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const errors = useObservableState(workspaceSetupGuideStepError$);
    const { eventListeners, scheduledTriggers } = useObservableState(selectedWorkspaceResources$);
    const selectedScreen = useObservableState(selectedWorkspaceSetupGuideTriggerScreenType$);
    const eventListenersBeingDeleted = useObservableState(eventListenersBeingDeleted$);
    const scheduledTriggersBeingDeleted = useObservableState(scheduledTriggersBeingDeleted$);
    const connections = useObservableState(loggedInUserConnections$);

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    const doesEventListenerNeedSetup = (eventListener: EventListener): boolean => {
        const completedEventListeners = readLocalStorage<string[]>(WEBHOOK_SETUP_COMPLETE_KEY, []);

        const connectionRequired =
            selectedWorkspace?.setupGuide === 'TEMPLATE' ? eventListener.appName !== APP.GENERIC.NAME : false; // TODO: For blank workspace guide, repository changes are necessary to see whether connection is required or not

        if (eventListener.warning || !completedEventListeners.includes(eventListener.uid)) {
            return true;
        }
        if (connectionRequired) {
            const foundConnection = connections.find((c) => c.uid === eventListener.connectionUid);

            return !foundConnection || !foundConnection.authorized;
        } else {
            return false;
        }
    };

    return (
        <SetupGuideTriggerScreen
            errors={errors}
            eventListeners={eventListeners.map((el) => ({
                appName: el.appName,
                deleting: eventListenersBeingDeleted[el.uid],
                needsSetup: doesEventListenerNeedSetup(el),
                scriptName: el.script?.name,
                uid: el.uid,
            }))}
            nextStepName={nextStep?.title}
            saving={saving}
            scheduledTriggers={scheduledTriggers.map((st) => ({
                cron: st.cronExpression,
                deleting: scheduledTriggersBeingDeleted[st.uid],
                needsSetup: st.warning,
                scriptName: st.script?.name,
                uid: st.uid,
            }))}
            selectedScreen={selectedScreen ?? 'event'}
            stepRequired={selectedSetupGuideStep?.required}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            workspaceLocked={!!selectedWorkspace?.locked}
            onChangeScreen={(screenType) => selectedWorkspaceSetupGuideTriggerScreenType$.next(screenType)}
            onContinue={() =>
                saveWorkspaceSetupGuideProgressAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onCreateNewEventListener={() =>
                openAppSelectorAction$.next({
                    nextAction: (app) =>
                        createEventListenerAction$.next({
                            appUid: app.uid,
                            eventListenerTypeUid: app.connectionType.eventListenerTypes[0]?.uid ?? '',
                        }),
                    mode: 'EVENT_LISTENER',
                })
            }
            onCreateNewScheduledTrigger={() => createScheduledTriggerAction$.next()}
            onDeleteEventListener={(uid) => deleteEventListenerAction$.next({ uid })}
            onDeleteScheduledTrigger={(uid) => deleteScheduledTriggerAction$.next({ uid })}
            onEditEventListener={(uid) =>
                navigate({ to: `${getBasePath()}guide/workspace/${selectedWorkspaceUid}/step/triggers/event/${uid}` })
            }
            onEditScheduledTrigger={(uid) => navigate({ to: `./scheduled/${uid}` })}
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
        />
    );
};
