import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { ProductIcon } from '../../../icons/ProductIcon';
import { IconCircle } from '../../../common/IconCircle';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import ListItem from '@mui/material/ListItem';

interface GitlabEventListenerSetupDialogProps {
    eventTypeName: string;
    gitlabUrl?: string;
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const GitlabEventListenerSetupDialog: React.FC<GitlabEventListenerSetupDialogProps> = ({
    eventTypeName,
    gitlabUrl,
    loading = false,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.GITLAB.NAME;

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} group or project to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                {gitlabUrl ? (
                                    <Typography>
                                        Visit your {appName}{' '}
                                        <Link target="_blank" href={gitlabUrl}>
                                            account
                                        </Link>{' '}
                                        page.
                                    </Typography>
                                ) : (
                                    <Typography>
                                        Open{' '}
                                        <Link target="_blank" href="https://www.gitlab.com">
                                            https://www.gitlab.com
                                        </Link>{' '}
                                        in your browser.
                                    </Typography>
                                )}
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Open the group or project you wish to setup the Event Listener for.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    On the left hand menu, select <strong>Settings</strong> ={'>'}{' '}
                                    <strong>Webhooks</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong>URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            <ListItem>
                                <Typography>
                                    Select ONLY the following event to listen to: <strong>{eventTypeName}</strong> and
                                    click <strong>Add webhook</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    If successful, you should see your webhook appear on the{' '}
                                    <strong>Project hooks</strong> card on the bottom of the page.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
