import { Button } from '../../../common/buttons/Button';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Dialog } from '../../../common/Dialog';
import { eventListenerDocumenationUrl } from '../../../../utils/documentation';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledOrderedList } from '../../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';

interface OpsgenieEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const OpsgenieEventListenerSetupDialog: React.FC<OpsgenieEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Opsgenie';
    const normalizedUrl = setupBaseUrl ? (setupBaseUrl.endsWith('/') ? setupBaseUrl.slice(0, -1) : setupBaseUrl) : '';

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumenationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={connectionTypeName} />} size="medium" />}
                open={open}
                title={`Event listener setup`}
                subtitle={connectionTypeName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <StyledOrderedList>
                            <ListItem>
                                {setupBaseUrl && (
                                    <Typography>
                                        Visit your instance's{' '}
                                        <Link
                                            target="_blank"
                                            href={normalizedUrl + '/settings/integration/add/Webhook/'}
                                        >
                                            Create a Webhook
                                        </Link>{' '}
                                        page.
                                    </Typography>
                                )}
                                {!setupBaseUrl && (
                                    <Typography>
                                        Open https://[YOUR_INSTANCE]/settings/integration/add/Webhook/ URL in your
                                        browser.
                                    </Typography>
                                )}
                            </ListItem>
                            <ListItem>
                                <Typography>Give your webhook a meaningful name.</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Make sure that <strong>Enabled</strong> setting is checked.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    In <strong>For Opsgenie alerts</strong> section select ONLY the following event to
                                    listen: <strong>{eventTypeName}</strong>.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Delete the rest predefined events using <strong>X</strong> icon on the right.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>
                                    Paste the copied URL into the <strong> Webhook URL</strong> field.
                                </Typography>
                            </ListItem>
                            <OutlinedReadOnlyTextField
                                label="Webhook URL"
                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                            />
                            <ListItem>
                                <Typography>
                                    Then click <strong>Save Integration</strong>.
                                </Typography>
                            </ListItem>
                        </StyledOrderedList>
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
