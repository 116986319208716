import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { autoFocus } from '../../../../utils/autoFocus';
import { handleKeyDown } from '../../../../utils/handleKeyDown';

interface NewEventListenerPayloadDialogProps {
    errors?: string;
    name?: string;
    open?: boolean;
    saving?: boolean; // Show spinner for save button and disable it
    onCancel(): void;
    onSave(name: string): void;
}

export const NewEventListenerPayloadDialog: React.FC<NewEventListenerPayloadDialogProps> = ({
    errors,
    name = '',
    open = false,
    saving = false,
    onCancel,
    onSave,
}) => {
    const [currentName, setCurrentName] = useState(name);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentName(name);
        autoFocus(inputRef);
    }, [open]);

    const canCreate = !saving && !!currentName;

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: canCreate, enterFn: () => onSave(currentName), escFn: onCancel })
            }
        >
            <DialogTitle sx={{ mb: 2 }} variant="h6">
                New Event Listener Payload
            </DialogTitle>
            {errors && <DialogAlert severity="error" title={errors} />}
            <DialogContent>
                <TextField
                    inputRef={inputRef}
                    label="Event Listener Payload name"
                    variant="outlined"
                    placeholder="Enter a name"
                    required
                    value={currentName}
                    onChange={(e) => setCurrentName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} variant="outlined">
                    Cancel
                </Button>
                <Button busy={saving} disabled={!canCreate} onClick={() => onSave(currentName)}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
