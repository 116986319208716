import { Divider, Link, ListItem, Typography } from '@mui/material';
import { handleKeyDown } from '../../../utils/handleKeyDown';
import { Dialog } from '../../common/Dialog';
import { IconCircle } from '../../common/IconCircle';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../common/LayoutComponents';
import { Button } from '../../common/buttons/Button';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import { StyledOrderedList } from '../../common/lists/OrderedList';
import { OutlinedReadOnlyTextField } from '../../common/textfield/OutlinedReadOnlyTextField';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';

interface NewApiKeyGeneratedDialog {
    email: string;
    apiKey: string;
    open?: boolean;
    onClose(): void;
}

export const NewApiKeyGeneratedDialog: React.FC<NewApiKeyGeneratedDialog> = ({
    email,
    apiKey,
    open = false,
    onClose,
}) => {
    const docsUrl = 'https://docs.adaptavist.com/src/latest'; // TODO: fix URL
    const swaggerUrl = 'https://docs.adaptavist.com/src/latest'; // TODO: fix URL

    return (
        <Dialog
            buttons={[
                <Button onClick={onClose} variant="outlined">
                    Close
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(docsUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<VpnKeyOutlined />} size="medium" />}
            open={open}
            title={'New API key'}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
        >
            <DialogAlert
                severity="warning"
                title="Warning"
                text="Once you close this dialog, you won't be able to retrieve the API key value again. Make sure to make a copy and store it securely!"
            />
            <StyledBorderBox>
                <StyledBorderBoxSubHeader>
                    <Typography variant="subtitle1" component="h6">
                        Using email and API key with basic authentication
                    </Typography>
                </StyledBorderBoxSubHeader>
                <Divider />
                <StyledBorderBoxContent gap={1.5}>
                    <StyledOrderedList>
                        <ListItem>
                            <Typography>Copy email and API key.</Typography>
                        </ListItem>
                        <OutlinedReadOnlyTextField label="Email (username)" value={email} />
                        <OutlinedReadOnlyTextField label="API Key (password)" value={apiKey} />
                        <ListItem>
                            <Typography>
                                And use it with the basic authentication where you need to, or try out our APIs in the{' '}
                                <Link target="_blank" href={swaggerUrl}>
                                    Swagger UI
                                </Link>
                                .
                            </Typography>
                        </ListItem>
                    </StyledOrderedList>
                </StyledBorderBoxContent>
            </StyledBorderBox>
            <StyledBorderBox>
                <StyledBorderBoxSubHeader>
                    <Typography variant="subtitle1" component="h6">
                        Using authorization header directly
                    </Typography>
                </StyledBorderBoxSubHeader>
                <Divider />
                <StyledBorderBoxContent gap={1.5}>
                    <StyledOrderedList>
                        <ListItem>
                            <Typography>
                                Alternatively you can copy the Authorization header value and use it directly.
                            </Typography>
                        </ListItem>
                        <OutlinedReadOnlyTextField
                            label="Authorization"
                            // eslint-disable-next-line sonarjs/no-nested-template-literals
                            value={`Basic ${btoa(`${email}:${apiKey}`)}`}
                        />
                    </StyledOrderedList>
                </StyledBorderBoxContent>
            </StyledBorderBox>
        </Dialog>
    );
};
