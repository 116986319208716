import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ChecklistIcon from '@mui/icons-material/Checklist';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Button } from '../../common/buttons/Button';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';

export interface OrganizationInvoicePlanInformationProps {
    applyDiscountInProgress?: boolean;
    appliedDiscountCode?: string;
    onApplyDiscount?: (code: string) => void;
    onDeleteDiscount?: () => void;
    discountCodeValidationError?: string;
    clearDiscountValidationError?: () => void;
}
const StyledHeaderContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'left',
}));

const StyledInformationContainer = styled('div')(({ theme }) => ({
    margin: theme.spacing(1, 0),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledDisclaimerContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.palette.warning.dark,
    textAlign: 'left',
}));

const StyledDiscountCodeContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
}));

const StyledDiscountCodeInput = styled(FormControl)(({ theme }) => ({
    color: theme.palette.warning.main,
    width: '80%',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: theme.spacing(5),
        width: '125%',
    },
    '& .MuiFormHelperText': {
        marginRight: 0,
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    marginLeft: theme.spacing(12),
    minWidth: '0',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    maxWidth: 'fit-content',
}));
const StyledDialogAlert = styled(DialogAlert)(({ theme }) => ({
    minWidth: 'fit-content',
    marginBottom: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    textAlign: 'left',
    '& .MuiAlertTitle-root': {
        fontSize: '0.8rem',
        color: theme.palette.text.primary,
    },
    '& .MuiAlert-icon': {
        color: theme.palette.text.primary,
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    boxShadow: theme.constants.boxShadow,
    maxWidth: 'fit-content',
    justifyContent: 'flex-start',
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0, 1),
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    flexDirection: 'row',
    '& .MuiChip-label': {
        padding: theme.spacing(0, 0.5, 0, 0.5),
    },
    '& .MuiSvgIcon-root': {
        margin: theme.spacing(0, 0.25, 0, 0),
    },
}));

export const OrganizationInvoicePlanInformation: React.FC<OrganizationInvoicePlanInformationProps> = ({
    appliedDiscountCode,
    onApplyDiscount,
    applyDiscountInProgress = false,
    onDeleteDiscount,
    discountCodeValidationError,
    clearDiscountValidationError,
}) => {
    const [discountCode, setDiscountCode] = useState('');
    const [shrink, setShrink] = useState(false);

    useEffect(() => {
        if (!applyDiscountInProgress && !discountCodeValidationError) {
            setDiscountCode('');
            if (!discountCode) {
                setShrink(false);
            }
        }
    }, [applyDiscountInProgress, discountCodeValidationError]);

    return (
        <StyledInformationContainer>
            <StyledHeaderContainer>
                <Typography className="extra-bold">How do invoice payments work?</Typography>
            </StyledHeaderContainer>
            <Divider />
            <StyledDialogAlert
                severity="success"
                text="By submitting this form you will trigger a request for an invoice and our Team will be in touch if any additional information is required."
                title="1. Invoice request submitted to the ScriptRunner Connect Team"
                icon={<ChecklistIcon />}
            />
            <Divider variant="middle" />
            <StyledDialogAlert
                severity="success"
                text="An invoice with a detailed breakdown of the charges will be sent to you by email after your purchase."
                title="2. Receive invoice by email"
                icon={<ReceiptLongOutlinedIcon />}
            />
            <Divider variant="middle" />
            <StyledDialogAlert
                severity="success"
                text="Take the time to check your invoice to make sure all the information is correct and matches your purchase."
                title="3. Review and verify"
                icon={<PreviewOutlinedIcon />}
            />
            <Divider variant="middle" />
            <StyledDialogAlert
                severity="success"
                text="Once you have reviewed the invoice, you can make payment using the payment instructions provided."
                title="4. Make payment"
                icon={<MonetizationOnOutlinedIcon />}
            />
            <Divider variant="middle" />
            <StyledDisclaimerContainer>
                <DialogAlert
                    severity="warning"
                    text="Please ensure that you complete payment by the due date to avoid any interruption
                    to your service."
                    title="Disclaimer"
                />
            </StyledDisclaimerContainer>
            {onApplyDiscount && onDeleteDiscount && (
                <>
                    <Divider variant="middle" />
                    <StyledDiscountCodeContainer>
                        <StyledDiscountCodeInput>
                            <StyledTextField
                                size="small"
                                variant="outlined"
                                InputProps={{ readOnly: Boolean(appliedDiscountCode) }}
                                InputLabelProps={{ shrink }}
                                onFocus={() => setShrink(true)}
                                onBlur={(e) => setShrink(!!e.target.value)}
                                error={!!discountCodeValidationError}
                                helperText={
                                    discountCodeValidationError ?? 'Only one discount code can be used per subscription'
                                }
                                label="Discount Code"
                                placeholder="Enter a discount code"
                                value={
                                    appliedDiscountCode && !discountCodeValidationError
                                        ? ''
                                        : !appliedDiscountCode && discountCodeValidationError
                                        ? discountCode
                                        : undefined
                                }
                                onChange={(e) => {
                                    clearDiscountValidationError?.();
                                    setDiscountCode(e.target.value);
                                }}
                            />
                            {appliedDiscountCode && (
                                <StyledChip
                                    label={appliedDiscountCode}
                                    size="medium"
                                    onDelete={() => {
                                        setDiscountCode('');
                                        onDeleteDiscount();
                                    }}
                                />
                            )}
                        </StyledDiscountCodeInput>
                        <StyledButton
                            busy={applyDiscountInProgress}
                            disabled={applyDiscountInProgress || !discountCode}
                            onClick={() => {
                                onApplyDiscount(discountCode);
                            }}
                            size="medium"
                            variant="outlined"
                        >
                            APPLY
                        </StyledButton>
                    </StyledDiscountCodeContainer>
                </>
            )}
        </StyledInformationContainer>
    );
};
