import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface TempoCloudConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface TempoCloudAppSetupSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export const tempoCloudManageConnectionOpenDialogAction$ = monitor(
    'tempoCloudManageConnectionOpenDialogAction$',
    new Subject<TempoCloudConnectionDetails>()
);
export const tempoCloudManageConnectionCloseDialogAction$ = monitor(
    'tempoCloudManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const tempoCloudAppSetupOpenDialogAction$ = monitor(
    'tempoCloudAppSetupOpenDialogAction$',
    new Subject<TempoCloudConnectionDetails>()
);
export const tempoCloudAppSetupCloseDialogAction$ = monitor(
    'tempoCloudAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const tempoCloudAppSetupDialogOpen$ = monitor('tempoCloudAppSetupDialogOpen$', new BehaviorSubject(false));
export const tempoCloudAppSetupAuthorizeAction$ = monitor(
    'tempoCloudAppSetupAuthorizeAction$',
    new Subject<TempoCloudAppSetupSaveProps>()
);
export const tempoCloudAppSetupDialogErrors$ = monitor(
    'tempoCloudAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const tempoCloudSaveConnectionAction$ = monitor('tempoCloudSaveConnectionAction$', new Subject<string>());
export const tempoCloudConnectionCreatedAction$ = monitor('tempoCloudConnectionCreatedAction$', new Subject<string>());
export const tempoCloudConnectionSavedAction$ = monitor(
    'tempoCloudConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const tempoCloudManageConnectionInitiateSetupAction$ = monitor(
    'tempoClodManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const tempoCloudManageConnectionDialogOpen$ = monitor(
    'tempoCloudManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const tempoCloudConnectionSaving$ = monitor('tempoCloudConnectionSaving$', new BehaviorSubject(false));
export const tempoCloudManageConnectionAuthorizeLoading$ = monitor(
    'tempoCloudManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const tempoCloudManageConnectionDetails$ = monitor(
    'tempoCloudManageConnectionDetails$',
    new BehaviorSubject<TempoCloudConnectionDetails | undefined>(undefined)
);
export const tempoCloudManageConnectionDialogErrors$ = monitor(
    'tempoCloudManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const tempoCloudAppSetupDialogStage$ = monitor('tempoCloudAppSetupDialogStage$', new BehaviorSubject(0));

tempoCloudAppSetupOpenDialogAction$.subscribe((details) => {
    tempoCloudAppSetupDialogErrors$.next(undefined);
    tempoCloudManageConnectionDialogErrors$.next(undefined);
    tempoCloudAppSetupDialogOpen$.next(true);
    tempoCloudAppSetupDialogStage$.next(0);
    tempoCloudManageConnectionDetails$.next(details);
});

tempoCloudAppSetupCloseDialogAction$.subscribe(() => {
    tempoCloudAppSetupDialogOpen$.next(false);
    tempoCloudManageConnectionAuthorizeLoading$.next(false);
});

tempoCloudManageConnectionOpenDialogAction$.subscribe((details) => {
    tempoCloudAppSetupDialogErrors$.next(undefined);
    tempoCloudManageConnectionDialogErrors$.next(undefined);
    tempoCloudManageConnectionDialogOpen$.next(true);
    tempoCloudManageConnectionDetails$.next(details);
});

tempoCloudManageConnectionCloseDialogAction$.subscribe(() => {
    tempoCloudManageConnectionDialogOpen$.next(false);
    tempoCloudAppSetupDialogOpen$.next(false);
    tempoCloudManageConnectionDetails$.next(undefined);
});

tempoCloudConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

tempoCloudAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            tempoCloudConnectionSaving$.next(true);
            tempoCloudAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = tempoCloudManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Tempo Cloud Connector type not defined');
                }
                if (!uid) {
                    throw Error('Tempo Cloud Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.tempoCloud?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Tempo Cloud Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'tempo-cloud',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, tempoCloudManageConnectionAuthorizeLoading$);

                tempoCloudConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    tempoCloudAppSetupDialogErrors$.next(e.message);
                } else {
                    tempoCloudAppSetupDialogErrors$.next(
                        'Failed to save Tempo Cloud application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Tempo Cloud application info', e);
                }
            }

            tempoCloudConnectionSaving$.next(false);
            tempoCloudManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

tempoCloudSaveConnectionAction$
    .pipe(
        map(async (name) => {
            tempoCloudConnectionSaving$.next(true);
            tempoCloudManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = tempoCloudManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Tempo Cloud Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveTempoCloudConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveTempoCloudConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                tempoCloudConnectionCreatedAction$.next(uid);
                                tempoCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                                tempoCloudManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    tempoCloudManageConnectionDialogErrors$,
                                    APP.TEMPO_CLOUD.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, tempoCloudManageConnectionDialogErrors$, APP.TEMPO_CLOUD.NAME);
            }

            tempoCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

tempoCloudManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            tempoCloudConnectionSaving$.next(true);
            tempoCloudManageConnectionAuthorizeLoading$.next(true);
            tempoCloudManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = tempoCloudManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Tempo Cloud Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    tempoCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    tempoCloudAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    tempoCloudConnectionCreatedAction$.next(uid);
                    tempoCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    tempoCloudManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    tempoCloudConnectionSaving$.next(false);

                    tempoCloudAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, tempoCloudManageConnectionDialogErrors$, APP.TEMPO_CLOUD.NAME);
                tempoCloudManageConnectionAuthorizeLoading$.next(false);
            }

            tempoCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(tempoCloudConnectionSavedAction$);

const saveTempoCloudConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        tempoCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
        tempoCloudManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, tempoCloudManageConnectionDialogErrors$, APP.TEMPO_CLOUD.NAME);
    }
};
