import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';
import { saveApiKey } from './save';

interface OpsgenieConnectionDetails extends ConnectionDetails {
    apiKey?: string;
    region?: 'EU' | 'US';
    instanceUrl?: string;
}

interface OpsgenieSetupSaveProps {
    apiKey: string;
    region: 'EU' | 'US';
    instanceUrl: string;
}

export const opsgenieManageConnectionOpenDialogAction$ = monitor(
    'opsgenieManageConnectionOpenDialogAction$',
    new Subject<OpsgenieConnectionDetails>()
);
export const opsgenieManageConnectionCloseDialogAction$ = monitor(
    'opsgenieManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const opsgenieSetupOpenDialogAction$ = monitor(
    'opsgenieSetupOpenDialogAction$',
    new Subject<OpsgenieConnectionDetails>()
);
export const opsgenieSetupCloseDialogAction$ = monitor('opsgenieSetupCloseDialogAction$', new Subject<void>());

export const opsgenieSetupDialogOpen$ = monitor('opsgenieSetupDialogOpen$', new BehaviorSubject(false));
export const opsgenieSetupAuthorizeAction$ = monitor(
    'opsgenieSetupAuthorizeAction$',
    new Subject<OpsgenieSetupSaveProps>()
);
export const opsgenieSetupDialogErrors$ = monitor(
    'opsgenieSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const opsgenieSaveConnectionAction$ = monitor('opsgenieSaveConnectionAction$', new Subject<string>());
export const opsgenieConnectionCreatedAction$ = monitor('opsgenieConnectionCreatedAction$', new Subject<string>());
export const opsgenieConnectionSavedAction$ = monitor(
    'opsgenieConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const opsgenieManageConnectionInitiateSetupAction$ = monitor(
    'opsgenieManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const opsgenieManageConnectionDialogOpen$ = monitor(
    'opsgenieManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const opsgenieConnectionSaving$ = monitor('opsgenieConnectionSaving$', new BehaviorSubject(false));
export const opsgenieManageConnectionAuthorizeLoading$ = monitor(
    'opsgenieManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const opsgenieManageConnectionDetails$ = monitor(
    'opsgenieManageConnectionDetails$',
    new BehaviorSubject<OpsgenieConnectionDetails | undefined>(undefined)
);
export const opsgenieManageConnectionDialogErrors$ = monitor(
    'opsgenieManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const opsgenieSetupDialogStage$ = monitor('opsgenieSetupDialogStage$', new BehaviorSubject(0));

opsgenieSetupOpenDialogAction$.subscribe((details) => {
    opsgenieSetupDialogErrors$.next(undefined);
    opsgenieManageConnectionDialogErrors$.next(undefined);
    opsgenieSetupDialogOpen$.next(true);
    opsgenieSetupDialogStage$.next(0);
    opsgenieManageConnectionDetails$.next(details);
});

opsgenieSetupCloseDialogAction$.subscribe(() => {
    opsgenieSetupDialogOpen$.next(false);
    opsgenieManageConnectionAuthorizeLoading$.next(false);
});

opsgenieManageConnectionOpenDialogAction$.subscribe((details) => {
    opsgenieSetupDialogErrors$.next(undefined);
    opsgenieManageConnectionDialogErrors$.next(undefined);
    opsgenieManageConnectionDialogOpen$.next(true);
    opsgenieManageConnectionDetails$.next(details);
});

opsgenieManageConnectionCloseDialogAction$.subscribe(() => {
    opsgenieManageConnectionDialogOpen$.next(false);
    opsgenieSetupDialogOpen$.next(false);
    opsgenieManageConnectionDetails$.next(undefined);
});

opsgenieConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

opsgenieSetupAuthorizeAction$
    .pipe(
        map(async ({ apiKey, region, instanceUrl }) => {
            opsgenieConnectionSaving$.next(true);
            opsgenieSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = opsgenieManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Opsgenie connector type not defined');
                }

                if (!uid) {
                    throw Error('Opsgenie connector has not been created so cannot configure it');
                }

                await saveApiKey({ apiKey, region, instanceUrl, connectionId: uid });

                opsgenieConnectionCreatedAction$.next(uid);
                opsgenieConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                opsgenieSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Opsgenie connector has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    opsgenieSetupDialogErrors$.next(e.message);
                } else {
                    opsgenieSetupDialogErrors$.next(
                        'Failed to save Opsgenie connector details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Opsgenie connector info', e);
                }
            }

            opsgenieConnectionSaving$.next(false);
            opsgenieManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

opsgenieSaveConnectionAction$
    .pipe(
        map(async (name) => {
            opsgenieConnectionSaving$.next(true);
            opsgenieManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = opsgenieManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Opsgenie connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveOpsgenieConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveOpsgenieConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                opsgenieConnectionCreatedAction$.next(uid);
                                opsgenieConnectionSavedAction$.next({ uid, connectionTypeUid });
                                opsgenieManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    opsgenieManageConnectionDialogErrors$,
                                    APP.OPSGENIE.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, opsgenieManageConnectionDialogErrors$, APP.OPSGENIE.NAME);
            }

            opsgenieConnectionSaving$.next(false);
        })
    )
    .subscribe();

opsgenieManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            opsgenieConnectionSaving$.next(true);
            opsgenieManageConnectionAuthorizeLoading$.next(true);
            opsgenieManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = opsgenieManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Opsgenie connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    opsgenieConnectionSavedAction$.next({ uid, connectionTypeUid });
                    opsgenieSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    opsgenieConnectionCreatedAction$.next(uid);
                    opsgenieConnectionSavedAction$.next({ uid, connectionTypeUid });

                    opsgenieManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    opsgenieConnectionSaving$.next(false);

                    opsgenieSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, opsgenieManageConnectionDialogErrors$, APP.OPSGENIE.NAME);
                opsgenieManageConnectionAuthorizeLoading$.next(false);
            }

            opsgenieConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(opsgenieConnectionSavedAction$);

const saveOpsgenieConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        opsgenieConnectionSavedAction$.next({ uid, connectionTypeUid });
        opsgenieManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, opsgenieManageConnectionDialogErrors$, APP.OPSGENIE.NAME);
    }
};
