import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime,
    distinct,
    EMPTY,
    filter,
    from,
    map,
    mergeMap,
    Subject,
    toArray,
} from 'rxjs';
import {
    getWorkspacePackages,
    saveWorkspacePackages,
    WorkspacePackage,
    WorkspacePackages,
} from '../../data/package-manager';
import { fetchPackageFromNpmRegistry, NPM_PACKAGE_URL } from '../../npm/npm';
import { InformativeError } from '../../utils/repository';
import { apps$ } from '../apps';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { loggedInUserDetails$ } from '../user';
import { monitor } from '../monitor';
import { PackageUpgrade } from '../../components/workspace-dialogs/PackageUpgradesDialog';
import { coerce, diff, gt, valid } from 'semver';
import latestSemver from 'latest-semver';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { compileAndBundleWorkspaceScriptsAction$ } from '../workspaces';
import {
    fetchPackagesAction$,
    legacyPackageImports$,
    legacyPackagesDialogErrors$,
    legacyPackagesDialogLoading$,
    legacyPackagesDialogOpen$,
} from '../editor/editor';
import {
    Package,
    PACKAGE_MANAGER_CORE_PACKAGES,
    PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES,
    RUNTIME_PACKAGE,
    ThirdPartyPackage,
} from '../../components/workspace-dialogs/package-manager';
import * as acorn from 'acorn';
import copy from 'copy-to-clipboard';

interface AddThirdPartyPackageEvent {
    name: string;
    workspaceUid: string;
}

interface SaveWorkspacePackagesEvent {
    workspaceUid: string;
    packages: WorkspacePackages;
}

interface UpgradeWorkspacePackagesEvent extends SaveWorkspacePackagesEvent {
    changelogClickCount: number;
}

interface UpgradeWorkspaceLegacyPackagesEvent {
    workspaceUid: string;
    legacyPackages: string[];
}

const packageChangelogsCache = new Map<string, string>();

export const openPackageManagerAction$ = monitor('openPackageManagerAction$', new Subject<void>());
export const closePackageManagerAction$ = monitor('closePackageManagerAction$', new Subject<void>());
export const copyPackageImportAction$ = monitor(
    'copyPackageImportAction$',
    new Subject<{ packageName: string; isCorePackage: boolean }>()
);
export const copyPackageImportLoading$ = monitor('copyPackageImportLoading$', new BehaviorSubject(false));
export const packageManagerOpen$ = monitor('packageManagerOpen$', new BehaviorSubject(false));

export const packageManagerManagedAPIs$ = monitor('packageManagerManagedAPIs$', new BehaviorSubject<Package[]>([]));
export const packageManagerCorePackages$ = monitor('packageManagerCorePackages$', new BehaviorSubject<Package[]>([]));
export const packageManagerThirdPartyPackages$ = monitor(
    'packageManagerThirdPartyPackages$',
    new BehaviorSubject<ThirdPartyPackage[]>([])
);

export const implicitlyIncludedPackages$ = monitor(
    'implicitlyIncludedPackages$',
    new BehaviorSubject<WorkspacePackages>([])
);

export const savingPackageManager$ = monitor('savingPackageManager$', new BehaviorSubject(false));
export const saveWorkspacePackagesAction$ = monitor(
    'saveWorkspacePackagesAction$',
    new Subject<SaveWorkspacePackagesEvent>()
);

export const addThirdPartyPackageAction$ = monitor(
    'addThirdPartyPackageAction$',
    new Subject<AddThirdPartyPackageEvent>()
);

export const openPackageUpgradesDialogAction$ = monitor(
    'openPackageUpgradesDialogAction$',
    new Subject<PackageUpgrade[]>()
);
export const upgradeWorkspacePackagesAction$ = monitor(
    'upgradeWorkspacePackagesAction$',
    new Subject<UpgradeWorkspacePackagesEvent>()
);

export const upgradeWorkspaceLegacyPackagesAction$ = monitor(
    'upgradeWorkspaceLegacyPackagesAction$',
    new Subject<UpgradeWorkspaceLegacyPackagesEvent>()
);

export const selectedWorkspacePackages$ = monitor(
    'selectedWorkspacePackages$',
    new BehaviorSubject<WorkspacePackages>([])
);
export const selectedWorkspaceImplicitPackages$ = monitor(
    'selectedWorkspaceImplicitPackages$',
    new BehaviorSubject<WorkspacePackages>([])
);
export const packageManagerAddThirdPartyPackageErrors$ = monitor(
    'packageManagerAddThirdPartyPackageErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const packageUpgradesDialogErrors$ = monitor(
    'packageUpgradesDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const packageUpgradesDialogOpen$ = monitor('packageUpgradesDialogOpen$', new BehaviorSubject(false));
export const packageUpgradesDialogSaving$ = monitor('packageUpgradesDialogSaving$', new BehaviorSubject(false));
export const workspacesSnoozingPackageUpgrades$ = monitor(
    'workspacesSnoozingPackageUpgrades$',
    new BehaviorSubject<string[]>([])
);
export const packageUpgradesDialogPackages$ = monitor(
    'packageUpgradesDialogPackages$',
    new BehaviorSubject<PackageUpgrade[]>([])
);
export const saveBlankWorkspaceCorePackagesAction$ = monitor(
    'saveBlankWorkspaceCorePackagesAction$',
    new Subject<{ workspaceUid: string }>()
);

openPackageUpgradesDialogAction$.subscribe((pkgs) => {
    packageUpgradesDialogPackages$.next(pkgs);
    packageUpgradesDialogOpen$.next(true);
});

openPackageManagerAction$.subscribe(() => {
    packageManagerOpen$.next(true);
    packageManagerAddThirdPartyPackageErrors$.next(undefined);
    savingPackageManager$.next(false);
});

closePackageManagerAction$.subscribe(() => packageManagerOpen$.next(false));

saveWorkspacePackagesAction$
    .pipe(
        map(async (event) => {
            savingPackageManager$.next(true);
            packageManagerAddThirdPartyPackageErrors$.next(undefined);

            try {
                await saveWorkspacePackages(event.workspaceUid, event.packages);

                if (!workspacesSnoozingPackageUpgrades$.value?.includes(event.workspaceUid)) {
                    workspacesSnoozingPackageUpgrades$.next([
                        ...workspacesSnoozingPackageUpgrades$.value,
                        event.workspaceUid,
                    ]);
                }

                compileAndBundleWorkspaceScriptsAction$.next();

                const selectedWorkspacePackages = await getWorkspacePackages(event.workspaceUid);

                selectedWorkspacePackages$.next(selectedWorkspacePackages);
                closePackageManagerAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace packages saved.',
                    noToast: true,
                });
            } catch (e) {
                savingPackageManager$.next(false);
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                        noToast: true,
                    });
                } else {
                    console.error('Failed to save workspace packages', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message:
                            'Failed to save workspace packages, please try again, if the issue persists please contact support.',
                        noToast: true,
                    });
                }
            }
        })
    )
    .subscribe();

upgradeWorkspaceLegacyPackagesAction$
    .pipe(
        map(async (event) => {
            legacyPackagesDialogLoading$.next(true);
            legacyPackagesDialogErrors$.next(undefined);

            try {
                const corePackages = packageManagerCorePackages$.value;
                const managedApiPackages = packageManagerManagedAPIs$.value;
                const thirdPartyPackages = packageManagerThirdPartyPackages$.value;

                const packageUpgrades = event.legacyPackages.reduce<WorkspacePackages>((acc, legacy) => {
                    const newPackage = legacy.replace('stitch-it', 'sr-connect');
                    const foundCorePackage = corePackages.find((pkg) => newPackage.startsWith(pkg.name));
                    if (foundCorePackage) {
                        acc.push({
                            name: foundCorePackage.name,
                            type: 'CORE',
                            version: foundCorePackage.selectedVersion,
                        });
                    }
                    const foundManagedApiPackage = managedApiPackages.find((pkg) => newPackage.startsWith(pkg.name));
                    if (foundManagedApiPackage) {
                        acc.push({
                            name: foundManagedApiPackage.name,
                            type: 'MANAGED_API',
                            version: foundManagedApiPackage.selectedVersion,
                        });
                    }
                    const foundThirdPartyPackage = thirdPartyPackages.find((pkg) => newPackage.startsWith(pkg.name));
                    if (foundThirdPartyPackage) {
                        acc.push({
                            name: foundThirdPartyPackage.name,
                            type: 'THIRD_PARTY',
                            version: foundThirdPartyPackage.selectedVersion,
                        });
                    }
                    return acc;
                }, []);

                const allSelectedPackages = selectedWorkspacePackages$.value;
                const restOfPackages = allSelectedPackages.filter(
                    (sp) =>
                        !packageUpgrades.some((p) => p.name === sp.name) &&
                        !event.legacyPackages.some((legacy) => legacy.startsWith(sp.name))
                );

                await saveWorkspacePackages(event.workspaceUid, [...restOfPackages, ...packageUpgrades]);

                if (!workspacesSnoozingPackageUpgrades$.value?.includes(event.workspaceUid)) {
                    workspacesSnoozingPackageUpgrades$.next([
                        ...workspacesSnoozingPackageUpgrades$.value,
                        event.workspaceUid,
                    ]);
                }

                compileAndBundleWorkspaceScriptsAction$.next();

                const selectedWorkspacePackages = await getWorkspacePackages(event.workspaceUid);
                selectedWorkspacePackages$.next(selectedWorkspacePackages);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace legacy packages upgraded.',
                });

                legacyPackagesDialogOpen$.next(false);
                legacyPackagesDialogLoading$.next(false);
                legacyPackagesDialogErrors$.next(undefined);
                legacyPackageImports$.next(null);
            } catch (e) {
                legacyPackagesDialogLoading$.next(false);
                if (e instanceof InformativeError) {
                    legacyPackagesDialogErrors$.next(e.message);
                } else {
                    console.error('Failed to upgrade workspace legacy packages', e);
                    legacyPackagesDialogErrors$.next(
                        'Failed to upgrade workspace legacy packages, please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

addThirdPartyPackageAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (event) => {
            savingPackageManager$.next(true);
            packageManagerAddThirdPartyPackageErrors$.next(undefined);
            const packages = packageManagerThirdPartyPackages$.value;

            try {
                if (!packages.find((pkg) => pkg.name === event.name)) {
                    const response = await fetchPackageFromNpmRegistry(event.name);

                    if (!workspacesSnoozingPackageUpgrades$.value?.includes(event.workspaceUid)) {
                        workspacesSnoozingPackageUpgrades$.next([
                            ...workspacesSnoozingPackageUpgrades$.value,
                            event.workspaceUid,
                        ]);
                    }

                    const parentPackage = packages.find((pkg) => `@types/${pkg.name}` === response.name);
                    const childPackage = packages.find(
                        (pkg) => pkg.name.startsWith('@types/') && pkg.name.slice(7) === response.name
                    );

                    if (parentPackage) {
                        packageManagerThirdPartyPackages$.next(
                            packages.map((pkg) => {
                                if (pkg.name === parentPackage.name) {
                                    return {
                                        ...pkg,
                                        childPackage: {
                                            name: response.name,
                                            selectedVersion: response['dist-tags'].latest,
                                            versions: Object.keys(response.versions),
                                            description: response.description,
                                            selected: true,
                                            repository: response.repository,
                                        },
                                    };
                                }
                                return pkg;
                            })
                        );
                    } else if (childPackage) {
                        packageManagerThirdPartyPackages$.next([
                            ...packages.filter((pkg) => pkg.name !== childPackage.name),
                            {
                                name: response.name,
                                selectedVersion: response['dist-tags'].latest,
                                versions: Object.keys(response.versions),
                                description: response.description,
                                thirdParty: true,
                                selected: true,
                                verified: false,
                                repository: response.repository,
                                childPackage: {
                                    name: childPackage.name,
                                    selectedVersion: childPackage.selectedVersion,
                                    versions: childPackage.versions,
                                    description: childPackage.description,
                                    selected: childPackage.selected,
                                    repository: childPackage.repository,
                                },
                            },
                        ]);
                    } else {
                        packageManagerThirdPartyPackages$.next([
                            ...packages,
                            {
                                name: response.name,
                                selectedVersion: response['dist-tags'].latest,
                                versions: Object.keys(response.versions),
                                description: response.description,
                                thirdParty: true,
                                selected: true,
                                verified: false,
                                repository: response.repository,
                            },
                        ]);
                    }
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    packageManagerAddThirdPartyPackageErrors$.next(e.message);
                } else {
                    console.error('Failed to add third party package', e);
                    packageManagerAddThirdPartyPackageErrors$.next(
                        'Failed to add third party package to workspace, please try again, if the issue persists please contact support'
                    );
                }
            }

            savingPackageManager$.next(false);
        })
    )
    .subscribe();

upgradeWorkspacePackagesAction$
    .pipe(
        map(async (event) => {
            packageUpgradesDialogSaving$.next(true);
            packageUpgradesDialogErrors$.next(undefined);

            try {
                const allSelectedPackages = selectedWorkspacePackages$.value;
                const restOfPackages = allSelectedPackages.filter(
                    (sp) => !event.packages.some((p) => p.name === sp.name)
                );

                await saveWorkspacePackages(event.workspaceUid, [...restOfPackages, ...event.packages]);

                if (!workspacesSnoozingPackageUpgrades$.value?.includes(event.workspaceUid)) {
                    workspacesSnoozingPackageUpgrades$.next([
                        ...workspacesSnoozingPackageUpgrades$.value,
                        event.workspaceUid,
                    ]);
                }

                compileAndBundleWorkspaceScriptsAction$.next();

                packageUpgradesDialogOpen$.next(false);
                packageUpgradesDialogSaving$.next(false);
                packageUpgradesDialogPackages$.next([]);
                segmentAnalyticsTrack('Package Upgrades Modal Closed', {
                    userId: loggedInUserDetails$.value?.uid,
                    stitchTeamMember: loggedInUserDetails$.value?.stitchTeamMember,
                    userOrigin: loggedInUserDetails$.value?.userOrigin,
                    upgradesNeeded: packageUpgradesDialogPackages$.value?.length,
                    upgradesCompleted: event.packages.length,
                    uniqueChangelogClicks: event.changelogClickCount,
                });
                const selectedWorkspacePackages = await getWorkspacePackages(event.workspaceUid);
                selectedWorkspacePackages$.next(selectedWorkspacePackages);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace packages upgraded.',
                });
            } catch (e) {
                packageUpgradesDialogSaving$.next(false);
                if (e instanceof InformativeError) {
                    packageUpgradesDialogErrors$.next(e.message);
                } else {
                    console.error('Failed to upgrade workspace packages', e);
                    packageUpgradesDialogErrors$.next(
                        'Failed to upgrade workspace packages, please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

apps$
    .pipe(
        filter((apps) => apps.length > 0),
        map((apps) =>
            apps.flatMap((app) =>
                app.connectionType.apiHandlerTypes.flatMap((apiHandlerType) =>
                    apiHandlerType.apiHandlerLibraries.map((apiHandlerLibrary) => apiHandlerLibrary.name)
                )
            )
        ),
        mergeMap((packages) =>
            from(packages).pipe(
                distinct((pkg) => pkg),
                mergeMap(fetchPackageFromNpmRegistry),
                catchError(() => EMPTY),
                map((pkg) => ({
                    name: pkg.name,
                    selectedVersion: pkg['dist-tags'].latest,
                    latestVersion: pkg['dist-tags'].latest,
                    versions: Object.keys(pkg.versions),
                    description: pkg.description,
                })),
                toArray()
            )
        )
    )
    .subscribe((pkgs) => packageManagerManagedAPIs$.next(pkgs));

apps$
    .pipe(
        filter((apps) => apps.length > 0),
        map((apps) =>
            apps.flatMap((app) =>
                app.connectionType.eventListenerTypes.flatMap((eventListenerType) =>
                    eventListenerType.eventListenerLibraries.map((eventListenerLibrary) => eventListenerLibrary.name)
                )
            )
        ),
        mergeMap((packages) =>
            from([...packages, ...PACKAGE_MANAGER_CORE_PACKAGES]).pipe(
                distinct((pkg) => pkg),
                mergeMap(fetchPackageFromNpmRegistry),
                catchError(() => EMPTY),
                map((pkg) => {
                    const isCore = PACKAGE_MANAGER_CORE_PACKAGES.includes(pkg.name);
                    return {
                        name: pkg.name,
                        selectedVersion: pkg['dist-tags'].latest,
                        latestVersion: pkg['dist-tags'].latest,
                        versions: Object.keys(pkg.versions),
                        description: pkg.description,
                        locked: isCore,
                        selected: isCore,
                    };
                }),
                toArray()
            )
        )
    )
    .subscribe((pkgs) => packageManagerCorePackages$.next(pkgs));

selectedWorkspacePackages$
    .pipe(
        map((pkgs) => pkgs.filter((pkg) => pkg.type === 'THIRD_PARTY')),
        mergeMap((pkgs) =>
            from([...PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES, ...pkgs.map((pkg) => pkg.name)]).pipe(
                distinct((pkg) => pkg),
                mergeMap(fetchPackageFromNpmRegistry),
                catchError(() => EMPTY),
                map((pkg) => {
                    const selectedPkg = pkgs.find((slcPkg) => slcPkg.name === pkg.name);
                    const versions = Object.keys(pkg.versions);
                    return {
                        name: pkg.name,
                        selectedVersion: selectedPkg?.version ?? pkg['dist-tags'].latest,
                        latestVersion: pkg['dist-tags'].latest,
                        versions,
                        description: pkg.description,
                        thirdParty: true,
                        selected: !!selectedPkg,
                        verified: !!PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES.find((name) => name === pkg.name),
                        repository: pkg.repository,
                    };
                }),
                toArray(),
                map((pkgs) => {
                    const thirdPartyPackages: Record<string, ThirdPartyPackage> = {};
                    pkgs.forEach((pkg) => {
                        if (pkg.name.startsWith('@types/')) {
                            const parentPackage = thirdPartyPackages[pkg.name.slice(7)];
                            if (parentPackage) {
                                thirdPartyPackages[parentPackage.name] = {
                                    ...parentPackage,
                                    childPackage: {
                                        name: pkg.name,
                                        selectedVersion: pkg.selectedVersion,
                                        latestVersion: pkg.latestVersion,
                                        versions: pkg.versions,
                                        description: pkg.description,
                                        selected: pkg.selected,
                                        repository: pkg.repository,
                                    },
                                };
                            } else {
                                thirdPartyPackages[pkg.name] = pkg;
                            }
                        } else {
                            const childPackage = thirdPartyPackages[`@types/${pkg.name}`];
                            if (childPackage) {
                                thirdPartyPackages[pkg.name] = {
                                    ...pkg,
                                    childPackage: {
                                        name: childPackage.name,
                                        selectedVersion: childPackage.selectedVersion,
                                        latestVersion: childPackage.latestVersion,
                                        versions: childPackage.versions,
                                        description: childPackage.description,
                                        selected: childPackage.selected,
                                        repository: childPackage.repository,
                                    },
                                };
                                delete thirdPartyPackages[`@types/${pkg.name}`];
                            } else {
                                thirdPartyPackages[pkg.name] = pkg;
                            }
                        }
                    });

                    return Object.values(thirdPartyPackages);
                })
            )
        )
    )
    .subscribe((pkgs) => packageManagerThirdPartyPackages$.next(pkgs));

combineLatest([
    selectedWorkspacePackages$,
    packageManagerCorePackages$,
    packageManagerManagedAPIs$,
    packageManagerThirdPartyPackages$,
])
    .pipe(
        map(async ([slcPkgs, cPkgs, mApis, tpPkgs]) => {
            const packageUpgrades = [
                (await Promise.all(slcPkgs.map((sp) => mapPackageUpgrade(sp, cPkgs, 'Core Package')))).flat(),
                (await Promise.all(slcPkgs.map((sp) => mapPackageUpgrade(sp, mApis, 'Managed API')))).flat(),
                (await Promise.all(slcPkgs.map((sp) => mapPackageUpgrade(sp, tpPkgs, 'Third Party')))).flat(),
            ].flat();

            if (packageUpgrades.length) {
                openPackageUpgradesDialogAction$.next(packageUpgrades);
            }
        })
    )
    .subscribe();

combineLatest([selectedWorkspacePackages$, implicitlyIncludedPackages$])
    .pipe(
        debounceTime(100),
        filter(([, impPkgs]) => impPkgs.length > 0),
        map(([slcPkgs, impPkgs]) => {
            const fetchPackages = impPkgs.reduce<Record<string, WorkspacePackage>>((acc, pkg) => {
                acc[pkg.name] = pkg;
                return acc;
            }, {});
            slcPkgs.forEach((pkg) => {
                fetchPackages[pkg.name] = pkg;
            });
            return Object.values(fetchPackages);
        })
    )
    .subscribe((pkgs) => fetchPackagesAction$.next(pkgs));

saveBlankWorkspaceCorePackagesAction$
    .pipe(
        map(async (event) => {
            try {
                const currentCorePackages = PACKAGE_MANAGER_CORE_PACKAGES.filter((pkg) => pkg !== RUNTIME_PACKAGE);
                const allCorePackages = packageManagerCorePackages$.value;
                let corePackages: WorkspacePackages = [];

                if (allCorePackages.length > 0) {
                    corePackages = allCorePackages
                        .filter((pkg) => currentCorePackages.includes(pkg.name))
                        .map((pkg) => ({
                            name: pkg.name,
                            version: pkg.latestVersion ?? pkg.selectedVersion,
                            type: 'CORE',
                        }));
                } else {
                    const coreNpmPackages = await Promise.all(currentCorePackages.map(fetchPackageFromNpmRegistry));
                    corePackages = coreNpmPackages.map((pkg) => ({
                        name: pkg.name,
                        version: pkg['dist-tags'].latest,
                        type: 'CORE',
                    }));
                }

                if (corePackages.length > 0) {
                    await saveWorkspacePackages(event.workspaceUid, corePackages);

                    const selectedWorkspacePackages = await getWorkspacePackages(event.workspaceUid);
                    selectedWorkspacePackages$.next(selectedWorkspacePackages);
                }
            } catch (e) {
                console.error('Failed to save workspace core packages', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        'Failed to save workspace core packages, please try again, if the issue persists please contact support.',
                    noToast: true,
                });
            }
        })
    )
    .subscribe();

const mapPackageUpgrade = async (
    selectedPackage: WorkspacePackages[number],
    packagesOfType: Package[],
    type: string
): Promise<PackageUpgrade[]> => {
    const selectedPackageOfType =
        packagesOfType.find((pkgOt) => pkgOt.name == selectedPackage.name) ??
        packagesOfType.find((pkgOt) => pkgOt.childPackage?.name === selectedPackage.name)?.childPackage;

    if (selectedPackageOfType) {
        const latestVersion = selectedPackageOfType.latestVersion ?? latestSemver(selectedPackageOfType.versions);

        //For the latest version, also checking for any 'alpha' or 'beta', etc. tags, the existence of which means no upgrade will be suggested
        const areVersionsValid = valid(coerce(latestVersion)) === latestVersion && valid(selectedPackage.version);

        const hasUpgrades = areVersionsValid && gt(latestVersion, selectedPackage.version);

        if (hasUpgrades) {
            const documentationLink = await getPackageChangelog(
                selectedPackage.name,
                type,
                selectedPackageOfType.repository
            );
            let isMajorUpdate = false;

            try {
                isMajorUpdate = diff(latestVersion, selectedPackage.version) === 'major';
            } catch (e) {
                console.error(`Error comparing versions for ${selectedPackage.name}`, e);
                isMajorUpdate = false;
            }
            return [
                {
                    installedVersion: selectedPackage.version,
                    latestVersion: latestVersion,
                    majorUpdate: isMajorUpdate,
                    name: selectedPackage.name,
                    npmLink: `${NPM_PACKAGE_URL}/${selectedPackage.name}`,
                    type,
                    documentationLink,
                },
            ];
        } else {
            return [];
        }
    } else {
        return [];
    }
};

const CUSTOM_CHANGELOGS = [
    { packageName: 'slack-block-builder', changelogUrl: 'https://github.com/raycharius/slack-block-builder/releases' },
    { packageName: 'chai', changelogUrl: 'https://github.com/chaijs/chai/releases' },
];

export const getPackageChangelog = async (
    packageName: string,
    packageType: string,
    repositoryData?: { type?: string; url?: string }
    // eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<string> => {
    const changelogFromCache = packageChangelogsCache.get(packageName);

    if (changelogFromCache) {
        return changelogFromCache;
    }

    const npmLink = `${NPM_PACKAGE_URL}/${packageName}`;

    const customChangelog = CUSTOM_CHANGELOGS.find((cl) => cl.packageName === packageName);

    if (customChangelog) {
        packageChangelogsCache.set(packageName, customChangelog.changelogUrl);
        return customChangelog.changelogUrl;
    }

    if (packageType === 'Managed API' || packageType === 'Core Package') {
        packageChangelogsCache.set(packageName, `${npmLink}#changelog`);
        return `${npmLink}#changelog`;
    }

    try {
        if (repositoryData?.type !== 'git') {
            packageChangelogsCache.set(packageName, npmLink);
            return npmLink;
        }

        const repositoryUrl = repositoryData.url?.slice(0, repositoryData.url.length - 4).replace('git+', '');

        if (repositoryUrl) {
            let changelogUrl: string | null = null;
            const potentialFileNames = ['CHANGELOG', 'changelog', 'ChangeLog', 'History', 'HISTORY', 'CHANGES'];

            for (const name of potentialFileNames) {
                const rawContentUrl = repositoryUrl.replace('github.com', 'raw.githubusercontent.com');

                const extensions = ['md', 'txt'];

                for (const extension of extensions) {
                    const urlAttempt = `${rawContentUrl}/master/${name}.${extension}`;

                    if (await isUrlReachable(urlAttempt)) {
                        changelogUrl = `${repositoryUrl}/blob/master/${name}.${extension}`;
                        break;
                    }
                }
                if (changelogUrl) {
                    break;
                }
            }
            if (changelogUrl) {
                packageChangelogsCache.set(packageName, changelogUrl);
                return changelogUrl;
            }
            if (await repoHasReleases(repositoryUrl)) {
                packageChangelogsCache.set(packageName, `${repositoryUrl}/releases`);
                return `${repositoryUrl}/releases`;
            }
        }
    } catch (e) {
        console.error(`Failed to get repository url for package: ${packageName}`);
        return npmLink;
    }

    packageChangelogsCache.set(packageName, npmLink);
    return npmLink;
};

const repoHasReleases = async (repoUrl: string): Promise<boolean> => {
    const repoName = `${repoUrl.replace('https://github.com/', '')}`;
    const response = await fetch(`https://api.github.com/repos/${repoName}/releases`, {
        headers: {
            Accept: 'application/vnd.github.v3+json',
        },
    });
    const releases = await response.json();

    if (releases.length) {
        return true;
    }
    return false;
};

const isUrlReachable = async (url: string): Promise<boolean> => {
    try {
        const response = await fetch(url);
        if (response.ok) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

copyPackageImportAction$
    .pipe(
        map(async ({ packageName, isCorePackage }) => {
            copyPackageImportLoading$.next(true);
            const corePackages = ['@sr-connect/convert', '@sr-connect/record-storage', '@sr-connect/trigger'];
            if (!corePackages.some((name) => name === packageName) && isCorePackage) {
                copyPackageImportLoading$.next(false);
                copy(`import * as ${importAlias(packageName)} from '${packageName}/events';`);
            } else {
                const importString = await getImportString(`https://cdn.jsdelivr.net/npm/${packageName}`, packageName);
                copyPackageImportLoading$.next(false);
                copy(importString);
            }
        })
    )
    .subscribe();

const importAlias = (name: string): string => {
    const alias = name.replace(/@[^/]+\/([a-zA-Z0-9-]+)/, (_, packageName: string) => packageName);
    return alias
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
};

// eslint-disable-next-line sonarjs/cognitive-complexity
async function getImportString(moduleUrl: string, packageName: string): Promise<string> {
    try {
        // Fetch the module source code
        const response = await fetch(moduleUrl);
        const code = await response.text();

        // Parse the source code into an AST
        const ast = acorn.parse(code, { sourceType: 'module', ecmaVersion: 'latest' });

        let hasDefaultExport = false;
        const confirmedNamedExports: string[] = [];

        for (const node of ast.body) {
            if (node.type === 'ExportNamedDeclaration') {
                if (node.declaration) {
                    if (node.declaration.type === 'VariableDeclaration') {
                        node.declaration.declarations.forEach((declarator) => {
                            if (declarator.id.type === 'Identifier') {
                                confirmedNamedExports.push(declarator.id.name);
                            }
                        });
                    } else if (
                        node.declaration.type === 'FunctionDeclaration' &&
                        node.declaration.id?.type === 'Identifier'
                    ) {
                        confirmedNamedExports.push(node.declaration.id.name);
                    } else if (
                        node.declaration.type === 'ClassDeclaration' &&
                        node.declaration.id?.type === 'Identifier'
                    ) {
                        confirmedNamedExports.push(node.declaration.id.name);
                    }
                }

                // Handle export specifiers (re-exports or specific exports)
                if (node.specifiers) {
                    node.specifiers.forEach((specifier) => {
                        if (specifier.exported.type === 'Identifier') {
                            confirmedNamedExports.push(specifier.exported.name);
                        }
                    });
                }
            } else if (node.type === 'ExportDefaultDeclaration') {
                hasDefaultExport = true;
            }
        }

        // Construct the appropriate import string
        if (confirmedNamedExports.length > 0) {
            const uniqueNamedExports = [...new Set(confirmedNamedExports)];
            const namedExportString = uniqueNamedExports.join(', ');
            return `import { ${namedExportString} } from '${packageName}';`;
        } else if (hasDefaultExport) {
            return `import ${importAlias(packageName)} from '${packageName}';`;
        } else {
            return `import * as ${importAlias(packageName)} from '${packageName}';`;
        }
    } catch (error) {
        console.error(`Failed to analyze AST for module: ${moduleUrl}`, error);
        return packageName;
    }
}
