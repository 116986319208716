import { LoaderFn, UnloaderFn } from 'react-location';
import { getWorkspace } from '../../data/workspace';
import { LocationGenerics } from '../../router';
import { loadApps } from '../../store/apps';
import {
    exitWorkspaceAction$,
    ignoreNextWorkspaceExit$,
    selectedWorkspace$,
    selectEnvironmentAction$,
    selectWorkspaceAction$,
} from '../../store/workspace';
import { loadEnvironmentVariables, loadWorkspaceResources, loadWorkspaces } from '../../store/workspace/utils';
import { initEditor, resetEditor } from './editor';
import { resetAiAssistanceChatAction$ } from '../../store/workspace/ai-assistance';
import { loadLoggedInUserOrganizations } from '../../store/organizations';
import { getBillingEntities } from '../../data/billing-details';
import { billingEntities$ } from '../../store/billing-details';
import { loggedInUserDetails$ } from '../../store/user';
import { loadErrorPage } from '../../store/error';
import { unsavedEnvironmentVariables$ } from '../../store/workspace/environment-variable';
import { selectedOrganizationUid$, switchOrganizationAction$ } from '../../store/organization';
import { Language } from '../../components/workspace-dialogs/language-selector';

export const workspaceLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    resetAiAssistanceChatAction$.next();

    try {
        const [workspace, billingEntities] = await Promise.all([
            getWorkspace(routeMatch.params.workspaceUid ?? '', !loggedInUserDetails$.value?.impersonating),
            getBillingEntities(),
            loadWorkspaces(selectedOrganizationUid$.value ?? '', false),
            loadApps(),
            loadLoggedInUserOrganizations(),
        ]);

        await loadWorkspaceResources(routeMatch.params.workspaceUid ?? '', routeMatch.params.environmentUid ?? '');
        if (!unsavedEnvironmentVariables$.value) {
            await loadEnvironmentVariables(
                routeMatch.params.workspaceUid ?? '',
                routeMatch.params.environmentUid ?? ''
            );
        }

        selectEnvironmentAction$.next(routeMatch.params.environmentUid);
        selectWorkspaceAction$.next(routeMatch.params.workspaceUid);
        selectedWorkspace$.next(workspace);
        billingEntities$.next(billingEntities);

        if (
            selectedWorkspace$.value?.organization?.uid &&
            selectedWorkspace$.value.organization.uid !== selectedOrganizationUid$.value
        ) {
            switchOrganizationAction$.next(selectedWorkspace$.value.organization.uid);
        }

        initEditor(workspace.language as Language);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load workspace.',
        });

        throw e;
    }

    return {};
};

export const workspaceUnloader: UnloaderFn<LocationGenerics> = (routeMatch) => {
    if (ignoreNextWorkspaceExit$.value) {
        ignoreNextWorkspaceExit$.next(false);
    } else {
        exitWorkspaceAction$.next(routeMatch.params.workspaceUid ?? '');
    }

    resetEditor();
};
