import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ThreeDotsButton } from '../../common/buttons/ThreeDotsButton';
import { AvatarContainer, OrgAvatarContainer } from '../../common/Avatars';
import {
    selectorCardStyle,
    SelectorItemTitle,
    selectorCardUserAvatarsStyle,
    SelectorImageBox,
    TemplateStyledChip,
} from '../../app-main/SelectorComponents';
import Tooltip from '@mui/material/Tooltip';
import { handleDisplayName } from '../../../utils/displayName';
import { InfoIcon } from '../../icons/InfoIcon';

interface OrganizationSelectorCardProps {
    uid: string;
    name: string;
    avatar?: string;
    createdDate: string;
    planName: string;
    ownedDefaultOrganization?: boolean;
    members: {
        firstName?: string;
        lastName?: string;
        email: string;
        avatar?: string;
    }[];
    onOpen(orgUid: string): void;
    onDelete(orgUid: string): void;
}

const StyledCard = styled(Card)(({ theme }) => ({
    ...selectorCardStyle(theme),
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    padding: theme.spacing(2, 2, 1),
    width: 380,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(1, 0, 0) + ' !important',
}));

const StyledDate = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginTop: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 16,
        marginRight: theme.spacing(1),
        width: 16,
    },
}));

const StyledMediaContainer = styled('div')(({ theme }) => ({
    alignItems: 'flex-end',
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
}));

const StyledOrgImageBox = styled('div')(({ theme }) => ({
    backgroundClip: 'padding-box',
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: 12,
    height: 55,
    overflow: 'hidden',
    width: 55,
}));

const StyledInfo = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiSvgIcon-root': {
        height: 20,
        marginRight: theme.spacing(1),
        width: 20,
    },
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 3,
}));

const StyledUserAvatars = styled('div')(({ theme }) => ({
    ...selectorCardUserAvatarsStyle(theme),
}));

const defaultOrganizationTooltip = 'This team cannot be deleted as it is the default team for personal use.';

export const OrganizationSelectorCard: React.FC<OrganizationSelectorCardProps> = ({
    uid,
    createdDate,
    ownedDefaultOrganization = false,
    name,
    planName,
    members = [],
    avatar,
    onOpen,
    onDelete,
}) => {
    const MediaContainer: React.FC = () => {
        return (
            <>
                <StyledMediaContainer>
                    <StyledUserAvatars>
                        {members.map((member, index) => {
                            return member.avatar ? (
                                <SelectorImageBox key={'userAvatar' + index}>
                                    <Tooltip title={handleDisplayName(member.email, member.firstName, member.lastName)}>
                                        <img
                                            src={`data:image/jpeg;base64,${member.avatar}`}
                                            width={30}
                                            height={30}
                                            alt={member.firstName + ' ' + member.lastName}
                                        />
                                    </Tooltip>
                                </SelectorImageBox>
                            ) : (
                                <Tooltip
                                    key={index}
                                    title={handleDisplayName(member.email, member.firstName, member.lastName)}
                                >
                                    <div>
                                        <AvatarContainer
                                            key={'userAvatar' + index}
                                            credentials={{
                                                firstName: member.firstName ?? '',
                                                lastName: member.lastName ?? '',
                                                email: member.email ?? '',
                                            }}
                                            size="small"
                                        />
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </StyledUserAvatars>
                    <StyledInfo>
                        {ownedDefaultOrganization && <TemplateStyledChip label="Personal team" />}
                        <DataUsageIcon />
                        <Typography variant="body2" color="text.secondary">
                            {planName}
                        </Typography>
                    </StyledInfo>
                </StyledMediaContainer>
            </>
        );
    };

    return (
        <>
            <StyledCard elevation={1} onClick={() => onOpen(uid)}>
                {ownedDefaultOrganization ? (
                    <StyledInfoIcon tooltip={defaultOrganizationTooltip} />
                ) : (
                    <ThreeDotsButton
                        uid={uid}
                        actions={[
                            {
                                name: 'Delete',
                                onSelect: onDelete,
                                icon: <DeleteOutlineOutlinedIcon />,
                            },
                        ]}
                    />
                )}

                {avatar ? (
                    <StyledOrgImageBox>
                        <img src={`data:image/jpeg;base64,${avatar}`} width={54} height={54} alt={name} />
                    </StyledOrgImageBox>
                ) : (
                    <OrgAvatarContainer style="square" name={name}></OrgAvatarContainer>
                )}
                <StyledCardContent>
                    <SelectorItemTitle title={name} />
                    <StyledDate>
                        <CalendarTodayIcon />
                        <Typography variant="body2" color="text.secondary">
                            {new Date(createdDate).toLocaleDateString()}
                        </Typography>
                    </StyledDate>
                </StyledCardContent>
                <CardMedia component={MediaContainer} />
            </StyledCard>
        </>
    );
};
