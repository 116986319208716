import { filter, map, Observable, withLatestFrom } from 'rxjs';
import {
    invocationsLimitProcessAction$,
    loadingWorkspaceEnvironments$,
    loadingWorkspaceResources$,
    selectedEnvironmentUid$,
    selectedWorkspaceDeployments$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceEnvironments$,
    selectedWorkspaceReadOnlyMode$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from '.';
import { getWorkspaceDeployments } from '../../data/deployment';
import { getWorkspacePackages } from '../../data/package-manager';
import { getReadmeFile } from '../../data/readme';
import { getWorkspaceEnvironments, getWorkspaceResources } from '../../data/workspace';
import { getLoggedInUserWorkspaces } from '../../data/workspaces';
import { addScriptModulesEditorAction$, updateApiModulesEditorAction$ } from '../editor/editor';
import { ApiHandlerModel } from '../editor/types';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { packageUpgradesDialogOpen$, selectedWorkspacePackages$ } from './packages';
import {
    parsedReadmeFileContent$,
    readmeFileHasUnsavedChanges$,
    savedReadmeFileDetails$,
    unsavedReadmeFileDetails$,
} from './readme';
import { externallyTriggerableScripts$, scriptHasUnsavedChanges$ } from './script';
import {
    environmentVariablesFormErrors$,
    environmentVariablesHaveUnsavedChanges$,
    savedEnvironmentVariables$,
    unsavedEnvironmentVariables$,
} from './environment-variable';
import { getEnvironmentVariables } from '../../data/environment-variable';
import { ulid } from 'ulid';
import { loggedInUserWorkspaces$ } from '../workspaces';
import { getSanitizedParsedMarkdown } from '../../utils/readme';

export const loadWorkspaceResourcesWhenEventIsEmitted = (observable: Observable<unknown>): void => {
    observable
        .pipe(
            withLatestFrom(selectedWorkspaceUid$, selectedEnvironmentUid$),
            map(async ([, workspaceUid, environmentUid]) => {
                if (workspaceUid && environmentUid) {
                    await loadWorkspaceResources(workspaceUid, environmentUid);
                }
            })
        )
        .subscribe();
};

export const loadWorkspaceEnvironmentsWhenEventIsEmitted = (observable: Observable<unknown>): void => {
    observable
        .pipe(
            withLatestFrom(selectedWorkspaceUid$, selectedWorkspaceReadOnlyMode$),
            filter(
                ([, selectedWorkspaceUid, selectedWorkspaceReadOnlyMode]) =>
                    !!selectedWorkspaceUid && !selectedWorkspaceReadOnlyMode
            ),
            map(async ([, workspaceUid]) => {
                if (workspaceUid) {
                    await loadWorkspaceEnvironments(workspaceUid);
                }
            })
        )
        .subscribe();
};

export const loadWorkspaceDeploymentsWhenEventIsEmitted = (observable: Observable<unknown>): void => {
    observable
        .pipe(
            withLatestFrom(selectedWorkspaceUid$, selectedWorkspaceReadOnlyMode$),
            filter(
                ([, selectedWorkspaceUid, selectedWorkspaceReadOnlyMode]) =>
                    !!selectedWorkspaceUid && !selectedWorkspaceReadOnlyMode
            ),
            map(async ([, workspaceUid]) => {
                if (workspaceUid) {
                    await loadWorkspaceDeployments(workspaceUid);
                }
            })
        )
        .subscribe();
};

export const loadWorkspaces = async (
    organizationUid: string,
    bypassCache = true,
    source?: 'workspaces'
): Promise<void> => {
    if (loggedInUserWorkspaces$.value.length === 0 || bypassCache) {
        const workspaces = await getLoggedInUserWorkspaces(organizationUid, source);
        loggedInUserWorkspaces$.next(workspaces);
    }
};

export const loadWorkspaceResources = async (workspaceUid: string, environmentUid: string): Promise<void> => {
    if (!loadingWorkspaceResources$.value) {
        try {
            packageUpgradesDialogOpen$.next(false);
            loadingWorkspaceResources$.next(true);

            const selectedWorkspaceReadOnlyMode = selectedWorkspaceReadOnlyMode$.value;

            const workspaceResources = await getWorkspaceResources(workspaceUid, environmentUid);
            const eventListeners = workspaceResources.eventListeners
                .map((listener) => listener.script?.uid)
                .filter((listener): listener is string => !!listener);
            const scheduledTriggers = workspaceResources.scheduledTriggers
                .map((trigger) => trigger.script?.uid)
                .filter((trigger): trigger is string => !!trigger);

            externallyTriggerableScripts$.next(Array.from(new Set([...eventListeners, ...scheduledTriggers])));
            const workspacePackages = selectedWorkspaceReadOnlyMode ? [] : await getWorkspacePackages(workspaceUid);

            invocationsLimitProcessAction$.next(workspaceResources.invocationsUsage);
            selectedWorkspaceResources$.next(workspaceResources);
            selectedWorkspacePackages$.next(workspacePackages);

            const apiHandlerModels = workspaceResources.apiHandlers
                .map((apiHandler) => {
                    const library =
                        apiHandler.selectedApiHandlerLibrary !== null
                            ? apiHandler.selectedApiHandlerLibrary
                            : apiHandler.libraries.find(
                                  (lib) => lib.recommended === true && lib.name.startsWith('@managed-api/')
                              ) || apiHandler.libraries.find((lib) => lib.name.startsWith('@managed-api/'));
                    if (library?.name && library?.namespace) {
                        return {
                            path: `api/${apiHandler.path ?? 'index'}`,
                            handlerId: apiHandler.uid,
                            spec: {
                                apiModule: library.name,
                                namespace: library.namespace,
                            },
                        };
                    }
                    return null;
                })
                .filter((apiHandler): apiHandler is ApiHandlerModel => !!apiHandler);

            updateApiModulesEditorAction$.next({ apiHandlers: apiHandlerModels });
            addScriptModulesEditorAction$.next({
                scripts: workspaceResources.scripts,
                environmentUid,
                workspaceUid,
            });
        } catch (e) {
            console.error('Failed to load workspace resources', e);

            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    'Failed to load workspace resources, try refreshing the browser, if the issue persists please contact support.',
                toastOptions: {
                    autoClose: false,
                },
            });
        }
        loadingWorkspaceResources$.next(false);
    }
};

export const loadWorkspaceEnvironments = async (workspaceUid: string): Promise<void> => {
    try {
        loadingWorkspaceEnvironments$.next(true);

        const workspaceEnvironments = await getWorkspaceEnvironments(workspaceUid);

        selectedWorkspaceEnvironments$.next(workspaceEnvironments);

        let selectedEnvironmentUid = selectedEnvironmentUid$.value;

        if (!selectedEnvironmentUid) {
            const defaultEnvironment = workspaceEnvironments.find((env) => env.default);
            selectedEnvironmentUid = defaultEnvironment?.uid;

            selectedEnvironmentUid$.next(selectedEnvironmentUid);
        }

        const selectedEnvironment = workspaceEnvironments.find((env) => env.uid === selectedEnvironmentUid);

        selectedWorkspaceEnvironment$.next(selectedEnvironment);
    } catch (e) {
        console.error('Failed to load workspace environments', e);

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                'Failed to load workspace environments, try refreshing the browser, if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });
    }
    loadingWorkspaceEnvironments$.next(false);
};

const loadWorkspaceDeployments = async (workspaceUid: string): Promise<void> => {
    try {
        const workspaceDeployments = await getWorkspaceDeployments(workspaceUid);

        selectedWorkspaceDeployments$.next(workspaceDeployments);
    } catch (e) {
        console.error('Failed to load workspace deployments', e);

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                'Failed to load workspace deployments, try refreshing the browser, if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });
    }
};

export const workspaceHasUnsavedChanges = (): boolean => {
    const scripts = selectedWorkspaceResources$.value.scripts;
    const readmeFile = selectedWorkspaceResources$.value.readmeFile;
    const readmeFileUid = readmeFile?.uid;

    const hasUnsavedScripts = scripts.some((script) => scriptHasUnsavedChanges$.value[script.uid]);
    const hasUnsavedReadmeFile = !!readmeFileHasUnsavedChanges$.value[readmeFileUid ?? ''];
    const hasUnsavedEnvironmentVariables = environmentVariablesHaveUnsavedChanges$.value;

    return hasUnsavedScripts || hasUnsavedReadmeFile || hasUnsavedEnvironmentVariables;
};

export const loadReadmeFile = async (fileUid: string, workspaceUid: string, environmentUid: string): Promise<void> => {
    const readmeKey = `${fileUid}_${environmentUid}`;
    let readmeFile = unsavedReadmeFileDetails$.value[readmeKey];

    if (!readmeFile) {
        readmeFile = await getReadmeFile(fileUid, workspaceUid, environmentUid);

        savedReadmeFileDetails$.next({ ...savedReadmeFileDetails$.value, [readmeKey]: readmeFile });
        unsavedReadmeFileDetails$.next({ ...unsavedReadmeFileDetails$.value, [readmeKey]: readmeFile });
    }

    parsedReadmeFileContent$.next({
        ...parsedReadmeFileContent$.value,
        [readmeKey]: getSanitizedParsedMarkdown(readmeFile.content),
    });
};

export const loadEnvironmentVariables = async (workspaceUid: string, environmentUid: string): Promise<void> => {
    environmentVariablesFormErrors$.next(undefined);

    const unsavedEnvironmentVariables = unsavedEnvironmentVariables$.value ?? [];
    const environmentVariables = await getEnvironmentVariables(workspaceUid, environmentUid);

    const remappedVariables = environmentVariables.map(({ children, key, ...ev }, i) => ({
        ...ev,
        childVariables: children?.map(({ key, ...cv }, ix) => ({
            ...cv,
            expanded: unsavedEnvironmentVariables[i]?.childVariables?.[ix]?.expanded,
            hasBeenEdited: true,
            id: ulid(),
            keyName: key,
        })),
        expanded: unsavedEnvironmentVariables[i]?.expanded,
        hasBeenEdited: true,
        id: ulid(),
        keyName: key,
    }));

    unsavedEnvironmentVariables$.next(remappedVariables);
    savedEnvironmentVariables$.next(remappedVariables);
};
